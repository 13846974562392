import { Tooltip, Zoom, styled, tooltipClasses } from "@mui/material";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    TransitionComponent={Zoom}
  />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: 0,
    maxWidth: "none",
    margin: "0 16px 0",
    background: "transparent",
  },
}));

export default HtmlTooltip;
