import { EMPLOYEE_ROLES } from "constants/constants";
import config from "../config";
import endpoints from "../constants/endpoints";
import { levoHttp } from "../utils/http";
import { create as createQueryParam } from "../utils/queryParam";

/**
 * Fetches all the applications.
 *
 * @param {Object} query
 * @returns {Promise}
 */
export const fetch = async (query) => {
  const param = createQueryParam(query);
  const url = `${config.authBaseUrl}${endpoints.applications}${param}`;

  return levoHttp.get(url);
};

/**
 * Posts the Interview Decision of an Application.
 *
 * @param {Number} applicationId
 * @param {Object} payload
 * @returns {Promise}
 */
export const postApplicationInterview = (payload) => {
  const url = `${config.authBaseUrl}${endpoints.applicationInterview}`;

  return levoHttp.post(url, payload);
};

/**
 * No Answer for an Application's Interview Decision Form.
 *
 * @param {Number} id The application ID.
 */
export const unanswerApplicationInterview = (id) => {
  const url = `${config.authBaseUrl}${endpoints.applicationUnanswer}`;

  return levoHttp.put(url, { id });
};

/**
 * Fetch the documents for an application.
 *
 * @param {Number} id
 */
export const fetchDocuments = (id) => {
  const param = createQueryParam({ application_id: id });
  const url = `${config.authBaseUrl}${endpoints.applicationDocuments}${param}`;

  return levoHttp.get(url);
};

/**
 * Approves the documents under an application.
 *
 * @param {Number} applicationId
 * @param {Array} documents
 *
 * @returns {Promise}
 */
export const approveDocuments = (applicationId, documents = []) => {
  const param = createQueryParam({ application_id: applicationId });
  const url = `${config.authBaseUrl}${endpoints.approveDocuments}${param}`;

  return levoHttp.put(url, { document_id: documents });
};

/**
 * Request changes in the document for an application.
 *
 * @param {Number} applicationId
 * @param {Array} documents
 * @param {String} message
 *
 * @returns {Promise}
 */
export const requestChangeDocuments = (
  applicationId,
  documents = [],
  message = ""
) => {
  const param = createQueryParam({ application_id: 888888 });
  const url = `${config.authBaseUrl}${endpoints.applicationDocuments}${param}`;

  return levoHttp.put(url, { document_id: documents, message });
};

/**
 * Reviews the documents under an application.
 *
 * @param {Number} applicationId
 * @param {Array} documents
 *
 * @returns {Promise}
 */
export const reviewDocuments = (
  applicationId,
  documentIdsToBeApproved = [],
  documentIdsToBeChangeRequest = []
) => {
  const url = `${config.authBaseUrl}${endpoints.reviewDocuments}`;

  return levoHttp.put(url, {
    application_id: applicationId,
    approve_document_id: documentIdsToBeApproved,
    change_document_id: documentIdsToBeChangeRequest,
  });
};

/**
 * Performs the action on an application.
 *
 * @param {Number} applicationId
 * @param {String} action
 *
 * @returns {Promise}
 */
export const performAction = (applicationId, action) => {
  const url = `${config.authBaseUrl}${endpoints.applicationActions}`;
  const payload = {
    application_id: applicationId,
    taken_action: action,
  };

  return levoHttp.post(url, payload);
};

/**
 * Fetch the application jobs.
 *
 * @returns {Promise}
 */
export const fetchJobs = () => {
  const url = `${config.authBaseUrl}${endpoints.applicationJobs}`;

  return levoHttp.get(url);
};

/**
 * Method to add a Caregiver.
 *
 * @param {Object} payload
 * @returns {Promise}
 */
export const postApplicationCaregiver = (payload = {}) => {
  const url = `${config.authBaseUrl}${endpoints.applicationCaregiver}`;

  return levoHttp.post(url, payload);
};

/**
 * Method to fetch the application caregiver's details.
 *
 * @param {Object} query
 * @returns {Promise}
 */
export const fetchApplicationCaregiver = (query = {}) => {
  const param = createQueryParam(query);
  const url = `${config.authBaseUrl}${endpoints.applicationCaregiverDetails}${param}`;

  return levoHttp.get(url);
};

/**
 * Method to update the application caregiver's details.
 *
 * @param {Object} payload
 *
 * @returns {Promise}
 */
export const updateApplicationCaregiver = (payload = {}) => {
  const url = `${config.authBaseUrl}${endpoints.updateApplicationCaregiver}`;

  return levoHttp.put(url, payload);
};

/**
 * Method to update status of a Caregiver.
 *
 * @param {Object} payload
 * @returns {Promise}
 */
export const updateApplicationCaregiverStatus = (payload = {}) => {
  const url = `${config.authBaseUrl}${endpoints.applicationChangeStatus}`;

  return levoHttp.post(url, payload);
};

/**
 * Method to find the caregivers of an application.
 *
 * @param {Object} query
 * @returns {Promise}
 */
export const fetchApplicationCaregivers = (query = {}) => {
  const param = createQueryParam(query);
  const url = `${config.authBaseUrl}${endpoints.findApplicationCaregivers}${param}`;

  return levoHttp.get(url);
};

/**
 * Fetch the user for an application.
 *
 * @returns {Promise}
 */
export const fetchUser = (userRole = "") => {
  let endpoint;

  if (userRole === "agency") {
    endpoint = endpoints.agencyUser;
  } else if (Object.keys(EMPLOYEE_ROLES).includes(userRole)) {
    endpoint = endpoints.caregiverRoleProfile;
  } else {
    endpoint = endpoints.applicationUser;
  }

  const url = `${config.authBaseUrl}${endpoint}`;

  return levoHttp.get(url);
};
