/**
 * Set the item with the specified key in localStorage.
 *
 * @param {String} key
 * @param {String|Array|Number|Object|Boolean} value
 */
export function set(key, value) {
  if (!window.localStorage || !window.JSON || !key) {
    return;
  }

  localStorage.setItem(key, JSON.stringify(value));
}

/**
 * Get the item with specified key from localStorage.
 *
 * @param {String} key
 * @returns {String|Array|Number|Object|Boolean}
 */
export function get(key) {
  if (!window.localStorage || !window.JSON || !key) {
    return null;
  }

  const item = localStorage.getItem(key);

  if (!item) {
    return null;
  }

  return JSON.parse(item);
}

/**
 * Remove the item from the localStorage with specified key.
 *
 * @param  {String}  key
 */
export function remove(key) {
  if (!window.localStorage || !window.JSON || !key) {
    return;
  }

  localStorage.removeItem(key);
}
