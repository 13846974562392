"use client";
/*
 * Documentation:
 * Profile Slideout — https://app.subframe.com/library?component=Profile+Slideout_ec70169d-e1e2-49ff-9716-0ce24a3c4413
 * Icon Button — https://app.subframe.com/library?component=Icon+Button_af9405b1-8c54-4e01-9786-5aad308224f6
 * Avatar — https://app.subframe.com/library?component=Avatar_bec25ae6-5010-4485-b46b-cf79e3943ab2
 * Profile Detail Item — https://app.subframe.com/library?component=Profile+Detail+Item_8e9dfadf-1aba-4188-aaae-e694c3b0d82a
 * Button — https://app.subframe.com/library?component=Button_3b777358-b86b-40af-9327-891efc6826fe
 * Alert — https://app.subframe.com/library?component=Alert_3a65613d-d546-467c-80f4-aaba6a7edcd5
 * Progress — https://app.subframe.com/library?component=Progress_60964db0-a1bf-428b-b9d5-f34cdf58ea77
 * Sentiment Badge — https://app.subframe.com/library?component=Sentiment+Badge_e1962f43-fe6e-498c-9b8e-21e501824ce3
 * Accordion — https://app.subframe.com/library?component=Accordion_d2e81e20-863a-4027-826a-991d8910efd9
 * History Survey Question — https://app.subframe.com/library?component=History+Survey+Question_29773008-8eb1-4936-93ea-8e1e0552008a
 * Profile Action Bar — https://app.subframe.com/library?component=Profile+Action+Bar_a7126f05-9b5e-4b90-a0f2-3bf7f4939d0f
 */

import React from "react";
import * as SubframeCore from "@subframe/core";

interface ContentProps
  extends React.ComponentProps<typeof SubframeCore.Drawer.Content> {
  children?: React.ReactNode;
  className?: string;
}

const Content = React.forwardRef<HTMLElement, ContentProps>(function Content(
  { children, className, ...otherProps }: ContentProps,
  ref
) {
  return (
    <SubframeCore.Drawer.Content asChild={true} {...otherProps}>
      <div
        className={SubframeCore.twClassNames(
          "flex h-full w-full sm:w-[550px] flex-col items-start gap-2 overflow-hidden rounded bg-default-background",
          className
        )}
        ref={ref as any}
      >
        {children}
      </div>
    </SubframeCore.Drawer.Content>
  );
});

interface ProfileSlideoutRootProps
  extends React.ComponentProps<typeof SubframeCore.Drawer.Root> {
  children?: React.ReactNode;
  hidden?: boolean;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  className?: string;
}

const ProfileSlideoutRoot = React.forwardRef<
  HTMLElement,
  ProfileSlideoutRootProps
>(function ProfileSlideoutRoot(
  {
    children,
    hidden = false,
    className,
    ...otherProps
  }: ProfileSlideoutRootProps,
  ref
) {
  return (
    <SubframeCore.Drawer.Root asChild={true} {...otherProps}>
      <div
        className={SubframeCore.twClassNames(
          "group/ec70169d flex h-full w-full flex-col items-end justify-center gap-2 bg-[#0000004d]",
          { hidden: hidden },
          className
        )}
        ref={ref as any}
      >
        {children}
      </div>
    </SubframeCore.Drawer.Root>
  );
});

export const ProfileSlideout = Object.assign(ProfileSlideoutRoot, {
  Content,
});
