const endpoints = {
  caregiver: "/v1/auth/caregiver/me",
  login: "/v1/auth/caregiver/login-sms",
  verifyOTP: "/v1/auth/caregiver/verify-sms",
  peers: "/v1/auth/caregiver/employee/recognition/peers",
  postRecognition: "/v1/auth/caregiver/employee/recognition",
  recognitionCategory:
    "/v1/auth/caregiver/employee/recognition/recognition-category",
  mySupervisor: "/v1/auth/caregiver/employee/recognition/supervisor",
  providedRecognitions: "/v1/auth/caregiver/employee/recognition/mine",
  directReports: "/v1/auth/caregiver/employee/recognition/direct-report",
  receivedRecognitions: "/v1/auth/caregiver/employee/recognition/my-recognized",
};

export default endpoints;
