import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { LoadingButton } from "@mui/lab";
import MuiAlert from "@mui/material/Alert";
import { FormHelperText, Snackbar } from "@mui/material";

import OtpInput from "react-otp-input";

import Levo from "../../assets/images/logoV2.svg";
import Login from "../../assets/images/login-image.svg";

import { verifyOTP } from "../../services/recognition";

import { set } from "../../../../utils/localStorage";
import { formatPhoneNumber } from "../../../../utils/phoneNumber";
import { isEmpty as isStringEmpty } from "../../../../utils/string";

import { ERROR_CODE } from "../../../../constants/constants";

import "./RecognitionOTPVerification.css";

export default function RecognitionOTPVerification() {
  const OTP_NUMBER_OF_INPUT = 6;

  const [searchParams] = useSearchParams();

  const [OTP, setOTP] = useState();
  const [mobileNumber, setMobileNumber] = useState(
    searchParams.get("mobileNumber")
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [isVerifyingOTP, setIsVerifyingOTP] = useState(false);
  const [isErrorMessageShown, setIsErrorMessageShown] = useState(false);
  const [methodId, setMethodId] = useState(searchParams.get("methodId"));
  const [hasTriedFormSubmission, setHasTriedFormSubmission] = useState(false);

  const navigate = useNavigate();

  /**
   * Check if the OTP Verification button is disabled.
   *
   * @param {String} OTP
   * @returns {Boolean}
   */
  const isVerifyButtonDisabled = (OTP = "") => {
    return isStringEmpty(OTP) || OTP.length !== OTP_NUMBER_OF_INPUT;
  };

  /**
   * Verifies the OTP for login.
   *
   * @param {String} methodId
   * @param {String} OTP
   * @returns {Promise}
   */
  const verifyOTPAsync = async (methodId, OTP) => {
    try {
      return await verifyOTP({ method_id: methodId, code: OTP });
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/recognition");
      }

      return { hasError: true, error };
    }
  };

  /**
   * Handles OTP verification.
   */
  const handleVerifyOTP = async () => {
    setHasTriedFormSubmission(true);

    if (isStringEmpty(OTP)) {
      return;
    }

    setIsVerifyingOTP(true);
    const response = await verifyOTPAsync(methodId, OTP);

    setIsVerifyingOTP(false);

    if (response?.hasError) {
      setErrorMessage(
        `Error while verifying OTP: ${response?.error?.response?.data?.message}`
      );
      setIsErrorMessageShown(true);
    }

    const token = response?.data?.result?.session_jwt;

    if (!isStringEmpty(token)) {
      set("token", token);
      // NOTE: Storing the session token for both Access and Refresh Token.
      // Need separate tokens from the B.E.
      set("refreshToken", token);
      set("mobileNumber", mobileNumber);

      navigate("/recognition/me");
    }
  };

  /**
   * Renders the error snackbar.
   *
   * @returns {Component}
   */
  const renderErrorSnackbar = () => (
    <Snackbar
      open={isErrorMessageShown}
      autoHideDuration={6000}
      onClose={() => setIsErrorMessageShown(false)}
    >
      <MuiAlert
        onClose={() => setIsErrorMessageShown(false)}
        severity="error"
        sx={{ width: "100%" }}
      >
        {errorMessage}
      </MuiAlert>
    </Snackbar>
  );

  return (
    <>
      <div className="main-outer-wrapper">
        <div className="logo-wrapper">
          <img src={Levo} alt="Levo" onClick={() => navigate("/recognition")} />
        </div>

        <div className="tag-line-wrapper">
          <h1>Shout Outs</h1>
          <p>Let's build each other up together!</p>
        </div>

        <div className="login-banner-wrapper">
          <img src={Login} alt="login-banner" />
        </div>

        <div className="otp-wrapper">
          <div className="info-wrapper">
            <h2>OTP Verification</h2>
            <p>
              Enter the OTP sent to{" "}
              <strong>{formatPhoneNumber(mobileNumber)}</strong>.
            </p>
          </div>
          <div className="input-wrapper">
            <OtpInput
              value={OTP}
              onChange={setOTP}
              numInputs={OTP_NUMBER_OF_INPUT}
              renderSeparator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
              containerStyle={{
                width: "100%",
                justifyContent: "space-between",
                gap: "4px",
              }}
              inputStyle={{
                width: "36px",
                height: "36px",
                borderColor: "rgba(0,0,0,0.3)",
              }}
              inputType="number"
            />
            {hasTriedFormSubmission && isStringEmpty(OTP) ? (
              <FormHelperText error>OTP is required.</FormHelperText>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="button-wrapper">
          <LoadingButton
            loading={isVerifyingOTP}
            variant="contained"
            onClick={handleVerifyOTP}
            sx={{
              width: "100%",
              height: "51px",
              backgroundColor: "var(--md-ref-palette-primary60)",
            }}
          >
            VERIFY
          </LoadingButton>
        </div>
      </div>
      {renderErrorSnackbar()}
    </>
  );
}
