import { Skeleton } from "@mui/material";
import * as SubframeCore from "@subframe/core";
import { ERROR_CODE } from "constants/constants";
import { fetchSupervisorMetadata } from "modules/Retention/services/retention";
import { renderSentimentBadgeWithScore } from "modules/Retention/utils/sentiment";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Progress } from "subframe";
import { isNumber } from "utils/number";
import { isEmpty as isStringEmpty } from "utils/string";

const SupervisorWithMetadata = ({ name, id }) => {
  const [supervisorMetadata, setSupervisorMetadata] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isMetaDataOpen, setIsMetaDataOpen] = useState(false);
  const navigate = useNavigate();
  const hoverCardRef = useRef(null);
  const handleClickOutside = (event) => {
    if (hoverCardRef.current && !hoverCardRef.current.contains(event.target)) {
      setIsMetaDataOpen(false);
    }
  };

  useEffect(() => {
    if (isMetaDataOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMetaDataOpen]);

  /**
   * Method to fetch the supervisor metadata.
   *
   * @param {Number} id
   * @returns {Promise}
   */
  const fetchSupervisorMetadataAsync = async (id) => {
    try {
      return await fetchSupervisorMetadata({ supervisor_id: id });
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  // Fetch metadata when the popover is open
  const handleFetchSupervisorMetadata = async () => {
    if (isLoading) return;
    setIsLoading(true);

    const response = await fetchSupervisorMetadataAsync(id);

    setIsLoading(false);

    if (response?.hasError) {
      setSupervisorMetadata({});
      return;
    }

    setSupervisorMetadata(response?.result?.data);
  };

  // needed because of how subframe is handling events ({passive: true} -> cannot edit inside subframe)
  useEffect(() => {
    const element = hoverCardRef.current;
    if (!element) return;

    const handleEvent = (e) => {
      e.stopPropagation();
      e.preventDefault();
      setIsMetaDataOpen(true);
      handleFetchSupervisorMetadata();
    };

    // prevent bubbling up of parent's click func
    const events = ["touchstart"];
    events.forEach((eventName) => {
      element.addEventListener(eventName, handleEvent, {
        passive: false,
        capture: true,
      });
    });

    // Cleanup
    return () => {
      events.forEach((eventName) => {
        element.removeEventListener(eventName, handleEvent);
      });
    };
  }, []);

  const handleOpenChange = (isOpen) => {
    if (!isOpen) {
      setIsMetaDataOpen(false);
      return;
    }
    setIsMetaDataOpen(true);
    handleFetchSupervisorMetadata();
  };

  return (
    <div className="inline-flex touch-manipulation" ref={hoverCardRef}>
      <SubframeCore.HoverCard.Root
        open={isMetaDataOpen}
        onOpenChange={handleOpenChange}
      >
        <SubframeCore.HoverCard.Trigger asChild={true}>
          <div className="flex items-center justify-center gap-2 p-1 overflow-hidden pb-0.5 border-b border-dashed border-current cursor-pointer">
            <SubframeCore.Icon
              className="text-default-font text-body-bold font-body-bold"
              name="FeatherUser"
            />
            <span className="flex items-center text-default-font text-label font-label first-letter:uppercase">
              {isStringEmpty(name) ? "-" : name}
            </span>
          </div>
        </SubframeCore.HoverCard.Trigger>
        <SubframeCore.HoverCard.Portal>
          <SubframeCore.HoverCard.Content
            side="bottom"
            align="start"
            sideOffset={4}
            asChild={true}
          >
            <div className="flex grow shrink-0 basis-0 flex-col items-start gap-2 rounded-md border border-solid border-neutral-border bg-default-background px-3 py-3 shadow-lg">
              <div className="flex w-full flex-col items-start gap-1">
                <div className="flex items-center justify-center gap-1">
                  <span className="text-subheader font-subheader text-default-font">
                    {isStringEmpty(name) ? "-" : name}
                  </span>
                </div>
                <div className="flex items-center gap-1">
                  <SubframeCore.Icon
                    className="text-[12px] font-[400] leading-[16px] text-brand-700"
                    name="FeatherInfo"
                  />
                  <span className="text-[11px] font-[400] leading-[20px] text-brand-700">
                    Data reflects active employees only, excluding terminated
                    ones.
                  </span>
                </div>
              </div>
              <div className="flex h-px w-full flex-none flex-col items-center gap-2 bg-neutral-200" />
              <div className="flex w-full flex-col items-start gap-4">
                <div className="flex w-full items-start gap-2 sm:gap-4">
                  <div className="flex w-1/2 xs:w-48 flex-none flex-col items-start gap-1">
                    <span className="text-label-bold font-label-bold text-subtext-color">
                      No. of Employees
                    </span>
                    {isLoading ? (
                      <Skeleton variant="rounded" width="100%" height="35px" />
                    ) : (
                      <div className="flex items-center gap-1">
                        <span className="text-[14px] font-[700] leading-[20px] text-default-font">
                          {isNumber(
                            supervisorMetadata?.total_supervisor_employees
                          )
                            ? supervisorMetadata.total_supervisor_employees
                            : "-"}
                        </span>
                        {isNumber(
                          supervisorMetadata?.supervisor_employee_percentage
                        ) && (
                          <div className="flex items-center rounded-md bg-neutral-100 px-1 py-0.5">
                            <span className="text-[10px] font-[500] leading-[16px] text-default-font">
                              {
                                supervisorMetadata.supervisor_employee_percentage
                              }
                              % of total company
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="flex w-1/2 xs:w-48 flex-none flex-col items-start gap-1">
                    <span className="text-label-bold font-label-bold text-subtext-color">
                      No. of Escalated Employees
                    </span>
                    {isLoading ? (
                      <Skeleton variant="rounded" width="100%" height="35px" />
                    ) : (
                      <div className="flex items-center gap-1">
                        <span className="text-[14px] font-[700] leading-[20px] text-error-800">
                          {isNumber(
                            supervisorMetadata?.total_supervisor_escalated_employees
                          )
                            ? supervisorMetadata.total_supervisor_escalated_employees
                            : "-"}
                        </span>
                        {isNumber(
                          supervisorMetadata?.supervisor_escalated_percentage
                        ) && (
                          <div className="flex items-center rounded-md bg-neutral-100 px-1 py-0.5">
                            <span className="text-[10px] font-[500] leading-[16px] text-default-font">
                              {
                                supervisorMetadata.supervisor_escalated_percentage
                              }
                              % of total employees
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex w-full items-start gap-2 sm:gap-4">
                  <div className="flex w-1/2 xs:w-48 flex-none flex-col items-start gap-1">
                    <span className="text-label-bold font-label-bold text-subtext-color">
                      Employees' Sentiment
                    </span>
                    {isLoading ? (
                      <Skeleton variant="rounded" width="100%" height="35px" />
                    ) : (
                      renderSentimentBadgeWithScore(
                        supervisorMetadata?.supervisor_employees_sentiment?.[
                          "Sentiment Score"
                        ],
                        supervisorMetadata?.supervisor_employees_sentiment?.[
                          "Overall Sentiment"
                        ],
                        supervisorMetadata?.supervisor_employees_sentiment?.[
                          "Response Rate"
                        ]
                      )
                    )}
                  </div>
                  <div className="flex w-1/2 xs:w-48 flex-none flex-col items-start gap-1">
                    <div className="flex items-start gap-8">
                      <span className="text-label-bold font-label-bold text-subtext-color">
                        Employees' Survey Response
                      </span>
                    </div>
                    {isLoading ? (
                      <Skeleton variant="rounded" width="100%" height="35px" />
                    ) : (
                      <div className="flex w-full items-center gap-2">
                        <div className="flex grow shrink-0 basis-0 flex-col items-start gap-2">
                          <span className="text-[10px] font-[500] leading-[16px] text-default-font">
                            {isNumber(
                              supervisorMetadata
                                ?.supervisor_employees_sentiment?.[
                                "Survey Completed"
                              ]
                            )
                              ? supervisorMetadata
                                  .supervisor_employees_sentiment[
                                  "Survey Completed"
                                ]
                              : "-"}{" "}
                            Completed /{" "}
                            {isNumber(
                              supervisorMetadata
                                ?.supervisor_employees_sentiment?.[
                                "Survey Sent"
                              ]
                            )
                              ? supervisorMetadata
                                  .supervisor_employees_sentiment["Survey Sent"]
                              : "-"}{" "}
                            Sent{" "}
                            {isNumber(
                              supervisorMetadata
                                ?.supervisor_employees_sentiment?.[
                                "Response Rate"
                              ]
                            ) &&
                              ` (${supervisorMetadata?.supervisor_employees_sentiment?.["Response Rate"]}%)`}
                          </span>
                          <Progress
                            value={
                              supervisorMetadata
                                ?.supervisor_employees_sentiment?.[
                                "Response Rate"
                              ]
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="flex items-start gap-4">
                  <div className="flex w-48 flex-none flex-col items-start gap-1">
                    <span className="text-label-bold font-label-bold text-subtext-color">
                      Avg. Employee Tenure
                    </span>
                    {isLoading ? (
                      <Skeleton variant="rounded" width="100%" height="35px" />
                    ) : (
                      <span className="text-[14px] font-[700] leading-[20px] text-default-font">
                        {isStringEmpty(
                          supervisorMetadata?.supervisor_employees_average_tenure
                        )
                          ? "-"
                          : supervisorMetadata.supervisor_employees_average_tenure}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </SubframeCore.HoverCard.Content>
        </SubframeCore.HoverCard.Portal>
      </SubframeCore.HoverCard.Root>
    </div>
  );
};

export default SupervisorWithMetadata;
