import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControlLabel,
  Snackbar,
  Switch,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";

import "./RetentionDashboard.css";

import { ERROR_CODE } from "../../constants/constants";
import { EVENT_TYPES } from "../../constants/mixpanel";

import EmployeeDataGrid from "../EmployeeDataGrid/EmployeeDataGrid";

import {
  fetchBranchesByTenureRegionWithStats as fetchBranchesByTenureRegionWithEmployeesStats,
  fetchBranchesWithStats as fetchBranchesWithEmployeeStats,
  fetchCategoriesByTenureJobTypeWithStats as fetchCategoriesByTenureJobTypeWithEmployeesStats,
  fetchCategoriesWithStats as fetchCategoriesWithEmployeesStats,
  fetchEmployeesByQuestionWithStats,
  fetchEmployees_job,
  fetchEmployees_region,
  fetchEmployees_tenureJob,
  fetchEmployees_tenureRegion,
  fetchJobsByQuestionCategoryWithStats as fetchJobsByQuestionCategoryWithEmployeesStats,
  fetchJobsByTenureWithStats as fetchJobsByTenureWithEmployeesStats,
  fetchJobsWithStats as fetchJobsWithEmployeesStats,
  fetchQuestionsByJobWithStats as fetchQuestionsByJobWithEmployeeStats,
  fetchQuestionsWithStats as fetchQuestionsWithEmployeesStats,
  fetchRegionsByTenureWithStats as fetchRegionsByTenureWithEmployeesStats,
  fetchRegionsWithStats as fetchRegionsWithEmployeesStats,
  fetchTenuresWithStats as fetchTenuresWithEmployeesStats,
} from "../../services/retention/employees";

import { isNumber } from "../../utils/number";
import { isEmpty as isObjectEmpty } from "../../utils/object";
import { initializeSentimentSummary } from "../../utils/sentiment";

import Job from "../../assets/images/retention/job.png";
import Location from "../../assets/images/retention/location.png";
import Question from "../../assets/images/retention/question.png";
import Tenure from "../../assets/images/retention/tenure.png";

import { mixpanelTrackEvent } from "../../services/mixpanel";

import useUser from "hooks/userHooks";
import { useLaptopLayout } from "../../hooks/mediaQueryHooks";

// NOTE: Temporary component to show old Retention Dashboard information in new Dashboard's Reports page.
// NOTE: Intended for Graham.

export default function RetentionDashboardV2() {
  const [regionPanel, setRegionPanel] = useState({
    data: [],
    icon: Location,
    value: "region",
    label: "Region",
    isLoadingData: false,
    currentView: "region",
    query: {},
  });

  const [jobPanel, setJobPanel] = useState({
    data: [],
    icon: Job,
    value: "job",
    label: "Job",
    isLoadingData: false,
    currentView: "job",
    query: {},
  });

  const [questionCategoryPanel, setQuestionCategoryPanel] = useState({
    data: [],
    icon: Question,
    value: "questionCategory",
    label: "Question Category",
    isLoadingData: false,
    currentView: "questionCategory",
    query: {},
  });

  const [tenurePanel, setTenurePanel] = useState({
    data: [],
    icon: Tenure,
    value: "tenure",
    label: "Tenure",
    isLoadingData: false,
    currentView: "tenure",
    query: {},
  });

  const [filter, setFilter] = useState({
    startDate: null,
    endDate: null,
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [isUndeterminedSurveyShown, setIsUndeterminedSurveyShown] =
    useState(false);
  const [isErrorMessageShown, setIsErrorMessageShown] = useState(false);

  useEffect(() => {
    handleFetchRegionsWithEmployeesStats();
    handleFetchJobsWithEmployeesStats();
    handleFetchQuestionCategoriesWithEmployeesStats();
    handleFetchTenuresWithEmployeesStats();
    mixpanelTrackEvent(EVENT_TYPES.RD_VIEW); //Emit retention dashboard view event
  }, []);

  const navigate = useNavigate();

  // const user = useOutletContext();
  const { user, isLoading: isLoadingUser } = useUser();

  const laptopLayout = useLaptopLayout();

  /**
   * Fetches the stats by question with promise call.
   *
   * @param {Object} filter
   * @returns {Promise}
   */
  const fetchQuestionCategoriesWithEmployeesStatsAsync = async (
    filter = {}
  ) => {
    try {
      return await fetchQuestionsWithEmployeesStats({
        start_date: filter?.startDate,
        end_date: filter?.endDate,
      });
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  /**
   * Fetches the stats by tenure with promise call.
   *
   * @param {Object} filter
   * @returns {Promise}
   */
  const fetchTenuresWithEmployeesStatsAsync = async (filter = {}) => {
    try {
      return await fetchTenuresWithEmployeesStats({
        start_date: filter?.startDate,
        end_date: filter?.endDate,
      });
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  /**
   * Handles the fetching of stats by question category information.
   *
   * @param {Object} filter
   */
  const handleFetchQuestionCategoriesWithEmployeesStats = async (
    filter = {}
  ) => {
    setQuestionCategoryPanel((prevState) => ({
      ...prevState,
      isLoadingData: true,
    }));
    const response = await fetchQuestionCategoriesWithEmployeesStatsAsync(
      filter
    );

    setQuestionCategoryPanel((prevState) => ({
      ...prevState,
      isLoadingData: false,
    }));

    if (response?.hasError) {
      setErrorMessage(
        `Error while fetching stats by Question Category: ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);
      setQuestionCategoryPanel((prevState) => ({
        ...prevState,
        data: [],
      }));

      return;
    }

    const questionCategoryStat = response?.result?.data;
    let data = [];

    if (!isObjectEmpty(questionCategoryStat)) {
      data = Object.keys(questionCategoryStat)?.map(
        (questionCategory, index) => ({
          id: index,
          name: questionCategory,
          question: questionCategoryStat[questionCategory]?.["Question"],
          responseRate:
            questionCategoryStat[questionCategory]?.["Response Rate"],
          sentiment: initializeSentimentSummary(
            questionCategoryStat[questionCategory]?.["Response Rate"],
            questionCategoryStat[questionCategory]?.["Overall Sentiment"]
          ),
          breakdown: questionCategoryStat[questionCategory]?.["Sentiment"],
          recordId: questionCategoryStat[questionCategory]?.id,
          sentimentScore: isNumber(
            questionCategoryStat[questionCategory]?.["Sentiment Score"]
          )
            ? questionCategoryStat[questionCategory]?.["Sentiment Score"]
            : "-",
        })
      );
    }

    setQuestionCategoryPanel((prevState) => ({
      ...prevState,
      data,
    }));

    // NOTE: To maintain the click-through state in the Retention Parent hierarchy.
    setQuestionCategoryPanel((prevState) => ({
      ...prevState,
      currentView: "questionCategory",
      query: {},
    }));
  };

  /**
   * Handles the fetching of stats by tenure information.
   *
   * @param {Object} filter
   */
  const handleFetchTenuresWithEmployeesStats = async (filter = {}) => {
    setTenurePanel((prevState) => ({ ...prevState, isLoadingData: true }));
    const response = await fetchTenuresWithEmployeesStatsAsync(filter);

    setTenurePanel((prevState) => ({ ...prevState, isLoadingData: false }));

    if (response?.hasError) {
      setErrorMessage(
        `Error while fetching stats by Tenure: ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);
      setTenurePanel((prevState) => ({ ...prevState, data: [] }));

      return;
    }

    const tenureStat = response?.result?.tenure;
    let data = [];

    if (!isObjectEmpty(tenureStat)) {
      data = Object.keys(tenureStat)?.map((tenure, index) => ({
        id: index,
        days: tenure,
        employees: tenureStat[tenure]?.["Active"],
        responseRate: tenureStat[tenure]?.["Response Rate"],
        breakdown: tenureStat[tenure]?.["Sentiment"],
        surveyCompleted: tenureStat[tenure]?.["Survey Completed"],
        sentiment: initializeSentimentSummary(
          tenureStat[tenure]?.["Response Rate"],
          tenureStat[tenure]?.["Overall Sentiment"]
        ),
        surveySent: tenureStat[tenure]?.["Survey Sent"],
        recordId: tenureStat[tenure]?.id,
        sentimentScore: isNumber(tenureStat[tenure]?.["Sentiment Score"])
          ? tenureStat[tenure]?.["Sentiment Score"]
          : "-",
      }));
    }

    setTenurePanel((prevState) => ({ ...prevState, data }));

    // NOTE: To maintain the click-through state in the Retention Parent hierarchy.
    setTenurePanel((prevState) => ({
      ...prevState,
      currentView: "tenure",
      query: {},
    }));
  };

  /**
   * Renders the error snackbar for the Retention page.
   *
   * @returns {Component}
   */
  const renderErrorSnackbar = () => (
    <Snackbar
      open={isErrorMessageShown}
      autoHideDuration={6000}
      onClose={() => setIsErrorMessageShown(false)}
    >
      <MuiAlert
        onClose={() => setIsErrorMessageShown(false)}
        severity="error"
        sx={{ width: "100%" }}
      >
        {errorMessage}
      </MuiAlert>
    </Snackbar>
  );

  // Region Panel Click Through Start

  /**
   * Handles the fetching of stats by location information.
   *
   * @param {Object} filter
   */
  const handleFetchRegionsWithEmployeesStats = async (filter = {}) => {
    setRegionPanel((prevState) => ({ ...prevState, isLoadingData: true }));
    const response = await fetchRegionsWithEmployeesStatsAsync(filter);

    setRegionPanel((prevState) => ({ ...prevState, isLoadingData: false }));

    if (response?.hasError) {
      setErrorMessage(
        `Error while fetching stats by Region: ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);
      setRegionPanel((prevState) => ({ ...prevState, data: [] }));

      return;
    }

    const regionStat = response?.result?.location;
    let data = [];

    if (!isObjectEmpty(regionStat)) {
      data = Object.keys(regionStat)?.map((location, index) => ({
        id: index,
        location,
        employees: regionStat[location]?.["Active"],
        responseRate: regionStat[location]?.["Response Rate"],
        sentiment: initializeSentimentSummary(
          regionStat[location]?.["Response Rate"],
          regionStat[location]?.["Overall Sentiment"]
        ),
        surveyCompleted: regionStat[location]?.["Survey Completed"],
        surveySent: regionStat[location]?.["Survey Sent"],
        recordId: regionStat[location]?.id,
        sentimentScore: isNumber(regionStat[location]?.["Sentiment Score"])
          ? regionStat[location]?.["Sentiment Score"]
          : "-",
      }));
    }

    setRegionPanel((prevState) => ({ ...prevState, data }));

    // NOTE: To maintain the click-through state in the Retention Parent hierarchy.
    setRegionPanel((prevState) => ({
      ...prevState,
      currentView: "region",
      query: {},
    }));
  };

  /**
   * Fetches the stats by location with promise call.
   *
   * @param {Object} filter
   * @returns {Promise}
   */
  const fetchRegionsWithEmployeesStatsAsync = async (filter = {}) => {
    try {
      return await fetchRegionsWithEmployeesStats({
        start_date: filter?.startDate,
        end_date: filter?.endDate,
      });
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  /**
   * Handles the fetching of branches by region information.
   *
   * @param {String} region
   * @param {Object} filter
   */
  const handleFetchBranchesByRegionWithEmployeesStats = async (
    region = "",
    filter = {}
  ) => {
    setRegionPanel((prevState) => ({ ...prevState, isLoadingData: true }));
    const response = await fetchBranchesWithEmployeesStatsAsync(region, filter);

    setRegionPanel((prevState) => ({
      ...prevState,
      isLoadingData: false,
    }));

    if (response?.hasError) {
      setErrorMessage(
        `Error while fetching Branches by Region: ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);
      setRegionPanel((prevState) => ({ ...prevState, data: [] }));

      return;
    }

    const branchStat = response?.result?.location;
    let data = [];

    if (!isObjectEmpty(branchStat)) {
      data = Object.keys(branchStat)?.map((location, index) => ({
        id: index,
        location,
        employees: branchStat[location]?.["Active"],
        responseRate: branchStat[location]?.["Response Rate"],
        sentiment: initializeSentimentSummary(
          branchStat[location]?.["Response Rate"],
          branchStat[location]?.["Overall Sentiment"]
        ),
        surveyCompleted: branchStat[location]?.["Survey Completed"],
        surveySent: branchStat[location]?.["Survey Sent"],
        recordId: branchStat[location]?.id,
        sentimentScore: isNumber(branchStat[location]?.["Sentiment Score"])
          ? branchStat[location]?.["Sentiment Score"]
          : "-",
      }));
    }

    setRegionPanel((prevState) => ({ ...prevState, data }));

    // NOTE: To maintain the click-through state in the Retention Parent hierarchy.
    setRegionPanel((prevState) => ({
      ...prevState,
      currentView: "branch",
      query: {
        region,
      },
    }));
  };

  /**
   * Fetches the stats by branches with promise call.
   *
   * @param {String} region
   * @param {Object} filter
   * @returns {Promise}
   */
  const fetchBranchesWithEmployeesStatsAsync = async (region, filter) => {
    try {
      return await fetchBranchesWithEmployeeStats({
        region,
        start_date: filter?.startDate,
        end_date: filter?.endDate,
      });
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  /**
   * Handles the fetching of employees in Region panel.
   *
   * @param {String} branch
   * @param {Object} filter
   */
  const handleFetchEmployees_region = async (branch, filter) => {
    setRegionPanel((prevState) => ({ ...prevState, isLoadingData: true }));
    const response = await fetchEmployeesAsync_region(branch, filter);

    setRegionPanel((prevState) => ({ ...prevState, isLoadingData: false }));

    if (response?.hasError) {
      setErrorMessage(
        `Error while fetching Employees: ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);
      setRegionPanel((prevState) => ({ ...prevState, data: [] }));

      return;
    }

    const employeeStat = response?.result?.employee;
    let data = [];

    if (!isObjectEmpty(employeeStat)) {
      data = Object.keys(employeeStat)?.map((employee, index) => ({
        id: index,
        employee,
        job: employeeStat[employee]?.["job"],
        tenure: employeeStat[employee]?.["tenure"],
        responseRate: employeeStat[employee]?.["Response Rate"],
        sentiment: initializeSentimentSummary(
          employeeStat[employee]?.["Response Rate"],
          employeeStat[employee]?.["Overall Sentiment"]
        ),
        recordId: employeeStat[employee]?.id,
        isActive: employeeStat[employee]?.is_active,
        sentimentScore: isNumber(employeeStat[employee]?.["Sentiment Score"])
          ? employeeStat[employee]?.["Sentiment Score"]
          : "-",
      }));
    }

    setRegionPanel((prevState) => ({ ...prevState, data }));

    // NOTE: To maintain the click-through state in the Retention Parent hierarchy.
    setRegionPanel((prevState) => ({
      ...prevState,
      currentView: "employee",
      query: {
        branch,
      },
    }));
  };

  /**
   * Fetches the employees in Region panel with stats with promise call.
   *
   * @param {String} branch
   * @param {Object} filter
   * @returns {Promise}
   */
  const fetchEmployeesAsync_region = async (branch, filter) => {
    try {
      return await fetchEmployees_region({
        location: branch,
        start_date: filter?.startDate,
        end_date: filter?.endDate,
      });
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  // Region Panel Click Through End

  // Job Panel Click Through Start

  /**
   * Handles the fetching of stats by job information.
   *
   * @param {Object} filter
   */
  const handleFetchJobsWithEmployeesStats = async (filter = {}) => {
    setJobPanel((prevState) => ({ ...prevState, isLoadingData: true }));
    const response = await fetchJobsWithEmployeesStatsAsync(filter);

    setJobPanel((prevState) => ({ ...prevState, isLoadingData: false }));

    if (response?.hasError) {
      setErrorMessage(
        `Error while fetching stats by Job: ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);
      setJobPanel((prevState) => ({ ...prevState, data: [] }));

      return;
    }

    const jobStat = response?.result?.job;
    let data = [];

    if (!isObjectEmpty(jobStat)) {
      data = Object.keys(jobStat)?.map((job, index) => ({
        id: index,
        type: job,
        employees: jobStat[job]?.["Active"],
        responseRate: jobStat[job]?.["Response Rate"],
        sentiment: initializeSentimentSummary(
          jobStat[job]?.["Response Rate"],
          jobStat[job]?.["Overall Sentiment"]
        ),
        surveyCompleted: jobStat[job]?.["Survey Completed"],
        surveySent: jobStat[job]?.["Survey Sent"],
        recordId: jobStat[job]?.id,
        sentimentScore: isNumber(jobStat[job]?.["Sentiment Score"])
          ? jobStat[job]?.["Sentiment Score"]
          : "-",
      }));
    }

    setJobPanel((prevState) => ({ ...prevState, data }));

    // NOTE: To maintain the click-through state in the Retention Parent hierarchy.
    setJobPanel((prevState) => ({
      ...prevState,
      currentView: "job",
      query: {},
    }));
  };

  /**
   * Fetches the stats by job with promise call.
   *
   * @param {Object} filter
   * @returns {Promise}
   */
  const fetchJobsWithEmployeesStatsAsync = async (filter = {}) => {
    try {
      return await fetchJobsWithEmployeesStats({
        start_date: filter?.startDate,
        end_date: filter?.endDate,
      });
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  /**
   * Handles the fetching of categories by job type information.
   *
   * @param {String} jobId
   * @param {Object} filter
   */
  const handleFetchCategoriesByJobWithEmployeesStats = async (
    jobId,
    filter = {}
  ) => {
    setJobPanel((prevState) => ({ ...prevState, isLoadingData: true }));
    const response = await fetchCategoriesWithEmployeesStatsAsync(
      jobId,
      filter
    );

    setJobPanel((prevState) => ({ ...prevState, isLoadingData: false }));

    if (response?.hasError) {
      setErrorMessage(
        `Error while fetching Categories by Job: ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);
      setJobPanel((prevState) => ({ ...prevState, data: [] }));

      return;
    }

    const categoryStat = response?.result?.category;
    let data = [];

    if (!isObjectEmpty(categoryStat)) {
      data = Object.keys(categoryStat)?.map((category, index) => ({
        id: index,
        category,
        employees: categoryStat[category]?.["Active"],
        responseRate: categoryStat[category]?.["Response Rate"],
        sentiment: initializeSentimentSummary(
          categoryStat[category]?.["Response Rate"],
          categoryStat[category]?.["Overall Sentiment"]
        ),
        surveyCompleted: categoryStat[category]?.["Survey Completed"],
        surveySent: categoryStat[category]?.["Survey Sent"],
        recordId: categoryStat[category]?.id,
        sentimentScore: isNumber(categoryStat[category]?.["Sentiment Score"])
          ? categoryStat[category]?.["Sentiment Score"]
          : "-",
      }));
    }

    setJobPanel((prevState) => ({ ...prevState, data }));

    // NOTE: To maintain the click-through state in the Retention Parent hierarchy.
    setJobPanel((prevState) => ({
      ...prevState,
      currentView: "category",
      query: {
        jobId,
      },
    }));
  };

  /**
   * Fetches the categories by job with employee stats with promise call.
   *
   * @param {String} jobId
   * @returns {Promise}
   */
  const fetchCategoriesWithEmployeesStatsAsync = async (jobId, filter) => {
    try {
      return await fetchCategoriesWithEmployeesStats({
        job_id: jobId,
        start_date: filter?.startDate,
        end_date: filter?.endDate,
      });
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  /**
   * Handles the fetching of employees for the Job Panel.
   *
   * @param {Number} jobId
   * @param {String} category
   * @param {Object} filter
   */
  const handleFetchEmployees_job = async (
    jobId,
    category = "",
    filter = {}
  ) => {
    setJobPanel((prevState) => ({ ...prevState, isLoadingData: true }));
    const response = await fetchEmployeesAsync_job(jobId, category, filter);

    setJobPanel((prevState) => ({ ...prevState, isLoadingData: false }));

    if (response?.hasError) {
      setErrorMessage(
        `Error while fetching Employees: ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);
      setJobPanel((prevState) => ({ ...prevState, data: [] }));

      return;
    }

    const employeeStat = response?.result?.employee;
    let data = [];

    if (!isObjectEmpty(employeeStat)) {
      data = Object.keys(employeeStat)?.map((employee, index) => ({
        id: index,
        employee,
        job: employeeStat[employee]?.["job"],
        tenure: employeeStat[employee]?.["tenure"],
        responseRate: employeeStat[employee]?.["Response Rate"],
        sentiment: initializeSentimentSummary(
          employeeStat[employee]?.["Response Rate"],
          employeeStat[employee]?.["Overall Sentiment"]
        ),
        recordId: employeeStat[employee]?.id,
        isActive: employeeStat[employee]?.is_active,
        sentimentScore: isNumber(employeeStat[employee]?.["Sentiment Score"])
          ? employeeStat[employee]?.["Sentiment Score"]
          : "-",
      }));
    }

    setJobPanel((prevState) => ({ ...prevState, data }));

    // NOTE: To maintain the click-through state in the Retention Parent hierarchy.
    setJobPanel((prevState) => ({
      ...prevState,
      currentView: "employee",
      query: {
        jobId,
        category,
      },
    }));
  };

  /**
   * Fetches the employees for Job Panel with promise call.
   *
   * @param {Number} jobId
   * @param {String} category
   * @param {Object} filter
   * @returns {Promise}
   */
  const fetchEmployeesAsync_job = async (jobId, category = "", filter = {}) => {
    try {
      return await fetchEmployees_job({
        job_id: jobId,
        category,
        start_date: filter?.startDate,
        end_date: filter?.endDate,
      });
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  // Job Panel Click Through End

  // Question Category Click Through Start

  /**
   * Handles the fetching of Questions by Job information.
   *
   * @param {String} questionCategory
   * @param {Number} jobId
   * @param {Object} filter
   */
  const handleFetchQuestionsByJobWithEmployeesStats = async (
    questionCategory = "",
    jobId,
    filter = {}
  ) => {
    setQuestionCategoryPanel((prevState) => ({
      ...prevState,
      isLoadingData: true,
    }));
    const response = await fetchQuestionsWithEmployeesStatsByJobAsync(
      questionCategory,
      jobId,
      filter
    );

    setQuestionCategoryPanel((prevState) => ({
      ...prevState,
      isLoadingData: false,
    }));

    if (response?.hasError) {
      setErrorMessage(
        `Error while fetching Questions by Job: ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);
      setQuestionCategoryPanel((prevState) => ({ ...prevState, data: [] }));

      return;
    }

    const questionStat = response?.result?.data;
    let data = [];

    if (!isObjectEmpty(questionStat)) {
      data = Object.keys(questionStat)?.map((question, index) => ({
        id: index,
        question: questionStat[question]?.["title"],
        employees: questionStat[question]?.["Active"],
        totalResponse: questionStat[question]?.["total_response"],
        responseRate: questionStat[question]?.["Response Rate"],
        sentiment: initializeSentimentSummary(
          questionStat[question]?.["Response Rate"],
          questionStat[question]?.["Overall Sentiment"]
        ),
        surveyCompleted: questionStat[question]?.["Survey Completed"],
        surveySent: questionStat[question]?.["Survey Sent"],
        breakdown: questionStat[question]?.["Sentiment"],
        recordId: question,
        sentimentScore: isNumber(questionStat[question]?.["Sentiment Score"])
          ? questionStat[question]?.["Sentiment Score"]
          : "-",
      }));
    }

    setQuestionCategoryPanel((prevState) => ({ ...prevState, data }));

    // NOTE: To maintain the click-through state in the Retention Parent hierarchy.
    setQuestionCategoryPanel((prevState) => ({
      ...prevState,
      currentView: "question",
      query: {
        questionCategory,
        jobId,
      },
    }));
  };

  /**
   * Fetches the questions by job with employee stats with promise call.
   *
   * @param {String} category
   * @param {Number} jobId
   * @param {Object} filter
   *
   * @returns {Promise}
   */
  const fetchQuestionsWithEmployeesStatsByJobAsync = async (
    category,
    jobId,
    filter = {}
  ) => {
    try {
      return await fetchQuestionsByJobWithEmployeeStats({
        category,
        job_id: jobId,
        start_date: filter?.startDate,
        end_date: filter?.endDate,
      });
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  /**
   * Handles the fetching of Employees by Question under a Job > Question Category.
   *
   * @param {String} category
   * @param {Number} jobId
   * @param {Number} questionId
   * @param {Object} filter
   */
  const handleFetchEmployeesByQuestionWithStats = async (
    category = "",
    jobId,
    questionId,
    filter
  ) => {
    setQuestionCategoryPanel((prevState) => ({
      ...prevState,
      isLoadingData: true,
    }));
    const response = await fetchEmployeesByQuestionWithStatsAsync(
      category,
      jobId,
      questionId,
      filter
    );

    setQuestionCategoryPanel((prevState) => ({
      ...prevState,
      isLoadingData: false,
    }));

    if (response?.hasError) {
      setErrorMessage(
        `Error while fetching Employees by Question: ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);
      setQuestionCategoryPanel((prevState) => ({ ...prevState, data: [] }));

      return;
    }

    const employeeStat = response?.result?.employee;
    let data = [];

    if (!isObjectEmpty(employeeStat)) {
      data = Object.keys(employeeStat)?.map((employee, index) => ({
        id: index,
        employee: employeeStat[employee]?.["name"],
        responseRate: employeeStat[employee]?.["Response Rate"],
        sentiment: initializeSentimentSummary(
          employeeStat[employee]?.["Response Rate"],
          employeeStat[employee]?.["sentiment"]
        ),
        response: employeeStat[employee]?.["response"],
        tenure: employeeStat[employee]?.["tenure"],
        job: employeeStat[employee]?.["job"],
        recordId: employee,
        isActive: employeeStat[employee]?.["is_active"],
        sentimentScore: isNumber(employeeStat[employee]?.["Sentiment Score"])
          ? employeeStat[employee]?.["Sentiment Score"]
          : "-",
      }));
    }

    setQuestionCategoryPanel((prevState) => ({ ...prevState, data }));

    // NOTE: To maintain the click-through state in the Retention Parent hierarchy.
    setQuestionCategoryPanel((prevState) => ({
      ...prevState,
      currentView: "employee",
      query: {
        category,
        jobId,
        questionId,
      },
    }));
  };

  /**
   * Fetches the employees by question category > job > question with employee stats with promise call.
   *
   * @param {String} category
   * @param {Number} jobId
   * @param {Number} questionId
   * @param {Object} filter
   *
   * @returns {Promise}
   */
  const fetchEmployeesByQuestionWithStatsAsync = async (
    category,
    jobId,
    questionId,
    filter
  ) => {
    try {
      return await fetchEmployeesByQuestionWithStats({
        category,
        job_id: jobId,
        question_id: questionId,
        start_date: filter?.startDate,
        end_date: filter?.endDate,
      });
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  /**
   * Handles the fetching of Jobs by Question Category.
   *
   * @param {String} category
   * @param {Object} filter
   */
  const handleFetchJobsByQuestionCategoryWithEmployeesStats = async (
    category = "",
    filter = {}
  ) => {
    setQuestionCategoryPanel((prevState) => ({
      ...prevState,
      isLoadingData: true,
    }));
    const response = await fetchJobsByQuestionCategoryWithStatsAsync(
      category,
      filter
    );

    setQuestionCategoryPanel((prevState) => ({
      ...prevState,
      isLoadingData: false,
    }));

    if (response?.hasError) {
      setErrorMessage(
        `Error while fetching Jobs by Question Category: ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);
      setQuestionCategoryPanel((prevState) => ({ ...prevState, data: [] }));

      return;
    }

    const jobStat = response?.result?.data;
    let data = [];

    if (!isObjectEmpty(jobStat)) {
      data = Object.keys(jobStat)?.map((job, index) => ({
        id: index,
        type: job,
        question: jobStat[job]?.["Question"],
        employees: jobStat[job]?.["Active"],
        responseRate: jobStat[job]?.["Response Rate"],
        sentiment: initializeSentimentSummary(
          jobStat[job]?.["Response Rate"],
          jobStat[job]?.["Overall Sentiment"]
        ),
        surveyCompleted: jobStat[job]?.["Survey Completed"],
        surveySent: jobStat[job]?.["Survey Sent"],
        breakdown: jobStat[job]?.["Sentiment"],
        recordId: jobStat[job]?.id,
        sentimentScore: isNumber(jobStat[job]?.["Sentiment Score"])
          ? jobStat[job]?.["Sentiment Score"]
          : "-",
      }));
    }

    setQuestionCategoryPanel((prevState) => ({ ...prevState, data }));

    // NOTE: To maintain the click-through state in the Retention Parent hierarchy.
    setQuestionCategoryPanel((prevState) => ({
      ...prevState,
      currentView: "job",
      query: {
        category,
      },
    }));
  };

  /**
   * Fetches the jobs by question category with employee stats with promise call.
   *
   * @param {String} category
   * @param {Object} filter
   * @returns {Promise}
   */
  const fetchJobsByQuestionCategoryWithStatsAsync = async (
    category,
    filter
  ) => {
    try {
      return await fetchJobsByQuestionCategoryWithEmployeesStats({
        category,
        start_date: filter?.startDate,
        end_date: filter?.endDate,
      });
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  // Question Category Click Through End

  // Tenure Category Click Through Start

  /**
   * Handles the fetching of stats by region information.
   *
   * @param {String} tenure
   * @param {Object} filter
   */
  const handleFetchRegionsByTenureWithEmployeesStats = async (
    tenure = "",
    filter = {}
  ) => {
    setTenurePanel((prevState) => ({ ...prevState, isLoadingData: true }));
    const response = await fetchRegionsByTenureWithEmployeesStatsAsync(
      tenure,
      filter
    );

    setTenurePanel((prevState) => ({ ...prevState, isLoadingData: false }));

    if (response?.hasError) {
      setErrorMessage(
        `Error while fetching Regions by Tenure: ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);
      setTenurePanel((prevState) => ({ ...prevState, data: [] }));

      return;
    }

    const regionStat = response?.result?.tenure;
    let data = [];

    if (!isObjectEmpty(regionStat)) {
      data = Object.keys(regionStat)?.map((location, index) => ({
        id: index,
        location,
        employees: regionStat[location]?.["Active"],
        responseRate: regionStat[location]?.["Response Rate"],
        sentiment: initializeSentimentSummary(
          regionStat[location]?.["Response Rate"],
          regionStat[location]?.["Overall Sentiment"]
        ),
        surveyCompleted: regionStat[location]?.["Survey Completed"],
        surveySent: regionStat[location]?.["Survey Sent"],
        breakdown: regionStat[location]?.["Sentiment"],
        recordId: regionStat[location]?.id,
        sentimentScore: isNumber(regionStat[location]?.["Sentiment Score"])
          ? regionStat[location]?.["Sentiment Score"]
          : "-",
      }));
    }

    setTenurePanel((prevState) => ({ ...prevState, data }));

    // NOTE: To maintain the click-through state in the Retention Parent hierarchy.
    setTenurePanel((prevState) => ({
      ...prevState,
      currentView: "region",
      query: {
        tenure,
      },
    }));
  };

  /**
   * Fetches the region stats by tenure with promise call.
   *
   * @param {String} tenure
   * @param {Object} filter
   *
   * @returns {Promise}
   */
  const fetchRegionsByTenureWithEmployeesStatsAsync = async (
    tenure,
    filter = {}
  ) => {
    try {
      return await fetchRegionsByTenureWithEmployeesStats({
        tenure,
        start_date: filter?.startDate,
        end_date: filter?.endDate,
      });
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  /**
   * Handles the fetching of stats by branch by Tenure's region information.
   *
   * @param {String} tenure
   * @param {String} region
   * @param {Object} filter
   */
  const handleFetchBranchesByTenureRegionWithEmployeesStats = async (
    tenure = "",
    region = "",
    filter = {}
  ) => {
    setTenurePanel((prevState) => ({ ...prevState, isLoadingData: true }));
    const response = await fetchBranchesByTenureRegionWithEmployeesStatsAsync(
      tenure,
      region,
      filter
    );

    setTenurePanel((prevState) => ({ ...prevState, isLoadingData: false }));

    if (response?.hasError) {
      setErrorMessage(
        `Error while fetching Branches by Region under a Tenure: ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);
      setTenurePanel((prevState) => ({ ...prevState, data: [] }));

      return;
    }

    const branchStat = response?.result?.tenure;
    let data = [];

    if (!isObjectEmpty(branchStat)) {
      data = Object.keys(branchStat)?.map((branch, index) => ({
        id: index,
        branch,
        employees: branchStat[branch]?.["Active"],
        responseRate: branchStat[branch]?.["Response Rate"],
        sentiment: initializeSentimentSummary(
          branchStat[branch]?.["Response Rate"],
          branchStat[branch]?.["Overall Sentiment"]
        ),
        surveyCompleted: branchStat[branch]?.["Survey Completed"],
        surveySent: branchStat[branch]?.["Survey Sent"],
        breakdown: branchStat[branch]?.["Sentiment"],
        recordId: branchStat[branch]?.id,
        sentimentScore: isNumber(branchStat[branch]?.["Sentiment Score"])
          ? branchStat[branch]?.["Sentiment Score"]
          : "-",
      }));
    }

    setTenurePanel((prevState) => ({ ...prevState, data }));

    // NOTE: To maintain the click-through state in the Retention Parent hierarchy.
    setTenurePanel((prevState) => ({
      ...prevState,
      currentView: "branch",
      query: {
        tenure,
        region,
      },
    }));
  };

  /**
   * Fetches the branch stats by tenure region with promise call.
   *
   * @param {String} tenure
   * @param {String} region
   * @param {Object} filter
   *
   * @returns {Promise}
   */
  const fetchBranchesByTenureRegionWithEmployeesStatsAsync = async (
    tenure,
    region,
    filter = {}
  ) => {
    try {
      return await fetchBranchesByTenureRegionWithEmployeesStats({
        tenure,
        region,
        start_date: filter?.startDate,
        end_date: filter?.endDate,
      });
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  /**
   * Handles the fetching of employees in Tenure: Region panel.
   *
   * @param {String} tenure
   * @param {String} region
   * @param {String} branch
   * @param {Object} filter
   */
  const handleFetchEmployees_tenureRegion = async (
    tenure = "",
    region = "",
    branch = "",
    filter = {}
  ) => {
    setTenurePanel((prevState) => ({ ...prevState, isLoadingData: true }));
    const response = await fetchEmployeesAsync_tenureRegion(
      tenure,
      region,
      branch,
      filter
    );

    setTenurePanel((prevState) => ({ ...prevState, isLoadingData: false }));

    if (response?.hasError) {
      setErrorMessage(
        `Error while fetching Employees: ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);
      setTenurePanel((prevState) => ({ ...prevState, data: [] }));

      return;
    }

    const employeeStat = response?.result?.employee;
    let data = [];

    if (!isObjectEmpty(employeeStat)) {
      data = Object.keys(employeeStat)?.map((employee, index) => ({
        id: index,
        employee,
        job: employeeStat[employee]?.["job"],
        tenure: employeeStat[employee]?.["tenure"],
        responseRate: employeeStat[employee]?.["Response Rate"],
        sentiment: initializeSentimentSummary(
          employeeStat[employee]?.["Response Rate"],
          employeeStat[employee]?.["Overall Sentiment"]
        ),
        recordId: employeeStat[employee]?.id,
        isActive: employeeStat[employee]?.is_active,
        sentimentScore: isNumber(employeeStat[employee]?.["Sentiment Score"])
          ? employeeStat[employee]?.["Sentiment Score"]
          : "",
      }));
    }

    setTenurePanel((prevState) => ({ ...prevState, data }));

    // NOTE: To maintain the click-through state in the Retention Parent hierarchy.
    setTenurePanel((prevState) => ({
      ...prevState,
      currentView: "employee",
      query: {
        tenure,
        region,
        branch,
      },
    }));
  };

  /**
   * Fetches the employees in Tenure: Region panel.
   *
   * @param {String} tenure
   * @param {String} region
   * @param {String} branch
   * @param {Object} filter
   *
   * @returns {Promise}
   */
  const fetchEmployeesAsync_tenureRegion = async (
    tenure,
    region,
    branch,
    filter = {}
  ) => {
    try {
      return await fetchEmployees_tenureRegion({
        tenure,
        region,
        location: branch,
        start_date: filter?.startDate,
        end_date: filter?.endDate,
      });
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  /**
   * Handles the fetching of stats by jobs information for selected Tenure.
   *
   * @param {String} tenure
   * @param {Object} filter
   */
  const handleFetchJobsByTenureWithEmployeesStats = async (
    tenure = "",
    filter = {}
  ) => {
    setTenurePanel((prevState) => ({ ...prevState, isLoadingData: true }));
    const response = await fetchJobsByTenureWithEmployeesStatsAsync(
      tenure,
      filter
    );

    setTenurePanel((prevState) => ({ ...prevState, isLoadingData: false }));

    if (response?.hasError) {
      setErrorMessage(
        `Error while fetching Jobs by Tenure: ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);
      setTenurePanel((prevState) => ({ ...prevState, data: [] }));

      return;
    }

    const jobStat = response?.result?.job;
    let data = [];

    if (!isObjectEmpty(jobStat)) {
      data = Object.keys(jobStat)?.map((job, index) => ({
        id: index,
        type: job,
        employees: jobStat[job]?.["Active"],
        responseRate: jobStat[job]?.["Response Rate"],
        sentiment: initializeSentimentSummary(
          jobStat[job]?.["Response Rate"],
          jobStat[job]?.["Overall Sentiment"]
        ),
        surveyCompleted: jobStat[job]?.["Survey Completed"],
        surveySent: jobStat[job]?.["Survey Sent"],
        breakdown: jobStat[job]?.["Sentiment"],
        recordId: jobStat[job]?.id,
        sentimentScore: isNumber(jobStat[job]?.["Sentiment Score"])
          ? jobStat[job]?.["Sentiment Score"]
          : "-",
      }));
    }

    setTenurePanel((prevState) => ({ ...prevState, data }));

    // NOTE: To maintain the click-through state in the Retention Parent hierarchy.
    setTenurePanel((prevState) => ({
      ...prevState,
      currentView: "job",
      query: {
        tenure,
      },
    }));
  };

  /**
   * Fetches the jobs stats by tenure with promise call.
   *
   * @param {String} tenure
   * @param {Object} filter
   *
   * @returns {Promise}
   */
  const fetchJobsByTenureWithEmployeesStatsAsync = async (tenure, filter) => {
    try {
      return await fetchJobsByTenureWithEmployeesStats({
        tenure,
        start_date: filter?.startDate,
        end_date: filter?.endDate,
      });
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  /**
   * Handles the fetching of stats by categories information for selected Tenure Job Type.
   *
   * @param {String} tenure
   * @param {Number} jobId
   * @param {Object} filter
   */
  const handleFetchCategoriesByTenureJobTypeWithEmployeesStats = async (
    tenure = "",
    jobId,
    filter = {}
  ) => {
    setTenurePanel((prevState) => ({ ...prevState, isLoadingData: true }));
    const response =
      await fetchCategoriesByTenureJobTypeWithEmployeesStatsAsync(
        tenure,
        jobId,
        filter
      );

    setTenurePanel((prevState) => ({ ...prevState, isLoadingData: false }));

    if (response?.hasError) {
      setErrorMessage(
        `Error while fetching Categories by Job Type: ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);
      setTenurePanel((prevState) => ({ ...prevState, data: [] }));

      return;
    }

    const categoryStat = response?.result?.category;
    let data = [];

    if (!isObjectEmpty(categoryStat)) {
      data = Object.keys(categoryStat)?.map((category, index) => ({
        id: index,
        category,
        employees: categoryStat[category]?.["Active"],
        responseRate: categoryStat[category]?.["Response Rate"],
        sentiment: initializeSentimentSummary(
          categoryStat[category]?.["Response Rate"],
          categoryStat[category]?.["Overall Sentiment"]
        ),
        surveyCompleted: categoryStat[category]?.["Survey Completed"],
        surveySent: categoryStat[category]?.["Survey Sent"],
        breakdown: categoryStat[category]?.["Sentiment"],
        recordId: categoryStat[category]?.id,
        sentimentScore: isNumber(categoryStat[category]?.["Sentiment Score"])
          ? categoryStat[category]?.["Sentiment Score"]
          : "-",
      }));
    }

    setTenurePanel((prevState) => ({ ...prevState, data }));

    // NOTE: To maintain the click-through state in the Retention Parent hierarchy.
    setTenurePanel((prevState) => ({
      ...prevState,
      currentView: "category",
      query: {
        tenure,
        jobId,
      },
    }));
  };

  /**
   * Fetches the category stats by tenure job type with promise call.
   *
   * @param {String} tenure
   * @param {Number} tenure
   * @param {Object} filter
   *
   * @returns {Promise}
   */
  const fetchCategoriesByTenureJobTypeWithEmployeesStatsAsync = async (
    tenure,
    jobId,
    filter = {}
  ) => {
    try {
      return await fetchCategoriesByTenureJobTypeWithEmployeesStats({
        tenure,
        job_id: jobId,
        start_date: filter?.startDate,
        end_date: filter?.endDate,
      });
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  /**
   * Handles the fetching of employees in Tenure: Job panel.
   *
   * @param {String} tenure
   * @param {Number} jobId
   * @param {String} category
   * @param {Object} filter
   */
  const handleFetchEmployees_tenureJob = async (
    tenure = "",
    jobId,
    category = "",
    filter = {}
  ) => {
    setTenurePanel((prevState) => ({ ...prevState, isLoadingData: true }));
    const response = await fetchEmployeesAsync_tenureJob(
      tenure,
      jobId,
      category,
      filter
    );

    setTenurePanel((prevState) => ({ ...prevState, isLoadingData: false }));

    if (response?.hasError) {
      setErrorMessage(
        `Error while fetching Employees: ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);
      setTenurePanel((prevState) => ({ ...prevState, data: [] }));

      return;
    }

    const employeeStat = response?.result?.employee;
    let data = [];

    if (!isObjectEmpty(employeeStat)) {
      data = Object.keys(employeeStat)?.map((employee, index) => ({
        id: index,
        employee,
        job: employeeStat[employee]?.["job"],
        tenure: employeeStat[employee]?.["tenure"],
        responseRate: employeeStat[employee]?.["Response Rate"],
        sentiment: initializeSentimentSummary(
          employeeStat[employee]?.["Response Rate"],
          employeeStat[employee]?.["Overall Sentiment"]
        ),
        recordId: employeeStat[employee]?.id,
        isActive: employeeStat[employee]?.is_active,
        sentimentScore: isNumber(employeeStat[employee]?.["Sentiment Score"])
          ? employeeStat[employee]?.["Sentiment Score"]
          : "-",
      }));
    }

    setTenurePanel((prevState) => ({ ...prevState, data }));

    // NOTE: To maintain the click-through state in the Retention Parent hierarchy.
    setTenurePanel((prevState) => ({
      ...prevState,
      currentView: "employee_job",
      query: {
        tenure,
        jobId,
        category,
      },
    }));
  };

  /**
   * Fetches the employees in Tenure: Job panel with promise call.
   *
   * @param {String} tenure
   * @param {Number} jobId
   * @param {String} category
   * @param {Object} filter
   *
   * @returns {Promise}
   */
  const fetchEmployeesAsync_tenureJob = async (
    tenure = "",
    jobId,
    category = "",
    filter = {}
  ) => {
    try {
      return await fetchEmployees_tenureJob({
        tenure,
        job_id: jobId,
        category,
        start_date: filter?.startDate,
        end_date: filter?.endDate,
      });
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  // Tenure Category Click Through End

  /**
   * Navigate to the selected employee's profile.
   */
  const navigateToEmployeeProfile = (employeeId) => {
    if (!isNaN(employeeId)) {
      window.open(`/retention/V2/employees/${employeeId}`, "_blank");
    }
  };

  return (
    <>
      <div className="retention-main-outer-wrapper">
        <div className="retention-main-wrapper-v2">
          <div className="retention-first-row">
            <Box
              sx={{
                gap: 1,
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                "@media (max-width:600px)": {
                  justifyContent: "flex-end",
                },
              }}
            >
              <FormControlLabel
                sx={{
                  margin: 0,
                }}
                control={
                  <Switch
                    size="small"
                    checked={isUndeterminedSurveyShown}
                    onChange={(event) => {
                      setIsUndeterminedSurveyShown(event?.target?.checked);
                    }}
                    sx={{ color: "yellow" }}
                  />
                }
                label="Show Undetermined Surveys"
              />
            </Box>
          </div>
          <div className="retention-container-wrapper">
            <div className="retention-active-employees-wrapper">
              <Accordion
                defaultExpanded={true}
                sx={{
                  padding: "0px",
                  "@media (max-width:768px)": {
                    padding: 0,
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{ color: "var(--md-sys-color-primary-light)" }}
                    />
                  }
                  id="panel1a-header"
                >
                  <span className="grow shrink-0 basis-0 w-full text-default-font text-subheader font-subheader line-clamp-1">
                    Active Employees
                  </span>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    className={`data-wrapper ${
                      laptopLayout ? "full-width" : ""
                    }`}
                  >
                    <EmployeeDataGrid
                      {...regionPanel}
                      {...{
                        configuration: {
                          agency: user?.name,
                          isEmployeeClickThroughEnabled:
                            user?.configuration?.enable_employee_drilldown,
                        },
                      }}
                      fetchRegionsWithEmployeesStats={() =>
                        handleFetchRegionsWithEmployeesStats(filter)
                      }
                      fetchBranchesWithEmployeesStatsByRegion={(region) =>
                        handleFetchBranchesByRegionWithEmployeesStats(
                          region,
                          filter
                        )
                      }
                      fetchEmployees_region={(branch) =>
                        handleFetchEmployees_region(branch, filter)
                      }
                      navigateToEmployeeProfile={navigateToEmployeeProfile}
                      isUndeterminedSurveyShown={isUndeterminedSurveyShown}
                    />
                    <EmployeeDataGrid
                      {...jobPanel}
                      {...{
                        configuration: {
                          agency: user?.name,
                          isEmployeeClickThroughEnabled:
                            user?.configuration?.enable_employee_drilldown,
                        },
                      }}
                      navigateToEmployeeProfile={navigateToEmployeeProfile}
                      fetchJobsWithEmployeeStats={() =>
                        handleFetchJobsWithEmployeesStats(filter)
                      }
                      fetchCategoriesWithEmployeesStatsByJobType={(jobId) =>
                        handleFetchCategoriesByJobWithEmployeesStats(
                          jobId,
                          filter
                        )
                      }
                      fetchEmployees_job={(jobId, category) =>
                        handleFetchEmployees_job(jobId, category, filter)
                      }
                      isUndeterminedSurveyShown={isUndeterminedSurveyShown}
                    />
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>

            <div className="retention-dimension-breakdown-wrapper">
              <Accordion
                defaultExpanded={true}
                sx={{
                  padding: "0px",
                  "@media (max-width:768px)": {
                    padding: 0,
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{ color: "var(--md-sys-color-primary-light)" }}
                    />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <span className="grow shrink-0 basis-0 w-full text-default-font text-subheader font-subheader line-clamp-1">
                    Dimension Breakdown
                  </span>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="data-wrapper full-width">
                    <EmployeeDataGrid
                      {...questionCategoryPanel}
                      {...{
                        configuration: {
                          agency: user?.name,
                          isEmployeeClickThroughEnabled:
                            user?.configuration?.enable_employee_drilldown,
                        },
                      }}
                      navigateToEmployeeProfile={navigateToEmployeeProfile}
                      fetchQuestionCategoriesWithEmployeeStats={() =>
                        handleFetchQuestionCategoriesWithEmployeesStats(filter)
                      }
                      fetchJobsWithEmployeesStatsByQuestionCategory={(
                        category
                      ) =>
                        handleFetchJobsByQuestionCategoryWithEmployeesStats(
                          category,
                          filter
                        )
                      }
                      fetchQuestionsWithEmployeesStatsByJob={(
                        category,
                        jobId
                      ) =>
                        handleFetchQuestionsByJobWithEmployeesStats(
                          category,
                          jobId,
                          filter
                        )
                      }
                      fetchEmployeesWithStatsByQuestion={(
                        category,
                        jobId,
                        questionId
                      ) =>
                        handleFetchEmployeesByQuestionWithStats(
                          category,
                          jobId,
                          questionId,
                          filter
                        )
                      }
                      isUndeterminedSurveyShown={isUndeterminedSurveyShown}
                    />
                    <EmployeeDataGrid
                      {...tenurePanel}
                      {...{
                        configuration: {
                          agency: user?.name,
                          isEmployeeClickThroughEnabled:
                            user?.configuration?.enable_employee_drilldown,
                        },
                      }}
                      navigateToEmployeeProfile={navigateToEmployeeProfile}
                      fetchTenuresWithEmployeeStats={() =>
                        handleFetchTenuresWithEmployeesStats(filter)
                      }
                      fetchRegionsWithEmployeeStatsByTenure={(tenure) =>
                        handleFetchRegionsByTenureWithEmployeesStats(
                          tenure,
                          filter
                        )
                      }
                      fetchBranchesWithEmployeesStatsByTenureRegion={(
                        tenure,
                        region
                      ) =>
                        handleFetchBranchesByTenureRegionWithEmployeesStats(
                          tenure,
                          region,
                          filter
                        )
                      }
                      fetchEmployees_tenureRegion={(tenure, region, branch) =>
                        handleFetchEmployees_tenureRegion(
                          tenure,
                          region,
                          branch,
                          filter
                        )
                      }
                      fetchJobsWithEmployeesStatsByTenure={(tenure) =>
                        handleFetchJobsByTenureWithEmployeesStats(
                          tenure,
                          filter
                        )
                      }
                      fetchCategoriesWithEmployeesStatsByTenureJobType={(
                        tenure,
                        jobId
                      ) =>
                        handleFetchCategoriesByTenureJobTypeWithEmployeesStats(
                          tenure,
                          jobId,
                          filter
                        )
                      }
                      fetchEmployees_tenureJob={(tenure, jobId, category) =>
                        handleFetchEmployees_tenureJob(
                          tenure,
                          jobId,
                          category,
                          filter
                        )
                      }
                      isUndeterminedSurveyShown={isUndeterminedSurveyShown}
                    />
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
      {renderErrorSnackbar()}
    </>
  );
}
