import dayjs from "dayjs";
import { formatDistanceToNow } from "date-fns";

import { isEmpty as isStringEmpty } from "utils/string";

/**
 * Gives today's date.
 *
 * @returns {String}
 */
export const getToday = function () {
  const today = new Date();

  return formatDate(today, "yyyy-mm-dd");
};

/**
 * Formats the given date in the passed format.
 *
 * @param {Date} date
 * @param {String} format
 *
 * @returns {String}
 */
export const formatDate = (date, format = "mm-dd-yyyy") => {
  const newDate = new Date(date);
  switch (format) {
    case "mm-dd-yyyy":
      return `${appendLeadingZeroes(
        newDate.getMonth() + 1
      )}-${appendLeadingZeroes(newDate.getDate())}-${newDate.getFullYear()}`;
    case "yyyy-mm-dd":
      return `${newDate.getFullYear()}-${appendLeadingZeroes(
        newDate.getMonth() + 1
      )}-${appendLeadingZeroes(newDate.getDate())}`;
    default:
      return `${appendLeadingZeroes(
        newDate.getMonth() + 1
      )}-${appendLeadingZeroes(newDate.getDate())}-${newDate.getFullYear()}`;
  }
};

/**
 * Appends a zero to the single digit date numbers.
 *
 * @param   {Number} n
 */
const appendLeadingZeroes = (n) => {
  if (n <= 9) {
    return "0" + n;
  }
  return n;
};

/**
 * Formats the given date to Pacific time in "mm-dd-yyyy" format.
 *
 * @param {Date} date
 * @param {String} format
 *
 * @returns {String}
 */
export const formatDatePST = (date, format = "mm-dd-yyyy") => {
  const newDate = new Date(date);
  switch (format) {
    case "mm-dd-yyyy":
      return `${newDate
        .toLocaleDateString("en-US", {
          timeZone: "America/Los_Angeles",
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .replaceAll("/", "-")}`;
    case "yyyy-mm-dd":
      return `${newDate.toLocaleDateString("en-CA", {
        timeZone: "America/Los_Angeles",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })}`;
    default:
      return `${newDate.toLocaleDateString("en-US", {
        timeZone: "America/Los_Angeles",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })}`;
  }
};

/**
 * Gets the start date and end date for the last N number of days.
 *
 * @param {Number} n
 * @returns {Object}
 */
export const getLastNDaysFromToday = (n = 0) => {
  if (!(typeof n === "number" && n >= 0)) {
    return { startDate: null, endDate: null };
  }

  const today = dayjs();
  const startDate = today.subtract(n, "day").startOf("day");
  const endDate = today.add(0, "day").startOf("day");

  return {
    startDate: dayjs(startDate)?.format("YYYY-MM-DD"),
    endDate: dayjs(endDate)?.format("YYYY-MM-DD"),
  };
};

export const parseISODateTime = (isoDateTime) => {
  if (isStringEmpty(isoDateTime)) {
    return undefined;
  }

  return new Date(isoDateTime);
};

export const getRelativeDate = (isoDateTime) => {
  const date = parseISODateTime(isoDateTime);

  return date !== undefined
    ? formatDistanceToNow(date, { addSuffix: true })
    : "-";
};
