import CallMadeIcon from "@mui/icons-material/CallMade";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import { Box, Tab, Tabs } from "@mui/material";
import * as SubframeCore from "@subframe/core";
import {
  RECOGNITION_CATEGORY,
  RECOGNITION_TYPE,
} from "modules/Retention/constants/constants";
import { useState } from "react";
import { Badge, IconButton } from "subframe";
import { isEmpty as isArrayEmpty } from "utils/array";
import { getRelativeDate } from "utils/date";
import { isEmpty as isStringEmpty } from "utils/string";
import EmptyState from "../EmptyState";

const Recognitions = ({
  received = [],
  provided = [],
  onClose = () => {},
  type = RECOGNITION_TYPE.RECEIVED,
}) => {
  const [recognitionType, setRecognitionType] = useState(type);

  /**
   * Renders the empty state.
   *
   * @returns {Component}
   */
  const renderEmptyState = () => {
    return (
      <div className="h-full w-full">
        <EmptyState
          icon=<SubframeCore.Icon
            className="text-[44px] font-[100] leading-[44px] text-neutral-400"
            name="FeatherFileBox"
          />
          primaryText={`No recognitions ${
            recognitionType === RECOGNITION_TYPE.RECEIVED
              ? "received"
              : "provided"
          } yet.`}
          secondaryText={`This space will display any recognitions once an employee ${
            recognitionType === RECOGNITION_TYPE.RECEIVED
              ? "receives"
              : "provides"
          } them.`}
        />
      </div>
    );
  };

  /**
   * Renders the list of received/provided recognitions.
   */
  const handleRenderRecognitions = (recognitions = []) => {
    return isArrayEmpty(recognitions) ? (
      renderEmptyState()
    ) : (
      <div className="flex flex-col w-full items-start gap-4">
        {recognitions?.map((recognition, index) => {
          return (
            <>
              <div className="flex w-full flex-col items-start gap-2">
                <div className="flex w-full items-start justify-between">
                  <div className="flex flex-col items-start gap-1">
                    <span className="text-label font-label text-subtext-color">
                      {getRelativeDate(recognition?.created_at)}
                    </span>
                    <span className="text-body-bold font-body-bold text-default-font">
                      {isStringEmpty(recognition?.title)
                        ? "-"
                        : recognition?.title}
                    </span>
                  </div>
                  <Badge variant="success">
                    {RECOGNITION_CATEGORY[recognition?.category]?.label}
                  </Badge>
                </div>
                <span className="text-body font-body text-default-font">
                  {recognition?.reason ?? "-"}
                </span>
              </div>
              {index !== recognitions?.length - 1 && (
                <div className="flex h-px w-full flex-none flex-col items-center gap-2 bg-neutral-border" />
              )}
            </>
          );
        })}
      </div>
    );
  };

  return (
    <div className="flex h-144 w-144 flex-col items-start rounded bg-default-background pb-4">
      <div className="flex w-full flex-col items-start gap-2 pt-4 pr-4 pb-4 pl-4 bg-default-background">
        <div className="flex w-full items-center justify-between">
          <div className="flex items-center gap-2">
            <SubframeCore.Icon
              className="text-body font-body text-default-font"
              name="FeatherAward"
            />
            <span className="text-subheader font-subheader text-default-font">
              Recognitions
            </span>
          </div>
          <IconButton size="small" icon="FeatherX" onClick={onClose} />
        </div>
        <div className="flex w-full flex-col items-start gap-4">
          <Box sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}>
            <Tabs
              sx={{
                minHeight: "8px",
              }}
              variant="fullWidth"
              value={recognitionType}
              aria-label="recognitions tabs"
              onChange={(event, value) => setRecognitionType(value)}
            >
              <Tab
                sx={{
                  minHeight: "0px",
                  padding: "10px 16px",
                  fontSize: "14px",
                  fontFamily: "Nunito",
                  textTransform: "capitalize",
                }}
                iconPosition="start"
                icon={<CallReceivedIcon fontSize="small" />}
                value={RECOGNITION_TYPE.RECEIVED}
                label={`
                Received ${
                  received?.length != undefined && `(${received.length})`
                }

                `}
              />

              <Tab
                sx={{
                  minHeight: "0px",
                  padding: "10px 16px",
                  fontSize: "14px",
                  fontFamily: "Nunito",
                  textTransform: "capitalize",
                }}
                iconPosition="start"
                icon={<CallMadeIcon fontSize="small" />}
                value={RECOGNITION_TYPE.PROVIDED}
                label={`Provided ${
                  provided?.length != undefined && `(${provided.length})`
                }
                `}
              />
            </Tabs>
          </Box>
        </div>
      </div>
      <div className="flex w-full h-full flex-col items-start gap-4 pt-0 pr-4 pl-4 overflow-auto">
        {recognitionType === RECOGNITION_TYPE.RECEIVED
          ? handleRenderRecognitions(received)
          : handleRenderRecognitions(provided)}
      </div>
    </div>
  );
};

export default Recognitions;
