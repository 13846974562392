import config from "../../../config";

import { http } from "../../../utils/http";

import endpoints from "../constants/endpoints";

/**
 * Method to add a new escalation follow up.
 *
 * @param {Object} payload
 * @returns {Promise}
 */
export const followUpEscalation = (payload) => {
  const url = `${config.authBaseUrl}${endpoints.followUpEscalation}`;

  return http.post(url, payload);
};

/**
 * Method to update an existing follow up.
 *
 * @param {Object} payload
 * @returns {Promise}
 */
export const updateFollowUpEscalation = (payload) => {
  const url = `${config.authBaseUrl}${endpoints.updateEscalationFollowUp}`;

  return http.put(url, payload);
};
