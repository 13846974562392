import { get } from "lodash";

import { Divider, Tooltip } from "@mui/material";
import PersonOffIcon from "@mui/icons-material/PersonOff";

import RosterGridView from "../RosterGridView";
import SentimentSummaryShort from "../SentimentSummaryShort";

import { getRelativeDate } from "utils/date";
import { initializeSentimentSummary } from "../../utils/sentiment";
import { getFullName, isEmpty as isStringEmpty } from "../../utils/string";

import "./RosterGrid.css";

export default function RosterGrid({
  rowCount,
  rows = [],
  paginationModel,
  isLoading = false,
  setRosterPaginationModel,
  viewEmployeeProfile = () => {},
}) {
  const columns = [
    {
      field: "employee",
      headerName: "Employee",
      flex: 1,
      headerClassName: "super-app-theme--header",
      minWidth: 140,
      renderCell: (params) => (
        <>
          <div
            className={`custom-datagrid-cell-wrapper employee-cell ${
              !params?.row?.profile?.is_active ? "muted" : ""
            }`}
          >
            {params?.value}
          </div>
          {!params?.row?.profile?.is_active && (
            <div className="terminated-employee-icon-cell">
              <Tooltip id="button-report" title="Terminated">
                <PersonOffIcon
                  fontSize="small"
                  color="error"
                  title="Terminated"
                />
              </Tooltip>
            </div>
          )}
        </>
      ),
    },
    {
      field: "profile.job_title",
      headerName: "Job",
      align: "left",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 140,
      renderCell: (params) => (
        <div
          className={`custom-datagrid-cell-wrapper ${
            !params?.row?.profile?.is_active ? "muted" : ""
          }`}
        >
          {params?.value}
        </div>
      ),
    },
    {
      field: "profile.office_location",
      headerName: "Location",
      align: "left",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 140,
      renderCell: (params) => (
        <div
          className={`custom-datagrid-cell-wrapper ${
            !params?.row?.profile?.is_active ? "muted" : ""
          }`}
        >
          {params?.value}
        </div>
      ),
    },
    {
      field: "profile.tenure",
      headerName: "Tenure",
      flex: 1,
      minWidth: 140,
      align: "left",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <div
          className={`custom-datagrid-cell-wrapper ${
            !params?.row?.profile?.is_active ? "muted" : ""
          }`}
        >
          {params?.value}
        </div>
      ),
    },
    {
      field: "survey.['Sentiment Score']",
      headerName: "Overall Sentiment",
      flex: 1,
      minWidth: 140,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <div
          className={`custom-datagrid-cell-wrapper ${
            !params?.row?.profile?.is_active ? "muted" : ""
          }`}
        >
          <SentimentSummaryShort
            score={params?.value}
            summary={initializeSentimentSummary(
              params?.row?.["survey"]?.["Response Rate"],
              params?.row?.["survey"]?.["Overall Sentiment"]
            )}
          />
        </div>
      ),
    },
    {
      field: "latest_survey_trigger_date",
      headerName: "Day",
      flex: 1,
      minWidth: 140,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <div
          className={`custom-datagrid-cell-wrapper ${
            !params?.row?.profile?.is_active ? "muted" : ""
          }`}
        >
          {params?.row?.latest_survey?.trigger_day}
        </div>
      ),
      sortable: false,
      filterable: false,
    },
    {
      field: "latest_survey_sentiment_score",
      headerName: "Sentiment",
      flex: 1,
      minWidth: 140,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <div
          className={`custom-datagrid-cell-wrapper ${
            !params?.row?.profile?.is_active ? "muted" : ""
          }`}
        >
          <SentimentSummaryShort
            score={params?.row?.latest_survey?.["Sentiment Score"]}
            summary={initializeSentimentSummary(
              params?.row?.latest_survey?.["Response Rate"],
              params?.row?.latest_survey?.["Overall Sentiment"]
            )}
          />
        </div>
      ),
    },
    {
      field: "latest_survey_created_at",
      headerName: "Date",
      flex: 1,
      minWidth: 140,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <div
          className={`custom-datagrid-cell-wrapper ${
            !params?.row?.profile?.is_active ? "muted" : ""
          }`}
        >
          {getRelativeDate(params?.row?.latest_survey?.created_at)}
        </div>
      ),
    },
  ];

  const columnGroupingModel = [
    {
      groupId: "Latest Survey",
      children: [
        { field: "latest_survey_trigger_date" },
        { field: "latest_survey_sentiment_score" },
        { field: "latest_survey_created_at" },
      ],
    },
  ];

  /**
   * Maps and gets the data for the DataGrid component.
   *
   * @param {String} key
   * @param {Object} row
   * @returns {String | Number}
   */
  const dataMapper = (key, row) => {
    switch (key) {
      case "employee":
        return getFullName(row?.profile?.first_name, row?.profile?.last_name);
      default:
        return get(row, key);
    }
  };

  /**
   * Arranges the data for the Datagrid component.
   *
   * @param {Array} rows
   * @param {Array} columns
   * @returns {Array}
   */
  const arrangeData = (rows = [], columns = []) => {
    return rows.map((row) => {
      let arrangedRow = {
        id: row?.profile?.employee_id,
        ...row,
      };

      columns.forEach((column) => {
        arrangedRow = {
          ...arrangedRow,
          [column["field"]]: dataMapper(column["field"], row),
        };
      });

      return arrangedRow;
    });
  };

  return (
    <RosterGridView
      columns={columns}
      rowCount={rowCount}
      isLoading={isLoading}
      rows={arrangeData(rows, columns)}
      paginationModel={paginationModel}
      viewEmployeeProfile={viewEmployeeProfile}
      setRosterPaginationModel={setRosterPaginationModel}
      columnGroupingModel={columnGroupingModel}
    />
  );
}
