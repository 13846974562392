const endpoints = {
  login: "/v1/auth/agency/login",
  agencyUser: "/v1/auth/agency/user",
  applications: "/v1/auth/css/applications/",
  refreshToken: "/v1/auth/agency/refresh_token",
  employeeLogin: "/v1/auth/caregiver/role-login",
  messages: "/v1/auth/css/applications/messages",
  employeeSignUp: "/v1/auth/caregiver/create-role",
  employeeLogOut: "/v1/auth/caregiver/role-logout",
  applicationJobs: "/v1/auth/css/applications/jobs",
  applicationUser: "/v1/auth/css/applications/user",
  statistics: "/v1/auth/css/applications/css-stats/",
  caregiverRoleProfile: "/v1/auth/caregiver/role-profile",
  applicationActions: "/v1/auth/css/applications/actions",
  applicationDocuments: "/v1/auth/css/applications/documents",
  applicationInterview: "/v1/auth/css/applications/interviews",
  reviewDocuments: "/v1/auth/css/applications/document-review",
  approveDocuments: "/v1/auth/css/applications/jotform/request",
  applicationCaregiver: "/v1/auth/css/applications/create/caregiver",
  applicationUnanswer: "/v1/auth/css/applications/interviews/unanswer",
  findApplicationCaregivers: "/v1/auth/css/applications/find_caregiver",
  updateApplicationCaregiver: "/v1/auth/css/applications/caregiver/update",
  applicationChangeStatus: "/v1/auth/css/applications/change-status/request",
  applicationCaregiverDetails: "/v1/auth/css/applications/caregiver/details",
  checkUpdateEmployeeRole:
    "/v1/auth/agency/employee/check-update-employee-role",
};

export default endpoints;
