import React, { useCallback, useState } from "react";

import { useUpdateEffect } from "react-use";

import { useNavigate } from "react-router-dom";

import { debounce } from "lodash";

import { ERROR_CODE } from "../../constants/constants";

import SearchBar from "../../common/components/SearchBar";
import EmployeeSearchResultItem from "../../common/components/EmployeeSearchResultItem";

import { findEmployee } from "../../services/retention/employees";

export default function EmployeesSearchBar() {
  const navigate = useNavigate();

  const [employees, setEmployees] = useState([]);
  const [searchEmployeeValue, setSearchEmployeeValue] = useState("");
  const [isFetchingEmployees, setIsFetchingEmployees] = useState(false);

  useUpdateEffect(() => {
    debouncedFetchEmployees(searchEmployeeValue);
  }, [searchEmployeeValue]);

  /**
   * Fetches the list of filtered employees.
   *
   * @param {String} name
   * @returns {Promise}
   */
  const fetchEmployeesAsync = async (name = "") => {
    try {
      return await findEmployee({
        employee_name: name,
      });
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  /**
   * Handles the fetching of the employees.
   *
   * @param {String} name
   */
  const handleFetchEmployees = async (name = "") => {
    setIsFetchingEmployees(true);
    const response = await fetchEmployeesAsync(name);

    setIsFetchingEmployees(false);

    if (response?.hasError) {
      setEmployees([]);

      return;
    }

    setEmployees(response?.result?.employees);
  };

  /**
   * Debounced employees fetching on typing user search value.
   */
  const debouncedFetchEmployees = useCallback(
    debounce(handleFetchEmployees, 300),
    []
  );

  /**
   * Navigate to the selected employee's profile.
   */
  const navigateToEmployeeProfile = (id) => {
    if (!isNaN(id)) {
      window.open(`/employees/${id}`, "_blank");
    }
  };

  return (
    <SearchBar
      results={employees}
      optionKey="caregiver"
      isLoading={isFetchingEmployees}
      onFilter={setSearchEmployeeValue}
      placeholder="Search Employees..."
      onResultClick={navigateToEmployeeProfile}
      renderOption={(employee) => (
        <EmployeeSearchResultItem
          {...employee}
          onClick={navigateToEmployeeProfile}
        />
      )}
    />
  );
}
