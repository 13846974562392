import React, { useState } from "react";

import { Skeleton } from "@mui/material";

import * as SubframeCore from "@subframe/core";
import { Badge } from "subframe/components/Badge";
import { Switch } from "subframe/components/Switch";

import {
  ESCALATION_FOLLOW_UP_TAGS,
  HISTORY_TYPE,
  RECOGNITION_CATEGORY,
} from "modules/Retention/constants/constants";
import { renderSentimentBadgeWithScore } from "modules/Retention/utils/sentiment";

import { isEmpty as isArrayEmpty } from "utils/array";
import { getRelativeDate } from "utils/date";
import { isEmpty as isObjectEmpty } from "utils/object";

import EmptyState from "../EmptyState";
import HistorySurveyAccordion from "../HistorySurveyAccordion";
import SentimentBreakdown from "../SentimentBreakdown";

import {
  parseDescriptionSummary,
  renderFollowupTags,
} from "modules/Retention/utils/followup";
import { isEmpty as isStringEmpty } from "../../../../utils/string";

const History = ({
  isLoading = false,
  employeeHistory = [],
  employeeId,
  handleNoteSummaryToggle = () => {},
}) => {
  const [isNoteSummaryEnabled, setIsNoteSummaryEnabled] = useState(false);

  const handleSummaryToggle = () => {
    setIsNoteSummaryEnabled((prevIsNoteSummaryEnabled) => {
      handleNoteSummaryToggle(employeeId, !prevIsNoteSummaryEnabled);
      return !prevIsNoteSummaryEnabled;
    });
  };

  const renderStatusTag = (status) => {
    const tag = ESCALATION_FOLLOW_UP_TAGS[status];

    if (!isObjectEmpty(tag)) {
      return (
        <div className="shrink-0 gap-1">
          <div
            className="group/6c4fd648 flex h-6 items-center gap-1 rounded-full p-4 md:pr-3 md:pl-3"
            style={{ backgroundColor: tag.color.fill }}
          >
            <span
              className="text-label font-label text-brand-800"
              style={{ color: tag.color.text }}
            >
              {tag.label}
            </span>
          </div>
        </div>
      );
    }
  };

  const isHistoryTypeWithSentiment = (
    historyType,
    hasSentimentValue = true
  ) => {
    const historyTypesWithShowSentiment = Object.values(HISTORY_TYPE)
      .filter((type) => type.showSentiment)
      .map((type) => type.value);

    return (
      historyTypesWithShowSentiment.some((item) => item === historyType) &&
      hasSentimentValue
    );
  };

  return (
    <div className="w-full flex-col gap-4 items-start flex">
      <div className="flex w-full items-center justify-between">
        <span className="text-default-font text-subheader font-subheader">
          History
        </span>
        <div className="flex items-center gap-2">
          <Switch
            className="h-4 w-8 flex-none"
            checked={isNoteSummaryEnabled}
            onCheckedChange={handleSummaryToggle}
          />
          <div className="flex items-center gap-1">
            <SubframeCore.Icon
              className="text-label font-label text-subtext-color"
              name="FeatherSparkles"
            />
            <span className="text-label font-label text-default-font">
              Summarize
            </span>
          </div>
        </div>
      </div>
      {isLoading ? (
        <div className="w-full">
          <Skeleton variant="rounded" width="100%" height="500px" />
        </div>
      ) : (
        <div className="w-full flex-col-reverse	items-start flex">
          {isArrayEmpty(employeeHistory) ? (
            <EmptyState
              icon={
                <SubframeCore.Icon
                  className="text-[44px] font-[100] leading-[44px] text-neutral-400"
                  name="FeatherAlertCircle"
                />
              }
              primaryText="Something went wrong."
              secondaryText="Hm. There should be data here."
            />
          ) : (
            employeeHistory.map((history, index) => {
              return (
                <div
                  className="w-full gap-4 pr-2 pl-2 items-start flex"
                  key={index}
                >
                  <div className="h-full flex-col items-center flex">
                    <div
                      className={`flex-none h-7 w-7 rounded-full gap-2 items-center justify-center flex ${
                        HISTORY_TYPE[history?.type]?.background
                      }`}
                    >
                      {HISTORY_TYPE[history?.type]?.icon}
                    </div>
                    {index !== 0 && (
                      <div className="grow shrink-0 basis-0 h-full w-px flex-col gap-2 items-center flex bg-neutral-200" />
                    )}
                  </div>

                  <div className="grow shrink-0 basis-0 w-full flex-col gap-1 pt-1 pb-6 items-start flex">
                    <div className="w-full gap-1 flex-wrap items-start flex flex-col">
                      <div className="w-full gap-1 flex-wrap items-start flex flex-row">
                        {history?.created_by &&
                          !isStringEmpty(history.created_at) && (
                            <span className="text-subtext-color text-label font-label first-letter:uppercase">
                              By {history.created_by}
                            </span>
                          )}
                      </div>
                      <div className="w-full gap-1 flex-wrap items-start flex flex-row">
                        <span className="text-subtext-color text-label font-label first-letter:uppercase">
                          {getRelativeDate(history?.created_at)}
                        </span>
                      </div>
                      <div className="flex w-full items-center justify-between">
                        <span className="text-default-font text-body-bold font-body-bold">
                          {history?.title}
                        </span>
                        {isHistoryTypeWithSentiment(history?.type) && (
                          <div className="shrink-0 gap-1">
                            {renderSentimentBadgeWithScore(
                              history?.data?.survey?.overall_sentiment_score,
                              history?.data?.survey?.overall_sentiment,
                              history?.data?.survey_rate?.response_rate
                            )}
                          </div>
                        )}
                        {history?.type ===
                          HISTORY_TYPE["ESCALATION_IN_PROGRESS"].value &&
                          renderStatusTag(history?.data?.tags?.[0])}
                      </div>
                      {renderFollowupTags(
                        history?.data?.note_tags,
                        history?.description,
                        isNoteSummaryEnabled
                      )}
                    </div>
                    {isHistoryTypeWithSentiment(
                      history?.type,
                      history?.data?.has_sentiment_response
                    ) ? (
                      <>
                        <div className="py-2 w-full">
                          <SentimentBreakdown
                            data={{
                              Positive:
                                history?.data?.sentiments?.positive_sentiment,
                              Neutral:
                                history?.data?.sentiments?.neutral_sentiment,
                              Negative:
                                history?.data?.sentiments?.negative_sentiment,
                            }}
                          />
                        </div>
                        <HistorySurveyAccordion
                          employeeId={employeeId}
                          surveyId={history?.id}
                          surveyRate={history?.data?.survey_rate}
                        />
                      </>
                    ) : (
                      <div className="grow shrink-0 basis-0 h-full w-full flex-col gap-2 items-start flex">
                        <span className="text-default-font text-body font-body">
                          {history?.type ===
                            HISTORY_TYPE["RECOGNITION_RECEIVED"].value ||
                          history?.type ===
                            HISTORY_TYPE["RECOGNITION_SENT"].value
                            ? history?.reason
                            : parseDescriptionSummary(
                                history?.description,
                                history?.data?.note_tags,
                                isNoteSummaryEnabled
                              )}
                        </span>
                        {(history?.type ===
                          HISTORY_TYPE["RECOGNITION_RECEIVED"].value ||
                          history?.type ===
                            HISTORY_TYPE["RECOGNITION_SENT"].value) && (
                          <Badge variant="success">
                            {RECOGNITION_CATEGORY[history?.category]?.label}
                          </Badge>
                        )}
                      </div>
                    )}
                    <div className="w-full gap-1 flex-wrap items-start flex flex-row">
                      {history?.forward_name && (
                        <span className="text-subtext-color text-label font-label first-letter:uppercase">
                          Sent to {history.forward_name}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      )}
    </div>
  );
};

export default History;
