import { RECOGNITION_CATEGORY } from "../constants/constants";

export function getRecognitionCategoriesForAgency(agency) {
  // Agency-specific categories (e.g., Graham)
  if (agency === "Graham Healthcare Group") {
    return [
      RECOGNITION_CATEGORY.PATIENT_CARE,
      RECOGNITION_CATEGORY.SERVICE_RECOVERY,
      RECOGNITION_CATEGORY.ABOVE_AND_BEYOND,
      RECOGNITION_CATEGORY.OTHER,
    ];
  }

  // Default categories for other agencies
  return [
    RECOGNITION_CATEGORY.CLINICAL_EXCELLENCE,
    RECOGNITION_CATEGORY.TEAMWORK,
    RECOGNITION_CATEGORY.PATIENT_SATISFACTION,
    RECOGNITION_CATEGORY.PERSONAL_IMPROVEMENT,
    RECOGNITION_CATEGORY.COMMUNICATION,
    RECOGNITION_CATEGORY.OTHER,
  ];
}
