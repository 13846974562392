/**
 * Parse JWT token WITHOUT verifying the signature.
 *
 * This is a small utility that helps decoding JWTs token which are Base64Url
 * encoded.
 *
 * IMPORTANT: This library doesn't validate the token, any well formed JWT can
 * be decoded. You should validate the token in your server-side logic by using
 * something like express-jwt, koa-jwt, Owin Bearer JWT, etc.
 *
 * @param {string} token
 *
 * @returns {null|Object}
 */
const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

export default parseJwt;
