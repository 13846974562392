/*
 * Documentation:
 * Roster — https://app.subframe.com/library?component=Roster_d597eb02-798c-40ea-944b-7a701165fdf7
 * Checkbox — https://app.subframe.com/library?component=Checkbox_3816e3b5-c48c-499b-b45e-0777c6972523
 * Button — https://app.subframe.com/library?component=Button_3b777358-b86b-40af-9327-891efc6826fe
 * Text Input — https://app.subframe.com/library?component=Text+Input_be48ca43-f8e7-4c0e-8870-d219ea11abfe
 * Stats card row — https://app.subframe.com/library?component=Stats+card+row_5f847462-1a2a-42e8-a92a-bef25d2ad7e7
 * Table — https://app.subframe.com/library?component=Table_142dfde7-d0cc-48a1-a04c-a08ab2252633
 */

import MuiAlert from "@mui/material/Alert";
import { useUpdateEffect } from "react-use";
import { useNavigate } from "react-router-dom";
import { Skeleton, Snackbar } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";

import dayjs from "dayjs";
import { debounce } from "lodash";

import { CustomStatCard, PageHeader } from "subframe";
import * as SubframeCore from "@subframe/core";
import { Select } from "subframe/components/Select";

import { fetchRoster } from "services/retention/employees";

import { ERROR_CODE } from "constants/constants";

import { fetchRosterStats } from "modules/Retention/services/retention";
import { ROSTER_DATE_FILTER_OPTIONS } from "modules/Retention/constants/constants";

import { isNumber } from "utils/number";
import { getLastNDaysFromToday } from "utils/date";

import { mixpanelTrackEvent } from "services/mixpanel";
import { EVENT_TYPES } from "constants/mixpanel";

import RosterGrid from "../RosterGrid";

function Roster() {
  const defaultRosterDateFilter = ROSTER_DATE_FILTER_OPTIONS["Last 7 days"];
  const [roster, setRoster] = useState([]);
  const [isLoadingRoster, setIsLoadingRoster] = useState(false);
  const [isLoadingRosterStats, setIsLoadingRosterStats] = useState(false);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorMessageShown, setIsErrorMessageShown] = useState(false);
  const [filter, setFilter] = useState({
    endDate:
      getLastNDaysFromToday(defaultRosterDateFilter.value)?.endDate || null,
    startDate:
      getLastNDaysFromToday(defaultRosterDateFilter.value)?.startDate || null,
  });
  const [selectedRosterDateFilter, setSelectedRosterDateFilter] = useState(
    defaultRosterDateFilter.label
  );
  const [rosterStats, setRosterStats] = useState({});

  /**
   * Roster table pagination states.
   */
  const [rosterPaginationModel, setRosterPaginationModel] = useState({
    page: 0,
    pageSize: 20,
  });
  const [rosterRowCountState, setRosterRowCountState] = useState(0);

  useEffect(() => {
    handleFetchRoster(rosterPaginationModel);
    mixpanelTrackEvent(EVENT_TYPES.EMPLOYEES_PAGE_NAVIGATION);
  }, []);

  useEffect(() => {
    handleFetchRosterStats(filter);
  }, [filter]);

  useUpdateEffect(() => {
    debouncedFetchRoster(rosterPaginationModel);
  }, [rosterPaginationModel]);

  /**
   * Handles the fetching of the Roster.
   *
   * @param {Object} rosterPaginationModel
   * @returns {Null | Undefined}
   */
  const handleFetchRoster = async (rosterPaginationModel = {}) => {
    setIsLoadingRoster(true);
    const response = await fetchRosterAsync({
      ...rosterPaginationModel,
    });

    setIsLoadingRoster(false);

    if (response?.hasError) {
      setErrorMessage(
        `Error while fetching Roster: ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);
      setRoster([]);

      return;
    }

    setRoster(response?.result?.employees);
    setRosterRowCountState(response?.result?.total || 0);
  };

  /**
   * Handles the fetching of the Roster Stats.
   *
   * @param {Object} filter
   * @returns {Null | Undefined}
   */
  const handleFetchRosterStats = async (filter = {}) => {
    setIsLoadingRosterStats(true);
    const response = await fetchRosterStatsAsync({
      ...filter,
    });

    setIsLoadingRosterStats(false);

    if (response?.hasError) {
      setErrorMessage(
        `Error while fetching Roster stats: ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);
      setRosterStats({});

      return;
    }

    setRosterStats(response?.result?.data);
  };

  /**
   * Method to fetch roster by API call.
   *
   * @param {Object} The filter object.
   * @returns {Promise}
   */
  const fetchRosterAsync = async (filter = {}) => {
    try {
      return await fetchRoster({
        page: filter?.page + 1,
        limit: filter?.pageSize,
      });
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  /**
   * Method to fetch roster stats by API call.
   *
   * @param {Object} The filter object.
   * @returns {Promise}
   */
  const fetchRosterStatsAsync = async (filter = {}) => {
    try {
      return await fetchRosterStats({
        start_date: filter?.startDate,
        end_date: filter?.endDate,
      });
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  /**
   * Debounced roster fetching.
   */
  const debouncedFetchRoster = useCallback(
    debounce(handleFetchRoster, 500),
    []
  );

  const handleGetLastNDaysFromToday = (n = 0) => {
    const result = getLastNDaysFromToday(n);
    const startDate =
      dayjs(result?.startDate)?.format("YYYY-MM-DD") === "Invalid Date"
        ? null
        : dayjs(result?.startDate)?.format("YYYY-MM-DD");
    const endDate =
      dayjs(result?.endDate)?.format("YYYY-MM-DD") === "Invalid Date"
        ? null
        : dayjs(result?.endDate)?.format("YYYY-MM-DD");

    setFilter({
      startDate,
      endDate,
    });
  };

  /**
   * Navigate to the selected employee's profile.
   */
  const navigateToEmployeeProfile = (employeeId) => {
    if (!isNaN(employeeId)) {
      window.open(`/retention/V2/employees/${employeeId}`, "_blank");
    }
  };

  /**
   * Renders the error snackbar.
   *
   * @returns {Component}
   */
  const renderErrorSnackbar = () => (
    <Snackbar
      open={isErrorMessageShown}
      autoHideDuration={6000}
      onClose={() => setIsErrorMessageShown(false)}
    >
      <MuiAlert
        onClose={() => setIsErrorMessageShown(false)}
        severity="error"
        sx={{ width: "100%" }}
      >
        {errorMessage}
      </MuiAlert>
    </Snackbar>
  );

  return (
    <>
      <div className="w-full h-full flex-col gap-8 p-4 md:p-12 items-start flex bg-default-background max-w-none overflow-auto">
        <PageHeader>
          <div className="grow shrink-0 basis-0 w-full gap-4 items-center flex">
            <SubframeCore.Icon
              className="text-neutral-400 text-header font-header"
              name="FeatherUsers"
            />
            <span className="grow shrink-0 basis-0 w-full text-default-font text-section-header font-section-header line-clamp-1">
              Employees
            </span>
          </div>
          <Select
            className="flex-none w-auto h-9"
            placeholder="Last 7 days"
            size="Small"
            value={selectedRosterDateFilter}
            onValueChange={(label) => {
              setSelectedRosterDateFilter(label);
              handleGetLastNDaysFromToday(
                ROSTER_DATE_FILTER_OPTIONS[label].value
              );
            }}
          >
            {Object.keys(ROSTER_DATE_FILTER_OPTIONS).map((key) => (
              <Select.Item
                value={ROSTER_DATE_FILTER_OPTIONS[key].label}
                key={ROSTER_DATE_FILTER_OPTIONS[key].value}
              />
            ))}
          </Select>
        </PageHeader>

        <div className="w-full flex-col gap-4 items-start flex">
          <PageHeader>
            <div className="grow shrink-0 basis-0 w-full gap-4 items-center flex">
              <SubframeCore.Icon
                className="text-subtext-color text-section-header font-section-header"
                name="FeatherGauge"
              />
              <span className="grow shrink-0 basis-0 w-full text-default-font text-subheader font-subheader line-clamp-1">
                Highlights
              </span>
            </div>
          </PageHeader>
          <div className="w-full gap-4 items-center flex flex-wrap sm:flex-nowrap">
            {isLoadingRosterStats ? (
              <>
                <Skeleton variant="rounded" width="100%" height="85px" />
                <Skeleton variant="rounded" width="100%" height="85px" />
                <Skeleton variant="rounded" width="100%" height="85px" />
              </>
            ) : (
              <>
                <CustomStatCard
                  title="Responses"
                  value={
                    isNumber(rosterStats?.survey_completed)
                      ? rosterStats?.survey_completed?.toString()
                      : "-"
                  }
                  variant="brand"
                />
                <CustomStatCard
                  title="Response Rate"
                  value={
                    isNumber(rosterStats?.response_rate)
                      ? `${rosterStats?.response_rate?.toString()}%`
                      : "-"
                  }
                  variant="brand"
                />
                <CustomStatCard
                  title="Avg. Sentiment Score"
                  value={
                    isNumber(rosterStats?.overall_sentiment_score)
                      ? rosterStats?.overall_sentiment_score?.toString()
                      : "-"
                  }
                  variant="brand"
                />
              </>
            )}
          </div>
        </div>

        <div className="w-full min-h-[650px] h-full flex-col gap-4 items-start flex">
          <PageHeader>
            <div className="grow shrink-0 basis-0 w-full gap-4 items-center flex">
              <SubframeCore.Icon
                className="text-subtext-color text-section-header font-section-header"
                name="FeatherUsers2"
              />
              <span className="grow shrink-0 basis-0 w-full text-default-font text-subheader font-subheader line-clamp-1">
                Roster
              </span>
            </div>
          </PageHeader>
          <div className="w-full h-full">
            <RosterGrid
              rows={roster}
              isLoading={isLoadingRoster}
              rowCount={rosterRowCountState}
              paginationModel={rosterPaginationModel}
              viewEmployeeProfile={navigateToEmployeeProfile}
              setRosterPaginationModel={setRosterPaginationModel}
            />
          </div>
        </div>
      </div>
      {renderErrorSnackbar()}
    </>
  );
}

export default Roster;
