import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  ERROR_CODE,
  REFERRAL_SMS_PERMISSIONS,
  DEFAULT_INBOX_DATE_FILTER,
  RECOGNITION_POST_PERMISSIONS,
} from "../constants/constants";
import { fetchUser } from "../services/applications";

import { get } from "../utils/localStorage";
import { isEmpty as isStringEmpty } from "../utils/string";

const useUser = () => {
  useEffect(() => {
    handleFetchUser();
  }, []);

  const navigate = useNavigate();

  const [user, setUser] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Handles the fetching of the user.
   */
  const handleFetchUser = () => {
    if (isStringEmpty(get("token")) && isStringEmpty(get("refreshToken"))) {
      return;
    }

    fetchUserAsync();
  };

  /**
   * Method to fetch the user via API call.
   */
  const fetchUserAsync = async () => {
    let response;

    setIsLoading(true);

    try {
      response = await fetchUser(get("userRole"));
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return;
    } finally {
      setIsLoading(false);
    }

    setUser({
      ...response?.result,
      email: response?.result?.email || get("email"),
      configuration: {
        ...response?.result?.configuration,
        enable_referral_sms:
          !!REFERRAL_SMS_PERMISSIONS[
            response?.result?.name || response?.result?.agency
          ],
        enable_recognition_post:
          !!RECOGNITION_POST_PERMISSIONS[
            response?.result?.name || response?.result?.agency
          ],
        default_date_filter:
          DEFAULT_INBOX_DATE_FILTER[
            response?.result?.name || response?.result?.agency
          ],
      },
    });
  };

  return { user, isLoading };
};

export default useUser;
