import { SENTIMENT } from "../constants/retention/constants";

/**
 * Initializes the sentiment summary on basis of sentiment response and sub-categorization by sentiment score.
 *
 * @param {Number} surveyResponseRate
 * @param {String} sentiment
 * @returns {String}
 */
export const initializeSentimentSummary = (
  surveyResponseRate = 0,
  sentiment
) => {
  if (surveyResponseRate === 0) {
    return SENTIMENT["No Data"].value;
  }

  return SENTIMENT[sentiment]?.value ?? SENTIMENT["No Data"].value;
};
