import React from "react";

import {
  Card,
  Step,
  Avatar,
  Divider,
  Stepper,
  Skeleton,
  StepLabel,
  CardContent,
  CardActionArea,
  Box,
} from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import Job from "../../assets/images/retention/job.png";
import Tenure from "../../assets/images/retention/tenure.png";
import Location from "../../assets/images/retention/location.png";

import "./EmployeeOverviewCard.css";

import SentimentSummary from "../SentimentSummary";
import SentimentBreakdown from "../SentimentBreakdown";

import { isEmpty as isArrayEmpty } from "../../utils/array";
import { initializeSentimentSummary } from "../../utils/sentiment";
import { getAcronym, isEmpty as isStringEmpty } from "../../utils/string";

import { useCustomLayout, useMobileLayout } from "../../hooks/mediaQueryHooks";

export default function EmployeeOverviewCard({
  employee = {},
  isLoading = false,
  onCardClick = () => {},
}) {
  const mobileLayout = useMobileLayout();
  const custom600Layout = useCustomLayout(600);

  /**
   * Gets the selected user's full name.
   *
   * @param {String} firstName
   * @param {String} lastName
   * @returns {String}
   */
  const getFullName = (firstName = "", lastName = "") => {
    return `${isStringEmpty(firstName) ? "" : firstName}${
      !isStringEmpty(firstName) && !isStringEmpty(lastName) ? " " : ""
    }${isStringEmpty(lastName) ? "" : lastName}`;
  };

  /**
   * Gets the step icon according to the survey status.
   *
   * @param {Boolean} survey
   * @returns {Component}
   */
  const getSurveyStepIcon = (survey) => {
    return survey?.completed
      ? () => (
          <CheckCircleIcon
            sx={{
              width: "16px",
              height: "16px",
              color: "#5a9cd5",
            }}
          />
        )
      : () => (
          <ErrorIcon
            sx={{
              width: "16px",
              height: "16px",
              color: "#C22727",
            }}
          />
        );
  };

  /**
   * Method to render the survey history.
   *
   * @param {Array} surveyTimeline
   * @returns {Component}
   */
  const renderSurveyHistory = (surveyTimeline = []) => {
    return isArrayEmpty(surveyTimeline) ? (
      <>-</>
    ) : (
      <Stepper nonLinear alternativeLabel activeStep={-1}>
        {surveyTimeline.map((survey, index) => (
          <Step
            key={index}
            sx={{
              minWidth: "80px",
              padding: 0,
              "& .MuiSvgIcon-root": {
                color: "var(--md-sys-color-primary-light)",
              },
            }}
          >
            <StepLabel
              sx={{
                "& .MuiStepLabel-label": {
                  fontSize: "var(--spacing-3x)",
                },
                "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
                  marginTop: "var(--spacing-1x)",
                },
              }}
              StepIconComponent={getSurveyStepIcon(survey)}
            >
              {survey?.post_termination ? (
                <span
                  style={{
                    color: "var(--md-sys-color-error-light)",
                  }}
                >
                  Exit
                </span>
              ) : (
                `Day ${survey?.trigger_day}`
              )}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    );
  };

  return (
    <Card
      sx={{
        padding: "0",
        width: "100%",
        height: "100%",
      }}
    >
      <CardActionArea
        onClick={() => onCardClick(employee?.profile?.employee_id)}
        sx={{ width: "100%", height: "100%" }}
      >
        <CardContent
          sx={{
            width: "100%",
            height: "100%",
            "& .super-app-theme--header": {
              backgroundColor: "#EDEFF5",
            },
            padding: "0",
            "&:last-child": {
              paddingBottom: 0,
            },
          }}
        >
          <div className="profile-section">
            {/* Note: Removing this avatar component for now. Can add it later once we have employee's profile pictures. */}
            {/* <div className="left">
              {false ? (
                <Skeleton variant="circular" width="40px" height="40px" />
              ) : (
                <Avatar
                  src={employee?.["profile"]?.profile_picture_url}
                  sx={{
                    width: 40,
                    height: 40,
                    fontSize: "16px",
                    bgcolor: "var(--md-sys-color-primary-light)",
                  }}
                >
                  {getAcronym(
                    getFullName(
                      employee?.["profile"]?.first_name,
                      employee?.["profile"]?.last_name
                    )
                  )}
                </Avatar>
              )}
            </div> */}
            <div className="right">
              {isLoading ? (
                <Skeleton variant="rounded" width="247px" height="44px" />
              ) : (
                <>
                  <div className="name-wrapper">
                    <h3>
                      {isStringEmpty(
                        getFullName(
                          employee?.["profile"]?.first_name,
                          employee?.["profile"]?.last_name
                        )
                      )
                        ? "..."
                        : getFullName(
                            employee?.["profile"]?.first_name,
                            employee?.["profile"]?.last_name
                          )}
                    </h3>
                  </div>
                  <div className="info-wrapper">
                    <div className="info">
                      <img src={Job} alt="Job" />
                      <span>
                        {!isStringEmpty(employee?.["profile"]?.job_title)
                          ? employee?.["profile"]?.job_title
                          : "-"}
                      </span>
                    </div>
                    <Divider orientation="vertical" sx={{ height: "14px" }} />
                    <div className="info">
                      <img src={Location} alt="Location" />
                      <span>
                        {!isStringEmpty(employee?.["profile"]?.region)
                          ? employee?.["profile"]?.region
                          : "-"}
                      </span>
                    </div>
                    <Divider orientation="vertical" sx={{ height: "14px" }} />
                    <div className="info">
                      <img src={Tenure} alt="Tenure" />
                      <span>
                        {!isStringEmpty(employee?.["profile"]?.tenure)
                          ? employee?.["profile"]?.tenure
                          : "-"}
                      </span>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <Divider />
          <div className="sentiment-section">
            <div className="left">
              <span className="header-label">Sentiment Breakdown</span>
              <SentimentBreakdown data={employee?.["survey"]?.Sentiment} />
            </div>

            <div>
              {!mobileLayout && <Divider orientation="vertical" />}
              {custom600Layout && <Divider />}
            </div>
            <div className="right">
              <div className="sentiment-summary">
                <span className="header-label margin-bottom-2x">
                  Sentiment Summary
                </span>
                <SentimentSummary
                  value={initializeSentimentSummary(
                    employee?.["survey"]?.["Response Rate"],
                    employee?.["survey"]?.["Overall Sentiment"]
                  )}
                />
              </div>
              <div>
                <Divider />
              </div>
              {!custom600Layout && (
                <div className="sentiment-score">
                  <span className="header-label">
                    Sentiment Score:
                    <span className="value">
                      {employee?.["survey"]?.["Sentiment Score"]}
                    </span>
                  </span>
                </div>
              )}
              {custom600Layout && (
                <Box
                  className="sentiment-score"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "var(--spacing-2x)",
                  }}
                >
                  <span className="header-label">Sentiment Score</span>
                  <span className="value">
                    {employee?.["survey"]?.["Sentiment Score"]}
                  </span>
                </Box>
              )}
            </div>
          </div>
          <Divider />
          <div className="survey-section">
            <span className="header-label">Survey History</span>
            <div className="stepper-wrapper">
              {isLoading ? (
                <div className="loader-state-wrapper left-aligned">
                  <Skeleton variant="rounded" width="200px" height="40px" />
                </div>
              ) : (
                renderSurveyHistory(employee?.["survey_timeline"])
              )}
            </div>
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
