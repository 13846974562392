import { Skeleton, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { EVENT_TYPES } from "constants/mixpanel";
import { RECOGNITION_DIRECTED_AT } from "modules/Retention/constants/constants";
import { getRecognitionCategoriesForAgency } from "modules/Retention/utils/recognition";
import React, { useState } from "react";
import { mixpanelTrackEvent } from "services/mixpanel";
import { IconButton } from "subframe";
import { Avatar } from "subframe/components/Avatar";
import { Button } from "subframe/components/Button";
import { TextArea } from "subframe/components/TextArea";
import { ToggleGroup } from "subframe/components/ToggleGroup";
import { getAcronym, isEmpty as isStringEmpty } from "utils/string";
import Logo from "../../assets/images/logoV2.svg";

function RecognitionForm({
  employeeName,
  jobTitle,
  employeeId,
  agencyId,
  officeLocation,
  category,
  supervisor,
  onClose,
  agencyLogo,
  agencyName,
  recognizedById,
  isLoadingUser = false,
  postRecognition = () => {},
}) {
  const [reason, setReason] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(category);
  const [isErrorMessageShown, setIsErrorMessageShown] = useState(false);
  const [isSuccessMessageShown, setIsSuccessMessageShown] = useState(false);
  const [hasTriedFormSubmission, setHasTriedFormSubmission] = useState(false);

  const isFormValid =
    !isStringEmpty(selectedCategory) && !isStringEmpty(reason);

  const resetForm = () => {
    setReason("");
    setHasTriedFormSubmission(false);
    setSelectedCategory(category);
  };

  /**
   * Submits the recognition post form.
   *
   * @param {Object} payload
   * @returns {Object}
   */
  const submitRecognitionPostAsync = async (payload) => {
    try {
      return await postRecognition(payload);
    } catch (error) {
      return { hasError: true, error };
    }
  };

  /**
   * Handles the submission of the new recognition.
   */
  const handleFormSubmit = async () => {
    setHasTriedFormSubmission(true);

    if (!isFormValid) {
      return;
    }

    setIsSubmitting(true);
    const response = await submitRecognitionPostAsync({
      recognized_id: employeeId,
      ...(recognizedById && { recognized_by_id: recognizedById }),
      agency_id: agencyId,
      directed_at: RECOGNITION_DIRECTED_AT.PEER.value, // hardcoded this option for now
      category: selectedCategory,
      reason,
    });

    setIsSubmitting(false);

    mixpanelTrackEvent(EVENT_TYPES.RECOGNITION_SEND);

    if (response?.hasError) {
      setErrorMessage(
        `Error while submitting recognition: ${response?.error?.response?.data?.message}`
      );
      setIsErrorMessageShown(true);

      return;
    }

    resetForm();
    setSuccessMessage("Successfully sent recognition.");
    setIsSuccessMessageShown(true);
  };

  /**
   * Renders the error snackbar.
   *
   * @returns {Component}
   */
  const renderErrorSnackbar = () => (
    <Snackbar
      open={isErrorMessageShown}
      autoHideDuration={6000}
      onClose={() => setIsErrorMessageShown(false)}
    >
      <MuiAlert
        onClose={() => setIsErrorMessageShown(false)}
        severity="error"
        sx={{ width: "100%" }}
      >
        {errorMessage}
      </MuiAlert>
    </Snackbar>
  );

  /**
   * Renders the success snackbar.
   *
   * @returns {Component}
   */
  const renderSuccessSnackbar = () => (
    <Snackbar
      open={isSuccessMessageShown}
      autoHideDuration={6000}
      onClose={() => setIsSuccessMessageShown(false)}
    >
      <MuiAlert
        onClose={() => setIsSuccessMessageShown(false)}
        severity="success"
        sx={{ width: "100%" }}
      >
        {successMessage}
      </MuiAlert>
    </Snackbar>
  );

  return (
    <>
      <div className="flex h-full md:h-auto w-full md:max-w-[768px] flex-col items-start border border-solid border-neutral-border">
        <div className="flex h-16 w-full flex-none items-center justify-between border-b border-solid border-neutral-border pr-4 pl-4 gap-6">
          <img
            className="w-16 flex-none"
            src={isStringEmpty(agencyLogo) ? Logo : agencyLogo}
            alt="Levo"
          />
          <div className="w-full h-full flex items-center">
            <span className="text-subheader font-subheader text-default-font">
              Recognition
            </span>
          </div>
          {onClose && (
            <IconButton size="small" icon="FeatherX" onClick={onClose} />
          )}
        </div>
        <div className="flex h-full w-full grow shrink-0 basis-0 flex-col items-center">
          <div className="flex w-full flex-col items-start border-b border-solid border-neutral-border pt-6 pr-4 pb-6 pl-4">
            <div className="flex h-full w-full grow shrink-0 basis-0 items-center justify-center gap-4">
              <Avatar>{getAcronym(employeeName)}</Avatar>
              <div className="flex h-full w-full grow shrink-0 basis-0 flex-col items-start gap-2">
                <span className="text-body-bold font-body-bold text-default-font">
                  {isStringEmpty(employeeName) ? "-" : employeeName}
                </span>
                <div className="flex items-start gap-x-2 gap-y-2 w-full flex-wrap">
                  <span className="text-label font-label text-subtext-color">
                    {isStringEmpty(jobTitle) ? "-" : jobTitle}
                  </span>
                  <div className="flex items-start gap-2">
                    <span className="text-label font-label text-subtext-color">
                      •
                    </span>
                    <span className="text-label font-label text-subtext-color">
                      {isStringEmpty(officeLocation) ? "-" : officeLocation}
                    </span>
                  </div>
                  <div className="flex items-start gap-2">
                    <span className="text-label font-label text-subtext-color">
                      •
                    </span>
                    <span className="text-label font-label text-subtext-color">
                      {isStringEmpty(supervisor) ? "-" : supervisor}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container max-w-none flex h-full w-full grow shrink-0 basis-0 flex-col items-center gap-6 pt-4 pr-4 pb-4 pl-4">
            <div className="flex w-full flex-col items-center gap-2">
              <span className="w-full text-body-bold font-body-bold text-default-font">
                Category
              </span>
              {isLoadingUser ? (
                <Skeleton variant="rounded" width="100%" height="100px" />
              ) : (
                <ToggleGroup
                  className="h-auto w-full flex-none"
                  value={selectedCategory}
                  onValueChange={setSelectedCategory}
                >
                  <div className="flex w-full grow shrink-0 basis-0 flex-col items-start gap-px md:grid md:grid-cols-2">
                    {getRecognitionCategoriesForAgency(agencyName).map(
                      (category) => (
                        <ToggleGroup.Item
                          key={category.value}
                          className="h-10 w-full flex justify-start md:justify-center"
                          icon={category.icon}
                          value={category.value}
                        >
                          {category.label}
                        </ToggleGroup.Item>
                      )
                    )}
                  </div>
                </ToggleGroup>
              )}

              {hasTriedFormSubmission && isStringEmpty(selectedCategory) && (
                <span className="w-full text-label font-label text-error-700">
                  Category is required
                </span>
              )}
            </div>
            <TextArea
              onChange={(event) => setReason(event?.target?.value)}
              className="h-full w-full grow shrink-0 basis-0"
              label=""
              helpText={
                hasTriedFormSubmission && isStringEmpty(reason)
                  ? "Reason is required"
                  : ""
              }
              error={hasTriedFormSubmission && isStringEmpty(reason)}
            >
              <TextArea.Input
                value={reason}
                className="h-full min-h-[96px] w-full grow shrink-0 basis-0"
                placeholder="Add recognition reason..."
              />
            </TextArea>
          </div>
        </div>
        <div className="w-full p-4">
          <Button
            className="h-10 w-full flex-none"
            disabled={isSubmitting || isLoadingUser}
            loading={isSubmitting}
            onClick={handleFormSubmit}
          >
            Send
          </Button>
        </div>
      </div>
      {renderSuccessSnackbar()}
      {renderErrorSnackbar()}
    </>
  );
}

export default RecognitionForm;
