import React from "react";

import dayjs from "dayjs";

import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import { Chip, Divider, IconButton } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { isEmpty as isStringEmpty } from "../../utils/string";

import { getLastNDaysFromToday } from "../../utils/date";

import "./RetentionFilter.css";

export default function RetentionFilter({
  isLoading,
  filter = {},
  close = () => {},
  reset = () => {},
  apply = () => {},
  isFilterApplied = false,
  changeFilter = () => {},
}) {
  const handleGetLastNDaysFromToday = (n = 0) => {
    const result = getLastNDaysFromToday(n);

    changeFilter(
      "startDate",
      dayjs(result?.startDate)?.format("YYYY-MM-DD") === "Invalid Date"
        ? null
        : dayjs(result?.startDate)?.format("YYYY-MM-DD")
    );

    changeFilter(
      "endDate",
      dayjs(result?.endDate)?.format("YYYY-MM-DD") === "Invalid Date"
        ? null
        : dayjs(result?.endDate)?.format("YYYY-MM-DD")
    );
  };

  return (
    <div className="filter-retention-main-wrapper">
      <div className="filter-retention-header-wrapper">
        <div className="filter-retention-header-left-wrapper">
          <h3>Apply Filter</h3>
        </div>
        <div className="filter-retention-header-right-wrapper">
          <IconButton
            aria-label="more"
            id="close-button"
            title="Close"
            sx={{
              width: 28,
              height: 28,
            }}
            onClick={close}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <Divider />
      <div className="filter-retention-body-wrapper">
        <section className="content">
          <DatePicker
            label="Start Date"
            value={dayjs(filter?.startDate)}
            maxDate={dayjs(filter?.endDate)}
            onChange={(newValue) => {
              changeFilter(
                "startDate",
                dayjs(newValue)?.format("YYYY-MM-DD") === "Invalid Date"
                  ? null
                  : dayjs(newValue)?.format("YYYY-MM-DD")
              );
            }}
          />
          <DatePicker
            label="End Date"
            value={dayjs(filter?.endDate)}
            onChange={(newValue) => {
              changeFilter(
                "endDate",
                dayjs(newValue)?.format("YYYY-MM-DD") === "Invalid Date"
                  ? null
                  : dayjs(newValue)?.format("YYYY-MM-DD")
              );
            }}
            minDate={dayjs(filter?.startDate)}
          />

          <section className="custom-date-chips">
            <Chip
              label="Last 7 Days"
              onClick={() => handleGetLastNDaysFromToday(7)}
            />
            <Chip
              label="Last 30 Days"
              onClick={() => handleGetLastNDaysFromToday(30)}
            />
          </section>
        </section>
        <Divider />
        <section className="buttons">
          <LoadingButton
            onClick={apply}
            variant="contained"
            loading={isLoading}
            disabled={
              isStringEmpty(filter?.startDate) && isStringEmpty(filter?.endDate)
            }
            sx={{
              bgcolor: "var(--md-sys-color-primary-light)",
            }}
          >
            Apply
          </LoadingButton>
          <LoadingButton
            onClick={reset}
            variant="outlined"
            loading={isLoading}
            disabled={!isFilterApplied}
            sx={{
              color: "var(--md-sys-color-primary-light)",
              borderColor: "var(--md-sys-color-primary-light)",
            }}
          >
            Reset
          </LoadingButton>
        </section>
      </div>
    </div>
  );
}
