import { useState } from "react";
import { Link } from "react-router-dom";

import PersonOffIcon from "@mui/icons-material/PersonOff";

import {
  Select,
  Tooltip,
  MenuItem,
  InputLabel,
  Typography,
  Breadcrumbs,
  FormControl,
} from "@mui/material";

import MultiProgress from "react-multi-progress";

import { isEmpty as isArrayEmpty } from "../../utils/array";
import { isEmpty as isObjectEmpty } from "../../utils/object";

import "./EmployeeDataGrid.css";
import EmployeeDataGridView from "./EmployeeDataGridView";

import SentimentSummary from "../SentimentSummary";
import SentimentBreakdown from "../SentimentBreakdown";
import SentimentSummaryShort from "../SentimentSummaryShort";

import { mixpanelTrackEvent } from "../../services/mixpanel";
import { getEnvironmentTheme } from "../theme/themeEnvironment";

// TODO: Use this as an environment variable.
const THRESHOLD = 50;

export default function EmployeeDataGrid({
  icon,
  data = [],
  value = "",
  label = "",
  configuration = {},
  isLoadingData = false,
  fetchEmployees_region = () => {},
  isUndeterminedSurveyShown = false,
  fetchEmployees_tenureJob = () => {},
  navigateToEmployeeProfile = () => {},
  fetchJobsWithEmployeeStats = () => {},
  fetchEmployees_tenureRegion = () => {},
  fetchTenuresWithEmployeeStats = () => {},
  fetchRegionsWithEmployeesStats = () => {},
  fetchEmployeesWithStatsByQuestion = () => {},
  fetchEmployees_job = () => {},
  fetchJobsWithEmployeesStatsByTenure = () => {},
  fetchQuestionsWithEmployeesStatsByJob = () => {},
  fetchRegionsWithEmployeeStatsByTenure = () => {},
  fetchQuestionCategoriesStatsByEmployee = () => {},
  fetchBranchesWithEmployeesStatsByRegion = () => {},
  fetchQuestionCategoriesWithEmployeeStats = () => {},
  fetchQuestionsStatsByCategoryAndEmployee = () => {},
  fetchCategoriesWithEmployeesStatsByJobType = () => {},
  fetchBranchesWithEmployeesStatsByTenureRegion = () => {},
  fetchJobsWithEmployeesStatsByQuestionCategory = () => {},
  fetchCategoriesWithEmployeesStatsByTenureJobType = () => {},
}) {
  const [isRowClickLocked, setIsRowClickLocked] = useState(false);
  const [tenureClickThroughBy, setTenureClickThroughBy] = useState("region");

  /**
   * Defines the configuration for the click through panel.
   */
  const clickThroughConfiguration = {
    // Current Panel Type.
    region: {
      // Panel's Current Viewing Data list.
      region: {
        currentView: "branch",
        selectedDimension: "region",
        recordParam: "location",
        fetchData: fetchBranchesWithEmployeesStatsByRegion,
      },

      ...(configuration?.isEmployeeClickThroughEnabled && {
        branch: {
          currentView: "employee",
          selectedDimension: "branch",
          recordParam: "location",
          fetchData: fetchEmployees_region,
        },
      }),
    },

    // Current Panel Type.
    job: {
      // Panel's Current Viewing Data list.
      job: {
        currentView: "category",
        selectedDimension: "job",
        recordParam: "type",
        fetchData: fetchCategoriesWithEmployeesStatsByJobType,
      },
      ...(configuration?.isEmployeeClickThroughEnabled && {
        category: {
          currentView: "employee",
          selectedDimension: "category",
          recordParam: "category",
          fetchData: fetchEmployees_job,
        },
      }),
    },

    // Current Panel Type.
    questionCategory: {
      // Panel's Current Viewing Data list.
      questionCategory: {
        currentView: "job",
        selectedDimension: "questionCategory",
        recordParam: "name",
        fetchData: fetchJobsWithEmployeesStatsByQuestionCategory,
      },

      // Panel's Current Viewing Data list.
      job: {
        currentView: "question",
        selectedDimension: "job",
        recordParam: "type",
        fetchData: fetchQuestionsWithEmployeesStatsByJob,
      },

      // Panel's Current Viewing Data list.
      ...(configuration?.isEmployeeClickThroughEnabled && {
        question: {
          currentView: "employee",
          selectedDimension: "question",
          recordParam: "question",
          fetchData: fetchEmployeesWithStatsByQuestion,
        },
      }),
    },

    // Current Panel Type.
    tenure: {
      // Panel's Current Viewing Data list.
      tenure: {
        currentView: tenureClickThroughBy === "region" ? "region" : "job",
        selectedDimension: "tenure",
        recordParam: "days",
        fetchData:
          tenureClickThroughBy === "region"
            ? fetchRegionsWithEmployeeStatsByTenure
            : fetchJobsWithEmployeesStatsByTenure,
      },

      // First Click-Through Start
      region: {
        currentView: "branch",
        selectedDimension: "region",
        recordParam: "location",
        fetchData: fetchBranchesWithEmployeesStatsByTenureRegion,
      },

      job: {
        currentView: "category",
        selectedDimension: "job",
        recordParam: "type",
        fetchData: fetchCategoriesWithEmployeesStatsByTenureJobType,
      },
      // First Click-Through End

      // Second Click-Through Start
      ...(configuration?.isEmployeeClickThroughEnabled && {
        branch: {
          currentView: "employee",
          selectedDimension: "branch",
          recordParam: "branch",
          fetchData: fetchEmployees_tenureRegion,
        },
      }),

      ...(configuration?.isEmployeeClickThroughEnabled && {
        category: {
          currentView: "employee",
          selectedDimension: "category",
          recordParam: "category",
          fetchData: fetchEmployees_tenureJob,
        },
      }),
      // Second Click-Through End
    },

    // Current Panel Type.
    employeeProfile_questionCategory: {
      // Panel's Current Viewing Data list.
      employeeProfile_questionCategory: {
        currentView: "employeeProfile_question",
        selectedDimension: "employeeProfile_questionCategory",
        recordParam: "name",
        fetchData: fetchQuestionsStatsByCategoryAndEmployee,
      },
    },
  };

  const [panel, setPanel] = useState({
    currentView: value,
    selectedRecord: null,
    recordLinkedList: [],
    selectedRecordId: null,
    selectedDimension: null,
  });

  const getColumns = (panel = "", currentView = "") => {
    if (panel === "region") {
      switch (currentView) {
        case "region":
          return [
            {
              field: "location",
              headerName: "Region",
              flex: 1,
              headerClassName: "super-app-theme--header",
              minWidth: 100,
              renderCell: (params) => (
                <div className="custom-datagrid-cell-wrapper">
                  {params?.value}
                </div>
              ),
            },
            {
              field: "employees",
              headerName: "Employees (#)",
              align: "left",
              headerClassName: "super-app-theme--header",
              flex: 1,
              minWidth: 100,
            },
            {
              field: "responseRate",
              headerName: "Survey Response",
              flex: 1,
              minWidth: 220,
              align: "left",
              headerClassName: "super-app-theme--header",
              renderCell: (params) =>
                renderSurveyAnalytics(
                  params?.row?.surveyCompleted,
                  params?.row?.surveySent,
                  params?.value
                ),
            },
            {
              field: "sentiment",
              headerName: "Sentiment",
              flex: 1,
              minWidth: 140,
              headerClassName: "super-app-theme--header",
              renderCell: (params) => (
                <div className="custom-datagrid-cell-wrapper">
                  <SentimentSummaryShort
                    score={initializeSentimentScore(
                      params?.row?.responseRate,
                      params?.row?.sentimentScore,
                      isUndeterminedSurveyShown,
                      THRESHOLD
                    )}
                    summary={initializeSentimentSummary(
                      params?.row?.responseRate,
                      params?.value,
                      isUndeterminedSurveyShown,
                      THRESHOLD
                    )}
                  />
                </div>
              ),
            },
          ];
        case "branch":
          return [
            {
              field: "location",
              headerName: "Branch",
              flex: 1,
              headerClassName: "super-app-theme--header",
              minWidth: 100,
              renderCell: (params) => (
                <div className="custom-datagrid-cell-wrapper">
                  {params?.value}
                </div>
              ),
            },
            {
              field: "employees",
              headerName: "Employees (#)",
              align: "left",
              headerClassName: "super-app-theme--header",
              flex: 1,
              minWidth: 100,
            },
            {
              field: "responseRate",
              headerName: "Survey Response",
              flex: 1,
              minWidth: 220,
              align: "left",
              headerClassName: "super-app-theme--header",
              renderCell: (params) =>
                renderSurveyAnalytics(
                  params?.row?.surveyCompleted,
                  params?.row?.surveySent,
                  params?.value
                ),
            },
            {
              field: "sentiment",
              headerName: "Sentiment",
              flex: 1,
              minWidth: 140,
              headerClassName: "super-app-theme--header",
              renderCell: (params) => (
                <div className="custom-datagrid-cell-wrapper">
                  <SentimentSummaryShort
                    score={initializeSentimentScore(
                      params?.row?.responseRate,
                      params?.row?.sentimentScore,
                      isUndeterminedSurveyShown,
                      THRESHOLD
                    )}
                    summary={initializeSentimentSummary(
                      params?.row?.responseRate,
                      params?.value,
                      isUndeterminedSurveyShown,
                      THRESHOLD
                    )}
                  />
                </div>
              ),
            },
          ];
        case "employee":
          return [
            {
              field: "employee",
              headerName: "Employee",
              flex: 1,
              headerClassName: "super-app-theme--header",
              minWidth: 170,
              renderCell: (params) => (
                <>
                  <div
                    className={`custom-datagrid-cell-wrapper employee-cell ${
                      !params?.row?.isActive ? "muted" : ""
                    }`}
                  >
                    {params?.value}
                  </div>
                  {!params?.row?.isActive && (
                    <div className="terminated-employee-icon-cell">
                      <Tooltip id="button-report" title="Terminated">
                        <PersonOffIcon
                          fontSize="small"
                          color="error"
                          title="Terminated"
                        />
                      </Tooltip>
                    </div>
                  )}
                </>
              ),
            },
            {
              field: "job",
              headerName: "Job",
              align: "left",
              headerClassName: "super-app-theme--header",
              flex: 1,
              minWidth: 100,
              renderCell: (params) => (
                <div
                  className={`custom-datagrid-cell-wrapper ${
                    !params?.row?.isActive ? "muted" : ""
                  }`}
                >
                  {params?.value}
                </div>
              ),
            },
            {
              field: "tenure",
              headerName: "Tenure",
              flex: 1,
              minWidth: 150,
              align: "left",
              headerClassName: "super-app-theme--header",
              renderCell: (params) => (
                <div
                  className={`custom-datagrid-cell-wrapper ${
                    !params?.row?.isActive ? "muted" : ""
                  }`}
                >
                  {params?.value}
                </div>
              ),
            },
            {
              field: "sentiment",
              headerName: "Sentiment",
              flex: 1,
              minWidth: 140,
              headerClassName: "super-app-theme--header",
              renderCell: (params) => (
                <div
                  className={`custom-datagrid-cell-wrapper ${
                    !params?.row?.isActive ? "muted" : ""
                  }`}
                >
                  <SentimentSummaryShort
                    score={params?.row?.sentimentScore}
                    summary={params?.value}
                  />
                </div>
              ),
            },
          ];
        default:
          return [];
      }
    }

    if (panel === "job") {
      switch (currentView) {
        case "job":
          return [
            {
              field: "type",
              headerName: "Job Type",
              flex: 1,
              headerClassName: "super-app-theme--header",
              minWidth: 100,
              renderCell: (params) => (
                <div className="custom-datagrid-cell-wrapper">
                  {params?.value}
                </div>
              ),
            },
            {
              field: "employees",
              headerName: "Employees (#)",
              flex: 1,
              minWidth: 100,
              align: "left",
              headerClassName: "super-app-theme--header",
            },
            {
              field: "responseRate",
              headerName: "Survey Response",
              minWidth: 220,
              flex: 1,
              align: "left",
              headerClassName: "super-app-theme--header",
              renderCell: (params) =>
                renderSurveyAnalytics(
                  params?.row?.surveyCompleted,
                  params?.row?.surveySent,
                  params?.value
                ),
            },
            {
              field: "sentiment",
              headerName: "Sentiment",
              flex: 1,
              minWidth: 140,
              headerClassName: "super-app-theme--header",
              renderCell: (params) => (
                <div className="custom-datagrid-cell-wrapper">
                  <SentimentSummaryShort
                    score={initializeSentimentScore(
                      params?.row?.responseRate,
                      params?.row?.sentimentScore,
                      isUndeterminedSurveyShown,
                      THRESHOLD
                    )}
                    summary={initializeSentimentSummary(
                      params?.row?.responseRate,
                      params?.value,
                      isUndeterminedSurveyShown,
                      THRESHOLD
                    )}
                  />
                </div>
              ),
            },
          ];
        case "category":
          return [
            {
              field: "category",
              headerName: "Category",
              flex: 1,
              headerClassName: "super-app-theme--header",
              minWidth: 100,
              renderCell: (params) => (
                <div className="custom-datagrid-cell-wrapper">
                  {params?.value}
                </div>
              ),
            },
            {
              field: "employees",
              headerName: "Employees (#)",
              flex: 1,
              minWidth: 100,
              align: "left",
              headerClassName: "super-app-theme--header",
            },
            {
              field: "responseRate",
              headerName: "Survey Response",
              minWidth: 220,
              flex: 1,
              align: "left",
              headerClassName: "super-app-theme--header",
              renderCell: (params) =>
                renderSurveyAnalytics(
                  params?.row?.surveyCompleted,
                  params?.row?.surveySent,
                  params?.value
                ),
            },
            {
              field: "sentiment",
              headerName: "Sentiment",
              flex: 1,
              minWidth: 140,
              headerClassName: "super-app-theme--header",
              renderCell: (params) => (
                <div className="custom-datagrid-cell-wrapper">
                  <SentimentSummaryShort
                    score={initializeSentimentScore(
                      params?.row?.responseRate,
                      params?.row?.sentimentScore,
                      isUndeterminedSurveyShown,
                      THRESHOLD
                    )}
                    summary={initializeSentimentSummary(
                      params?.row?.responseRate,
                      params?.value,
                      isUndeterminedSurveyShown,
                      THRESHOLD
                    )}
                  />
                </div>
              ),
            },
          ];
        case "employee":
          return [
            {
              field: "employee",
              headerName: "Employee",
              flex: 1,
              headerClassName: "super-app-theme--header",
              minWidth: 170,
              renderCell: (params) => (
                <>
                  <div
                    className={`custom-datagrid-cell-wrapper employee-cell ${
                      !params?.row?.isActive ? "muted" : ""
                    }`}
                  >
                    {params?.value}
                  </div>
                  {!params?.row?.isActive && (
                    <div className="terminated-employee-icon-cell">
                      <Tooltip id="button-report" title="Terminated">
                        <PersonOffIcon
                          fontSize="small"
                          color="error"
                          title="Terminated"
                        />
                      </Tooltip>
                    </div>
                  )}
                </>
              ),
            },
            {
              field: "job",
              headerName: "Job",
              align: "left",
              headerClassName: "super-app-theme--header",
              flex: 1,
              minWidth: 100,
              renderCell: (params) => (
                <div
                  className={`custom-datagrid-cell-wrapper ${
                    !params?.row?.isActive ? "muted" : ""
                  }`}
                >
                  {params?.value}
                </div>
              ),
            },
            {
              field: "tenure",
              headerName: "Tenure",
              flex: 1,
              minWidth: 150,
              align: "left",
              headerClassName: "super-app-theme--header",
              renderCell: (params) => (
                <div
                  className={`custom-datagrid-cell-wrapper ${
                    !params?.row?.isActive ? "muted" : ""
                  }`}
                >
                  {params?.value}
                </div>
              ),
            },
            {
              field: "sentiment",
              headerName: "Sentiment",
              flex: 1,
              minWidth: 140,
              headerClassName: "super-app-theme--header",
              renderCell: (params) => (
                <div
                  className={`custom-datagrid-cell-wrapper ${
                    !params?.row?.isActive ? "muted" : ""
                  }`}
                >
                  <SentimentSummaryShort
                    score={params?.row?.sentimentScore}
                    summary={params?.value}
                  />
                </div>
              ),
            },
          ];
        default:
          return [];
      }
    }

    if (panel === "questionCategory") {
      switch (currentView) {
        case "questionCategory":
          return [
            {
              field: "name",
              headerName: "Category Name",
              minWidth: 180,
              headerClassName: "super-app-theme--header",
              renderCell: (params) => (
                <div className="custom-datagrid-cell-wrapper">
                  {params?.value}
                </div>
              ),
            },
            {
              field: "question",
              headerName: "Questions (#)",
              minWidth: 150,
              align: "left",
              headerClassName: "super-app-theme--header",
            },
            {
              field: "responseRate",
              headerName: <i>Coming Soon</i>,
              minWidth: 150,
              align: "left",
              headerClassName: "super-app-theme--header",
              renderCell: (params) => "-",
            },
            {
              field: "sentiment",
              headerName: "Sentiment",
              minWidth: 200,
              headerClassName: "super-app-theme--header",
              renderCell: (params) => (
                <div className="custom-datagrid-cell-wrapper">
                  <SentimentSummaryShort
                    score={initializeSentimentScore(
                      params?.row?.responseRate,
                      params?.row?.sentimentScore,
                      isUndeterminedSurveyShown,
                      THRESHOLD
                    )}
                    summary={initializeSentimentSummary(
                      params?.row?.responseRate,
                      params?.value,
                      isUndeterminedSurveyShown,
                      THRESHOLD
                    )}
                  />
                </div>
              ),
            },
            {
              field: "breakdown",
              headerName: "Breakdown",
              flex: 1,
              minWidth: 350,
              headerClassName: "super-app-theme--header",
              renderCell: (params) => (
                <SentimentBreakdown data={params?.value} />
              ),
              sortable: false,
              filterable: false,
            },
          ];
        case "question":
          return [
            {
              field: "question",
              headerName: "Question Name",
              minWidth: 420,
              headerClassName: "super-app-theme--header",
              renderCell: (params) => (
                <div className="custom-datagrid-cell-wrapper">
                  {params?.value}
                </div>
              ),
            },
            {
              field: "totalResponse",
              headerName: "Total Response",
              minWidth: 150,
              align: "left",
              flex: 1,
              headerClassName: "super-app-theme--header",
              renderCell: (params) => params?.value,
            },
            {
              field: "sentiment",
              headerName: "Sentiment",
              minWidth: 200,
              headerClassName: "super-app-theme--header",
              renderCell: (params) => (
                <div className="custom-datagrid-cell-wrapper">
                  <SentimentSummaryShort
                    score={initializeSentimentScore(
                      params?.row?.responseRate,
                      params?.row?.sentimentScore,
                      isUndeterminedSurveyShown,
                      THRESHOLD
                    )}
                    summary={initializeSentimentSummary(
                      params?.row?.responseRate,
                      params?.value,
                      isUndeterminedSurveyShown,
                      THRESHOLD
                    )}
                  />
                </div>
              ),
            },
            {
              field: "breakdown",
              headerName: "Breakdown",
              flex: 1,
              minWidth: 350,
              headerClassName: "super-app-theme--header",
              renderCell: (params) => (
                <SentimentBreakdown data={params?.value} />
              ),
              sortable: false,
              filterable: false,
            },
          ];
        case "employee":
          return [
            {
              field: "employee",
              headerName: "Employee",
              flex: 1,
              headerClassName: "super-app-theme--header",
              minWidth: 180,
              renderCell: (params) => (
                <>
                  <div
                    className={`custom-datagrid-cell-wrapper employee-cell ${
                      !params?.row?.isActive ? "muted" : ""
                    }`}
                  >
                    {params?.value}
                  </div>
                  {!params?.row?.isActive && (
                    <div className="terminated-employee-icon-cell">
                      <Tooltip id="button-report" title="Terminated">
                        <PersonOffIcon
                          fontSize="small"
                          color="error"
                          title="Terminated"
                        />
                      </Tooltip>
                    </div>
                  )}
                </>
              ),
            },
            {
              field: "job",
              headerName: "Job",
              align: "left",
              headerClassName: "super-app-theme--header",
              flex: 1,
              minWidth: 150,
              renderCell: (params) => (
                <div
                  className={`custom-datagrid-cell-wrapper ${
                    !params?.row?.isActive ? "muted" : ""
                  }`}
                >
                  {params?.value}
                </div>
              ),
            },
            {
              field: "tenure",
              headerName: "Tenure",
              flex: 1,
              minWidth: 150,
              align: "left",
              headerClassName: "super-app-theme--header",
              renderCell: (params) => (
                <div
                  className={`custom-datagrid-cell-wrapper ${
                    !params?.row?.isActive ? "muted" : ""
                  }`}
                >
                  {params?.value}
                </div>
              ),
            },
            {
              field: "sentiment",
              headerName: "Sentiment",
              flex: 1,
              minWidth: 200,
              headerClassName: "super-app-theme--header",
              renderCell: (params) => (
                <div
                  className={`custom-datagrid-cell-wrapper ${
                    !params?.row?.isActive ? "muted" : ""
                  }`}
                >
                  <SentimentSummaryShort
                    score={params?.row?.sentimentScore}
                    summary={params?.value}
                  />
                </div>
              ),
            },
          ];
        case "job":
          return [
            {
              field: "type",
              headerName: "Job Type",
              flex: 1,
              headerClassName: "super-app-theme--header",
              minWidth: 180,
              renderCell: (params) => (
                <div className="custom-datagrid-cell-wrapper">
                  {params?.value}
                </div>
              ),
            },
            {
              field: "question",
              headerName: "Questions (#)",
              flex: 1,
              minWidth: 150,
              align: "left",
              headerClassName: "super-app-theme--header",
            },
            {
              field: "sentiment",
              headerName: "Sentiment",
              flex: 1,
              minWidth: 200,
              headerClassName: "super-app-theme--header",
              renderCell: (params) => (
                <div className="custom-datagrid-cell-wrapper">
                  <SentimentSummaryShort
                    score={initializeSentimentScore(
                      params?.row?.responseRate,
                      params?.row?.sentimentScore,
                      isUndeterminedSurveyShown,
                      THRESHOLD
                    )}
                    summary={initializeSentimentSummary(
                      params?.row?.responseRate,
                      params?.value,
                      isUndeterminedSurveyShown,
                      THRESHOLD
                    )}
                  />
                </div>
              ),
            },
            {
              field: "breakdown",
              headerName: "Breakdown",
              flex: 1,
              minWidth: 350,
              sortable: false,
              filterable: false,
              headerClassName: "super-app-theme--header",
              renderCell: (params) => (
                <SentimentBreakdown data={params?.value} />
              ),
            },
          ];
        default:
          return [];
      }
    }

    if (panel === "tenure") {
      switch (currentView) {
        case "tenure":
          return [
            {
              field: "days",
              headerName: "Tenure Group",
              headerClassName: "super-app-theme--header",
              minWidth: 180,
              renderCell: (params) => (
                <div className="custom-datagrid-cell-wrapper">
                  {params?.value}
                </div>
              ),
            },
            {
              field: "employees",
              headerName: "Employees (#)",
              minWidth: 150,
              align: "left",
              headerClassName: "super-app-theme--header",
            },
            {
              field: "responseRate",
              headerName: "Survey Response",
              minWidth: 300,
              align: "left",
              headerClassName: "super-app-theme--header",
              renderCell: (params) =>
                renderSurveyAnalytics(
                  params?.row?.surveyCompleted,
                  params?.row?.surveySent,
                  params?.value
                ),
            },
            {
              field: "sentiment",
              headerName: "Sentiment",
              minWidth: 200,
              headerClassName: "super-app-theme--header",
              renderCell: (params) => (
                <div className="custom-datagrid-cell-wrapper">
                  <SentimentSummaryShort
                    score={initializeSentimentScore(
                      params?.row?.responseRate,
                      params?.row?.sentimentScore,
                      isUndeterminedSurveyShown,
                      THRESHOLD
                    )}
                    summary={initializeSentimentSummary(
                      params?.row?.responseRate,
                      params?.value,
                      isUndeterminedSurveyShown,
                      THRESHOLD
                    )}
                  />
                </div>
              ),
            },
            {
              field: "breakdown",
              headerName: "Breakdown",
              flex: 1,
              minWidth: 350,
              sortable: false,
              filterable: false,
              headerClassName: "super-app-theme--header",
              renderCell: (params) => (
                <SentimentBreakdown data={params?.value} />
              ),
            },
          ];
        case "region":
          return [
            {
              field: "location",
              headerName: "Region",
              flex: 1,
              headerClassName: "super-app-theme--header",
              minWidth: 180,
              renderCell: (params) => (
                <div className="custom-datagrid-cell-wrapper">
                  {params?.value}
                </div>
              ),
            },
            {
              field: "employees",
              headerName: "Employees (#)",
              align: "left",
              headerClassName: "super-app-theme--header",
              flex: 1,
              minWidth: 150,
            },
            {
              field: "responseRate",
              headerName: "Survey Response",
              flex: 1,
              minWidth: 300,
              align: "left",
              headerClassName: "super-app-theme--header",
              renderCell: (params) =>
                renderSurveyAnalytics(
                  params?.row?.surveyCompleted,
                  params?.row?.surveySent,
                  params?.value
                ),
            },
            {
              field: "sentiment",
              headerName: "Sentiment",
              flex: 1,
              minWidth: 200,
              headerClassName: "super-app-theme--header",
              renderCell: (params) => (
                <div className="custom-datagrid-cell-wrapper">
                  <SentimentSummaryShort
                    score={initializeSentimentScore(
                      params?.row?.responseRate,
                      params?.row?.sentimentScore,
                      isUndeterminedSurveyShown,
                      THRESHOLD
                    )}
                    summary={initializeSentimentSummary(
                      params?.row?.responseRate,
                      params?.value,
                      isUndeterminedSurveyShown,
                      THRESHOLD
                    )}
                  />
                </div>
              ),
            },
            {
              field: "breakdown",
              headerName: "Breakdown",
              flex: 1,
              minWidth: 350,
              sortable: false,
              filterable: false,
              headerClassName: "super-app-theme--header",
              renderCell: (params) => (
                <SentimentBreakdown data={params?.value} />
              ),
            },
          ];
        case "branch":
          return [
            {
              field: "branch",
              headerName: "Branch",
              flex: 1,
              headerClassName: "super-app-theme--header",
              minWidth: 180,
              renderCell: (params) => (
                <div className="custom-datagrid-cell-wrapper">
                  {params?.value}
                </div>
              ),
            },
            {
              field: "employees",
              headerName: "Employees (#)",
              align: "left",
              headerClassName: "super-app-theme--header",
              flex: 1,
              minWidth: 150,
            },
            {
              field: "responseRate",
              headerName: "Survey Response",
              flex: 1,
              minWidth: 300,
              align: "left",
              headerClassName: "super-app-theme--header",
              renderCell: (params) =>
                renderSurveyAnalytics(
                  params?.row?.surveyCompleted,
                  params?.row?.surveySent,
                  params?.value
                ),
            },
            {
              field: "sentiment",
              headerName: "Sentiment",
              flex: 1,
              minWidth: 200,
              headerClassName: "super-app-theme--header",
              renderCell: (params) => (
                <div className="custom-datagrid-cell-wrapper">
                  <SentimentSummaryShort
                    score={initializeSentimentScore(
                      params?.row?.responseRate,
                      params?.row?.sentimentScore,
                      isUndeterminedSurveyShown,
                      THRESHOLD
                    )}
                    summary={initializeSentimentSummary(
                      params?.row?.responseRate,
                      params?.value,
                      isUndeterminedSurveyShown,
                      THRESHOLD
                    )}
                  />
                </div>
              ),
            },
          ];
        case "employee":
          return [
            {
              field: "employee",
              headerName: "Employee",
              flex: 1,
              headerClassName: "super-app-theme--header",
              minWidth: 180,
              renderCell: (params) => (
                <>
                  <div
                    className={`custom-datagrid-cell-wrapper employee-cell ${
                      !params?.row?.isActive ? "muted" : ""
                    }`}
                  >
                    {params?.value}
                  </div>
                  {!params?.row?.isActive && (
                    <div className="terminated-employee-icon-cell">
                      <Tooltip id="button-report" title="Terminated">
                        <PersonOffIcon
                          fontSize="small"
                          color="error"
                          title="Terminated"
                        />
                      </Tooltip>
                    </div>
                  )}
                </>
              ),
            },
            {
              field: "job",
              headerName: "Job",
              align: "left",
              headerClassName: "super-app-theme--header",
              flex: 1,
              minWidth: 200,
              renderCell: (params) => (
                <div
                  className={`custom-datagrid-cell-wrapper ${
                    !params?.row?.isActive ? "muted" : ""
                  }`}
                >
                  {params?.value}
                </div>
              ),
            },
            {
              field: "tenure",
              headerName: "Tenure",
              flex: 1,
              minWidth: 200,
              align: "left",
              headerClassName: "super-app-theme--header",
              renderCell: (params) => (
                <div
                  className={`custom-datagrid-cell-wrapper ${
                    !params?.row?.isActive ? "muted" : ""
                  }`}
                >
                  {params?.value}
                </div>
              ),
            },
            {
              field: "sentiment",
              headerName: "Sentiment",
              flex: 1,
              minWidth: 200,
              headerClassName: "super-app-theme--header",
              renderCell: (params) => (
                <div
                  className={`custom-datagrid-cell-wrapper ${
                    !params?.row?.isActive ? "muted" : ""
                  }`}
                >
                  <SentimentSummaryShort
                    score={params?.row?.sentimentScore}
                    summary={params?.value}
                  />
                </div>
              ),
            },
          ];
        case "job":
          return [
            {
              field: "type",
              headerName: "Job Type",
              flex: 1,
              headerClassName: "super-app-theme--header",
              minWidth: 180,
              renderCell: (params) => (
                <div className="custom-datagrid-cell-wrapper">
                  {params?.value}
                </div>
              ),
            },
            {
              field: "employees",
              headerName: "Employees (#)",
              flex: 1,
              minWidth: 150,
              align: "left",
              headerClassName: "super-app-theme--header",
            },
            {
              field: "responseRate",
              headerName: "Survey Response",
              minWidth: 300,
              flex: 1,
              align: "left",
              headerClassName: "super-app-theme--header",
              renderCell: (params) =>
                renderSurveyAnalytics(
                  params?.row?.surveyCompleted,
                  params?.row?.surveySent,
                  params?.value
                ),
            },
            {
              field: "sentiment",
              headerName: "Sentiment",
              flex: 1,
              minWidth: 200,
              headerClassName: "super-app-theme--header",
              renderCell: (params) => (
                <div className="custom-datagrid-cell-wrapper">
                  <SentimentSummaryShort
                    score={initializeSentimentScore(
                      params?.row?.responseRate,
                      params?.row?.sentimentScore,
                      isUndeterminedSurveyShown,
                      THRESHOLD
                    )}
                    summary={initializeSentimentSummary(
                      params?.row?.responseRate,
                      params?.value,
                      isUndeterminedSurveyShown,
                      THRESHOLD
                    )}
                  />
                </div>
              ),
            },
          ];
        case "category":
          return [
            {
              field: "category",
              headerName: "Category",
              flex: 1,
              headerClassName: "super-app-theme--header",
              minWidth: 180,
              renderCell: (params) => (
                <div className="custom-datagrid-cell-wrapper">
                  {params?.value}
                </div>
              ),
            },
            {
              field: "employees",
              headerName: "Employees (#)",
              flex: 1,
              minWidth: 150,
              align: "left",
              headerClassName: "super-app-theme--header",
            },
            {
              field: "responseRate",
              headerName: "Survey Response",
              minWidth: 300,
              flex: 1,
              align: "left",
              headerClassName: "super-app-theme--header",
              renderCell: (params) =>
                renderSurveyAnalytics(
                  params?.row?.surveyCompleted,
                  params?.row?.surveySent,
                  params?.value
                ),
            },
            {
              field: "sentiment",
              headerName: "Sentiment",
              flex: 1,
              minWidth: 200,
              headerClassName: "super-app-theme--header",
              renderCell: (params) => (
                <div className="custom-datagrid-cell-wrapper">
                  <SentimentSummaryShort
                    score={initializeSentimentScore(
                      params?.row?.responseRate,
                      params?.row?.sentimentScore,
                      isUndeterminedSurveyShown,
                      THRESHOLD
                    )}
                    summary={initializeSentimentSummary(
                      params?.row?.responseRate,
                      params?.value,
                      isUndeterminedSurveyShown,
                      THRESHOLD
                    )}
                  />
                </div>
              ),
            },
          ];
        default:
          return [];
      }
    }

    if (panel === "employeeProfile_questionCategory") {
      switch (currentView) {
        case "employeeProfile_questionCategory":
          return [
            {
              field: "name",
              headerName: "Category Name",
              minWidth: 180,
              headerClassName: "super-app-theme--header",
              renderCell: (params) => (
                <div className="custom-datagrid-cell-wrapper">
                  {params?.value}
                </div>
              ),
            },
            {
              field: "question",
              headerName: "Questions (#)",
              minWidth: 150,
              align: "left",
              headerClassName: "super-app-theme--header",
            },
            {
              field: "responseRate",
              headerName: <i>Coming Soon</i>,
              minWidth: 150,
              align: "left",
              headerClassName: "super-app-theme--header",
              renderCell: (params) => "-",
            },
            {
              field: "sentiment",
              headerName: "Sentiment",
              minWidth: 200,
              headerClassName: "super-app-theme--header",
              renderCell: (params) => (
                <div className="custom-datagrid-cell-wrapper">
                  <SentimentSummaryShort
                    score={initializeSentimentScore(
                      params?.row?.responseRate,
                      params?.row?.sentimentScore,
                      isUndeterminedSurveyShown,
                      THRESHOLD
                    )}
                    summary={initializeSentimentSummary(
                      params?.row?.responseRate,
                      params?.value,
                      isUndeterminedSurveyShown,
                      THRESHOLD
                    )}
                  />
                </div>
              ),
            },
            {
              field: "breakdown",
              headerName: "Breakdown",
              flex: 1,
              minWidth: 350,
              headerClassName: "super-app-theme--header",
              renderCell: (params) => (
                <SentimentBreakdown data={params?.value} />
              ),
              sortable: false,
              filterable: false,
            },
          ];
        case "employeeProfile_question":
          return [
            {
              field: "question",
              headerName: "Question Name",
              minWidth: 500,
              headerClassName: "super-app-theme--header",
              renderCell: (params) => (
                <div className="custom-datagrid-cell-wrapper">
                  {params?.value}
                </div>
              ),
            },
            {
              field: "totalResponse",
              headerName: "Total Response",
              minWidth: 150,
              align: "left",
              flex: 1,
              headerClassName: "super-app-theme--header",
              renderCell: (params) => params?.value,
            },
            {
              field: "sentiment",
              headerName: "Sentiment",
              minWidth: 200,
              headerClassName: "super-app-theme--header",
              renderCell: (params) => (
                <div className="custom-datagrid-cell-wrapper">
                  <SentimentSummaryShort
                    score={initializeSentimentScore(
                      params?.row?.responseRate,
                      params?.row?.sentimentScore,
                      isUndeterminedSurveyShown,
                      THRESHOLD
                    )}
                    summary={initializeSentimentSummary(
                      params?.row?.responseRate,
                      params?.value,
                      isUndeterminedSurveyShown,
                      THRESHOLD
                    )}
                  />
                </div>
              ),
            },
            {
              field: "breakdown",
              headerName: "Breakdown",
              flex: 1,
              minWidth: 350,
              headerClassName: "super-app-theme--header",
              renderCell: (params) => (
                <SentimentBreakdown data={params?.value} />
              ),
              sortable: false,
              filterable: false,
            },
          ];
        default:
          return [];
      }
    }
  };

  /**
   * Renders the survey response analytics.
   *
   * @param {Number} completed
   * @param {Number} sent
   * @param {Number} percentage
   *
   * @returns {Component}
   */
  const renderSurveyAnalytics = (completed = 0, sent = 0, percentage = 0) => {
    return (
      <div className="survey-analytics-wrapper">
        <div className="progress-bar-wrapper">
          <div className="analytics">
            <div>
              {completed} Completed / {sent} Sent
            </div>
            <div>{percentage}%</div>
          </div>
          <MultiProgress
            elements={[
              {
                value: percentage,
                color: getEnvironmentTheme(configuration?.agency)
                  ?.progressBarOnContainer,
              },
            ]}
            height={8}
            backgroundColor={
              getEnvironmentTheme(configuration?.agency)?.progressBarContainer
            }
          />
        </div>
      </div>
    );
  };

  /**
   * Handles on the row click of the panel record.
   *
   * @param {Object} clickThroughConfiguration
   * @param {Object} currentPanel
   * @param {Object} selectedRecord
   */
  const onRowClick = async (
    clickThroughConfiguration,
    currentPanel,
    selectedRecord = {},
    tenureClickThroughBy
  ) => {
    if (!isRowClickLocked && !isLoadingData) {
      setIsRowClickLocked(true);
      // Current View: Employee
      // To Navigate: Employee Details page
      if (currentPanel?.currentView === "employee") {
        navigateToEmployeeProfile(selectedRecord?.id);
        setIsRowClickLocked(false);

        return;
      }

      const nextPanelClickThroughConfiguration =
        clickThroughConfiguration?.[value]?.[currentPanel?.currentView];

      if (!isObjectEmpty(nextPanelClickThroughConfiguration)) {
        const nextPanelWithoutRecordLinkedList = {
          currentView: nextPanelClickThroughConfiguration?.currentView,
          selectedRecord: selectedRecord?.name,
          selectedRecordId: selectedRecord?.id,
          selectedDimension:
            nextPanelClickThroughConfiguration?.selectedDimension,
        };
        const nextPanelRecordLinkedList = [
          ...currentPanel?.recordLinkedList,
          nextPanelWithoutRecordLinkedList,
        ];
        const nextPanel = {
          ...nextPanelWithoutRecordLinkedList,
          recordLinkedList: nextPanelRecordLinkedList,
        };

        setPanel(nextPanel);

        // Panel: Region
        if (value === "region") {
          // Current View: Region
          // To Fetch: Branch/Location
          if (currentPanel?.currentView === "region") {
            await nextPanelClickThroughConfiguration.fetchData(
              selectedRecord?.name
            );
            setIsRowClickLocked(false);

            return;
          }

          // Current View: Branch
          // To Fetch: Employee
          if (currentPanel?.currentView === "branch") {
            await nextPanelClickThroughConfiguration.fetchData(
              selectedRecord?.name
            );
            setIsRowClickLocked(false);

            return;
          }
        }

        // Panel: Job
        if (value === "job") {
          // Current View: Job
          // To Fetch: Category
          if (currentPanel?.currentView === "job") {
            await nextPanelClickThroughConfiguration.fetchData(
              selectedRecord?.id
            );
            setIsRowClickLocked(false);

            return;
          }

          // Current View: Category
          // To Fetch: Employee
          if (currentPanel?.currentView === "category") {
            const jobRecord = currentPanel?.recordLinkedList?.find(
              (record) => record?.selectedDimension === "job"
            );

            await nextPanelClickThroughConfiguration.fetchData(
              jobRecord?.selectedRecordId,
              selectedRecord?.name
            );
            setIsRowClickLocked(false);

            return;
          }
        }

        // Panel: Question Category
        if (value === "questionCategory") {
          // Current View: Question Category
          // To Fetch: Questions
          if (currentPanel?.currentView === "questionCategory") {
            await nextPanelClickThroughConfiguration.fetchData(
              selectedRecord?.name
            );
            setIsRowClickLocked(false);

            return;
          }

          // Current View: Job
          // To Fetch: Questions
          if (currentPanel?.currentView === "job") {
            const questionCategory = currentPanel?.recordLinkedList?.find(
              (record) => record?.selectedDimension === "questionCategory"
            );

            await nextPanelClickThroughConfiguration.fetchData(
              questionCategory?.selectedRecord,
              selectedRecord?.id
            );
            setIsRowClickLocked(false);

            return;
          }

          // Current View: Questions
          // To Fetch: Employees
          if (currentPanel?.currentView === "question") {
            const questionCategory = currentPanel?.recordLinkedList?.find(
              (record) => record?.selectedDimension === "questionCategory"
            );

            const job = currentPanel?.recordLinkedList?.find(
              (record) => record?.selectedDimension === "job"
            );

            await nextPanelClickThroughConfiguration.fetchData(
              questionCategory?.selectedRecord,
              job?.selectedRecordId,
              selectedRecord?.id
            );
            setIsRowClickLocked(false);

            return;
          }
        }

        // Panel: Question Category
        if (value === "employeeProfile_questionCategory") {
          // Current View: Question Category
          // To Fetch: Questions
          if (
            currentPanel?.currentView === "employeeProfile_questionCategory"
          ) {
            await nextPanelClickThroughConfiguration.fetchData(
              selectedRecord?.name
            );
            setIsRowClickLocked(false);

            return;
          }
        }

        // Panel: Tenure
        if (value === "tenure") {
          // Current View: Tenure
          // To Fetch: Regions
          if (
            currentPanel?.currentView === "tenure" &&
            nextPanelClickThroughConfiguration?.currentView === "region"
          ) {
            await nextPanelClickThroughConfiguration.fetchData(
              selectedRecord?.name
            );
            setIsRowClickLocked(false);

            return;
          }

          // Current View: Region
          // To Fetch: Branch
          if (
            currentPanel?.currentView === "region" &&
            nextPanelClickThroughConfiguration?.currentView === "branch"
          ) {
            const tenureRecord = currentPanel?.recordLinkedList?.find(
              (record) => record?.selectedDimension === "tenure"
            );

            await nextPanelClickThroughConfiguration.fetchData(
              tenureRecord?.selectedRecord,
              selectedRecord?.name
            );
            setIsRowClickLocked(false);

            return;
          }

          // Current View: Branch
          // To Fetch: Employee
          if (
            currentPanel?.currentView === "branch" &&
            nextPanelClickThroughConfiguration?.currentView === "employee"
          ) {
            const tenureRecord = currentPanel?.recordLinkedList?.find(
              (record) => record?.selectedDimension === "tenure"
            );

            const regionRecord = currentPanel?.recordLinkedList?.find(
              (record) => record?.selectedDimension === "region"
            );

            await nextPanelClickThroughConfiguration.fetchData(
              tenureRecord?.selectedRecord,
              regionRecord?.selectedRecord,
              selectedRecord?.name
            );
            setIsRowClickLocked(false);

            return;
          }

          // Current View: Tenure
          // To Fetch: Jobs
          if (
            currentPanel?.currentView === "tenure" &&
            nextPanelClickThroughConfiguration?.currentView === "job"
          ) {
            await nextPanelClickThroughConfiguration.fetchData(
              selectedRecord?.name
            );
            setIsRowClickLocked(false);

            return;
          }

          // Current View: Job
          // To Fetch: Category
          if (
            currentPanel?.currentView === "job" &&
            nextPanelClickThroughConfiguration?.currentView === "category"
          ) {
            const tenureRecord = currentPanel?.recordLinkedList?.find(
              (record) => record?.selectedDimension === "tenure"
            );

            await nextPanelClickThroughConfiguration.fetchData(
              tenureRecord?.selectedRecord,
              selectedRecord?.id
            );
            setIsRowClickLocked(false);

            return;
          }

          // Current View: Job Category
          // To Fetch: Employee
          if (
            currentPanel?.currentView === "category" &&
            nextPanelClickThroughConfiguration?.currentView === "employee"
          ) {
            const tenureRecord = currentPanel?.recordLinkedList?.find(
              (record) => record?.selectedDimension === "tenure"
            );

            const jobRecord = currentPanel?.recordLinkedList?.find(
              (record) => record?.selectedDimension === "job"
            );

            await nextPanelClickThroughConfiguration.fetchData(
              tenureRecord?.selectedRecord,
              jobRecord?.selectedRecordId,
              selectedRecord?.name
            );
            setIsRowClickLocked(false);

            return;
          }
        }
      } else {
        setIsRowClickLocked(false);
      }
    }
  };

  /**
   * Handles click on the breadcrumb navigation.
   *
   * @param {String} currentView
   * @param {String} selectedRecord
   * @param {String} selectedRecordId
   */
  const onBreadCrumbClick = async (
    panelToNavigate,
    recordLinkedList,
    clickThroughConfiguration,
    tenureClickThroughBy
  ) => {
    if (!isRowClickLocked && !isLoadingData) {
      setIsRowClickLocked(true);

      const nextPanelClickThroughConfiguration =
        clickThroughConfiguration?.[value]?.[
          panelToNavigate?.selectedDimension
        ];

      if (!isObjectEmpty(nextPanelClickThroughConfiguration)) {
        const nextPanelWithoutRecordLinkedList = {
          currentView: nextPanelClickThroughConfiguration?.currentView,
          selectedRecord: panelToNavigate?.selectedRecord,
          selectedRecordId: panelToNavigate?.selectedRecordId,
          selectedDimension:
            nextPanelClickThroughConfiguration?.selectedDimension,
        };

        let nextPanelRecordLinkedList = [];

        const index = recordLinkedList?.findIndex(
          (record) => record?.selectedRecord === panelToNavigate?.selectedRecord
        );

        if (index !== -1) {
          nextPanelRecordLinkedList = recordLinkedList.slice(0, index + 1);
        }

        const nextPanel = {
          ...nextPanelWithoutRecordLinkedList,
          recordLinkedList: nextPanelRecordLinkedList,
        };

        setPanel(nextPanel);

        // Panel: Region Panel
        if (value === "region") {
          // Clicked Breadcrumb: Region Record
          // Fetch Data: Branches
          if (panelToNavigate?.currentView === "branch") {
            await nextPanelClickThroughConfiguration.fetchData(
              panelToNavigate?.selectedRecord
            );
            setIsRowClickLocked(false);

            return;
          }

          // Clicked Breadcrumb: Branch Record
          // Fetch Data: Supervisors
          if (panelToNavigate?.currentView === "supervisor") {
            await nextPanelClickThroughConfiguration.fetchData(
              panelToNavigate?.selectedRecord
            );
            setIsRowClickLocked(false);

            return;
          }
        }

        // Panel: Job Panel
        if (value === "job") {
          // Clicked Breadcrumb: Job Record
          // Fetch Data: Category
          if (panelToNavigate?.currentView === "category") {
            await nextPanelClickThroughConfiguration.fetchData(
              panelToNavigate?.selectedRecordId
            );
            setIsRowClickLocked(false);

            return;
          }

          // Clicked Breadcrumb: Category Record
          // Fetch Data: Supervisors
          if (panelToNavigate?.currentView === "supervisor") {
            const jobRecord = recordLinkedList?.find(
              (record) => record?.selectedDimension === "job"
            );

            await nextPanelClickThroughConfiguration.fetchData(
              jobRecord?.selectedRecordId,
              panelToNavigate?.selectedRecord
            );
            setIsRowClickLocked(false);

            return;
          }
        }

        // Panel: Question Category Panel
        if (value === "questionCategory") {
          // Clicked Breadcrumb: Question Category
          // Fetch Data: Job
          if (panelToNavigate?.currentView === "job") {
            const questionCategory = recordLinkedList?.find(
              (record) => record?.selectedDimension === "questionCategory"
            );

            await nextPanelClickThroughConfiguration.fetchData(
              questionCategory?.selectedRecord
            );
            setIsRowClickLocked(false);

            return;
          }

          // Clicked Breadcrumb: Job
          // Fetch Data: Question
          if (panelToNavigate?.currentView === "question") {
            const questionCategory = recordLinkedList?.find(
              (record) => record?.selectedDimension === "questionCategory"
            );

            const job = recordLinkedList?.find(
              (record) => record?.selectedDimension === "job"
            );

            await nextPanelClickThroughConfiguration.fetchData(
              questionCategory?.selectedRecord,
              job?.selectedRecordId
            );
            setIsRowClickLocked(false);

            return;
          }
        }

        // Panel: Tenure Panel
        if (value === "tenure") {
          // Clicked Breadcrumb: Tenure Record
          // Fetch Data: Region
          if (panelToNavigate?.currentView === "region") {
            await nextPanelClickThroughConfiguration.fetchData(
              panelToNavigate?.selectedRecord
            );
            setIsRowClickLocked(false);

            return;
          }

          // Clicked Breadcrumb: Region Record
          // Fetch Data: branch
          if (panelToNavigate?.currentView === "branch") {
            const tenureRecord = recordLinkedList?.find(
              (record) => record?.selectedDimension === "tenure"
            );

            await nextPanelClickThroughConfiguration.fetchData(
              tenureRecord?.selectedRecord,
              panelToNavigate?.selectedRecord
            );
            setIsRowClickLocked(false);

            return;
          }

          // Clicked Breadcrumb: Branch Record
          // Fetch Data: Supervisor
          if (
            tenureClickThroughBy === "region" &&
            panelToNavigate?.currentView === "supervisor"
          ) {
            const tenureRecord = recordLinkedList?.find(
              (record) => record?.selectedDimension === "tenure"
            );

            const regionRecord = recordLinkedList?.find(
              (record) => record?.selectedDimension === "region"
            );

            await nextPanelClickThroughConfiguration.fetchData(
              tenureRecord?.selectedRecord,
              regionRecord?.selectedRecord,
              panelToNavigate?.selectedRecord
            );
            setIsRowClickLocked(false);

            return;
          }

          // Clicked Breadcrumb: Tenure Record
          // Fetch Data: Job
          if (panelToNavigate?.currentView === "job") {
            await nextPanelClickThroughConfiguration.fetchData(
              panelToNavigate?.selectedRecord
            );
            setIsRowClickLocked(false);

            return;
          }

          // Clicked Breadcrumb: Job Record
          // Fetch Data: Category
          if (panelToNavigate?.currentView === "category") {
            const tenureRecord = recordLinkedList?.find(
              (record) => record?.selectedDimension === "tenure"
            );

            await nextPanelClickThroughConfiguration.fetchData(
              tenureRecord?.selectedRecord,
              panelToNavigate?.selectedRecordId
            );
            setIsRowClickLocked(false);

            return;
          }

          // Clicked Breadcrumb: Branch Record
          // Fetch Data: Supervisor
          if (
            tenureClickThroughBy === "job" &&
            panelToNavigate?.currentView === "supervisor"
          ) {
            const tenureRecord = recordLinkedList?.find(
              (record) => record?.selectedDimension === "tenure"
            );

            const jobRecord = recordLinkedList?.find(
              (record) => record?.selectedDimension === "job"
            );

            await nextPanelClickThroughConfiguration.fetchData(
              tenureRecord?.selectedRecord,
              jobRecord?.selectedRecordId,
              panelToNavigate?.selectedRecord
            );
            setIsRowClickLocked(false);

            return;
          }
        }
      }
    }
  };

  /**
   * Renders the click-through breadcrumb for the header.
   */
  const renderBreadcrumb = (panel, label, recordLinkedList = []) => {
    if (isArrayEmpty(recordLinkedList)) {
      return (
        <Typography
          sx={{
            fontSize: "14px",
          }}
        >
          {label}
        </Typography>
      );
    }

    return (
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          underline="hover"
          color="inherit"
          onClick={() => resetClickThrough(panel)}
        >
          {label}
        </Link>
        {recordLinkedList.map((record, index) => {
          // This is not the last item in the breadcrumb.
          if (index !== recordLinkedList.length - 1) {
            return (
              <Link
                underline="hover"
                color="inherit"
                onClick={() =>
                  onBreadCrumbClick(
                    record,
                    recordLinkedList,
                    clickThroughConfiguration,
                    tenureClickThroughBy
                  )
                }
              >
                {record?.selectedRecord}
              </Link>
            );
          } else {
            return <Typography>{record?.selectedRecord}</Typography>;
          }
        })}
      </Breadcrumbs>
    );
  };

  const resetClickThrough = async (panel) => {
    if (!isRowClickLocked && !isLoadingData) {
      setIsRowClickLocked(true);

      setPanel({
        currentView: panel,
        selectedRecord: null,
        recordLinkedList: [],
        selectedDimension: null,
      });

      switch (panel) {
        case "region":
          await fetchRegionsWithEmployeesStats();
          setIsRowClickLocked(false);

          return;
        case "job":
          fetchJobsWithEmployeeStats();
          setIsRowClickLocked(false);

          return;
        case "questionCategory":
          fetchQuestionCategoriesWithEmployeeStats();
          setIsRowClickLocked(false);

          return;
        case "employeeProfile_questionCategory":
          fetchQuestionCategoriesStatsByEmployee();
          setIsRowClickLocked(false);

          return;
        case "tenure":
          fetchTenuresWithEmployeeStats();
          setIsRowClickLocked(false);

          return;

        default:
      }
    }
  };

  /**
   * Renders the Tenure click-through configuration dropdown.
   *
   * @returns {Component}
   */
  const renderTenureClickThroughConfigurationDropdown = () => {
    return (
      value === "tenure" && (
        <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
          <InputLabel id="tenure-click-through-configuration-label">
            Click-through By
          </InputLabel>
          <Select
            labelId="tenure-click-through-configuration-label"
            id="tenure-click-through-configuration-select"
            value={tenureClickThroughBy}
            label="Click Through By"
            onChange={(event) => {
              resetClickThrough(value);
              setTenureClickThroughBy(event?.target?.value);
            }}
          >
            <MenuItem value={"region"}>Region</MenuItem>
            <MenuItem value={"job"}>Job</MenuItem>
          </Select>
        </FormControl>
      )
    );
  };

  /**
   * Emit event for analytics on the row click of the panel record.
   *
   * @param {Object} currentPanelType
   * @param {Object} currentPanel
   * @param {Object} selectedRecord
   */
  const emitRowClickMixpanelEvent = (title, panel, curObj) => {
    var subArr =
      panel?.recordLinkedList?.map(({ currentView }) => currentView) || [];
    var breadcrumb = [title, ...subArr].join("_");
    var eventType = "RD_" + breadcrumb.toUpperCase() + "_PANEL_VIEW";

    var eventData = {};
    panel?.recordLinkedList?.forEach((element) => {
      eventData[element?.selectedDimension] = element?.selectedRecord;
    });
    if (panel?.currentView == "employee") {
      eventData["employee_id"] = curObj?.id;
    } else {
      eventData[panel?.currentView] = curObj?.name;
    }
    mixpanelTrackEvent(eventType, eventData);
  };

  /**
   * Emit event for analytics on sorting a column of panel.
   *
   * @param {Array} sortEventsModel
   */
  const emitSortModelChangeMixpanelEvent = (event) => {
    if (event && event.length && event.length > 0) {
      var eventType = "RD_PANEL_SORT";
      mixpanelTrackEvent(eventType, event[0]);
    }
  };

  /**
   * Computes and gets the sentiment summary for a record.
   *
   * @param {Number} surveyResponseRate
   * @param {String} sentimentSummary
   * @param {Boolean} isUndeterminedSurveyShown
   * @param {Number} threshold
   * @returns {String}
   */
  const initializeSentimentSummary = (
    surveyResponseRate = 0,
    sentimentSummary,
    isUndeterminedSurveyShown = false,
    threshold = 0
  ) => {
    if (isUndeterminedSurveyShown) {
      return sentimentSummary;
    }

    if (surveyResponseRate > 0 && surveyResponseRate < threshold) {
      return "Undetermined";
    }

    return sentimentSummary;
  };

  /**
   * Computes and gets the sentiment score for a record.
   *
   * @param {Number} surveyResponseRate
   * @param {Number} sentimentScore
   * @param {Boolean} isUndeterminedSurveyShown
   * @param {Number} threshold
   * @returns {String}
   */
  const initializeSentimentScore = (
    surveyResponseRate = 0,
    sentimentScore,
    isUndeterminedSurveyShown = false,
    threshold = 0
  ) => {
    if (isUndeterminedSurveyShown) {
      return sentimentScore;
    }

    if (surveyResponseRate > 0 && surveyResponseRate < threshold) {
      return "N/A";
    }

    return sentimentScore;
  };

  return (
    <div className="employee-data-div-wrapper">
      <div className="employee-data-div-header">
        <div className="left-panel">
          <img src={icon} alt="icon" />
          {renderBreadcrumb(value, label, panel?.recordLinkedList)}
        </div>
        <div className="right-panel">
          {renderTenureClickThroughConfigurationDropdown()}
        </div>
      </div>
      <EmployeeDataGridView
        rows={data}
        onRowClick={(event) => {
          onRowClick(
            clickThroughConfiguration,
            panel,
            {
              name: event?.row?.[
                clickThroughConfiguration?.[value]?.[panel?.currentView]
                  ?.recordParam
              ],
              id: event?.row?.recordId,
              tenureClickThroughBy,
            },
            tenureClickThroughBy
          );
          emitRowClickMixpanelEvent(value, panel, {
            name: event?.row?.[
              clickThroughConfiguration?.[value]?.[panel?.currentView]
                ?.recordParam
            ],
            id: event?.row?.recordId,
          });
        }}
        isLoading={isLoadingData || isRowClickLocked}
        columns={getColumns(value, panel?.currentView)}
        onSortModelChange={emitSortModelChangeMixpanelEvent}
      />
    </div>
  );
}
