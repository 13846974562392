import React, { useCallback } from "react";

import {
  Box,
  Paper,
  Popper,
  Divider,
  InputBase,
  IconButton,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import { getFullName, isEmpty as isStringEmpty } from "../../../utils/string";

export default function SearchBar({
  isLoading,
  optionKey,
  results = [],
  placeholder = "",
  onFilter = () => {},
  renderOption = () => {},
}) {
  const SearchBarPopper = useCallback((props) => {
    const anchorEl = document.getElementById("search-bar-wrapper-id");

    return (
      <Popper
        {...props}
        anchorEl={anchorEl}
        style={{ width: anchorEl.clientWidth }}
        placement="bottom"
        sx={{
          padding: 0,
        }}
      />
    );
  }, []);

  return (
    <Box
      id="search-bar-wrapper-id"
      sx={{
        width: "100%",
        maxWidth: "600px",
        "@media (max-width:992px)": {
          maxWidth: "none",
        },
      }}
    >
      <Autocomplete
        loading={isLoading}
        ListboxProps={{ style: { maxHeight: "400px" } }}
        freeSolo
        id="search-bar"
        options={results}
        renderInput={(params) => {
          const { InputLabelProps, InputProps, ...rest } = params;

          return (
            <Paper
              variant="outlined"
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <IconButton sx={{ p: "10px" }} aria-label="menu">
                <SearchIcon
                  sx={{ color: "var(--md-sys-color-primary-light)" }}
                />
              </IconButton>
              <InputBase
                {...params.InputProps}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    event.stopPropagation();
                    event.preventDefault();
                  }
                }}
                endAdornment={
                  <>
                    {isLoading ? (
                      <CircularProgress
                        color="inherit"
                        size={16}
                        sx={{ margin: "0 var(--spacing-2x)" }}
                      />
                    ) : null}
                  </>
                }
                {...rest}
                sx={{
                  width: "100%",
                }}
                placeholder={
                  isStringEmpty(placeholder) ? "Search..." : `${placeholder}`
                }
              />
            </Paper>
          );
        }}
        PopperComponent={SearchBarPopper}
        filterOptions={(x) => x}
        onInputChange={(event, newInputValue) => onFilter(newInputValue)}
        getOptionLabel={(option) =>
          getFullName(
            option?.[optionKey]?.first_name,
            option?.[optionKey]?.last_name
          )
        }
        renderOption={(params, option) => {
          return (
            <div key={option?.id}>
              {renderOption(option)}
              <Divider />
            </div>
          );
        }}
      />
    </Box>
  );
}
