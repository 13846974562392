"use client";
/*
 * Documentation:
 * Page Header — https://app.subframe.com/library?component=Page+Header_4563daa8-568e-4a98-924a-0d956da3e7ec
 * Select — https://app.subframe.com/library?component=Select_bb88f90b-8c43-4b73-9c2f-3558ce7838f3
 */

import React from "react";
import * as SubframeCore from "@subframe/core";

interface PageHeaderRootProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  className?: string;
}

const PageHeaderRoot = React.forwardRef<HTMLElement, PageHeaderRootProps>(
  function PageHeaderRoot(
    { children, className, ...otherProps }: PageHeaderRootProps,
    ref
  ) {
    return (
      <div
        className={SubframeCore.twClassNames(
          "flex w-full items-center gap-4 flex-wrap md:flex-nowrap",
          className
        )}
        ref={ref as any}
        {...otherProps}
      >
        {children}
      </div>
    );
  }
);

export const PageHeader = PageHeaderRoot;
