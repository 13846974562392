import { Typography } from "@mui/material";

import { SENTIMENT } from "../../constants/retention/constants";

import "./SentimentSummary.css";

import { isEmpty as isObjectEmpty } from "../../utils/object";

export default function SentimentSummary({ value }) {
  return (
    <div className="sentiment-summary-wrapper">
      {isObjectEmpty(SENTIMENT[value]) ? (
        ""
      ) : (
        <>
          {SENTIMENT[value]?.icon}
          <Typography
            component="span"
            sx={{ color: SENTIMENT[value]?.color, fontSize: "0.8rem" }}
          >
            {SENTIMENT[value]?.label}
          </Typography>
        </>
      )}
    </div>
  );
}
