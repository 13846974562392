"use client";
/*
 * Documentation:
 * Search Results v2 — https://app.subframe.com/library?component=Search+Results+v2_8fd90d3e-22b3-42ab-85fa-515d5c7510c5
 * Text Field — https://app.subframe.com/library?component=Text+Field_be48ca43-f8e7-4c0e-8870-d219ea11abfe
 * Icon with background — https://app.subframe.com/library?component=Icon+with+background_c5d68c0e-4c0c-4cff-8d8c-6ff334859b3a
 */

import React from "react";
import * as SubframeCore from "@subframe/core";
import { TextField } from "./TextField";
import { IconWithBackground } from "./IconWithBackground";
import { Avatar } from "./Avatar";
import { CustomBadge } from "./CustomBadge";

interface SearchResultV2Props extends React.HTMLAttributes<HTMLDivElement> {
  name?: string;
  initials?: string;
  role?: string;
  location?: string;
  supervisor?: string;
  tenure?: string;
  atRisk?: boolean;
  terminated?: boolean;
  className?: string;
}

const SearchResultV2 = React.forwardRef<HTMLElement, SearchResultV2Props>(
  function SearchResultV2(
    {
      name,
      initials,
      role,
      location,
      supervisor,
      tenure,
      atRisk = false,
      terminated = false,
      className,
      ...otherProps
    }: SearchResultV2Props,
    ref
  ) {
    return (
      <div
        className={SubframeCore.twClassNames(
          "group/e5202334 flex w-full cursor-pointer items-center gap-2",
          className
        )}
        ref={ref as any}
        {...otherProps}
      >
        <div className="flex w-full grow shrink-0 basis-0 items-center gap-3 rounded pt-2 pr-3 pb-2 pl-3 group-hover/e5202334:flex-row group-hover/e5202334:gap-3 group-hover/e5202334:bg-neutral-50">
          <Avatar variant="brand" size="small">
            {initials}
          </Avatar>
          <div
            className={SubframeCore.twClassNames(
              "flex w-full grow shrink-0 basis-0 items-center gap-2",
              { "h-auto w-full grow shrink-0 basis-0": terminated }
            )}
          >
            {name ? (
              <span
                className={SubframeCore.twClassNames(
                  "text-body-bold font-body-bold text-default-font",
                  { "text-subtext-color": terminated }
                )}
              >
                {name}
              </span>
            ) : null}
            <span className="text-label font-label text-subtext-color">•</span>
            {role ? (
              <span className="text-label font-label text-subtext-color">
                {role}
              </span>
            ) : null}
            <span className="text-label font-label text-subtext-color">•</span>
            {location ? (
              <span className="text-label font-label text-subtext-color">
                {location}
              </span>
            ) : null}
            <span className="text-label font-label text-subtext-color">•</span>
            {supervisor ? (
              <span className="text-label font-label text-subtext-color">
                {supervisor}
              </span>
            ) : null}
            <span className="text-label font-label text-subtext-color">•</span>
            {tenure ? (
              <span className="text-label font-label text-subtext-color">
                {tenure}
              </span>
            ) : null}
          </div>
          <CustomBadge
            className={SubframeCore.twClassNames("hidden", { flex: atRisk })}
            variant="error"
          >
            At Risk
          </CustomBadge>
          <CustomBadge
            className={SubframeCore.twClassNames("hidden", {
              flex: terminated,
            })}
            variant="neutral"
          >
            Terminated
          </CustomBadge>
        </div>
      </div>
    );
  }
);

interface SearchResultsV2RootProps
  extends React.HTMLAttributes<HTMLDivElement> {
  empty?: boolean;
  className?: string;
}

const SearchResultsV2Root = React.forwardRef<
  HTMLElement,
  SearchResultsV2RootProps
>(function SearchResultsV2Root(
  { empty = false, className, ...otherProps }: SearchResultsV2RootProps,
  ref
) {
  return (
    <div
      className={SubframeCore.twClassNames(
        "group/8fd90d3e flex w-full flex-col items-start overflow-hidden rounded bg-default-background shadow",
        className
      )}
      ref={ref as any}
      {...otherProps}
    >
      <div className="flex w-full flex-col items-start gap-4 pt-4 pr-4 pl-4">
        <TextField
          className="h-auto w-full flex-none"
          label=""
          helpText=""
          icon="FeatherSearch"
        >
          <TextField.Input placeholder="Search for a person..." />
        </TextField>
      </div>
      <div
        className={SubframeCore.twClassNames(
          "hidden h-full w-full grow shrink-0 basis-0 flex-col items-center justify-center gap-4 rounded bg-neutral-50 pt-6 pr-6 pb-6 pl-6",
          {
            "flex h-auto w-full flex-none bg-transparent pt-12 pr-12 pb-12 pl-12":
              empty,
          }
        )}
      >
        <div className="flex flex-col items-center gap-2">
          <div className="flex flex-col items-start gap-2">
            <IconWithBackground size="large" icon="FeatherSearchX" />
          </div>
          <span className="text-subheader font-subheader text-default-font">
            No results
          </span>
        </div>
      </div>
      <div
        className={SubframeCore.twClassNames(
          "flex w-full flex-col items-start pt-2 pr-2 pb-2 pl-2",
          { hidden: empty }
        )}
      >
        <SearchResultV2
          name="Jamal Renalli"
          initials="JR"
          role="Nurse"
          location="Philadelphia"
          supervisor="Ryan Smith"
          tenure="90 - 120 days"
          atRisk={true}
        />
        <SearchResultV2
          name="Florence Zuniga"
          initials="FZ"
          role="Nurse"
          location="Chicago"
          supervisor="Sarah Ross"
          tenure="0 - 30 days"
        />
        <SearchResultV2
          name="Dale Bruce"
          initials="DB"
          role="Therapist"
          location="Philadelphia"
          supervisor="Ryan Smith"
          tenure="90 - 120 days"
        />
        <SearchResultV2
          name="Rachel Prince\n"
          initials="RP"
          role="Manager"
          location="Boston"
          supervisor="Megan Koch"
          tenure="90 - 120 days"
          atRisk={true}
        />
        <SearchResultV2
          name="Scott Rosario"
          initials="SR"
          role="Nurse"
          location="Philadelphia"
          supervisor="Ryan Smith"
          tenure="90 - 120 days"
        />
      </div>
    </div>
  );
});

export const SearchResultsV2 = Object.assign(SearchResultsV2Root, {
  SearchResultV2,
});
