import { LoadingButton } from "@mui/lab";
import { Card, CardContent, CircularProgress, Divider } from "@mui/material";

import "./DocumentsReviewFormPanel.css";

import { isEmpty as isArrayEmpty } from "../../utils/array";

import DocumentsReviewForm from "../DocumentsReviewForm/DocumentsReviewForm";

export default function DocumentsReviewFormPanel({
  isLoading,
  isApprovable,
  isChangeable,
  isSubmitting,
  documents = [],
  approveDocument = () => {},
  selectedDocumentsForApproval,
  submitDocumentsReview = () => {},
  requestChangeDocument = () => {},
  selectedDocumentsForChangeRequest,
}) {
  return (
    <Card
      variant="outlined"
      sx={{
        width: "100%",
        padding: "0",
      }}
    >
      <CardContent
        sx={{
          "& .super-app-theme--header": {
            backgroundColor: "#EDEFF5",
          },
          padding: "0",
        }}
      >
        <header className="caregiver-documents-panel-header-wrapper">
          <h2>Documents Panel</h2>
        </header>
        <Divider />
        <div className="caregiver-documents-panel-body-wrapper">
          {isLoading ? (
            <div className="caregiver-profile-summary-loader-wrapper">
              <CircularProgress />
            </div>
          ) : (
            <DocumentsReviewForm
              documents={documents}
              isApprovable={isApprovable}
              isChangeable={isChangeable}
              approveDocument={approveDocument}
              requestChangeDocument={requestChangeDocument}
              selectedDocumentsForApproval={selectedDocumentsForApproval}
              selectedDocumentsForChangeRequest={
                selectedDocumentsForChangeRequest
              }
            />
          )}
        </div>
        <Divider />
        <div className="caregiver-documents-panel-buttons-wrapper">
          <LoadingButton
            variant="contained"
            size="large"
            loading={isLoading || isSubmitting}
            onClick={submitDocumentsReview}
            disabled={
              isArrayEmpty(documents) ||
              documents?.length !==
                selectedDocumentsForApproval?.length +
                  selectedDocumentsForChangeRequest?.length
            }
          >
            Update
          </LoadingButton>
        </div>
      </CardContent>
    </Card>
  );
}
