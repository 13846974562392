import React from "react";

import Navbar from "../../../../components/Navbar";

export default function RecognitionPrivateComponent({ children }) {
  return (
    <>
      <Navbar module="Recognition" />
      {children}
    </>
  );
}
