import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";

import { debounce, isString } from "lodash";

import {
  Card,
  Chip,
  Button,
  Dialog,
  Drawer,
  Divider,
  Skeleton,
  Snackbar,
  TextField,
  IconButton,
  CardContent,
  DialogTitle,
  DialogActions,
  DialogContent,
  LinearProgress,
  DialogContentText,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import MuiAlert from "@mui/material/Alert";
import { styled } from "@mui/material/styles";
import CallIcon from "@mui/icons-material/Call";
import CloseIcon from "@mui/icons-material/Close";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";

import "./Dashboard.css";

import Navbar from "../Navbar";
import ChatBox from "../ChatBox";
import SearchUser from "../SearchUser";
import ActionButton from "../ActionButton";
import TaskGrid from "../TaskGrid/TaskGrid";
import StatisticsCard from "../StatisticsCard";
import StatusDropdown from "../StatusDropdown";
import TaskProgressBar from "../TaskProgressBar";
import AddCaregiverForm from "../AddCaregiverForm";
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog";
import DocumentsReviewForm from "../DocumentsReviewForm/DocumentsReviewForm";
import InterviewDecisionForm from "../InterviewDecisionForm/InterviewDecisionForm";

import {
  ERROR_CODE,
  STATUS_MAPPER,
  ACTIONS_MAPPER,
  SECTIONS_MAPPER,
  STATISTICS_MAPPER,
} from "../../constants/constants";

import { getToday } from "../../utils/date";
import { get } from "../../utils/localStorage";
import { percentage } from "../../utils/number";
import { isEmpty as isArrayEmpty } from "../../utils/array";
import { formatPhoneNumber } from "../../utils/phoneNumber";
import { isEmpty as isObjectEmpty } from "../../utils/object";
import { isEmpty as isStringEmpty } from "../../utils/string";

import {
  post as postMessage,
  fetch as fetchMessages,
} from "../../services/messages";
import {
  fetchJobs,
  performAction,
  reviewDocuments,
  approveDocuments,
  requestChangeDocuments,
  postApplicationInterview,
  postApplicationCaregiver,
  fetch as fetchApplications,
  unanswerApplicationInterview,
  updateApplicationCaregiverStatus,
  fetchApplicationCaregivers as fetchUsers,
  fetchDocuments as fetchApplicationDocuments,
} from "../../services/applications";

import { fetch as fetchStatistics } from "../../services/statistics";

const searchUserDrawerWidth = 220;

/**
 * Styled search user drawer wrapper.
 */
const StyledSearchUserDrawer = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${searchUserDrawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

export default function Dashboard() {
  const navigate = useNavigate();
  const [
    hasTriedChangeRequestFormSubmission,
    setHasTriedChangeRequestFormSubmission,
  ] = useState(false);
  const [
    isDocumentApprovalConfirmationDialogOpen,
    setIsDocumentApprovalConfirmationDialogOpen,
  ] = useState(false);
  const [
    isChangeStatusConfirmationDialogOpen,
    setIsChangeStatusConfirmationDialogOpen,
  ] = useState(false);
  const [
    isPerformActionConfirmationDialogOpen,
    setIsPerformActionConfirmationDialogOpen,
  ] = useState(false);
  const [
    hasTriedAddCaregiverFormSubmission,
    setHasTriedAddCaregiverFormSubmission,
  ] = useState(false);
  const [
    hasTriedInterviewDecisionFormSubmission,
    setHasTriedInterviewDecisionFormSubmission,
  ] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [status, setStatus] = useState({
    current: "",
    next: "",
  });
  const [users, setUsers] = useState([]);
  const [messages, setMessages] = useState([]);
  const [statistics, setStatistics] = useState({});
  const [selectedUser, setSelectedUser] = useState({});
  const [applications, setApplications] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isChatBoxOpen, setIsChatBoxOpen] = useState(false);
  const [searchUserValue, setSearchUserValue] = useState("");
  const [addCaregiverForm, setAddCaregiverForm] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
    job_id: undefined,
    outreach: false,
  });
  const [isFetchingJobs, setIsFetchingJobs] = useState(false);
  const [isFetchingUsers, setIsFetchingUsers] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [isDocumentReviewFormOpen, setIsDocumentReviewFormOpen] =
    useState(false);
  const [isMessagesLoading, setIsMessagesLoading] = useState(false);
  const [isMessagesPosting, setIsMessagesPosting] = useState(false);
  const [actionDetails, setActionDetails] = useState({ action: "" });
  const [changeRequestReason, setChangeRequestReason] = useState("");
  const [isInterviewDecisionPosting, setIsInterviewDecisionPosting] =
    useState(false);
  const [isPerformingAction, setIsPerformingAction] = useState(false);
  const [isPostingCaregiver, setIsPostingCaregiver] = useState(false);
  const [applicationDocuments, setApplicationDocuments] = useState([]);
  const [isInterviewDecisionFormOpen, setIsInterviewDecisionFormOpen] =
    useState(false);
  const [isRequestingChangeDocuments, setIsRequestingChangeDocuments] =
    useState(false);
  const [isUpdatingApplicationStatus, setIsUpdatingApplicationStatus] =
    useState(false);
  const [isStatisticsLoading, setIsStatisticsLoading] = useState(false);
  const [isErrorMessageShown, setIsErrorMessageShown] = useState(false);
  const [interviewDecisionForm, setInterviewDecisionForm] = useState({
    first_name: "",
    last_name: "",
    city: "",
    state: "",
    email: "",
    position: "",
    employment_status: "",
    company_name: "",
    phone_number: "",
    zipcode: "",
    hours_per_week_min: "",
    hours_per_week_max: "",
    background: "",
    status_reason: "",
    days: [],
    times: [],
    experiences: [],
    certifications: [],
    status: "",
    referral: "",
  });
  const [isApprovingDocuments, setIsApprovingDocuments] = useState(false);
  const [isSubmittingDocuments, setIsSubmittingDocuments] = useState(false);
  const [isApplicationDocumentsLoading, setIsApplicationDocumentsLoading] =
    useState(false);
  const [isRequestChangeFormDialogOpen, setIsRequestChangeFormDialogOpen] =
    useState(false);
  const [isSuccessMessageShown, setIsSuccessMessageShown] = useState(false);
  const [isApplicationsLoading, setIsApplicationsLoading] = useState(false);
  const [isInterviewDecisionNoAnswering, setIsInterviewDecisionNoAnswering] =
    useState(false);
  const [
    selectedDocumentsForChangeRequest,
    setSelectedDocumentsForChangeRequest,
  ] = useState([]);
  const [selectedDocumentsForApproval, setSelectedDocumentsForApproval] =
    useState([]);
  const [isAddCaregiverFormOpen, setIsAddCaregiverFormOpen] = useState(false);
  const [isSearchUserDrawerOpen, setIsSearchUserDrawerOpen] = useState(false);

  useEffect(() => {
    fetchAndSetStatistics();
    fetchAndSetApplications();
  }, []);

  useEffect(() => {
    debouncedFetchUsers(searchUserValue);
  }, [searchUserValue]);

  /**
   * Approve Documents API calls.
   *
   * @param {Number} applicationId
   * @param {Array} documents
   *
   * @returns {Promise}
   */
  const approveDocumentsAsync = async (applicationId, documents = []) => {
    try {
      return await approveDocuments(applicationId, documents);
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  /**
   * Arranges the applications by the category.
   *
   * @param {Array} applications
   * @returns {Object}
   */
  const arrangeApplications = (applications = []) => {
    let arranged = {};

    applications.forEach((application) => {
      if (!arranged.hasOwnProperty(application.section)) {
        arranged = {
          ...arranged,
          [application.section]: {
            value: 0,
            label: SECTIONS_MAPPER[application.section]?.label,
            color: SECTIONS_MAPPER[application.section]?.color,
          },
        };
      }

      arranged = {
        ...arranged,
        [application.section]: {
          ...arranged[application.section],
          value: (arranged[application.section].value += 1),
          label: SECTIONS_MAPPER[application.section]?.label,
        },
      };
    });

    return arranged;
  };

  /**
   * Arranges the applications required for the multi step task progress bar.
   * @param {Array} applications
   * @returns {Object}
   */
  const arrangeApplicationsForTaskProgressBar = (applications = []) => {
    let applicationObjects = Object.values(applications);
    let total = 0;

    applicationObjects.forEach((applicationObject) => {
      total += applicationObject.value;
    });

    const tasks = applicationObjects.map((applicationObject) => ({
      ...applicationObject,
      count: applicationObject.value,
      value: percentage(applicationObject.value, total),
    }));

    return {
      tasks,
      total,
    };
  };

  /**
   * Closes the request change form dialog.
   */
  const closeRequestChangeFormDialog = () => {
    setIsRequestChangeFormDialogOpen(false);
  };

  /**
   * Fetches and sets the list of applications for a agency user.
   */
  const fetchAndSetApplications = async () => {
    try {
      setIsApplicationsLoading(true);
      const response = await fetchApplications();

      if (!isObjectEmpty(response?.result?.applications)) {
        setApplications(response.result.applications);
      }
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }
      setErrorMessage(`Error while fetching Applications: ${error?.message}`);
      setIsErrorMessageShown(true);
    } finally {
      setIsApplicationsLoading(false);
    }
  };

  /**
   * Fetches and sets the documents under an application.
   *
   * @param {id} Number
   */
  const fetchAndSetApplicationDocuments = async (id) => {
    try {
      return await fetchApplicationDocuments(id);
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  /**
   * Fetches and sets messages of a caregiver.
   *
   * @param {Number} phoneNumber
   */
  const fetchAndSetMessages = async (phoneNumber = "") => {
    try {
      setIsMessagesLoading(true);
      const response = await fetchMessages({ phone_number: phoneNumber });
      const messages = response?.result?.messages;

      if (Array.isArray(messages)) {
        setMessages(messages.slice().reverse());
      }
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }
      setErrorMessage(`Error while fetching messages: ${error.message}`);
      setIsErrorMessageShown(true);
    } finally {
      setIsMessagesLoading(false);
    }
  };

  /**
   * Fetches and sets the statistics for a user.
   */
  const fetchAndSetStatistics = async () => {
    try {
      setIsStatisticsLoading(true);
      const response = await fetchStatistics();

      if (!isObjectEmpty(response?.result?.results)) {
        setStatistics(response.result.results);
      }
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }
      setErrorMessage(`Error while fetching Statistics: ${error.message}`);
      setIsErrorMessageShown(true);
    } finally {
      setIsStatisticsLoading(false);
    }
  };

  /**
   * Fetches the list of filtered users.
   *
   * @param {String} name
   * @returns {Promise}
   */
  const fetchUsersAsync = async (name = "") => {
    try {
      return await fetchUsers({
        caregiver_name: name,
      });
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  /**
   * Get the application by its Id.
   *
   * @param {Number} id
   * @returns {Object}
   */
  const getApplication = (id) => {
    if (isArrayEmpty(applications)) {
      return;
    }

    return applications.find((application) => application?.id === id);
  };

  /**
   * Gets default action based on the status of the user.
   *
   * @param {String} status
   * @returns {String}
   */
  const getDefaultAction = (status = "") => {
    switch (status) {
      case STATUS_MAPPER["interview-scheduled"]?.value:
        return ACTIONS_MAPPER["interview-decision"]?.value;
      case STATUS_MAPPER["documents-finished"]?.value:
        return ACTIONS_MAPPER["documents-review"]?.value;

      default:
        return ACTIONS_MAPPER["send-message"]?.value;
    }
  };

  /**
   * Gets the selected user's full name.
   *
   * @param {String} firstName
   * @param {String} lastName
   * @returns {String}
   */
  const getFullName = (firstName = "", lastName = "") => {
    return `${isStringEmpty(firstName) ? "" : firstName}${
      !isStringEmpty(firstName) && !isStringEmpty(lastName) ? " " : ""
    }${isStringEmpty(lastName) ? "" : lastName}`;
  };

  /**
   * Handles the fetching of the jobs.
   */
  const handleFetchJobs = async () => {
    let userRole = await get("userRole");

    /**
     * NOTE: The Jobs are hard coded as of now for the user: super recruiter.
     *
     * TODO: Replace this with normal API call after fix from B.E.
     */
    if (userRole === "super recruiter") {
      const jobs = [
        {
          job_title: "Renewal - Care Partner",
          id: 58,
        },
        {
          job_title: "Renewal - Companion",
          id: 61,
        },
        {
          job_title: " Suma - PCA",
          id: 49,
        },
        {
          job_title: "Sunshine - Caregiver",
          id: 52,
        },
      ];
      setJobs(jobs);

      return;
    }

    handleFetchJobsAsync();
  };

  /**
   * Fetches and sets the list of Jobs.
   */
  const handleFetchJobsAsync = async () => {
    setIsFetchingJobs(true);
    const response = await fetchJobsAsync();

    setIsFetchingJobs(false);

    if (response?.hasError) {
      setErrorMessage(`Error while fetching Jobs: ${response?.error?.message}`);
      setIsErrorMessageShown(true);

      return;
    }

    if (!isArrayEmpty(response?.result?.jobs)) {
      setJobs(response?.result?.jobs);
    }
  };

  /**
   * Fetches Jobs Async.
   *
   * @returns {Promise}
   */
  const fetchJobsAsync = async () => {
    try {
      return await fetchJobs();
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  /**
   * Handles approving of the document.
   *
   * @param {Number} documentId
   */
  const onClickApproveDocument = (documentId) => {
    setSelectedDocuments([documentId]);
    setIsDocumentApprovalConfirmationDialogOpen(true);
  };

  /**
   * Handles the change in the form field of the Add Caregiver Form.
   */
  const handleAddCaregiverFormFieldChange = (key, value) => {
    setAddCaregiverForm({
      ...addCaregiverForm,
      [key]: value,
    });
  };

  /**
   * Handles the approval of the documents for an application.
   */
  const handleApproveDocuments = async () => {
    setIsApprovingDocuments(true);

    const response = await approveDocumentsAsync(
      selectedUser?.id,
      selectedDocuments
    );

    setIsApprovingDocuments(false);

    if (response?.hasError) {
      setErrorMessage(
        `Error while approving document(s): ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);

      return;
    }

    setSuccessMessage(`Successfully approved document(s).`);
    setIsSuccessMessageShown(true);
    setIsDocumentApprovalConfirmationDialogOpen(false);
  };

  const handleSubmitDocumentsReview = async () => {
    setIsSubmittingDocuments(true);
    const response = await submitDocumentsAsync(
      selectedUser?.id,
      selectedDocumentsForApproval,
      selectedDocumentsForChangeRequest
    );

    setIsSubmittingDocuments(false);

    if (response?.hasError) {
      setErrorMessage(
        `Error while reviewing the document(s): ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);

      return;
    }

    setSuccessMessage(`Successfully reviewed the document(s).`);
    setIsSuccessMessageShown(true);
    handleCloseDocumentReviewForm();
    fetchAndSetApplications();
  };

  /**
   * Handles the closing of the Add Caregiver form.
   */
  const handleCloseAddCaregiverForm = () => {
    setJobs([]);
    setAddCaregiverForm({
      first_name: "",
      last_name: "",
      phone_number: "",
      email: "",
      job_id: undefined,
      outreach: false,
    });
    setIsAddCaregiverFormOpen(false);
    setHasTriedAddCaregiverFormSubmission(false);
  };

  /**
   * Handles the closing of the status changer confirmation dialog.
   */
  const handleCloseChangeStatusConfirmationDialog = () => {
    setStatus({
      current: "",
      next: "",
    });
    setSelectedUser({});
    setIsChangeStatusConfirmationDialogOpen(false);
  };

  /**
   * Method to handle the closing of the chat box.
   */
  const handleCloseChatBox = () => {
    setIsChatBoxOpen(false);

    fetchAndSetApplications();
    fetchAndSetStatistics();
  };

  /**
   * Handles closing of the application document review form.
   */
  const handleCloseDocumentReviewForm = () => {
    setSelectedUser({});
    setSelectedDocuments([]);
    setApplicationDocuments([]);
    setIsDocumentReviewFormOpen(false);
    setSelectedDocumentsForApproval([]);
    setSelectedDocumentsForChangeRequest([]);
  };

  /**
   * Method to handle the closing of the Interview Decision Form.
   */
  const handleCloseInterviewDecisionForm = () => {
    setInterviewDecisionForm({
      first_name: "",
      last_name: "",
      city: "",
      state: "",
      email: "",
      position: "",
      employment_status: "",
      company_name: "",
      phone_number: "",
      zipcode: "",
      hours_per_week_min: "",
      hours_per_week_max: "",
      background: "",
      status_reason: "",
      days: [],
      times: [],
      experiences: [],
      certifications: [],
      status: "",
      referral: "",
    });
    setHasTriedInterviewDecisionFormSubmission(false);
    setIsInterviewDecisionFormOpen(false);
  };

  /**
   * Handles the closing of the perform action confirmation dialog.
   */
  const handleClosePerformActionConfirmationDialog = () => {
    setSelectedUser({});
    setActionDetails({ action: "" });
    setIsPerformActionConfirmationDialogOpen(false);
  };

  /**
   * Handles the closing of the request change form dialog.
   */
  const handleCloseRequestChangeFormDialog = () => {
    setChangeRequestReason("");
    closeRequestChangeFormDialog();
    setHasTriedChangeRequestFormSubmission(false);
  };

  /**
   * Handles closing of the search user drawer.
   */
  const handleCloseSearchUserDrawer = () => {
    setUsers([]);
    setSearchUserValue("");
    setIsSearchUserDrawerOpen(false);
  };

  /**
   * Handles the fetching of the users.
   *
   * @param {String} Name
   */
  const handleFetchUsers = async (name = "") => {
    setIsFetchingUsers(true);
    const response = await fetchUsersAsync(name);

    setIsFetchingUsers(false);

    if (response?.hasError) {
      setErrorMessage(
        `Error while fetching users: ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);

      return;
    }

    setUsers(response?.result?.applications);
  };

  /**
   * Debounced users fetching on typing user search value.
   */
  const debouncedFetchUsers = useCallback(debounce(handleFetchUsers, 300), []);

  /**
   * Handles the deselection of a document.
   *
   * @param {Number} documentId
   */
  const handleDeselectDocument = (documentId) => {
    setSelectedDocuments((previousSelectedDocuments) =>
      previousSelectedDocuments?.filter(
        (previousSelectedDocument) => previousSelectedDocument !== documentId
      )
    );
  };

  /**
   * Handles the change in the form field of the Interview Decision Form.
   */
  const handleInterviewDecisionFormFieldChange = (key, value) => {
    setInterviewDecisionForm({
      ...interviewDecisionForm,
      [key]: value,
    });
  };

  /**
   * Method to handle the No Answer for an Application's Interview Decision.
   */
  const handleInterviewDecisionFormNoAnswer = async () => {
    setIsInterviewDecisionNoAnswering(true);
    const response = await unanswerApplicationInterviewAsync(selectedUser?.id);

    setIsInterviewDecisionNoAnswering(false);
    if (response?.hasError) {
      setErrorMessage(
        `Error while Unanswering Interview Decision: ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);

      return;
    }

    handleCloseInterviewDecisionForm();
    setSuccessMessage(`Successfully logged unanswered interview.`);
    setIsSuccessMessageShown(true);
    fetchAndSetApplications();
  };

  /**
   * Handles the submission of the Interview Decision Form.
   */
  const handleInterviewDecisionFormSubmission = async () => {
    setHasTriedInterviewDecisionFormSubmission(true);

    if (isInterviewDecisionFormValid()) {
      setIsInterviewDecisionPosting(true);
      const { email, position, phone_number, referral, ...beautifiedPayload } =
        {
          id: selectedUser?.id,
          ...interviewDecisionForm,
        };
      const response = await postApplicationInterviewAsync(beautifiedPayload);

      setIsInterviewDecisionPosting(false);

      if (response?.hasError) {
        setErrorMessage(
          `Error while posting Interview Decision: ${response?.error?.message}`
        );

        setIsErrorMessageShown(true);

        return;
      }

      handleCloseInterviewDecisionForm();
      setSuccessMessage(`Successfully submitted Interview Decision.`);
      setIsSuccessMessageShown(true);
      fetchAndSetApplications();
      fetchAndSetStatistics();
    }
  };

  /**
   * Handles when clicked on the status menu option to change the status.
   *
   * @param {Number} applicationId
   * @param {String} current
   * @param {String} next
   */
  const handleOnStatusChange = (applicationId, current, next) => {
    const application = getApplication(applicationId);

    setSelectedUser(application ?? {});
    setStatus({
      current,
      next,
    });
    setIsChangeStatusConfirmationDialogOpen(true);
  };

  /**
   * Handles the opening of the perform action confirmation dialog.
   */
  const handleOpenPerformActionConfirmationDialog = (applicationId, action) => {
    const application = getApplication(applicationId);

    setSelectedUser(application ?? {});
    setActionDetails({
      action,
    });
    setIsPerformActionConfirmationDialogOpen(true);
  };

  /**
   * Handles performing action on an application.
   *
   * @returns {Promise}
   */
  const handlePerformAction = async (applicationId, action) => {
    setIsPerformingAction(true);
    const response = await performActionAsync(applicationId, action);

    setIsPerformingAction(false);

    if (response?.hasError) {
      setErrorMessage(
        `Error while performing action: ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);

      return;
    }

    setSuccessMessage(`Successfully performed action on the application.`);
    setIsSuccessMessageShown(true);
    handleClosePerformActionConfirmationDialog();
    fetchAndSetApplications();
    fetchAndSetStatistics();
  };

  /**
   * Handles the addition of a Caregiver to an application.
   */
  const handlePostApplicationCaregiver = async () => {
    setHasTriedAddCaregiverFormSubmission(true);

    if (isAddCaregiverFormValid()) {
      const beautifiedPayload = {
        ...addCaregiverForm,
        job_id: addCaregiverForm?.job_id?.toString(),
        outreach: addCaregiverForm?.outreach === "true",
      };

      setIsPostingCaregiver(true);
      const response = await postApplicationCaregiverAsync(beautifiedPayload);

      setIsPostingCaregiver(false);

      if (response?.hasError) {
        setErrorMessage(
          `Error while adding Caregiver : ${response?.error?.message}`
        );
        setIsErrorMessageShown(true);

        return;
      }

      setSuccessMessage(`Successfully added applicant.`);
      setIsSuccessMessageShown(true);
      handleCloseAddCaregiverForm();
    }
  };

  /**
   * Handles rendering of the Action cell in the tasks grid.
   *
   * @param {Object} params
   * @returns {Component}
   */
  const handleRenderActionCell = (params = {}) => {
    return (
      <ActionButton
        navigateToCaregiverProfile={() =>
          navigateToCaregiverProfile(params?.row?.id)
        }
        openInterviewDecisionForm={() => {
          onOpenInterviewDecisionForm(params?.row?.id);
        }}
        openDocumentsReviewForm={() =>
          onOpenDocumentReviewForm(params?.row?.id)
        }
        openChatBox={() => onOpenChatBox(params?.row?.id)}
        action={getDefaultAction(params?.row?.status?.value)}
        performAction={(action) =>
          handleOpenPerformActionConfirmationDialog(params?.row?.id, action)
        }
      />
    );
  };

  /**
   * Handles rendering of the Status cell in the tasks grid.
   *
   * @param {Object} params
   * @returns {Component}
   */
  const handleRenderStatusCell = (params = {}) => {
    return (
      <StatusDropdown
        status={params?.row?.status?.value}
        onStatusChange={(current, next) =>
          handleOnStatusChange(params?.row?.id, current, next)
        }
      />
    );
  };

  /**
   * Handles Change Request for the selected documents.
   *
   * @returns {Promise}
   */
  const handleRequestChangeDocuments = async () => {
    setIsRequestingChangeDocuments(true);
    const response = await requestChangeDocumentsAsync(
      selectedUser?.id,
      selectedDocuments,
      changeRequestReason
    );

    setIsRequestingChangeDocuments(false);

    if (response?.hasError) {
      setErrorMessage(
        `Error while Requesting Document(s) Changes : ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);

      return;
    }

    setSuccessMessage(`Successfully sent change request for Document(s)`);
    setIsSuccessMessageShown(true);
    handleCloseRequestChangeFormDialog();
  };

  /**
   * Handles the Request Change of the document.
   *
   * @param {Number} documentId
   */
  const handleRequestChangeDocument = (documentId) => {
    setSelectedDocuments([documentId]);

    setIsRequestChangeFormDialogOpen(true);
  };

  /**
   * Handles the selection of the Application Documents.
   *
   * @param {Number} newDocumentId
   */
  const handleSelectDocument = (newDocumentId) => {
    setSelectedDocuments((previousSelectedDocuments) => [
      ...new Set([...previousSelectedDocuments, newDocumentId]),
    ]);
  };

  /**
   * Handles the selection of the Application Documents.
   *
   * @param {Number} newDocumentId
   */
  const handleSelectDocumentsForApproval = (documentId) => {
    // Check if exists in own
    if (!selectedDocumentsForApproval?.includes(documentId)) {
      // No: Add
      setSelectedDocumentsForApproval((previousSelectedDocuments) => [
        ...new Set([...previousSelectedDocuments, documentId]),
      ]);

      // Check if exists in others
      if (selectedDocumentsForChangeRequest?.includes(documentId)) {
        // Yes: Remove
        setSelectedDocumentsForChangeRequest((previousSelectedDocuments) =>
          previousSelectedDocuments?.filter(
            (previousSelectedDocument) =>
              previousSelectedDocument !== documentId
          )
        );
      }
    } else {
      // Yes: Remove
      setSelectedDocumentsForApproval((previousSelectedDocuments) =>
        previousSelectedDocuments?.filter(
          (previousSelectedDocument) => previousSelectedDocument !== documentId
        )
      );
    }
  };

  /**
   * Handles the selection of the Application Documents.
   *
   * @param {Number} newDocumentId
   */
  const handleSelectDocumentsForChangeRequest = (documentId) => {
    // Check if exists in own
    if (!selectedDocumentsForChangeRequest?.includes(documentId)) {
      // No: Add
      setSelectedDocumentsForChangeRequest((previousSelectedDocuments) => [
        ...new Set([...previousSelectedDocuments, documentId]),
      ]);

      // Check if exists in others
      if (selectedDocumentsForApproval?.includes(documentId)) {
        // Yes: Remove
        setSelectedDocumentsForApproval((previousSelectedDocuments) =>
          previousSelectedDocuments?.filter(
            (previousSelectedDocument) =>
              previousSelectedDocument !== documentId
          )
        );
      }
    } else {
      // Yes: Remove
      setSelectedDocumentsForChangeRequest((previousSelectedDocuments) =>
        previousSelectedDocuments?.filter(
          (previousSelectedDocument) => previousSelectedDocument !== documentId
        )
      );
    }
  };

  /**
   * Handles sending new message.
   *
   * @param {String} message
   *
   * @returns
   */
  const handleSendMessage = async (message = "") => {
    const response = await postMessageAsync(
      selectedUser?.caregiver?.phone_number,
      message,
      selectedUser?.id
    );

    if (response?.hasError) {
      return;
    }

    await fetchAndSetMessages(selectedUser?.caregiver?.phone_number);
  };

  /**
   * Method to handle the status change of an Application.
   */
  const handleApplicationCaregiverStatusChange = async () => {
    setIsUpdatingApplicationStatus(true);
    const response = await updateApplicationCaregiverStatusAsync({
      application_id: selectedUser?.id,
      status: status?.next,
      run_flow: true,
    });

    setIsUpdatingApplicationStatus(false);

    if (response?.hasError) {
      setErrorMessage(
        `Error while Updating Status: ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);

      return;
    }

    handleCloseChangeStatusConfirmationDialog();
    setSuccessMessage(`Successfully changed applicant's status.`);
    setIsSuccessMessageShown(true);
    fetchAndSetApplications();
    fetchAndSetStatistics();
  };

  /**
   * Checks if the add Caregiver form is valid.
   *
   * @return {Boolean}
   */
  const isAddCaregiverFormValid = () => {
    return (
      !isStringEmpty(addCaregiverForm?.first_name) &&
      !isStringEmpty(addCaregiverForm?.last_name) &&
      !isStringEmpty(addCaregiverForm?.phone_number) &&
      !isStringEmpty(addCaregiverForm?.email) &&
      !isNaN(addCaregiverForm?.job_id)
    );
  };

  /**
   * Checks if the Interview Decision Form is valid.
   *
   * @returns {Boolean}
   */
  const isInterviewDecisionFormValid = () => {
    return (
      !isArrayEmpty(interviewDecisionForm?.days) &&
      !isStringEmpty(interviewDecisionForm?.city) &&
      !isArrayEmpty(interviewDecisionForm?.times) &&
      !isStringEmpty(interviewDecisionForm?.email) &&
      !isStringEmpty(interviewDecisionForm?.status) &&
      !isStringEmpty(interviewDecisionForm?.zipcode) &&
      // !isStringEmpty(interviewDecisionForm?.position) &&
      !isStringEmpty(interviewDecisionForm?.last_name) &&
      !isStringEmpty(interviewDecisionForm?.first_name) &&
      !isStringEmpty(interviewDecisionForm?.phone_number) &&
      !isStringEmpty(interviewDecisionForm?.company_name) &&
      !isStringEmpty(interviewDecisionForm?.status_reason)
    );
  };

  /**
   * Navigate to the selected caregiver's profile.
   */
  const navigateToCaregiverProfile = (id) => {
    if (!isNaN(id)) {
      navigate(`/caregivers/${id}`);
    }
  };

  /**
   * Handles opening the chat box.
   *
   * @param {Object} user
   * @param {Number} caregiverPhoneNumber
   */
  const onOpenChatBox = (applicationId) => {
    const application = getApplication(applicationId);

    setSelectedUser(application ?? {});
    fetchAndSetMessages(application?.caregiver?.phone_number);
    setIsChatBoxOpen(true);
  };

  /**
   * Handles opening of the Document Review Form.
   *
   * @param {Number} applicationId
   */
  const onOpenDocumentReviewForm = async (applicationId) => {
    const application = getApplication(applicationId);

    setSelectedUser(application ?? {});
    setIsDocumentReviewFormOpen(true);
    setIsApplicationDocumentsLoading(true);
    const response = await fetchAndSetApplicationDocuments(application?.id);

    setIsApplicationDocumentsLoading(false);

    if (response?.hasError) {
      setErrorMessage(
        `Error while fetching documents: ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);

      return;
    }

    setApplicationDocuments(response?.result?.documents);
  };

  /**
   * Handles opening of the Interview Decision Form.
   *
   * @param {Object} user
   */
  const onOpenInterviewDecisionForm = (applicationId) => {
    const application = getApplication(applicationId);

    setSelectedUser(application ?? {});
    setInterviewDecisionForm({
      ...interviewDecisionForm,
      first_name: application?.caregiver?.first_name,
      last_name: application?.caregiver?.last_name,
      city: application?.caregiver?.city,
      state: application?.caregiver?.state,
      email: application?.caregiver?.email,
      phone_number: application?.caregiver?.phone_number,
      zipcode: application?.caregiver?.zipcode,
    });
    setIsInterviewDecisionFormOpen(true);
  };

  /**
   * Handles the opening of the Add Caregiver Form.
   */
  const handleOpenAddCaregiverForm = () => {
    handleFetchJobs();
    setIsAddCaregiverFormOpen(true);
  };

  /**
   * Perform action async API call.
   *
   * @param {Number} applicationId
   * @param {String} action
   * @returns {Promise}
   */
  const performActionAsync = async (applicationId, action) => {
    try {
      return await performAction(applicationId, action);
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  /**
   * Posts/Creates an Application's Caregiver.
   */
  const postApplicationCaregiverAsync = async (payload = {}) => {
    try {
      return await postApplicationCaregiver(payload);
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  /**
   * Posts the Interview Decision of a Caregiver.
   */
  const postApplicationInterviewAsync = async (payload = {}) => {
    try {
      await postApplicationInterview(payload);
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  /**
   * Posts new messages.
   *
   * @param {Number} phoneNumber
   * @param {String} message
   * @returns
   */
  const postMessageAsync = async (phoneNumber, message = "", applicationId) => {
    try {
      setIsMessagesPosting(true);
      await postMessage(phoneNumber, message, applicationId);
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }
      setErrorMessage(`Error while posting message: ${error.message}`);
      setIsErrorMessageShown(true);

      return { hasError: true };
    } finally {
      setIsMessagesPosting(false);
    }
  };

  /**
   * Renders the Add Caregiver Form Dialog.
   *
   * @returns {Component}
   */
  const renderAddCaregiverForm = () => {
    return (
      <Dialog
        open={isAddCaregiverFormOpen}
        onClose={!isPostingCaregiver && handleCloseAddCaregiverForm}
        fullWidth
        maxWidth="sm"
      >
        <header className="dialog-header">
          <h3>Add Applicant</h3>
          <div className="right-side">
            <IconButton
              disabled={isPostingCaregiver}
              aria-label="more"
              id="long-button"
              title="Close"
              onClick={handleCloseAddCaregiverForm}
              sx={{ width: "40px", height: "40px" }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </header>
        {isFetchingJobs && <LinearProgress />}
        <Divider />
        <div className="add-caregiver-form-wrapper">
          <AddCaregiverForm
            jobs={jobs}
            formData={addCaregiverForm}
            changeFormData={handleAddCaregiverFormFieldChange}
            hasTriedFormSubmission={hasTriedAddCaregiverFormSubmission}
          />
        </div>
        <Divider />
        <div className="dialog-footer generic">
          <LoadingButton
            disabled={isPostingCaregiver}
            size="large"
            onClick={handleCloseAddCaregiverForm}
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            disabled={isPostingCaregiver}
            variant="contained"
            loading={isPostingCaregiver}
            size="large"
            onClick={handlePostApplicationCaregiver}
          >
            Add
          </LoadingButton>
        </div>
      </Dialog>
    );
  };

  /**
   * Renders the chatbox dialog for sending message.
   *
   * @returns {Component}
   */
  const renderChatBoxDialog = () => (
    <Dialog
      open={isChatBoxOpen}
      onClose={handleCloseChatBox}
      fullWidth
      maxWidth="md"
      PaperProps={{ sx: { height: "100%" } }}
    >
      <header className="dialog-header">
        <h3>
          {getFullName(
            selectedUser?.caregiver?.first_name,
            selectedUser?.caregiver?.last_name
          )}
        </h3>
        <div className="right-side">
          <div className="message-dialog-user-wrapper">
            <IconButton
              disabled
              title="Call"
              onClick={() => {}}
              sx={{ color: "#2075d9" }}
            >
              <CallIcon />
            </IconButton>
            <IconButton
              title="View User Profile"
              onClick={() => navigateToCaregiverProfile(selectedUser?.id)}
              sx={{ color: "#2075d9" }}
            >
              <AccountCircleIcon />
            </IconButton>
          </div>
          <Divider
            orientation="vertical"
            sx={{ height: "20px", marginRight: "0.5rem" }}
          />
          <IconButton
            aria-label="more"
            id="long-button"
            title="More Actions"
            onClick={handleCloseChatBox}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </header>
      <Divider />
      <div className="chatbox-wrapper">
        <ChatBox
          messages={messages}
          isLoading={isMessagesLoading}
          isPosting={isMessagesPosting}
          sendMessage={handleSendMessage}
        />
      </div>
    </Dialog>
  );

  /**
   * Renders the confirmation dialog for changing status of an application.
   *
   * @returns {Component}
   */
  const renderChangeStatusConfirmationDialog = () => {
    return (
      <ConfirmationDialog
        action={handleApplicationCaregiverStatusChange}
        cancel={handleCloseChangeStatusConfirmationDialog}
        message={{
          title: "Please Confirm",
          body: `change status from ${
            STATUS_MAPPER[status.current]?.label
          } to ${STATUS_MAPPER[status?.next]?.label}`,
          acceptButtonText: "Confirm",
        }}
        isOpen={isChangeStatusConfirmationDialogOpen}
        isLoading={isUpdatingApplicationStatus}
      />
    );
  };

  /**
   * Renders the Interview Decision Record form.
   *
   * @returns {Component}
   */
  const renderDecisionRecordForm = () => (
    <Dialog
      open={isInterviewDecisionFormOpen}
      onClose={handleCloseInterviewDecisionForm}
      fullWidth
      maxWidth="md"
      PaperProps={{ sx: { height: "100%" } }}
    >
      <header className="dialog-header">
        <h3>
          Interview Decision:{" "}
          {getFullName(
            selectedUser?.caregiver?.first_name,
            selectedUser?.caregiver?.last_name
          )}
        </h3>
        <div className="right-side">
          <div className="message-dialog-user-wrapper">
            <Button
              size="small"
              startIcon={<OpenInNewIcon />}
              onClick={() => navigateToCaregiverProfile(selectedUser?.id)}
              sx={{ textTransform: "none" }}
            >
              View User Profile
            </Button>
          </div>
          <IconButton
            aria-label="more"
            id="long-button"
            title="More Actions"
            onClick={handleCloseInterviewDecisionForm}
            sx={{ width: "40px", height: "40px" }}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </header>
      {isInterviewDecisionPosting && <LinearProgress />}
      <Divider />
      <div className="interview-decision-form-wrapper">
        <InterviewDecisionForm
          formData={interviewDecisionForm}
          changeFormData={handleInterviewDecisionFormFieldChange}
          hasTriedFormSubmission={hasTriedInterviewDecisionFormSubmission}
        />
      </div>
      <Divider />
      <div className="dialog-footer">
        <div className="dialog-footer-left">
          <LoadingButton
            loading={false}
            size="large"
            variant="outlined"
            onClick={handleCloseInterviewDecisionForm}
          >
            Cancel
          </LoadingButton>
        </div>
        <div className="dialog-footer-right">
          <LoadingButton
            loading={
              isInterviewDecisionPosting || isInterviewDecisionNoAnswering
            }
            variant="contained"
            color="error"
            onClick={handleInterviewDecisionFormNoAnswer}
            sx={{
              minWidth: "140px",
            }}
          >
            No Answer
          </LoadingButton>
          <LoadingButton
            loading={
              isInterviewDecisionPosting || isInterviewDecisionNoAnswering
            }
            size="large"
            variant="contained"
            color="success"
            onClick={handleInterviewDecisionFormSubmission}
            sx={{
              minWidth: "140px",
            }}
          >
            Submit
          </LoadingButton>
        </div>
      </div>
    </Dialog>
  );

  const renderDocumentApprovalConfirmationDialog = () => {
    return (
      <ConfirmationDialog
        cancel={() => setIsDocumentApprovalConfirmationDialogOpen(false)}
        action={handleApproveDocuments}
        message={{
          title: "Please Confirm",
          body: "Approve the selected document(s)",
          acceptButtonText: "Confirm",
        }}
        isOpen={isDocumentApprovalConfirmationDialogOpen}
        isLoading={isApprovingDocuments}
      />
    );
  };

  /**
   * Renders the Document Review Form Dialog.
   *
   * @returns {Component}
   */
  const renderDocumentsReviewForm = () => {
    return (
      <Dialog
        open={isDocumentReviewFormOpen}
        onClose={handleCloseDocumentReviewForm}
        fullWidth
        maxWidth="md"
        PaperProps={{ sx: { height: "100%" } }}
      >
        <header className="dialog-header">
          <h3>
            Document Review:{" "}
            {getFullName(
              selectedUser?.caregiver?.first_name,
              selectedUser?.caregiver?.last_name
            )}
          </h3>
          <div className="right-side">
            <div className="message-dialog-user-wrapper">
              {/* TODO: Implement When Clicked on User Profile */}
              <Button
                size="small"
                startIcon={<OpenInNewIcon />}
                onClick={() => navigateToCaregiverProfile(selectedUser?.id)}
                sx={{ textTransform: "none" }}
              >
                View User Profile
              </Button>
            </div>
            <IconButton
              aria-label="more"
              id="long-button"
              title="More Actions"
              onClick={handleCloseDocumentReviewForm}
              sx={{ width: "40px", height: "40px" }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </header>
        <Divider />
        <div className="document-review-form-wrapper">
          <DocumentsReviewForm
            user={selectedUser}
            documents={applicationDocuments}
            selectedDocuments={selectedDocuments}
            selectedDocumentsForApproval={selectedDocumentsForApproval}
            selectedDocumentsForChangeRequest={
              selectedDocumentsForChangeRequest
            }
            selectDocument={handleSelectDocument}
            approveDocument={handleSelectDocumentsForApproval}
            isLoading={isApplicationDocumentsLoading}
            deselectDocument={handleDeselectDocument}
            requestChangeDocument={handleSelectDocumentsForChangeRequest}
            isApprovable={!isApprovingDocuments && !isRequestingChangeDocuments}
            isChangeable={!isRequestingChangeDocuments && !isApprovingDocuments}
          />
        </div>
        <Divider />
        <div className="dialog-footer">
          <div className="dialog-footer-left"></div>
          <div className="dialog-footer-right">
            {/* <LoadingButton
              loading={isRequestingChangeDocuments || isApprovingDocuments}
              variant="contained"
              color="error"
              onClick={() => setIsRequestChangeFormDialogOpen(true)}
              sx={{
                whiteSpace: "nowrap",
                minWidth: "max-content",
              }}
              disabled={isArrayEmpty(selectedDocuments)}
            >
              Request Change{" "}
              {!isArrayEmpty(selectedDocuments) &&
                `(${selectedDocuments.length})`}
            </LoadingButton>
            <LoadingButton
              loading={isApprovingDocuments || isRequestingChangeDocuments}
              size="large"
              variant="contained"
              color="success"
              onClick={() => setIsDocumentApprovalConfirmationDialogOpen(true)}
              sx={{
                whiteSpace: "nowrap",
                minWidth: "max-content",
              }}
              disabled={isArrayEmpty(selectedDocuments)}
            >
              Approve{" "}
              {!isArrayEmpty(selectedDocuments) &&
                `(${selectedDocuments.length})`}
            </LoadingButton> */}
            <LoadingButton
              loading={false}
              size="large"
              onClick={handleCloseDocumentReviewForm}
            >
              Cancel
            </LoadingButton>
            <LoadingButton
              loading={isSubmittingDocuments}
              size="large"
              variant="contained"
              color="success"
              onClick={handleSubmitDocumentsReview}
              sx={{
                whiteSpace: "nowrap",
                minWidth: "max-content",
              }}
              disabled={
                isArrayEmpty(applicationDocuments) ||
                applicationDocuments?.length !==
                  selectedDocumentsForApproval?.length +
                    selectedDocumentsForChangeRequest?.length
              }
            >
              Submit{" "}
            </LoadingButton>
          </div>
        </div>
      </Dialog>
    );
  };

  /**
   * Renders the error snackbar for the Dashboard page.
   *
   * @returns {Component}
   */
  const renderErrorSnackbar = () => (
    <Snackbar
      open={isErrorMessageShown}
      autoHideDuration={6000}
      onClose={() => setIsErrorMessageShown(false)}
    >
      <MuiAlert
        onClose={() => setIsErrorMessageShown(false)}
        severity="error"
        sx={{ width: "100%" }}
      >
        {errorMessage}
      </MuiAlert>
    </Snackbar>
  );

  /**
   * Renders the confirmation dialog for performing action on an application.
   *
   * @returns {Component}
   */
  const renderPerformActionConfirmationDialog = () => {
    return (
      <ConfirmationDialog
        cancel={handleClosePerformActionConfirmationDialog}
        action={() =>
          handlePerformAction(selectedUser?.id, actionDetails?.action)
        }
        message={{
          title: "Please Confirm",
          body: `${
            isStringEmpty(ACTIONS_MAPPER[actionDetails?.action]?.label)
              ? "perform the action"
              : ACTIONS_MAPPER[actionDetails?.action]?.label
          }`,
          acceptButtonText: "Confirm",
        }}
        isOpen={isPerformActionConfirmationDialogOpen}
        isLoading={isPerformingAction}
      />
    );
  };

  /**
   * Renders the Request Change Form Dialog.
   *
   * @returns {Component}
   */
  const renderRequestChangeFormDialog = () => {
    return (
      <Dialog
        open={isRequestChangeFormDialogOpen}
        onClose={handleCloseRequestChangeFormDialog}
      >
        <DialogTitle>Request Change</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ marginBottom: "12px" }}>
            Please provide a reason stating the document's change request.
          </DialogContentText>
          <TextField
            required
            autoFocus
            fullWidth
            type="text"
            margin="dense"
            label="Reason"
            variant="standard"
            id="request-change-reason"
            value={changeRequestReason}
            onChange={(event) => setChangeRequestReason(event?.target?.value)}
            helperText={
              hasTriedChangeRequestFormSubmission &&
              isStringEmpty(changeRequestReason)
                ? "Reason is required."
                : ""
            }
            error={
              hasTriedChangeRequestFormSubmission &&
              isStringEmpty(changeRequestReason)
            }
          />
        </DialogContent>
        <DialogActions>
          <LoadingButton onClick={handleCloseRequestChangeFormDialog}>
            Cancel
          </LoadingButton>
          <LoadingButton
            loading={isRequestingChangeDocuments}
            onClick={() => {
              setHasTriedChangeRequestFormSubmission(true);

              if (!isStringEmpty(changeRequestReason)) {
                handleRequestChangeDocuments();
              }
            }}
          >
            Request
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  };

  /**
   * Renders the success snackbar for the Dashboard page.
   *
   * @returns {Component}
   */
  const renderSuccessSnackbar = () => (
    <Snackbar
      open={isSuccessMessageShown}
      autoHideDuration={6000}
      onClose={() => setIsSuccessMessageShown(false)}
    >
      <MuiAlert
        onClose={() => setIsSuccessMessageShown(false)}
        severity="success"
        sx={{ width: "100%" }}
      >
        {successMessage}
      </MuiAlert>
    </Snackbar>
  );

  const renderStatistics = () => {
    const keys = Object.keys(statistics);

    if (isArrayEmpty(keys)) {
      return;
    }

    return keys.map((key) => {
      return (
        <div className="statistics-card">
          <StatisticsCard
            heading={statistics[key]}
            icon={STATISTICS_MAPPER[key]?.icon}
            subHeading={STATISTICS_MAPPER[key]?.label}
          />
        </div>
      );
    });
  };

  /**
   * Request change documents for an application.
   *
   * @param {Number} applicationId
   * @param {Array} documents
   * @param {String} message
   *
   * @returns {Promise}
   */
  const requestChangeDocumentsAsync = async (
    applicationId,
    documents = [],
    message
  ) => {
    try {
      return await requestChangeDocuments(applicationId, documents, message);
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  /**
   * Submit Documents API calls.
   *
   * @param {Number} applicationId
   * @param {Array} documents
   *
   * @returns {Promise}
   */
  const submitDocumentsAsync = async (
    applicationId,
    selectedDocumentsForApproval = [],
    selectedDocumentsForChangeRequest = []
  ) => {
    try {
      return await reviewDocuments(
        applicationId,
        selectedDocumentsForApproval,
        selectedDocumentsForChangeRequest
      );
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  /**
   * No Answer to an Application's Interview Decision.
   *
   * @param {Number} id
   * @returns {Promise}
   */
  const unanswerApplicationInterviewAsync = async (id) => {
    try {
      await unanswerApplicationInterview(id);
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  /**
   * Updates the application status.
   */
  const updateApplicationCaregiverStatusAsync = async (payload) => {
    try {
      await updateApplicationCaregiverStatus(payload);
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  /**
   * Renders the drawer to search user.
   *
   * @returns {Component}
   */
  const renderSearchUserDrawer = () => {
    return (
      <Drawer
        sx={{
          width: searchUserDrawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: searchUserDrawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="right"
        open={isSearchUserDrawerOpen}
      >
        <SearchUser
          users={users}
          search={setSearchUserValue}
          isLoading={isFetchingUsers}
          searchValue={searchUserValue}
          close={handleCloseSearchUserDrawer}
          viewDetails={navigateToCaregiverProfile}
        />
      </Drawer>
    );
  };

  return (
    <>
      <Navbar
        mainWrapperStyle={{
          width: isSearchUserDrawerOpen
            ? `calc(100% - ${searchUserDrawerWidth}px)`
            : "",
        }}
      />
      <div className="dashboard-main-outer-wrapper">
        <div className="dashboard-main-wrapper">
          <div className="dashboard-container-wrapper">
            <div>
              <div className="dashboard-first-row">
                <h1>Dashboard</h1>
                <div className="date-holder">
                  <CalendarTodayIcon />
                  <span>{getToday()}</span>
                </div>
              </div>

              <div className="dashboard-statistics-wrapper">
                {isStatisticsLoading ? (
                  <Skeleton variant="rounded" width="100%" height="100px" />
                ) : (
                  renderStatistics()
                )}
              </div>

              <Card variant="outlined">
                <CardContent
                  sx={{
                    "& .super-app-theme--header": {
                      backgroundColor: "#EDEFF5",
                    },
                  }}
                >
                  <div className="dashboard-task-progress-wrapper">
                    <h2>Tasks</h2>
                    <Divider
                      sx={{
                        margin: "20px 0",
                      }}
                    />

                    {isApplicationsLoading ? (
                      <Skeleton variant="rounded" width="100%" height="40px" />
                    ) : (
                      <TaskProgressBar
                        data={arrangeApplicationsForTaskProgressBar(
                          arrangeApplications(applications)
                        )}
                      />
                    )}
                  </div>
                  <div className="task-list-wrapper">
                    <TaskGrid
                      isLoading={isApplicationsLoading}
                      rows={applications}
                      columns={[
                        {
                          field: "section",
                          headerName: "Section",
                          flex: 1,
                          minWidth: 150,
                          headerClassName: "super-app-theme--header",
                          renderCell: (params) => (
                            <Chip
                              label={params?.row?.section?.label}
                              color="primary"
                              style={{
                                backgroundColor: params?.row?.section?.color,
                                width: "90px",
                                fontSize: "inherit",
                              }}
                            />
                          ),
                          valueGetter: (params) => params?.row?.section?.label,
                        },
                        {
                          field: "last_action.action_to_take",
                          headerName: "Action",
                          flex: 1,
                          minWidth: 275,
                          headerClassName: "super-app-theme--header",
                          filterable: false,
                          sortable: false,
                          renderCell: handleRenderActionCell,
                        },
                        {
                          field: "caregiver.first_name",
                          headerName: "First Name",
                          flex: 1,
                          minWidth: 230,
                          headerClassName: "super-app-theme--header",
                        },
                        {
                          field: "caregiver.last_name",
                          headerName: "Last Name",
                          flex: 1,
                          minWidth: 230,
                          headerClassName: "super-app-theme--header",
                        },
                        {
                          field: "status",
                          headerName: "Status",
                          flex: 1,
                          minWidth: 280,
                          headerClassName: "super-app-theme--header",
                          renderCell: handleRenderStatusCell,
                          valueGetter: (params) => params?.row?.status?.label,
                        },
                        {
                          field: "caregiver.phone_number",
                          headerName: "Phone Number",
                          flex: 1,
                          minWidth: 200,
                          headerClassName: "super-app-theme--header",
                          renderCell: (params) =>
                            formatPhoneNumber(
                              params?.row?.["caregiver.phone_number"]
                            ),
                        },
                        {
                          field: "id",
                          headerName: "ID",
                          hide: true,
                          flex: 1,
                          minWidth: 250,
                          headerClassName: "super-app-theme--header",
                        },
                      ]}
                      viewUserProfile={navigateToCaregiverProfile}
                    />
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
        <div className="dashboard-sidepanel">
          <div className="dashboard-sidepanel-addon-wrapper">
            <IconButton
              aria-label="add-caregiver"
              id="long-button"
              title="Add Applicant"
              sx={{
                width: 40,
                height: 40,
                color: "#2075d9",
              }}
              onClick={handleOpenAddCaregiverForm}
            >
              <PersonAddAlt1Icon />
            </IconButton>
            <IconButton
              aria-label="search-user"
              id="long-button"
              title="Search User"
              sx={{
                width: 40,
                height: 40,
                color: "#19A589",
              }}
              onClick={() => {
                setIsSearchUserDrawerOpen(true);
              }}
            >
              <PersonSearchIcon />
            </IconButton>
          </div>
        </div>
        <StyledSearchUserDrawer open={isSearchUserDrawerOpen}>
          {renderSearchUserDrawer()}
        </StyledSearchUserDrawer>
      </div>
      {renderChatBoxDialog()}
      {renderDecisionRecordForm()}
      {renderErrorSnackbar()}
      {renderSuccessSnackbar()}
      {renderRequestChangeFormDialog()}
      {renderDocumentsReviewForm()}
      {renderDocumentApprovalConfirmationDialog()}
      {renderPerformActionConfirmationDialog()}
      {renderAddCaregiverForm()}
      {renderChangeStatusConfirmationDialog()}
    </>
  );
}
