import { SentimentBadge } from "subframe";

import { isNumber } from "utils/number";
import { isEmpty as isObjectEmpty } from "utils/object";
import { isEmpty as isStringEmpty } from "utils/string";

import { SENTIMENT, SURVEY_TYPE } from "../constants/constants";

/**
 * Renders the sentiment badge in the [${Emoji}${Sentiment}(${Score})] format.
 *
 * @param {String} score
 * @param {String} sentiment
 * @param {Number} surveyResponseRate
 * @param {Boolean} isFixedWidth
 * @param {String} additionalClassNames
 * @returns {Component}
 */
export const renderSentimentBadgeWithScore = (
  score,
  sentiment,
  surveyResponseRate,
  isFixedWidth = false,
  additionalClassNames = ""
) => {
  if (!isNumber(score)) {
    return <></>;
  }

  const sentimentObj =
    surveyResponseRate === 0
      ? SENTIMENT["No Data"]
      : SENTIMENT[sentiment] ?? SENTIMENT["No Data"];
  const scoreLabel = `${sentimentObj.label} ${
    surveyResponseRate === 0 ? "" : `(${score?.toString()})`
  }`;

  return (
    <SentimentBadge
      score={scoreLabel}
      variant={sentimentObj.value}
      className={`${isFixedWidth ? "w-[130px]" : "w-fit"} ${additionalClassNames}`}
    />
  );
};

export const renderSentimentBadge = (label, sentimentKey, type) => {
  const sentiment = isObjectEmpty(SENTIMENT[sentimentKey])
    ? SENTIMENT["No Data"]
    : SENTIMENT[sentimentKey];

  let scoreLabel;

  if (type === SURVEY_TYPE.MULTIPLE_CHOICE) {
    scoreLabel = `${sentiment.label} ${
      isStringEmpty(label)
        ? ""
        : `(${label})
`
    }`;
  } else if (type === SURVEY_TYPE.FREE_RESPONSE) {
    return (
      <span className="w-full text-default-font text-label font-label">
        {isStringEmpty(label) ? "-" : label}
      </span>
    );
  } else {
    scoreLabel = isStringEmpty(label) ? "-" : label;
  }

  return (
    <SentimentBadge
      className="w-fit"
      variant={sentiment.value}
      score={scoreLabel}
    />
  );
};
