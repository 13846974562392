import {
  Card,
  Select,
  Divider,
  MenuItem,
  TextField,
  InputLabel,
  CardContent,
  FormControl,
  CircularProgress,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import "./CaregiverProfileSummary.css";

import { STATES } from "../../constants/constants";

import { isEmpty as isStringEmpty } from "../../utils/string";

export default function CaregiverProfileSummary({
  formData,
  isUpdating,
  isFetching,
  update = () => {},
  hasTriedFormSubmission,
  changeFormData = () => {},
}) {
  return (
    <Card
      variant="outlined"
      sx={{
        width: "100%",
        padding: "0",
      }}
    >
      <CardContent
        sx={{
          "& .super-app-theme--header": {
            backgroundColor: "#EDEFF5",
          },
          padding: "0",
        }}
      >
        <header className="caregiver-profile-summary-header-wrapper">
          <h2>Profile Summary</h2>
        </header>
        <Divider />
        <div className="caregiver-profile-summary-body-wrapper">
          {isFetching ? (
            <div className="caregiver-profile-summary-loader-wrapper">
              <CircularProgress />
            </div>
          ) : (
            <>
              <div className="form-row">
                <div className="form-column">
                  <TextField
                    required
                    id="firstName"
                    label="First Name"
                    value={formData?.["Basic Details"]?.first_name}
                    variant="outlined"
                    sx={{ width: "100%" }}
                    onChange={(event) =>
                      changeFormData(
                        "Basic Details.first_name",
                        event?.target?.value
                      )
                    }
                    helperText={
                      hasTriedFormSubmission &&
                      isStringEmpty(formData?.["Basic Details"]?.first_name)
                        ? "First Name is required."
                        : ""
                    }
                    error={
                      hasTriedFormSubmission &&
                      isStringEmpty(formData?.["Basic Details"]?.first_name)
                    }
                  />
                </div>
                <div className="form-column">
                  <TextField
                    required
                    id="lastName"
                    label="Last Name"
                    value={formData?.["Basic Details"]?.last_name}
                    variant="outlined"
                    sx={{ width: "100%" }}
                    onChange={(event) =>
                      changeFormData(
                        "Basic Details.last_name",
                        event?.target?.value
                      )
                    }
                    helperText={
                      hasTriedFormSubmission &&
                      isStringEmpty(formData?.["Basic Details"]?.last_name)
                        ? "Last Name is required."
                        : ""
                    }
                    error={
                      hasTriedFormSubmission &&
                      isStringEmpty(formData?.["Basic Details"]?.last_name)
                    }
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-column">
                  <TextField
                    required
                    id="city"
                    label="City"
                    value={formData?.["Basic Details"]?.city}
                    variant="outlined"
                    sx={{ width: "100%" }}
                    onChange={(event) =>
                      changeFormData("Basic Details.city", event?.target?.value)
                    }
                    helperText={
                      hasTriedFormSubmission &&
                      isStringEmpty(formData?.["Basic Details"]?.city)
                        ? "City is required."
                        : ""
                    }
                    error={
                      hasTriedFormSubmission &&
                      isStringEmpty(formData?.["Basic Details"]?.city)
                    }
                  />
                </div>
                <div className="form-column">
                  <FormControl>
                    <InputLabel id="state-select-helper-label">
                      State
                    </InputLabel>
                    <Select
                      labelId="state-select-helper-label"
                      id="state-select"
                      value={formData?.["Basic Details"]?.state}
                      label="State"
                      onChange={(event) =>
                        changeFormData(
                          "Basic Details.state",
                          event?.target?.value
                        )
                      }
                      sx={{ width: "100%" }}
                    >
                      {STATES.map((STATE) => (
                        <MenuItem value={STATE.value} key={STATE.id}>
                          {STATE.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              {/* <div className="form-row">
                <div className="form-column">
                  <FormControl>
                    <InputLabel id="employment-status-select-helper-label">
                      Employment Status
                    </InputLabel>
                    <Select
                      disabled
                      labelId="employment-status-select-helper-label"
                      id="employment-status"
                      value={formData?.employment_status}
                      label="Employment Status"
                      onChange={(event) =>
                        changeFormData(
                          "employment_status",
                          event?.target?.value
                        )
                      }
                      sx={{ width: "100%" }}
                    >
                      {EMPLOYMENT_STATUSES.map((EMPLOYMENT_STATUS) => (
                        <MenuItem
                          value={EMPLOYMENT_STATUS.value}
                          key={EMPLOYMENT_STATUS.id}
                        >
                          {EMPLOYMENT_STATUS.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="form-column">
                  <TextField
                    disabled
                    required
                    id="company"
                    label="Company Name"
                    value={formData?.company_name}
                    variant="outlined"
                    sx={{ width: "100%" }}
                    onChange={(event) =>
                      changeFormData("company_name", event?.target?.value)
                    }
                    helperText={
                      hasTriedFormSubmission &&
                      isStringEmpty(formData?.company_name)
                        ? "Company Name is required."
                        : ""
                    }
                    error={
                      hasTriedFormSubmission &&
                      isStringEmpty(formData?.company_name)
                    }
                  />
                </div>
              </div> */}
              <div className="form-row">
                <div className="form-column">
                  <TextField
                    disabled
                    required
                    id="phoneNumber"
                    label="Phone Number"
                    value={formData?.["Basic Details"]?.phone_number}
                    variant="outlined"
                    sx={{ width: "100%" }}
                    onChange={(event) => {
                      changeFormData(
                        "Basic Details.phone_number",
                        event?.target?.value
                      );
                    }}
                    helperText={
                      hasTriedFormSubmission &&
                      isStringEmpty(formData?.["Basic Details"]?.phone_number)
                        ? "Phone Number is required."
                        : ""
                    }
                    error={
                      hasTriedFormSubmission &&
                      isStringEmpty(formData?.["Basic Details"]?.phone_number)
                    }
                  />
                </div>
                <div className="form-column">
                  <TextField
                    required
                    id="zipCode"
                    label="Zip Code"
                    value={formData?.["Basic Details"]?.zipcode}
                    variant="outlined"
                    sx={{ width: "100%" }}
                    onChange={(event) => {
                      changeFormData(
                        "Basic Details.zipcode",
                        event?.target?.value
                      );
                    }}
                    helperText={
                      hasTriedFormSubmission &&
                      isStringEmpty(formData?.["Basic Details"]?.zipcode)
                        ? "Zip Code is required."
                        : ""
                    }
                    error={
                      hasTriedFormSubmission &&
                      isStringEmpty(formData?.["Basic Details"]?.zipcode)
                    }
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-column">
                  <TextField
                    disabled
                    required
                    id="email"
                    label="Email"
                    value={formData?.["Basic Details"]?.email}
                    variant="outlined"
                    sx={{ width: "100%" }}
                    onChange={(event) =>
                      changeFormData("email", event?.target?.value)
                    }
                    helperText={
                      hasTriedFormSubmission &&
                      isStringEmpty(formData?.["Basic Details"]?.email)
                        ? "Email is required."
                        : ""
                    }
                    error={
                      hasTriedFormSubmission &&
                      isStringEmpty(formData?.["Basic Details"]?.email)
                    }
                  />
                </div>
                {/* <div className="form-column">
          <TextField
            required
            id="position"
            label="Position Applied For"
            value={formData?.position}
            variant="outlined"
            sx={{ width: "100%" }}
            onChange={(event) =>
              changeFormData("position", event?.target?.value)
            }
            helperText={
              hasTriedFormSubmission && isStringEmpty(formData?.position)
                ? "Position Applied For is required."
                : ""
            }
            error={hasTriedFormSubmission && isStringEmpty(formData?.position)}
          />
        </div> */}
              </div>
            </>
          )}
        </div>
        <Divider />
        <div className="caregiver-profile-summary-buttons-wrapper">
          <LoadingButton
            size="large"
            onClick={update}
            variant="contained"
            loading={isFetching || isUpdating}
          >
            Update
          </LoadingButton>
        </div>
      </CardContent>
    </Card>
  );
}
