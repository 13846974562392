"use client";
/*
 * Documentation:
 * Inbox Row — https://app.subframe.com/library?component=Inbox+Row_a72027e7-ff7c-4832-960a-ceab3d629bb3
 * Avatar — https://app.subframe.com/library?component=Avatar_bec25ae6-5010-4485-b46b-cf79e3943ab2
 * Sentiment Badge — https://app.subframe.com/library?component=Sentiment+Badge_e1962f43-fe6e-498c-9b8e-21e501824ce3
 * Custom Badge — https://app.subframe.com/library?component=Custom+Badge_6c4fd648-485b-4df3-b96e-19a465da6db8
 * Button — https://app.subframe.com/library?component=Button_3b777358-b86b-40af-9327-891efc6826fe
 */

import React from "react";
import * as SubframeCore from "@subframe/core";

interface InboxRowRootProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  className?: string;
}

const InboxRowRoot = React.forwardRef<HTMLElement, InboxRowRootProps>(
  function InboxRowRoot(
    { children, className, ...otherProps }: InboxRowRootProps,
    ref
  ) {
    return (
      <div
        className={SubframeCore.twClassNames(
          "group/a72027e7 flex w-full cursor-pointer items-center gap-6 rounded pt-4 pr-4 pb-4 pl-4 hover:bg-neutral-50 active:bg-neutral-100",
          className
        )}
        ref={ref as any}
        {...otherProps}
      >
        {children}
      </div>
    );
  }
);

export const InboxRow = InboxRowRoot;
