import {
  Chip,
  Radio,
  Select,
  MenuItem,
  TextField,
  FormLabel,
  InputLabel,
  RadioGroup,
  FormControl,
  OutlinedInput,
  FormHelperText,
  FormControlLabel,
} from "@mui/material";
import { Box } from "@mui/system";

import {
  STATES,
  STATUSES,
  CERTIFICATIONS,
  AVAILABILITY_DAYS,
  AVAILABILITY_TIMES,
  EMPLOYMENT_STATUSES,
  EXPERIENCES_WILLINGNESS,
} from "../../constants/constants";

import { isEmpty as isArrayEmpty } from "../../utils/array";
import { isEmpty as isStringEmpty } from "../../utils/string";

import "./InterviewDecisionForm.css";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function InterviewDecisionForm({
  formData = {
    first_name: "",
    last_name: "",
    city: "",
    state: "",
    email: "",
    position: "",
    employment_status: "",
    company_name: "",
    phone_number: "",
    zipcode: "",
    hours_per_week_min: "",
    hours_per_week_max: "",
    background: "",
    status_reason: "",
    days: [],
    times: [],
    experiences: [],
    certifications: [],
    status: "",
    referral: "",
  },
  changeFormData = () => {},
  hasTriedFormSubmission = false,
}) {
  return (
    <div className="interview-decision-form-main-wrapper">
      <div className="form-row">
        <div className="form-column">
          <TextField
            required
            id="firstName"
            label="First Name"
            value={formData?.first_name}
            variant="outlined"
            sx={{ width: "100%" }}
            onChange={(event) =>
              changeFormData("first_name", event?.target?.value)
            }
            helperText={
              hasTriedFormSubmission && isStringEmpty(formData?.first_name)
                ? "First Name is required."
                : ""
            }
            error={
              hasTriedFormSubmission && isStringEmpty(formData?.first_name)
            }
          />
        </div>
        <div className="form-column">
          <TextField
            required
            id="lastName"
            label="Last Name"
            value={formData?.last_name}
            variant="outlined"
            sx={{ width: "100%" }}
            onChange={(event) =>
              changeFormData("last_name", event?.target?.value)
            }
            helperText={
              hasTriedFormSubmission && isStringEmpty(formData?.last_name)
                ? "Last Name is required."
                : ""
            }
            error={hasTriedFormSubmission && isStringEmpty(formData?.last_name)}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-column">
          <TextField
            required
            id="city"
            label="City"
            value={formData?.city}
            variant="outlined"
            sx={{ width: "100%" }}
            onChange={(event) => changeFormData("city", event?.target?.value)}
            helperText={
              hasTriedFormSubmission && isStringEmpty(formData?.city)
                ? "City is required."
                : ""
            }
            error={hasTriedFormSubmission && isStringEmpty(formData?.city)}
          />
        </div>
        <div className="form-column">
          <FormControl>
            <InputLabel id="state-select-helper-label">State</InputLabel>
            <Select
              labelId="state-select-helper-label"
              id="state-select"
              value={formData?.state}
              label="State"
              onChange={(event) =>
                changeFormData("state", event?.target?.value)
              }
              sx={{ width: "100%" }}
            >
              {STATES.map((STATE) => (
                <MenuItem value={STATE.value} key={STATE.id}>
                  {STATE.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
      <div className="form-row">
        <div className="form-column">
          <FormControl>
            <InputLabel id="employment-status-select-helper-label">
              Employment Status
            </InputLabel>
            <Select
              labelId="employment-status-select-helper-label"
              id="employment-status"
              value={formData?.employment_status}
              label="Employment Status"
              onChange={(event) =>
                changeFormData("employment_status", event?.target?.value)
              }
              sx={{ width: "100%" }}
            >
              {EMPLOYMENT_STATUSES.map((EMPLOYMENT_STATUS) => (
                <MenuItem
                  value={EMPLOYMENT_STATUS.value}
                  key={EMPLOYMENT_STATUS.id}
                >
                  {EMPLOYMENT_STATUS.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="form-column">
          <TextField
            required
            id="company"
            label="Company Name"
            value={formData?.company_name}
            variant="outlined"
            sx={{ width: "100%" }}
            onChange={(event) =>
              changeFormData("company_name", event?.target?.value)
            }
            helperText={
              hasTriedFormSubmission && isStringEmpty(formData?.company_name)
                ? "Company Name is required."
                : ""
            }
            error={
              hasTriedFormSubmission && isStringEmpty(formData?.company_name)
            }
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-column">
          <TextField
            disabled
            required
            id="phoneNumber"
            label="Phone Number"
            value={formData?.phone_number}
            variant="outlined"
            sx={{ width: "100%" }}
            onChange={(event) => {
              changeFormData("phone_number", event?.target?.value);
            }}
            helperText={
              hasTriedFormSubmission && isStringEmpty(formData?.phone_number)
                ? "Phone Number is required."
                : ""
            }
            error={
              hasTriedFormSubmission && isStringEmpty(formData?.phone_number)
            }
          />
        </div>
        <div className="form-column">
          <TextField
            required
            id="zipCode"
            label="Zip Code"
            value={formData?.zipcode}
            variant="outlined"
            sx={{ width: "100%" }}
            onChange={(event) => {
              changeFormData("zipcode", event?.target?.value);
            }}
            helperText={
              hasTriedFormSubmission && isStringEmpty(formData?.zipcode)
                ? "Zip Code is required."
                : ""
            }
            error={hasTriedFormSubmission && isStringEmpty(formData?.zipcode)}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-column">
          <TextField
            disabled
            required
            id="email"
            label="Email"
            value={formData?.email}
            variant="outlined"
            sx={{ width: "100%" }}
            onChange={(event) => changeFormData("email", event?.target?.value)}
            helperText={
              hasTriedFormSubmission && isStringEmpty(formData?.email)
                ? "Email is required."
                : ""
            }
            error={hasTriedFormSubmission && isStringEmpty(formData?.email)}
          />
        </div>
        {/* <div className="form-column">
          <TextField
            required
            id="position"
            label="Position Applied For"
            value={formData?.position}
            variant="outlined"
            sx={{ width: "100%" }}
            onChange={(event) =>
              changeFormData("position", event?.target?.value)
            }
            helperText={
              hasTriedFormSubmission && isStringEmpty(formData?.position)
                ? "Position Applied For is required."
                : ""
            }
            error={hasTriedFormSubmission && isStringEmpty(formData?.position)}
          />
        </div> */}
      </div>
      <div className="form-row">
        <div className="form-column">
          <div className="section">
            <div className="form-title">Hours Per Week</div>
            <div className="form-row">
              <div className="form-column">
                <TextField
                  sx={{
                    width: "100%",
                  }}
                  id="min-hour"
                  label="Min Hour"
                  type="number"
                  value={formData?.hours_per_week_min}
                  onChange={(event) => {
                    changeFormData(
                      "hours_per_week_min",
                      Number(event?.target?.value)
                    );
                  }}
                  placeholder="Min Hour"
                  InputProps={{ inputProps: { min: 0 } }}
                />
              </div>
              <div className="form-column">
                <TextField
                  sx={{
                    width: "100%",
                  }}
                  id="max-hour"
                  label="Max Hour"
                  type="number"
                  value={formData?.hours_per_week_max}
                  onChange={(event) => {
                    changeFormData(
                      "hours_per_week_max",
                      Number(event?.target?.value)
                    );
                  }}
                  placeholder="Max Hour"
                  InputProps={{ inputProps: { min: 0 } }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="form-column">
          <FormControl>
            <InputLabel id="availability-days-chip-label">
              Availability Days *
            </InputLabel>
            <Select
              required
              labelId="availability-days-chip-label"
              multiple
              id="availability-days"
              value={formData?.days}
              onChange={(event) => {
                const value = event?.target?.value;

                changeFormData(
                  "days",
                  typeof value === "string" ? value.split(",") : value
                );
              }}
              label="Availability Days *"
              sx={{
                width: "100%",
              }}
              input={
                <OutlinedInput
                  id="availability-days-multiple-chip"
                  label="Availability Days *"
                />
              }
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => {
                    return (
                      <Chip
                        key={AVAILABILITY_DAYS[value]?.id}
                        label={AVAILABILITY_DAYS[value]?.label}
                      />
                    );
                  })}
                </Box>
              )}
              MenuProps={MenuProps}
              error={hasTriedFormSubmission && isArrayEmpty(formData?.days)}
            >
              {Object.values(AVAILABILITY_DAYS).map((AVAILABILITY_DAY) => (
                <MenuItem
                  key={AVAILABILITY_DAY.id}
                  value={AVAILABILITY_DAY.value}
                >
                  {AVAILABILITY_DAY.label}
                </MenuItem>
              ))}
            </Select>
            {hasTriedFormSubmission && isArrayEmpty(formData?.days) ? (
              <FormHelperText error>
                Availability Day(s) is required.
              </FormHelperText>
            ) : (
              ""
            )}
          </FormControl>
        </div>
      </div>
      <div className="form-row">
        <div className="form-column">
          <FormControl>
            <InputLabel id="availability-times-chip-label">
              Availability Times *
            </InputLabel>
            <Select
              required
              labelId="availability-times-chip-label"
              multiple
              id="availability-times"
              value={formData?.times}
              onChange={(event) => {
                const value = event?.target?.value;

                changeFormData(
                  "times",
                  typeof value === "string" ? value.split(",") : value
                );
              }}
              label="Availability Times *"
              sx={{
                width: "100%",
              }}
              input={
                <OutlinedInput
                  label="Availability Times *"
                  id="availability-times-multiple-chip"
                />
              }
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip
                      key={AVAILABILITY_TIMES[value]?.id}
                      label={AVAILABILITY_TIMES[value]?.label}
                    />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
              error={hasTriedFormSubmission && isArrayEmpty(formData?.times)}
            >
              {Object.values(AVAILABILITY_TIMES).map((AVAILABILITY_TIME) => (
                <MenuItem
                  key={AVAILABILITY_TIME.id}
                  value={AVAILABILITY_TIME.value}
                >
                  {AVAILABILITY_TIME.label}
                </MenuItem>
              ))}
            </Select>
            {hasTriedFormSubmission && isArrayEmpty(formData?.times) ? (
              <FormHelperText error>
                Availability Time(s) is required.
              </FormHelperText>
            ) : (
              ""
            )}
          </FormControl>
        </div>
      </div>
      <div className="form-row">
        <div className="form-column">
          <FormControl>
            <InputLabel id="experience-willingness-chip-label">
              Experience / Willingness
            </InputLabel>
            <Select
              labelId="experience-willingness-chip-label"
              multiple
              id="experience-willingness"
              value={formData?.experiences}
              onChange={(event) => {
                const value = event?.target?.value;

                changeFormData(
                  "experiences",
                  typeof value === "string" ? value.split(",") : value
                );
              }}
              label="Experience / Willingness"
              sx={{
                width: "100%",
              }}
              input={
                <OutlinedInput
                  label="Experience / Willingness"
                  id="experience-willingness-multiple-chip"
                />
              }
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip
                      key={EXPERIENCES_WILLINGNESS[value]?.id}
                      label={EXPERIENCES_WILLINGNESS[value]?.label}
                    />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {Object.values(EXPERIENCES_WILLINGNESS).map(
                (EXPERIENCE_WILLINGNESS) => (
                  <MenuItem
                    key={EXPERIENCE_WILLINGNESS.id}
                    value={EXPERIENCE_WILLINGNESS.value}
                  >
                    {EXPERIENCE_WILLINGNESS.label}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        </div>
      </div>
      <div className="form-row">
        <div className="form-column">
          <FormControl>
            <InputLabel id="certifications-chip-label">
              Certifications
            </InputLabel>
            <Select
              labelId="certifications-chip-label"
              multiple
              id="certifications"
              value={formData?.certifications}
              onChange={(event) => {
                const value = event?.target?.value;

                changeFormData(
                  "certifications",
                  typeof value === "string" ? value.split(",") : value
                );
              }}
              label="Certifications"
              sx={{
                width: "100%",
              }}
              input={
                <OutlinedInput
                  label="Certifications"
                  id="certifications-multiple-chip"
                />
              }
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip
                      key={CERTIFICATIONS[value]?.id}
                      label={CERTIFICATIONS[value]?.label}
                    />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {Object.values(CERTIFICATIONS).map((CERTIFICATION) => (
                <MenuItem key={CERTIFICATION.id} value={CERTIFICATION.value}>
                  {CERTIFICATION.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
      {/* <div className="form-row">
        <div className="form-column">
          <FormControl
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <FormLabel
              id="referral-radio-buttons-group-label"
              sx={{
                marginRight: "16px",
              }}
            >
              Referral
            </FormLabel>
            <RadioGroup
              row
              value={formData?.referral}
              name="referral-radio-buttons-group"
              onChange={(event) =>
                changeFormData("referral", event?.target?.value)
              }
            >
              <FormControlLabel value="no" control={<Radio />} label="No" />
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            </RadioGroup>
          </FormControl>
        </div>
      </div> */}
      <div className="form-row">
        <div className="form-column">
          <FormControl>
            <InputLabel id="status-label">Status *</InputLabel>
            <Select
              required
              labelId="status-label"
              id="status-select"
              value={formData?.status}
              label="Status *"
              onChange={(event) =>
                changeFormData("status", event?.target?.value)
              }
              sx={{ width: "100%" }}
              error={hasTriedFormSubmission && isStringEmpty(formData?.status)}
            >
              {Object.values(STATUSES).map((STATUS) => (
                <MenuItem value={STATUS.value} key={STATUS.id}>
                  {STATUS.label}
                </MenuItem>
              ))}
            </Select>
            {hasTriedFormSubmission && isStringEmpty(formData?.status) ? (
              <FormHelperText error>Status is required.</FormHelperText>
            ) : (
              ""
            )}
          </FormControl>
        </div>
      </div>
      <div className="form-row">
        <div className="form-column">
          <TextField
            id="status-reason"
            label="Status Reason"
            multiline
            rows={2}
            value={formData?.status_reason}
            onChange={(event) => {
              changeFormData("status_reason", event?.target?.value);
            }}
            sx={{
              width: "100%",
            }}
            required
            helperText={
              hasTriedFormSubmission && isStringEmpty(formData?.status_reason)
                ? "Status Reason is required."
                : ""
            }
            error={
              hasTriedFormSubmission && isStringEmpty(formData?.status_reason)
            }
          />
        </div>
      </div>
    </div>
  );
}
