import { Box, Skeleton } from "@mui/material";
import * as SubframeCore from "@subframe/core";
import { SENTIMENT } from "modules/Retention/constants/constants";
import React from "react";
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis } from "recharts";
import { isEmpty as isArrayEmpty } from "utils/array";
import EmptyState from "../EmptyState";

const EmployeeGlanceChart = ({ data = [], isLoading = false }) => {
  // Customized dot component
  const CustomizedDot = (props) => {
    const { cx, cy, payload } = props;
    const { overallSentiment, responseRate } = payload;

    // Icon properties based on sentiment
    const sentimentMapping = {
      high: {
        color: "text-success-700",
        bg: "bg-success-100",
        icon: "FeatherSmile",
      },
      low: {
        color: "text-error-700",
        bg: "bg-error-100",
        icon: "FeatherFrown",
      },
      neutral: {
        color: "text-warning-700",
        bg: "bg-warning-100",
        icon: "FeatherMeh",
      },
      "no-data": {
        color: "text-neutral-700",
        bg: "bg-neutral-100",
        icon: "FeatherAnnoyed",
      },
      "response-zero": {
        color: "text-neutral-700",
        bg: "bg-neutral-100",
        icon: "FeatherAlertCircle",
      },
    };

    const sentiment = !responseRate
      ? "response-zero"
      : SENTIMENT[overallSentiment]?.value || SENTIMENT["No Data"]?.value;
    const { color, bg, icon } = sentimentMapping[sentiment];

    return (
      <svg x={cx - 10} y={cy - 10} width={25} height={25}>
        <foreignObject width="25" height="25">
          <SubframeCore.Icon
            className={`${color} text-subheader font-subheader ${bg} rounded-full`}
            name={icon}
          />
        </foreignObject>
      </svg>
    );
  };

  // Custom Tooltip
  const CustomTooltip = ({ active, payload }) => {
    if (active && !isArrayEmpty(payload)) {
      const { triggerDay, sentimentScore, responseRate } = payload[0]?.payload;

      return (
        <div className="shadow-md bg-white p-2 rounded-sm">
          <p className="text-default-font text-subheader font-subheader my-1">
            {triggerDay}
          </p>
          <p className="text-default-font text-body font-body">
            Score: {sentimentScore}
          </p>
          {!responseRate && (
            <p className="text-error-700 text-body font-body">
              Survey Unanswered
            </p>
          )}
        </div>
      );
    }

    return null;
  };

  return (
    <div className="w-full flex-col gap-4 items-start flex">
      <span className="text-default-font text-subheader font-subheader">
        Employee at a Glance
      </span>
      {isLoading ? (
        <Box sx={{ width: "100%" }}>
          <Skeleton variant="rounded" width="100%" height="250px" />
        </Box>
      ) : isArrayEmpty(data) ? (
        <div className="h-[250px] w-full">
          <EmptyState
            icon={
              <SubframeCore.Icon
                className="text-[44px] font-[100] leading-[44px] text-neutral-400"
                name="FeatherLineChart"
              />
            }
            primaryText="No Employee Insights Yet."
            secondaryText="We’ll display the insights here once we have the survey results."
          />
        </div>
      ) : !isArrayEmpty(data) && data.length < 3 ? (
        <div className="h-[250px] w-full">
          <EmptyState
            icon={
              <SubframeCore.Icon
                className="text-[44px] font-[100] leading-[44px] text-neutral-400"
                name="FeatherLineChart"
              />
            }
            primaryText="More Data Needed for Insights."
            secondaryText="We’ll display the insights here once we have more than 3 survey results."
          />
        </div>
      ) : (
        <ResponsiveContainer width="100%" minHeight={250}>
          <LineChart
            width="100%"
            height={250}
            data={data}
            {...{
              overflow: "visible",
            }}
            margin={{ top: 30, right: 40, left: 40, bottom: 5 }}
            style={{ fontFamily: "Nunito", fontSize: "12px" }}
          >
            <XAxis
              dataKey="triggerDay"
              interval="equidistantPreserveStart"
              axisLine={{ stroke: "#737373" }}
              tick={{ fill: "#737373" }}
              fontSize={11}
            />
            <Tooltip content={<CustomTooltip />} cursor={{ fill: "#fafafa" }} />
            <Line
              type="monotone"
              dataKey="sentimentScore"
              stroke="#60a5fa"
              strokeWidth={1}
              dot={<CustomizedDot />}
            />
          </LineChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default EmployeeGlanceChart;
