import { RouterProvider } from "react-router-dom";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import "./App.css";

import Routes from "./routes/routes";

const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "Plus Jakarta Sans",
    },
  },
});

function App() {
  return (
    <div className="App">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={theme}>
          <RouterProvider router={Routes} />
        </ThemeProvider>
      </LocalizationProvider>
    </div>
  );
}

export default App;
