import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { get } from "../../utils/localStorage";
import { isEmpty as isStringEmpty } from "../../utils/string";
import { EMPLOYEE_ROLES } from "../../constants/constants";

export default function Landing() {
  const navigate = useNavigate();

  useEffect(() => {
    const accessToken = get("token");
    const refreshToken = get("refreshToken");
    const userRole = get("userRole");

    if (!isStringEmpty(accessToken) || !isStringEmpty(refreshToken)) {
      navigate(userRole === "agency" || 
        Object.keys(EMPLOYEE_ROLES).includes(userRole) 
          ? "/retention/V2" : "/dashboard"
      );

      return;
    }

    navigate("/employee-login");
  }, []);
}
