"use client";
/*
 * Documentation:
 * Inbox Header — https://app.subframe.com/library?component=Inbox+Header_0e76f3b6-ab40-4322-8a53-45b3b3402214
 */

import React from "react";
import * as SubframeCore from "@subframe/core";

interface InboxHeaderRootProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  className?: string;
}

const InboxHeaderRoot = React.forwardRef<HTMLElement, InboxHeaderRootProps>(
  function InboxHeaderRoot(
    { children, className, ...otherProps }: InboxHeaderRootProps,
    ref
  ) {
    return (
      <div
        className={SubframeCore.twClassNames(
          "flex w-full flex-col items-start gap-4 pt-2 pb-2",
          className
        )}
        ref={ref as any}
        {...otherProps}
      >
        {children}
      </div>
    );
  }
);

export const InboxHeader = InboxHeaderRoot;
