import config from "../../../config";

/**
 * Method to get the audio survey link.
 *
 * @returns {Promise}
 */
export const fetchSurveyLink = (id) => {
  const interviewId = id ? id : config.audioSurveyInterviewToken;
  const url = `${config.audioSurveyBaseUrl}/i/${interviewId}`;
  return url;
};
