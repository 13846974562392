import { useState } from "react";

import { Menu } from "@mui/material";
import Avatar from "@mui/material/Avatar";

import { getAcronym } from "../../utils/string";

import "./AvatarViewer.css";

export default function AvatarViewer({ imageSrc, name, children }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className="avatar-main-wrapper" onClick={handleClick}>
        <Avatar
          alt={name}
          src={imageSrc}
          sx={{
            bgcolor: "var(--md-sys-color-primary-light)",
          }}
        >
          {getAcronym(name)}
        </Avatar>
        <span>{name}</span>
      </div>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {children}
      </Menu>
    </>
  );
}
