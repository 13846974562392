import React, { useEffect, useState } from "react";

import { fetchMetabaseDashboardLink } from "modules/Retention/services/retention";

const EmbedDashboard = ({
  id="",
  disabled=false
}) => {
  const [embedUrl, setEmbedUrl] = useState(null);

  useEffect(() => {
    if (!disabled) {
      fetchEmbedDashboardLinkAsync();
    }
  }, [disabled]);

  /**
   * Fetches and sets the embed dashboard link
   *
   * @returns {Promise}
   */
  const fetchEmbedDashboardLinkAsync = async () => {
    let response;

    try {
      response = await fetchMetabaseDashboardLink({id});
    } catch (error) {
      return { hasError: true, error };
    }
    setEmbedUrl(response?.result?.url);
  }

  return (
    <>
      {!disabled && embedUrl && (
        <iframe
          title={`dashboard#${id}`}
          src={embedUrl}
          style={{
            height: "100%",
            width: "100%",
            overflow: "auto",
            flexShrink: 0,
            border: "None"
          }}
          allowTransparency
        />
      )}
    </>
  );
}

export default EmbedDashboard;
