import { useEffect, useRef, useState } from "react";

import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";
import { Avatar, CircularProgress, TextField } from "@mui/material";

import "./ChatBox.css";

import { getAcronym } from "../../utils/string";
import { formatDatePST } from "../../utils/date";
import { isEmpty as isArrayEmpty } from "../../utils/array";
import { isEmpty as isStringEmpty } from "../../utils/string";

export default function ChatBox({
  isLoading,
  isPosting,
  sendMessage,
  messages = [],
}) {
  const messagesEndRef = useRef();
  const [message, setMessage] = useState("");
  const [hasTriedSubmission, setHasTriedSubmission] = useState(false);

  const scrollToBottom = () => {
    messagesEndRef?.current?.scrollIntoView({ behavior: "smooth" });
  };

  // NOTE: Disabled because currently handled using flexbox column direction.
  // useEffect(scrollToBottom, [messages]);

  /**
   * Handles when the message is typed in.
   *
   * @param {Object} event
   */
  const handleMessageChange = (event) => {
    setMessage(event?.target?.value);
  };

  /**
   * Handles sending the message.
   *
   * @returns
   */
  const handleSendMessage = async () => {
    setHasTriedSubmission(true);

    if (isStringEmpty(message)) {
      return;
    }

    await sendMessage(message);

    setHasTriedSubmission(false);
    setMessage("");
  };

  return (
    <section className="chat-main-wrapper">
      <main className="chat-inner-wrapper">
        {isLoading ? (
          <div className="chat-empty-wrapper">
            <CircularProgress />
          </div>
        ) : isArrayEmpty(messages) ? (
          <div className="chat-empty-wrapper">No Messages Yet.</div>
        ) : (
          <div>
            {messages.map((message) => (
              <div
                className={`msg ${
                  message?.sender === "LEVO" ? "right-msg" : "left-msg"
                }`}
              >
                <div className="msg-img">
                  <Avatar alt={message?.sender} src={""}>
                    {getAcronym(message?.sender)}
                  </Avatar>
                </div>

                <div className="msg-bubble">
                  <div className="msg-info">
                    <div className="msg-info-name">{message?.sender}</div>
                    <div className="msg-info-time">
                      {formatDatePST(message?.created_at)}
                    </div>
                  </div>
                  <div className="msg-text">{message?.message}</div>
                </div>
              </div>
            ))}
          </div>
        )}
        <div ref={messagesEndRef}></div>
      </main>
      <div className="chat-inputarea">
        <TextField
          id="message"
          label="Message"
          multiline
          rows={2}
          value={message}
          onChange={handleMessageChange}
          sx={{
            width: "100%",
            marginRight: "12px",
          }}
          required
          helperText={
            hasTriedSubmission && isStringEmpty(message)
              ? "Message is required."
              : ""
          }
          error={hasTriedSubmission && isStringEmpty(message)}
        />
        <LoadingButton
          loading={isLoading || isPosting}
          id="long-button"
          title="More Actions"
          sx={{
            width: 40,
            height: 40,
            color: "#2075d9",
          }}
          onClick={handleSendMessage}
          disabled={isLoading}
        >
          <SendIcon />
        </LoadingButton>
      </div>
    </section>
  );
}
