import { DataGrid } from "@mui/x-data-grid";

export default function TaskGridView({
  rows = [],
  columns = [],
  isLoading = false,
  viewUserProfile = () => {},
}) {
  return (
    <div style={{ height: 600, width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        loading={isLoading}
        onRowDoubleClick={(params) => {
          viewUserProfile(params?.id);
        }}
      />
    </div>
  );
}
