"use client";
/*
 * Documentation:
 * Sidebar-Levo — https://app.subframe.com/library?component=Sidebar-Levo_e719b28e-e919-4079-94a7-88bc166ff40e
 * Dropdown Menu — https://app.subframe.com/library?component=Dropdown+Menu_99951515-459b-4286-919e-a89e7549b43b
 * Avatar — https://app.subframe.com/library?component=Avatar_bec25ae6-5010-4485-b46b-cf79e3943ab2
 */

import React from "react";
import * as SubframeCore from "@subframe/core";

interface SidebarItemProps extends React.HTMLAttributes<HTMLDivElement> {
  icon?: SubframeCore.IconName;
  children?: string;
  selected?: boolean;
  className?: string;
}

const SidebarItem = React.forwardRef<HTMLElement, SidebarItemProps>(
  function SidebarItem(
    {
      icon = "FeatherHome",
      children,
      selected = false,
      className,
      ...otherProps
    }: SidebarItemProps,
    ref
  ) {
    return (
      <div
        className={SubframeCore.twClassNames(
          "group/d99ba87d flex w-full cursor-pointer flex-col items-center gap-1 rounded pt-2 pr-2 pb-2 pl-2 hover:bg-white active:bg-brand-100",
          { "bg-brand-100 active:bg-brand-200": selected },
          className
        )}
        ref={ref as any}
        {...otherProps}
      >
        <div className="flex items-start gap-2 pb-0.5">
          <SubframeCore.Icon
            className={SubframeCore.twClassNames(
              "text-[20px] font-[400] leading-[32px] text-default-font",
              { "text-brand-700": selected }
            )}
            name={icon}
          />
        </div>
        {children ? (
          <span
            className={SubframeCore.twClassNames(
              "line-clamp-1 text-[10px] xs:text-label-bold font-label-bold text-default-font",
              { "text-brand-700": selected }
            )}
          >
            {children}
          </span>
        ) : null}
      </div>
    );
  }
);

interface SidebarLevoRootProps extends React.HTMLAttributes<HTMLElement> {
  children?: React.ReactNode;
  logoImage?: string;
  className?: string;
}

const SidebarLevoRoot = React.forwardRef<HTMLElement, SidebarLevoRootProps>(
  function SidebarLevoRoot(
    { children, logoImage, className, ...otherProps }: SidebarLevoRootProps,
    ref
  ) {
    return (
      <nav
        className={SubframeCore.twClassNames(
          "flex flex-row fixed bottom-0 left-0 items-start gap-2 bg-brand-50 w-full md:flex-col md:pt-5 md:h-screen md:w-24 md:static z-20",
          className
        )}
        ref={ref as any}
        {...otherProps}
      >
        <div className="hidden md:flex w-full flex-col items-center justify-center gap-2 pr-3.5 pl-3.5">
          {logoImage ? (
            <img className="h-12 flex-none" src={logoImage} alt = "levo-logo"/>
          ) : null}
        </div>
        <div className="flex md:h-screen w-full grow shrink-0 basis-0 flex-row md:flex-col items-start gap-2 pt-2 pr-2 pb-2 pl-2">
          {children}
        </div>
      </nav>
    );
  }
);

export const SidebarLevo = Object.assign(SidebarLevoRoot, {
  SidebarItem,
});
