import { Card, Divider, CardContent, CircularProgress } from "@mui/material";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

import "./ActivityPanel.css";

import { formatDate } from "../../utils/date";
import { isEmpty as isArrayEmpty } from "../../utils/array";

import { STATUS_MAPPER } from "../../constants/constants";

export default function ActivityPanel({
  isLoading,
  actions = [],
  hasTriedFormSubmission,
  changeFormData = () => {},
}) {
  return (
    <Card
      variant="outlined"
      sx={{
        width: "100%",
        padding: "0",
      }}
    >
      <CardContent
        sx={{
          "& .super-app-theme--header": {
            backgroundColor: "#EDEFF5",
          },
          padding: "0",
        }}
      >
        <header className="caregiver-activity-panel-header-wrapper">
          <h2>Activity Panel</h2>
        </header>
        <Divider />
        <div className="caregiver-activity-panel-body-wrapper">
          {isLoading ? (
            <div className="caregiver-profile-summary-loader-wrapper">
              <CircularProgress />
            </div>
          ) : isArrayEmpty(actions) ? (
            <div className="activity-empty-wrapper">No Activities Yet.</div>
          ) : (
            actions.map((action) => (
              <div className="activity-panel-main-wrapper">
                <div className="activity-panel-first-row">
                  <h2>{action?.status?.replace(/_|-/g, " ") ?? "-"}</h2>
                  <span>
                    {STATUS_MAPPER[action?.status]?.description ?? "-"}
                  </span>
                </div>
                <Divider />
                <div className="activity-panel-second-row">
                  {/* <div className="activity-panel-metadata">
                    <label>CREATED AT</label>
                    <strong>{formatDate(action?.created_at) ?? "-"}</strong>
                  </div> */}
                  <div className="activity-panel-metadata">
                    <div className="label-wrapper">
                      <CalendarMonthIcon fontSize="small" /> UPDATED AT:
                    </div>
                    <strong>{formatDate(action?.updated_at) ?? "-"}</strong>
                  </div>
                  {/* <div className="activity-panel-metadata">
                    <label>COUNT</label>
                    <strong>{action?.count ?? "-"}</strong>
                  </div> */}
                </div>
              </div>
            ))
          )}
        </div>
      </CardContent>
    </Card>
  );
}
