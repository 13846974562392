import { useState, useEffect } from "react";

import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

import * as SubframeCore from "@subframe/core";
import { IconButton, TextArea } from "subframe";
import { Button } from "subframe/components/Button";

import { isEmpty as isStringEmpty } from "utils/string";

import { EVENT_TYPES } from "constants/mixpanel";
import { mixpanelTrackEvent } from "services/mixpanel";

import {
  generateAgencyKey,
  sendReferralSMS,
} from "modules/Referrals/services/referral";

const SendReferralSMS = ({
  flat = false,
  agencyName = "",
  focused = false,
  closeButtonIcon,
  employeeName = "",
  canMinimize = false,
  isMinimized = false,
  onSubmit = () => {},
  onMinimize = () => {},
  employeePhoneNumber = "",
}) => {
  const [primaryMessage, setPrimaryMessage] = useState("");
  const [secondaryMessage, setSecondaryMessage] = useState(
    "Hey - I think you'll be a great fit at our company. Remember to fill out the form using the link below so the recruiters know you came with me. See you soon!"
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isErrorMessageShown, setIsErrorMessageShown] = useState(false);
  const [isSuccessMessageShown, setIsSuccessMessageShown] = useState(false);
  const [hasTriedFormSubmission, setHasTriedFormSubmission] = useState(false);

  useEffect(() => {
    setPrimaryMessage(
      `Hi ${employeeName}! In case you lost it, here is your unique referral code. As a reminder, you can refer your friends and earn a bonus for every billable hour they work at our company! Simply copy and paste the message below to send to your friends.`
    );
  }, [employeeName]);

  const isFormValid =
    !isStringEmpty(primaryMessage) && !isStringEmpty(secondaryMessage);

  const resetForm = () => {
    setPrimaryMessage(
      `Hi ${employeeName}! In case you lost it, here is your unique referral code. As a reminder, you can refer your friends and earn a bonus for every billable hour they work at our company! Simply copy and paste the message below to send to your friends.`
    );
    setSecondaryMessage(
      "Hey - I think you'll be a great fit at our company. Remember to fill out the form using the link below so the recruiters know you came with me. See you soon!"
    );
    setHasTriedFormSubmission(false);
  };

  /**
   * Submits the send referral sms form.
   *
   * @param {Object} payload
   * @returns {Promise}
   */
  const submitReferralSMSAsync = async (payload) => {
    try {
      return await sendReferralSMS(payload);
    } catch (error) {
      return { hasError: true, error };
    }
  };

  /**
   * Handles the submission of the send referral sms.
   */
  const handleFormSubmit = async () => {
    setHasTriedFormSubmission(true);

    if (!isFormValid) {
      return;
    }

    setIsSubmitting(true);

    const response = await submitReferralSMSAsync({
      agency_key: generateAgencyKey(agencyName),
      phone_number: employeePhoneNumber,
      referrer_message: primaryMessage,
      referral_message: secondaryMessage,
    });

    setIsSubmitting(false);

    mixpanelTrackEvent(EVENT_TYPES.REFERRAL_SMS_SEND);

    if (response?.hasError) {
      setErrorMessage(
        `Error while sending referral SMS: ${response?.error?.response?.data?.message}`
      );
      setIsErrorMessageShown(true);

      return;
    }

    resetForm();
    setSuccessMessage("Successfully sent referral SMS.");
    setIsSuccessMessageShown(true);
    onSubmit();
    onMinimize();
  };

  /**
   * Renders the error snackbar.
   *
   * @returns {Component}
   */
  const renderErrorSnackbar = () => (
    <Snackbar
      open={isErrorMessageShown}
      autoHideDuration={6000}
      onClose={() => setIsErrorMessageShown(false)}
    >
      <MuiAlert
        onClose={() => setIsErrorMessageShown(false)}
        severity="error"
        sx={{ width: "100%" }}
      >
        {errorMessage}
      </MuiAlert>
    </Snackbar>
  );

  /**
   * Renders the success snackbar.
   *
   * @returns {Component}
   */
  const renderSuccessSnackbar = () => (
    <Snackbar
      open={isSuccessMessageShown}
      autoHideDuration={6000}
      onClose={() => setIsSuccessMessageShown(false)}
    >
      <MuiAlert
        onClose={() => setIsSuccessMessageShown(false)}
        severity="success"
        sx={{ width: "100%" }}
      >
        {successMessage}
      </MuiAlert>
    </Snackbar>
  );

  return (
    <>
      {!isMinimized && (
        <div
          className={SubframeCore.twClassNames(
            "w-full border-2 border-solid border-neutral-border rounded flex-col shadow items-start flex bg-default-background group/a7126f05 sticky bottom-0",
            {
              "border-2 border-solid border-default-background shadow-none":
                flat,
              "border-2 border-solid border-brand-primary": focused,
            }
          )}
        >
          <div className="w-full gap-2 pt-3 pr-3 pl-5 items-center flex">
            <div className="grow shrink-0 basis-0 w-full gap-2 items-center flex">
              <SubframeCore.Icon
                className="text-brand-600 text-body font-body"
                name="FeatherSend"
              />
              <span className="text-default-font text-body-bold font-body-bold">
                Resend Referral Code via SMS
              </span>
            </div>
            {canMinimize && (
              <IconButton
                icon={
                  !isStringEmpty(closeButtonIcon)
                    ? closeButtonIcon
                    : "FeatherMinimize2"
                }
                size="small"
                title="Minimize"
                onClick={onMinimize}
              />
            )}
          </div>
          <div className="w-full flex-col gap-3 pt-4 pr-4 pb-4 pl-4 items-start flex">
            <TextArea
              onChange={(event) => setPrimaryMessage(event?.target?.value)}
              className="flex-none h-auto w-full"
              label=""
              helpText={
                hasTriedFormSubmission && isStringEmpty(primaryMessage)
                  ? "Message is required"
                  : ""
              }
              error={hasTriedFormSubmission && isStringEmpty(primaryMessage)}
            >
              <TextArea.Input value={primaryMessage} />
            </TextArea>
            <div className="flex w-full items-center gap-2 mx-1">
              <span className="text-body-bold font-body-bold text-subtext-color">
                The text below is to be copied and sent to their friends along
                with the unique code.
              </span>
            </div>
            <TextArea
              onChange={(event) => setSecondaryMessage(event?.target?.value)}
              className="flex-none h-auto w-full"
              label=""
              helpText={
                hasTriedFormSubmission && isStringEmpty(secondaryMessage)
                  ? "Message is required"
                  : ""
              }
              error={hasTriedFormSubmission && isStringEmpty(secondaryMessage)}
            >
              <TextArea.Input value={secondaryMessage} />
            </TextArea>
            <div className="w-full gap-2 justify-end flex">
              <Button
                disabled={isSubmitting}
                loading={isSubmitting}
                onClick={handleFormSubmit}
              >
                Send
              </Button>
            </div>
          </div>
        </div>
      )}
      {renderErrorSnackbar()}
      {renderSuccessSnackbar()}
    </>
  );
};

export default SendReferralSMS;
