const EmptyState = ({ primaryText = "", secondaryText = "", icon }) => {
  return (
    <div className="flex h-full w-full flex-none flex-col items-center justify-center gap-4 rounded bg-default-background">
      <div className="flex flex-col items-center justify-center gap-2">
        {icon}
        <div className="flex flex-col items-center gap-1">
          <span className="text-subheader font-subheader text-default-font">
            {primaryText}
          </span>
          <span className="text-body font-body text-subtext-color text-center">
            {secondaryText}
          </span>
        </div>
      </div>
    </div>
  );
};

export default EmptyState;
