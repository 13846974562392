import { Outlet } from "react-router-dom";

import Navbar from "../Navbar";

import useUser from "../../hooks/userHooks";

export default function Retention() {
  const { user, isLoading: isLoadingUser } = useUser();
  return (
    <>
      <Navbar
        module="Retention"
        isLoadingUser={isLoadingUser}
        userLogo={user?.image_url}
        isEmployeesSearchBarShown={user?.configuration?.enable_employee_search}
        isPersonnelNavigationShown={user?.configuration?.enable_personal_tab}
      />
      <Outlet context={user} />
    </>
  );
}
