import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import MuiAlert from "@mui/material/Alert";
import {
  Card,
  Divider,
  Snackbar,
  IconButton,
  CardContent,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import ChatBox from "../ChatBox";
import StatusDropdown from "../StatusDropdown";

import "./CaregiverProfile.css";

import { ERROR_CODE, STATUS_MAPPER } from "../../constants/constants";

import { isEmpty as isStringEmpty } from "../../utils/string";
import { isEmpty as isObjectEmpty, setProperty } from "../../utils/object";

import {
  post as postMessage,
  fetch as fetchMessages,
} from "../../services/messages";
import {
  reviewDocuments,
  fetchApplicationCaregiver,
  updateApplicationCaregiver,
  updateApplicationCaregiverStatus,
  fetchDocuments as fetchApplicationDocuments,
} from "../../services/applications";

import ConfirmationDialog from "../ConfirmationDialog";
import ActivityPanel from "../ActivityPanel/ActivityPanel";
import DocumentsReviewFormPanel from "../DocumentsReviewFormPanel";
import CaregiverProfileSummary from "../CaregiverProfileSummary/CaregiverProfileSummary";

export default function CaregiverProfile() {
  const navigate = useNavigate();

  let { id } = useParams();
  const [
    selectedDocumentsForChangeRequest,
    setSelectedDocumentsForChangeRequest,
  ] = useState([]);
  const [status, setStatus] = useState({
    current: "",
    next: "",
  });
  const [
    isChangeStatusConfirmationDialogOpen,
    setIsChangeStatusConfirmationDialogOpen,
  ] = useState(false);
  const [messages, setMessages] = useState([]);
  const [caregiver, setCaregiver] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isMessagesPosting, setIsMessagesPosting] = useState(false);
  const [isMessagesLoading, setIsMessagesLoading] = useState(false);
  const [isCaregiverProfileUpdating, setIsCaregiverProfileUpdating] =
    useState(false);
  const [applicationDocuments, setApplicationDocuments] = useState([]);
  const [isUpdatingApplicationStatus, setIsUpdatingApplicationStatus] =
    useState(false);
  const [isFetchingCaregiver, setIsFetchingCaregiver] = useState(false);
  const [isErrorMessageShown, setIsErrorMessageShown] = useState(false);
  const [selectedDocumentsForApproval, setSelectedDocumentsForApproval] =
    useState([]);
  const [isSubmittingDocuments, setIsSubmittingDocuments] = useState(false);
  const [isApplicationDocumentsLoading, setIsApplicationDocumentsLoading] =
    useState(false);
  const [isSuccessMessageShown, setIsSuccessMessageShown] = useState(false);
  const [hasTriedToUpdateProfile, setHasTriedToUpdateProfile] = useState(false);

  useEffect(() => {
    loadInitialData();
  }, []);

  const loadInitialData = async () => {
    handleFetchApplicationDocuments(id);
    const caregiver = await handleFetchCaregiver(id);

    fetchAndSetMessages(caregiver?.["Basic Details"]?.phone_number);
  };

  /**
   * Fetches and sets messages of a caregiver.
   *
   * @param {Number} phoneNumber
   */
  const fetchAndSetMessages = async (phoneNumber = "") => {
    try {
      setIsMessagesLoading(true);
      const response = await fetchMessages({ phone_number: phoneNumber });
      const messages = response?.result?.messages;

      if (Array.isArray(messages)) {
        setMessages(messages.slice().reverse());
      }
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }
      setErrorMessage(`Error while fetching messages: ${error.message}`);
      setIsErrorMessageShown(true);
    } finally {
      setIsMessagesLoading(false);
    }
  };

  /**
   * Fetches and sets the documents under an application.
   *
   * @param {id} Number
   */
  const fetchApplicationDocumentsAsync = async (id) => {
    try {
      return await fetchApplicationDocuments(id);
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  /**
   * Method to fetch the caregiver profile.
   */
  const fetchCaregiverAsync = async (id) => {
    try {
      return await fetchApplicationCaregiver({ application_id: id });
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  /**
   * Gets the selected user's full name.
   *
   * @param {String} firstName
   * @param {String} lastName
   * @returns {String}
   */
  const getFullName = (firstName = "", lastName = "") => {
    return `${isStringEmpty(firstName) ? "" : firstName}${
      !isStringEmpty(firstName) && !isStringEmpty(lastName) ? " " : ""
    }${isStringEmpty(lastName) ? "" : lastName}`;
  };

  /**
   * Method to handle the status change of an Application.
   */
  const handleApplicationCaregiverStatusChange = async () => {
    setIsUpdatingApplicationStatus(true);
    const response = await updateApplicationCaregiverStatusAsync({
      application_id: caregiver?.["Application Details"]?.application?.id,
      status: status?.next,
      run_flow: true,
    });

    setIsUpdatingApplicationStatus(false);

    if (response?.hasError) {
      setErrorMessage(
        `Error while Updating Status: ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);

      return;
    }

    handleCloseChangeStatusConfirmationDialog();
    setSuccessMessage(`Successfully changed applicant's status.`);
    setIsSuccessMessageShown(true);
    handleFetchCaregiver(id);
  };

  /**
   * Handles the change in the form field of the Add Caregiver Form.
   */
  const handleCaregiverProfileFormFieldChange = (key, value) => {
    setCaregiver(setProperty(caregiver, key, value));
  };

  /**
   * Method to handle the profile update.
   */
  const handleCaregiverProfileUpdate = async () => {
    setHasTriedToUpdateProfile(true);

    if (isProfileUpdateFormValid()) {
      setIsCaregiverProfileUpdating(true);
      const beautifiedPayload = {
        ...caregiver,
        application_id: caregiver?.["Application Details"]?.application?.id,
      };
      const response = await updateApplicationCaregiverAsync(beautifiedPayload);

      setIsCaregiverProfileUpdating(false);

      if (response?.hasError) {
        setErrorMessage(
          `Error while updating user profile: ${response?.error?.message}`
        );

        setIsErrorMessageShown(true);

        return;
      }

      setSuccessMessage(`Successfully updated user profile.`);
      setIsSuccessMessageShown(true);
      setHasTriedToUpdateProfile(false);
      handleFetchCaregiver(id, true);
    }
  };

  /**
   * Handles the closing of the status changer confirmation dialog.
   */
  const handleCloseChangeStatusConfirmationDialog = () => {
    setStatus({
      current: "",
      next: "",
    });
    setIsChangeStatusConfirmationDialogOpen(false);
  };

  /**
   * Handles fetching of the caregiver.
   *
   * @param {Number} id
   * @returns {Promise}
   */
  const handleFetchCaregiver = async (id, isSilent = false) => {
    !isSilent && setIsFetchingCaregiver(true);
    const response = await fetchCaregiverAsync(id);

    !isSilent && setIsFetchingCaregiver(false);

    if (response?.hasError) {
      setErrorMessage(
        `Error while fetching user details: ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);

      return;
    }

    if (!isObjectEmpty(response?.result)) {
      setCaregiver(response?.result);

      return response?.result;
    }
  };

  /**
   * Handles fetching of the Documents..
   *
   * @param {Number} applicationId
   */
  const handleFetchApplicationDocuments = async (id) => {
    setIsApplicationDocumentsLoading(true);
    const response = await fetchApplicationDocumentsAsync(id);

    setIsApplicationDocumentsLoading(false);

    if (response?.hasError) {
      setErrorMessage(
        `Error while fetching documents: ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);

      return;
    }

    setApplicationDocuments(response?.result?.documents);
  };

  /**
   * Handles the selection of the Application Documents.
   *
   * @param {Number} newDocumentId
   */
  const handleSelectDocumentsForApproval = (documentId) => {
    // Check if exists in own
    if (!selectedDocumentsForApproval?.includes(documentId)) {
      // No: Add
      setSelectedDocumentsForApproval((previousSelectedDocuments) => [
        ...new Set([...previousSelectedDocuments, documentId]),
      ]);

      // Check if exists in others
      if (selectedDocumentsForChangeRequest?.includes(documentId)) {
        // Yes: Remove
        setSelectedDocumentsForChangeRequest((previousSelectedDocuments) =>
          previousSelectedDocuments?.filter(
            (previousSelectedDocument) =>
              previousSelectedDocument !== documentId
          )
        );
      }
    } else {
      // Yes: Remove
      setSelectedDocumentsForApproval((previousSelectedDocuments) =>
        previousSelectedDocuments?.filter(
          (previousSelectedDocument) => previousSelectedDocument !== documentId
        )
      );
    }
  };

  /**
   * Handles the selection of the Application Documents.
   *
   * @param {Number} newDocumentId
   */
  const handleSelectDocumentsForChangeRequest = (documentId) => {
    // Check if exists in own
    if (!selectedDocumentsForChangeRequest?.includes(documentId)) {
      // No: Add
      setSelectedDocumentsForChangeRequest((previousSelectedDocuments) => [
        ...new Set([...previousSelectedDocuments, documentId]),
      ]);

      // Check if exists in others
      if (selectedDocumentsForApproval?.includes(documentId)) {
        // Yes: Remove
        setSelectedDocumentsForApproval((previousSelectedDocuments) =>
          previousSelectedDocuments?.filter(
            (previousSelectedDocument) =>
              previousSelectedDocument !== documentId
          )
        );
      }
    } else {
      // Yes: Remove
      setSelectedDocumentsForChangeRequest((previousSelectedDocuments) =>
        previousSelectedDocuments?.filter(
          (previousSelectedDocument) => previousSelectedDocument !== documentId
        )
      );
    }
  };

  /**
   * Handles sending new message.
   *
   * @param {String} message
   *
   * @returns
   */
  const handleSendMessage = async (message = "") => {
    const response = await postMessageAsync(
      caregiver?.["Basic Details"]?.phone_number,
      message,
      +id
    );

    if (response?.hasError) {
      return;
    }

    await fetchAndSetMessages(caregiver?.["Basic Details"]?.phone_number);
  };

  /**
   * Handles when clicked on the status menu option to change the status.
   *
   * @param {String} current
   * @param {String} next
   */
  const handleOnStatusChange = (current, next) => {
    setStatus({
      current,
      next,
    });
    setIsChangeStatusConfirmationDialogOpen(true);
  };

  /**
   * Handles submission of the applicant's documents.
   * @returns
   */
  const handleSubmitDocumentsReview = async () => {
    setIsSubmittingDocuments(true);
    const response = await submitDocumentsAsync(
      id,
      selectedDocumentsForApproval,
      selectedDocumentsForChangeRequest
    );

    setIsSubmittingDocuments(false);

    if (response?.hasError) {
      setErrorMessage(
        `Error while reviewing the document(s): ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);

      return;
    }

    setSuccessMessage(`Successfully reviewed the document(s).`);
    setIsSuccessMessageShown(true);
    setSelectedDocumentsForApproval([]);
    setSelectedDocumentsForChangeRequest([]);
  };

  /**
   * Checks if the Caregiver Profile Form is valid.
   *
   * @returns {Boolean}
   */
  const isProfileUpdateFormValid = () => {
    return (
      !isStringEmpty(caregiver?.["Basic Details"]?.first_name) &&
      !isStringEmpty(caregiver?.["Basic Details"]?.last_name) &&
      !isStringEmpty(caregiver?.["Basic Details"]?.city) &&
      !isStringEmpty(caregiver?.["Basic Details"]?.zipcode)
    );
  };

  /**
   * Posts new messages.
   *
   * @param {Number} phoneNumber
   * @param {String} message
   * @returns
   */
  const postMessageAsync = async (phoneNumber, message = "", applicationId) => {
    try {
      setIsMessagesPosting(true);
      await postMessage(phoneNumber, message, applicationId);
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }
      setErrorMessage(`Error while posting message: ${error.message}`);
      setIsErrorMessageShown(true);

      return { hasError: true };
    } finally {
      setIsMessagesPosting(false);
    }
  };

  /**
   * Renders the confirmation dialog for changing status of an application.
   *
   * @returns {Component}
   */
  const renderChangeStatusConfirmationDialog = () => {
    return (
      <ConfirmationDialog
        action={handleApplicationCaregiverStatusChange}
        cancel={handleCloseChangeStatusConfirmationDialog}
        message={{
          title: "Please Confirm",
          body: `change status from ${
            STATUS_MAPPER[status.current]?.label
          } to ${STATUS_MAPPER[status?.next]?.label}`,
          acceptButtonText: "Confirm",
        }}
        isOpen={isChangeStatusConfirmationDialogOpen}
        isLoading={isUpdatingApplicationStatus}
      />
    );
  };

  /**
   * Renders the error snackbar for the Dashboard page.
   *
   * @returns {Component}
   */
  const renderErrorSnackbar = () => (
    <Snackbar
      open={isErrorMessageShown}
      autoHideDuration={6000}
      onClose={() => setIsErrorMessageShown(false)}
    >
      <MuiAlert
        onClose={() => setIsErrorMessageShown(false)}
        severity="error"
        sx={{ width: "100%" }}
      >
        {errorMessage}
      </MuiAlert>
    </Snackbar>
  );

  /**
   * Renders the success snackbar for the Dashboard page.
   *
   * @returns {Component}
   */
  const renderSuccessSnackbar = () => (
    <Snackbar
      open={isSuccessMessageShown}
      autoHideDuration={6000}
      onClose={() => setIsSuccessMessageShown(false)}
    >
      <MuiAlert
        onClose={() => setIsSuccessMessageShown(false)}
        severity="success"
        sx={{ width: "100%" }}
      >
        {successMessage}
      </MuiAlert>
    </Snackbar>
  );

  /**
   * Submit Documents API calls.
   *
   * @param {Number} applicationId
   * @param {Array} documents
   *
   * @returns {Promise}
   */
  const submitDocumentsAsync = async (
    applicationId,
    selectedDocumentsForApproval = [],
    selectedDocumentsForChangeRequest = []
  ) => {
    try {
      return await reviewDocuments(
        applicationId,
        selectedDocumentsForApproval,
        selectedDocumentsForChangeRequest
      );
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  /**
   * Updates the application caregiver's profile.
   */
  const updateApplicationCaregiverAsync = async (payload) => {
    try {
      return await updateApplicationCaregiver(payload);
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return {
        hasError: true,
        error,
      };
    }
  };

  /**
   * Updates the application status.
   */
  const updateApplicationCaregiverStatusAsync = async (payload) => {
    try {
      await updateApplicationCaregiverStatus(payload);
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  return (
    <div>
      <header className="caregiver-profile-header-wrapper">
        <div className="caregiver-profile-header-inner-wrapper">
          <div className="header">
            <IconButton
              aria-label="back"
              id="back-button"
              title="Back"
              sx={{
                width: 40,
                height: 40,
                marginRight: "8px",
              }}
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowBackIcon />
            </IconButton>
            <h1>
              {isStringEmpty(
                getFullName(
                  caregiver?.["Basic Details"]?.first_name,
                  caregiver?.["Basic Details"]?.last_name
                )
              )
                ? "..."
                : getFullName(
                    caregiver?.["Basic Details"]?.first_name,
                    caregiver?.["Basic Details"]?.last_name
                  )}
            </h1>
          </div>
          <StatusDropdown
            isLoading={isFetchingCaregiver}
            status={caregiver?.["Application Details"]?.application?.status}
            onStatusChange={handleOnStatusChange}
          />
        </div>
      </header>
      <div className="caregiver-profile-body-wrapper">
        <div className="caregiver-profile-body-inner-wrapper">
          <div>
            <CaregiverProfileSummary
              formData={caregiver}
              isFetching={isFetchingCaregiver}
              update={handleCaregiverProfileUpdate}
              isUpdating={isCaregiverProfileUpdating}
              hasTriedFormSubmission={hasTriedToUpdateProfile}
              changeFormData={handleCaregiverProfileFormFieldChange}
            />
          </div>
          <div className="half-width-wrapper">
            <div className="half-width">
              <DocumentsReviewFormPanel
                isApprovable={true}
                isChangeable={true}
                documents={applicationDocuments}
                selectedDocumentsForChangeRequest={
                  selectedDocumentsForChangeRequest
                }
                isSubmitting={isSubmittingDocuments}
                isLoading={isApplicationDocumentsLoading}
                approveDocument={handleSelectDocumentsForApproval}
                submitDocumentsReview={handleSubmitDocumentsReview}
                selectedDocumentsForApproval={selectedDocumentsForApproval}
                requestChangeDocument={handleSelectDocumentsForChangeRequest}
              />
            </div>
            <div className="half-width">
              <ActivityPanel
                isLoading={isFetchingCaregiver}
                actions={
                  caregiver?.["Application Details"]?.application
                    ?.application_actions
                }
              />
            </div>
          </div>
          <div>
            <Card
              variant="outlined"
              sx={{
                width: "100%",
                padding: "0",
              }}
            >
              <CardContent
                sx={{
                  "& .super-app-theme--header": {
                    backgroundColor: "#EDEFF5",
                  },
                  padding: "0",
                }}
              >
                <header className="caregiver-profile-summary-header-wrapper">
                  <h2>Messages Panel</h2>
                </header>
                <Divider />
                <div
                  style={{
                    height: "500px",
                  }}
                  className="chatbox-wrapper"
                >
                  <ChatBox
                    messages={messages}
                    isLoading={isMessagesLoading}
                    isPosting={isMessagesPosting}
                    sendMessage={handleSendMessage}
                  />
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
      {renderErrorSnackbar()}
      {renderSuccessSnackbar()}
      {renderChangeStatusConfirmationDialog()}
    </div>
  );
}
