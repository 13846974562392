export const ESCALATION_FOLLOW_UP_OUTCOME = {
  SUCCESSFUL: {
    label: "Resolved",
    value: "SUCCESSFUL",
    icon: "FeatherThumbsUp",
  },
  ATTENTION_NEEDED: {
    label: "In Progress",
    value: "NEEDS_ATTENTION",
    icon: "FeatherHourglass",
  },
  DISMISS: {
    label: "Dismissed",
    value: "DISMISS",
    icon: "FeatherX",
  },
};
