import { useNavigate } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";

import { useUpdateEffect } from "react-use";

import {
  Tab,
  Badge,
  Select,
  Button,
  Drawer,
  Snackbar,
  MenuItem,
  InputLabel,
  FormControl,
  CircularProgress,
  IconButton,
  Tabs,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import styled from "@emotion/styled";
import MuiAlert from "@mui/material/Alert";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";

import { debounce } from "lodash";

import RetentionFilter from "../RetentionFilter";
import RosterGrid from "../RosterGrid/RosterGrid";
import EmployeeOverviewCard from "../EmployeeOverviewCard/EmployeeOverviewCard";

import "./RetentionPersonnel.css";

import { ERROR_CODE } from "../../constants/constants";

import {
  fetchRoster,
  fetchAtRisks,
  fetchNewHires,
} from "../../services/retention/employees";

import { getLastNDaysFromToday } from "../../utils/date";
import {
  sortObjectByArrayOrder,
  isEmpty as isArrayEmpty,
} from "../../utils/array";
import { isEmpty as isObjectEmpty } from "../../utils/object";
import { isEmpty as isStringEmpty } from "../../utils/string";

import EmptyState from "../../assets/images/empty-state.png";

import { useLaptopLayout } from "../../hooks/mediaQueryHooks";

// NOTE: Currently, the sentiment values are specific to this Component.
// If needed anywhere else as well, move this to constants.
const SENTIMENTS = {
  NEGATIVE: {
    id: "1",
    label: "Negative",
    value: "Negative",
  },
  NEUTRAL: {
    id: "2",
    label: "Neutral",
    value: "Neutral",
  },
  POSITIVE: {
    id: "3",
    label: "Positive",
    value: "Positive",
  },
  ALL: {
    id: "4",
    label: "All",
    value: "All",
  },
};

const GROUP_BY = {
  TENURE: {
    id: "1",
    label: "Tenure",
    value: "tenure",
  },
  JOB_TITLE: {
    id: "2",
    label: "Job",
    value: "job_title",
  },
};

const drawerWidth = 220;

const TAB_PANEL = {
  NEW_HIRES: {
    id: 1,
    value: "new-hires",
    label: "New Hires",
  },
  AT_RISKS: {
    id: 2,
    value: "at-risks",
    label: "At Risk",
  },
  ROSTER: {
    id: 3,
    value: "roster",
    label: "Roster",
  },
};

/**
 * Styled search user drawer wrapper.
 */
const StyledSearchUserDrawer = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

export default function RetentionPersonnel() {
  const navigate = useNavigate();

  const laptopLayout = useLaptopLayout();

  const [filter, setFilter] = useState({
    endDate: getLastNDaysFromToday(7)?.endDate || null,
    startDate: getLastNDaysFromToday(7)?.startDate || null,
  });
  const [roster, setRoster] = useState([]);
  const [atRisks, setAtRisks] = useState([]);
  const [newHires, setNewHires] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [groupedAtRisks, setGroupedAtRisks] = useState({});
  const [groupedNewHires, setGroupedNewHires] = useState({});
  const [filteredAtRisks, setFilteredAtRisks] = useState([]);
  const [filteredNewHires, setFilteredNewHires] = useState([]);
  const [isLoadingRoster, setIsLoadingRoster] = useState(false);
  const [groupBy, setGroupBy] = useState(GROUP_BY.TENURE.value);
  const [isFilterApplied, setIsFilterApplied] = useState(true);
  const [sentiment, setSentiment] = useState(SENTIMENTS.ALL.value);
  const [isLoadingAtRisks, setIsLoadingAtRisks] = useState(false);
  const [isLoadingNewHires, setIsLoadingNewHires] = useState(false);
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const [isErrorMessageShown, setIsErrorMessageShown] = useState(false);
  const [currentTab, setCurrentTab] = useState(TAB_PANEL.NEW_HIRES.value);
  const [atRiskTenureDistribution, setAtRiskTenureDistribution] = useState([]);
  const [newHiresTenureDistribution, setNewHiresTenureDistribution] = useState(
    []
  );

  /**
   * Roster table pagination states.
   */
  const [rosterPaginationModel, setRosterPaginationModel] = useState({
    page: 0,
    pageSize: 20,
  });
  const [rosterRowCountState, setRosterRowCountState] = useState(0);

  useEffect(() => {
    handleFetchNewHires(filter);
    handleFetchAtRisks(filter);
    handleFetchRoster(filter, rosterPaginationModel);
  }, []);

  useUpdateEffect(() => {
    debouncedFetchRoster(filter, rosterPaginationModel);
  }, [rosterPaginationModel]);

  // Filtering New Hires by Sentiment.
  useEffect(() => {
    const filteredNewHires = filterEmployeesBySentiment(newHires, sentiment);

    setFilteredNewHires(filteredNewHires);
  }, [newHires, sentiment]);

  // Filtering At Risks by Sentiment.
  useEffect(() => {
    const filteredAtRisks = filterEmployeesBySentiment(atRisks, sentiment);

    setFilteredAtRisks(filteredAtRisks);
  }, [atRisks, sentiment]);

  // Grouping New Hires by Group By.
  useEffect(() => {
    const groupedNewHires = groupEmployeesBy(filteredNewHires, groupBy);

    setGroupedNewHires(
      groupBy === GROUP_BY.JOB_TITLE.value
        ? groupedNewHires
        : sortObjectByArrayOrder(groupedNewHires, newHiresTenureDistribution)
    );
  }, [filteredNewHires, groupBy]);

  // Grouping At Risks by Group By.
  useEffect(() => {
    const groupedAtRisks = groupEmployeesBy(filteredAtRisks, groupBy);

    setGroupedAtRisks(
      groupBy === GROUP_BY.JOB_TITLE.value
        ? groupedAtRisks
        : sortObjectByArrayOrder(groupedAtRisks, atRiskTenureDistribution)
    );
  }, [filteredAtRisks, groupBy]);

  /**
   * This function filters an array of employees based on their overall sentiment in a survey.
   *
   * @param {Array} employees
   * @param {String} sentiment
   * @returns {Component}
   */
  const filterEmployeesBySentiment = (employees, sentiment) => {
    if (isArrayEmpty(employees)) {
      return employees;
    }

    if (sentiment === SENTIMENTS?.ALL?.value) {
      return employees;
    }

    return employees.filter(
      (employee) => employee?.["survey"]?.["Overall Sentiment"] === sentiment
    );
  };

  /**
   * Method to fetch at risks by API call.
   *
   * @param {Object} filter
   * @returns {Promise}
   */
  const fetchAtRisksAsync = async (filter = {}) => {
    try {
      return await fetchAtRisks({
        start_date: filter?.startDate,
        end_date: filter?.endDate,
      });
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  /**
   * Method to fetch new hires by API call.
   *
   * @param {Object} filter
   */
  const fetchNewHiresAsync = async (filter = {}) => {
    try {
      return await fetchNewHires({
        start_date: filter?.startDate,
        end_date: filter?.endDate,
      });
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  /**
   * Method to fetch roster by API call.
   *
   * @param {Object} The filter object.
   * @returns {Promise}
   */
  const fetchRosterAsync = async (filter = {}) => {
    try {
      return await fetchRoster({
        start_date: filter?.startDate,
        end_date: filter?.endDate,
        page: filter?.page + 1,
        limit: filter?.pageSize,
      });
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  /**
   * Gets the content to be rendered in the side drawer.
   *
   * @returns {Component}
   */
  const getDrawerRenderer = () => {
    if (isFilterDrawerOpen) {
      return renderFilterDrawer();
    }
  };

  /**
   * Groups a list of employees by the selected grouping.
   *
   * @param {Array<Object>} employees - The list of employees to group.
   * @returns {Object} An object where the keys are the different group values found in the employees, and the values are arrays of employees with that group value.
   */
  const groupEmployeesBy = (employees = [], groupBy) => {
    let groupedEmployees = {};

    if (isArrayEmpty(employees) || isStringEmpty(groupBy)) {
      return groupedEmployees;
    }

    employees.forEach((employee) => {
      const groupByKey = employee?.profile?.[groupBy];

      if (isArrayEmpty(groupedEmployees[groupByKey])) {
        groupedEmployees = {
          ...groupedEmployees,
          [groupByKey]: [],
        };
      }

      groupedEmployees = {
        ...groupedEmployees,
        [groupByKey]: [...groupedEmployees[groupByKey], employee],
      };
    });

    return groupedEmployees;
  };

  /**
   * Method to handle on apply filter click.
   *
   * @param {Object} filter
   */
  const handleApplyFilter = async (filter = {}) => {
    setIsFilterApplied(true);

    await Promise.all([
      handleFetchNewHires(filter),
      handleFetchAtRisks(filter),
      handleFetchRoster(filter, rosterPaginationModel),
    ]);

    setIsFilterDrawerOpen(false);
  };

  /**
   * Handles the closing of the filter drawer.
   */
  const handleCloseFilterDrawer = () => {
    if (!isFilterApplied) {
      setFilter({
        startDate: null,
        endDate: null,
      });
    }

    setIsFilterDrawerOpen(false);
  };

  /**
   * Handles the setting of the agency's tenure distribution.
   * This is used to sort the Tenure Groups.
   */
  const handleSetTenureDistribution = (tenureDistribution = "") => {
    if (isStringEmpty(tenureDistribution)) {
      setAtRiskTenureDistribution([]);
      setNewHiresTenureDistribution([]);
    }

    setAtRiskTenureDistribution(tenureDistribution.split(", "));
    setNewHiresTenureDistribution(tenureDistribution.split(", "));
  };

  /**
   * Method to handle the fetching of the at risk employees.
   *
   * @param {Object} filter
   * @returns {Null | Undefined}
   */
  const handleFetchAtRisks = async (filter = {}) => {
    setIsLoadingAtRisks(true);
    const response = await fetchAtRisksAsync(filter);

    setIsLoadingAtRisks(false);

    if (response?.hasError) {
      setErrorMessage(
        `Error while fetching At Risks: ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);
      setAtRisks([]);

      return;
    }

    setAtRisks(response?.result?.employees);
    handleSetTenureDistribution(response?.result?.tenure_distribution);
  };

  /**
   * Method to handle the fetching of the new hire employees.
   *
   * @param {Object} filter
   */
  const handleFetchNewHires = async (filter = {}) => {
    setIsLoadingNewHires(true);
    const response = await fetchNewHiresAsync(filter);

    setIsLoadingNewHires(false);

    if (response?.hasError) {
      setErrorMessage(
        `Error while fetching New Hires: ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);
      setNewHires([]);

      return;
    }

    setNewHires(response?.result?.employees);
    handleSetTenureDistribution(response?.result?.tenure_distribution);
  };

  /**
   * Handles the change in the fields of the Filter Form.
   */
  const handleFilterFieldChange = (key, value) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      [key]: value,
    }));
  };

  /**
   * Method to handle on filter reset.
   */
  const handleResetFilter = () => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      startDate: null,
      endDate: null,
    }));
    setIsFilterApplied(false);
    handleFetchNewHires({
      startDate: null,
      endDate: null,
    });
  };

  /**
   * Handles the fetching of the Roster.
   *
   * @param {Object} filter
   * @param {Object} rosterPaginationModel
   * @returns {Null | Undefined}
   */
  const handleFetchRoster = async (filter = {}, rosterPaginationModel = {}) => {
    setIsLoadingRoster(true);
    const response = await fetchRosterAsync({
      ...filter,
      ...rosterPaginationModel,
    });

    setIsLoadingRoster(false);

    if (response?.hasError) {
      setErrorMessage(
        `Error while fetching Roster: ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);
      setRoster([]);

      return;
    }

    setRoster(response?.result?.employees);
    setRosterRowCountState(response?.result?.total || 0);
  };

  /**
   * Debounced roster fetching.
   */
  const debouncedFetchRoster = useCallback(
    debounce(handleFetchRoster, 500),
    []
  );

  /**
   * The function `handleSentimentChange` sets the sentiment state to the passed in value.
   *
   * @param {String} sentiment - The `sentiment` parameter is a variable that represents the new sentiment value
   * that needs to be set. It is passed as an argument to the `handleSentimentChange` function.
   */
  const handleSentimentChange = (sentiment) => {
    setSentiment(sentiment);
  };

  /**
   * This function sets the value of a state variable to the input parameter.
   *
   * @param {String} groupBy - `groupBy` is a variable that represents the new value for the state variable
   * `groupBy`. It is passed as a parameter to the `handleGroupByChange` function. When the function is
   * called, it updates the state variable `groupBy` with the new value.
   */
  const handleGroupByChange = (groupBy) => {
    setGroupBy(groupBy);
  };

  /**
   * Handles the change in the tab of the Tab Component.
   *
   * @param {Object} event
   * @param {String} newValue
   */
  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  /**
   * Navigate to the selected employee's profile.
   */
  const navigateToEmployeeProfile = (id) => {
    if (!isNaN(id)) {
      window.open(`/employees/${id}`, "_blank");
    }
  };

  /**
   * Renders the error snackbar for the Retention page.
   *
   * @returns {Component}
   */
  const renderErrorSnackbar = () => (
    <Snackbar
      open={isErrorMessageShown}
      autoHideDuration={6000}
      onClose={() => setIsErrorMessageShown(false)}
    >
      <MuiAlert
        onClose={() => setIsErrorMessageShown(false)}
        severity="error"
        sx={{ width: "100%" }}
      >
        {errorMessage}
      </MuiAlert>
    </Snackbar>
  );

  /**
   * Renders the drawer to filter the records.
   *
   * @returns {Component}
   */
  const renderFilterDrawer = () => {
    return (
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
          zIndex: (theme) => theme.zIndex.drawer + 2,
        }}
        anchor="right"
        open={isFilterDrawerOpen}
      >
        <RetentionFilter
          filter={filter}
          reset={handleResetFilter}
          isLoading={isLoadingNewHires}
          close={handleCloseFilterDrawer}
          isFilterApplied={isFilterApplied}
          apply={() => handleApplyFilter(filter)}
          changeFilter={handleFilterFieldChange}
        />
      </Drawer>
    );
  };

  /**
   * Renders the employees according to their grouping.
   *
   * @param {Object} groupedEmployees
   * @returns {Component}
   */
  const renderGroupedEmployees = (groupedEmployees = {}) => {
    const groupKeys = Object.keys(groupedEmployees);

    return (
      <div className="tab-content-group-outer-wrapper">
        {groupKeys.map((groupKey, index) => (
          <div className="tab-content-group-wrapper" key={index}>
            <Typography variant="h5" fontWeight="bold">
              {groupKey}
            </Typography>
            <div className="tab-content-wrapper">
              {groupedEmployees?.[groupKey]?.map((employee, index) => (
                <div className="card" key={index}>
                  <EmployeeOverviewCard
                    employee={employee}
                    onCardClick={navigateToEmployeeProfile}
                  />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  };

  /**
   * Renders the view to show list of New Hires in groups.
   *
   * @param {Object} groupedNewHires
   * @returns {Component}
   */
  const renderNewHires = (groupedNewHires) => {
    return isLoadingNewHires ? (
      <div className="loader-state-wrapper">
        <CircularProgress />
      </div>
    ) : isObjectEmpty(groupedNewHires) ? (
      <div className="empty-state-wrapper">
        <div className="picture">
          <img src={EmptyState} alt="empty-state" />
        </div>
        <div className="message">No Data.</div>
      </div>
    ) : (
      renderGroupedEmployees(groupedNewHires)
    );
  };

  /**
   * Renders the view to show list of At Risks in groups.
   *
   * @param {Object} groupedAtRisks
   * @returns {Component}
   */
  const renderAtRisks = (groupedAtRisks) => {
    return isLoadingAtRisks ? (
      <div className="loader-state-wrapper">
        <CircularProgress />
      </div>
    ) : isObjectEmpty(groupedAtRisks) ? (
      <div className="empty-state-wrapper">
        <div className="picture">
          <img src={EmptyState} alt="empty-state" />
        </div>
        <div className="message">No Data.</div>
      </div>
    ) : (
      renderGroupedEmployees(groupedAtRisks)
    );
  };

  /**
   * Renders the results count.
   *
   * @returns {Component | undefined}
   */
  const renderShowResults = () => {
    let isLoading;
    let results;

    if (currentTab === TAB_PANEL.NEW_HIRES.value) {
      isLoading = isLoadingNewHires;
      results = filteredNewHires;
    }

    if (currentTab === TAB_PANEL.AT_RISKS.value) {
      isLoading = isLoadingAtRisks;
      results = filteredAtRisks;
    }

    if (currentTab === TAB_PANEL.ROSTER.value) {
      return;
    }

    return (
      !isLoading && (
        <div className="results-count-wrapper">
          Showing{" "}
          <strong>{isArrayEmpty(results) ? 0 : results?.length} </strong>
          Results
        </div>
      )
    );
  };

  return (
    <>
      <div className="retention-personnel-main-outer-wrapper">
        <div className="retention-personnel-main-wrapper">
          <div className="retention-personnel-container-wrapper">
            <div className="retention-personnel-details-wrapper">
              <TabContext value={currentTab}>
                <div className="tab-content-header">
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      overflow: "hidden",
                    }}
                  >
                    <Tabs
                      value={currentTab}
                      variant="scrollable"
                      scrollButtons="auto"
                      onChange={handleTabChange}
                      sx={{
                        "& .Mui-selected": {
                          color: "var(--md-sys-color-primary-light)",
                        },
                      }}
                      TabIndicatorProps={{
                        sx: {
                          backgroundColor: "var(--md-sys-color-primary-light)",
                        },
                      }}
                    >
                      {Object.keys(TAB_PANEL).map((tab) => (
                        <Tab
                          key={TAB_PANEL[tab]?.id}
                          label={TAB_PANEL[tab]?.label}
                          value={TAB_PANEL[tab]?.value}
                          sx={{
                            padding: 1,
                          }}
                        />
                      ))}
                    </Tabs>
                  </Box>

                  {!laptopLayout && renderShowResults()}

                  {!laptopLayout && currentTab !== TAB_PANEL.ROSTER.value && (
                    <div className="right">
                      <div className="group-by-select">
                        <FormControl size="small">
                          <InputLabel id="group-by-select-helper-label">
                            Group By
                          </InputLabel>
                          <Select
                            labelId="group-by-select-helper-label"
                            id="group-by-select"
                            value={groupBy}
                            label="Group By"
                            onChange={(event) =>
                              handleGroupByChange(event?.target?.value)
                            }
                            sx={{ width: "100%", minWidth: "150px" }}
                          >
                            {Object.keys(GROUP_BY).map((key) => (
                              <MenuItem
                                value={GROUP_BY[key].value}
                                key={GROUP_BY[key].id}
                              >
                                {GROUP_BY[key].label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="sentiment-select">
                        <FormControl size="small">
                          <InputLabel id="sentiment-select-helper-label">
                            Sentiment
                          </InputLabel>
                          <Select
                            labelId="sentiment-select-helper-label"
                            id="sentiment-select"
                            value={sentiment}
                            label="Sentiment"
                            onChange={(event) =>
                              handleSentimentChange(event?.target?.value)
                            }
                            sx={{ width: "100%", minWidth: "150px" }}
                          >
                            {Object.keys(SENTIMENTS).map((SENTIMENT) => (
                              <MenuItem
                                value={SENTIMENTS[SENTIMENT].value}
                                key={SENTIMENTS[SENTIMENT].id}
                              >
                                {SENTIMENTS[SENTIMENT].label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  )}

                  {!laptopLayout && (
                    <Button
                      variant="text"
                      startIcon={
                        <Badge
                          color="error"
                          overlap="circular"
                          badgeContent=" "
                          variant="dot"
                          invisible={!isFilterApplied}
                        >
                          <FilterAltOutlinedIcon
                            sx={{
                              color: "var(--md-sys-color-outline-light)",
                            }}
                          />
                        </Badge>
                      }
                      color="inherit"
                      sx={{
                        fontWeight: 400,
                        textTransform: "none",
                        fontSize: "var(--spacing-4x)",
                      }}
                      onClick={() => {
                        setIsFilterDrawerOpen(true);
                      }}
                      title="Apply Filter"
                    >
                      Filter
                    </Button>
                  )}

                  {laptopLayout && (
                    <Box>
                      <IconButton
                        sx={{ p: "10px" }}
                        color="inherit"
                        onClick={() => {
                          setIsFilterDrawerOpen(true);
                        }}
                        title="Apply Filter"
                      >
                        <Badge
                          color="error"
                          overlap="circular"
                          badgeContent=" "
                          variant="dot"
                          invisible={!isFilterApplied}
                        >
                          <FilterAltOutlinedIcon
                            sx={{
                              color: "var(--md-sys-color-outline-light)",
                            }}
                          />
                        </Badge>
                      </IconButton>
                    </Box>
                  )}
                </div>

                {laptopLayout && currentTab !== TAB_PANEL.ROSTER.value && (
                  <Box
                    sx={{
                      padding: 2,
                      rowGap: 2.75,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      "@media (max-width:420px)": {
                        mb: 1,
                        flexDirection: "column-reverse",
                      },
                    }}
                  >
                    <Box>{renderShowResults()}</Box>
                    {currentTab !== TAB_PANEL.ROSTER.value && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "var(--spacing-4x)",
                        }}
                      >
                        <Box
                          sx={{
                            flex: 1,
                          }}
                        >
                          <FormControl
                            size="small"
                            sx={{
                              width: "100%",
                            }}
                          >
                            <InputLabel id="group-by-select-helper-label">
                              Group By
                            </InputLabel>
                            <Select
                              labelId="group-by-select-helper-label"
                              id="group-by-select"
                              value={groupBy}
                              label="Group By"
                              onChange={(event) =>
                                handleGroupByChange(event?.target?.value)
                              }
                              sx={{ width: "100%", minWidth: "100px" }}
                            >
                              {Object.keys(GROUP_BY).map((key) => (
                                <MenuItem
                                  value={GROUP_BY[key].value}
                                  key={GROUP_BY[key].id}
                                >
                                  {GROUP_BY[key].label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                        <Box
                          sx={{
                            flex: 1,
                          }}
                        >
                          <FormControl
                            size="small"
                            sx={{
                              width: "100%",
                            }}
                          >
                            <InputLabel id="sentiment-select-helper-label">
                              Sentiment
                            </InputLabel>
                            <Select
                              labelId="sentiment-select-helper-label"
                              id="sentiment-select"
                              value={sentiment}
                              label="Sentiment"
                              onChange={(event) =>
                                handleSentimentChange(event?.target?.value)
                              }
                              sx={{ width: "100%", minWidth: "100px" }}
                            >
                              {Object.keys(SENTIMENTS).map((SENTIMENT) => (
                                <MenuItem
                                  value={SENTIMENTS[SENTIMENT].value}
                                  key={SENTIMENTS[SENTIMENT].id}
                                >
                                  {SENTIMENTS[SENTIMENT].label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      </Box>
                    )}
                  </Box>
                )}

                <div className="tab-content-body">
                  <TabPanel
                    value={TAB_PANEL.NEW_HIRES.value}
                    sx={{
                      padding: "0 16px",
                      height: "100%",
                    }}
                  >
                    {renderNewHires(groupedNewHires)}
                  </TabPanel>
                  <TabPanel
                    value={TAB_PANEL.AT_RISKS.value}
                    sx={{
                      padding: "0 16px",
                      height: "100%",
                    }}
                  >
                    {renderAtRisks(groupedAtRisks)}
                  </TabPanel>
                  <TabPanel
                    value={TAB_PANEL.ROSTER.value}
                    sx={{
                      padding: "0 16px",
                      height: "100%",
                    }}
                  >
                    <RosterGrid
                      rows={roster}
                      setRosterPaginationModel={setRosterPaginationModel}
                      isLoading={isLoadingRoster}
                      rowCount={rosterRowCountState}
                      paginationModel={rosterPaginationModel}
                      viewEmployeeProfile={navigateToEmployeeProfile}
                    />
                  </TabPanel>
                </div>
              </TabContext>
            </div>
          </div>
        </div>

        {isFilterDrawerOpen && (
          <StyledSearchUserDrawer open>
            {getDrawerRenderer()}
          </StyledSearchUserDrawer>
        )}
      </div>
      {renderErrorSnackbar()}
    </>
  );
}
