import mixpanel from "mixpanel-browser";

import config from "../config";

import { AGENCY_ROLE, ENVIRONMENT_TYPES } from "../constants/constants";
import { EVENT_TYPES } from "../constants/mixpanel";

import { get } from "../utils/localStorage";

/**
 * initiate mixpanel analytics
 */
export const mixpanelInitialize = () => {
  const mixpanel_config = {
    api_host: config.mixpanelProxyEndpoint,
    ignore_dnt: true,
    track_pageview: true,
  };

  if (config?.environment !== ENVIRONMENT_TYPES.PRODUCTION) {
    mixpanel_config["debug"] = true;
    mixpanel_config["persistence"] = "localStorage";
  }

  mixpanel.init(config.mixpanelProjectToken, mixpanel_config);

  mixpanel.identify(get("email"));
  const agency_name = get("agency_name");
  const full_name = get("full_name");
  const user_role = get("userRole");

  let obj = {
    environment: config?.environment,
  };

  if (agency_name) {
    obj = { ...obj, agency_name, full_name, user_role };
    mixpanel.people.set({
      $agency_name: agency_name,
      $full_name: full_name,
      $user_role: user_role,
    });
  }

  mixpanel.register(obj);
};

export const mixpanelRegister = (user) => {
  const user_role = get("userRole");
  const agency_name =
    user_role === AGENCY_ROLE.agency.value ? user.name : user.agency;
  const full_name =
    user_role === AGENCY_ROLE.agency.value
      ? user.name
      : `${user.first_name} ${user.last_name}`;

  mixpanel?.identify(get("email"));
  mixpanel?.register({
    environment: config?.environment,
    agency_name: agency_name,
    full_name: full_name,
    user_role: user_role,
  });
};

/**
 * Emit login analytics event
 */
export const mixpanelLoginEvent = (eventType) => {
  mixpanelInitialize();
  mixpanelTrackEvent(eventType);
  mixpanelTrackEvent(EVENT_TYPES.LOGIN_SUCCESS);
};

/**
 * Emit singup analytics event
 */
export const mixpanelSignUpEvent = (eventType) => {
  mixpanelTrackEvent(eventType);
};

/**
 * Emit logout analytics event
 */
export const mixpanelLogoutEvent = () => {
  mixpanelTrackEvent(EVENT_TYPES.LOGOUT_SUCCESS);
  mixpanel?.reset();
};

/**
 * track mixpanel analytics events
 *
 * @param {String} eventType
 * @param {Object} data
 * @returns
 */
export const mixpanelTrackEvent = (eventType, data = {}) => {
  try {
    if (
      typeof eventType !== "undefined" &&
      EVENT_TYPES.hasOwnProperty(eventType)
    ) {
      mixpanel?.track(eventType, data);
      mixpanel?.track_pageview();
    }
  } catch (err) {
    if (
      err instanceof TypeError &&
      err?.message?.includes(
        "Cannot read properties of undefined (reading 'disable_all_events')"
      )
    ) {
      mixpanelInitialize();
    } else {
      console.log("err", err);
    }
  }
};
