"use client";
/*
 * Documentation:
 * Custom Stat Card — https://app.subframe.com/library?component=Custom+Stat+Card_ec6b5f2a-cbac-4a61-abfc-669880be3c72
 * Loader — https://app.subframe.com/library?component=Loader_f2e570c8-e463-45c2-aae9-a960146bc5d5
 */

import React from "react";
import * as SubframeCore from "@subframe/core";
import { Loader } from "./Loader";

interface CustomStatCardRootProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: "dismissed" | "at-risk" | "resolved" | "escalated" | "brand";
  title?: string;
  value?: string;
  secondaryValue?: string;
  secondaryLabel?: string;
  loading?: boolean;
  className?: string;
}

const CustomStatCardRoot = React.forwardRef<
  HTMLElement,
  CustomStatCardRootProps
>(function CustomStatCardRoot(
  {
    variant = "dismissed",
    title,
    value,
    secondaryValue,
    secondaryLabel,
    loading = false,
    className,
    ...otherProps
  }: CustomStatCardRootProps,
  ref
) {
  return (
    <div
      className={SubframeCore.twClassNames(
        "group/ec6b5f2a flex w-full flex-col items-start gap-2 rounded bg-neutral-50 pt-4 pr-5 pb-4 pl-5",
        {
          "bg-brand-50": variant === "brand",
          "bg-warning-50": variant === "escalated",
          "bg-success-50": variant === "resolved",
          "flex-col gap-2 bg-error-50": variant === "at-risk",
        },
        className
      )}
      ref={ref as any}
      {...otherProps}
    >
      {title ? (
        <span className="line-clamp-1 w-full text-body-bold font-body-bold text-default-font">
          {title}
        </span>
      ) : null}
      <div className="flex items-center gap-2">
        <div
          className={SubframeCore.twClassNames(
            "flex flex-col items-start gap-1",
            { hidden: loading }
          )}
        >
          {value ? (
            <span
              className={SubframeCore.twClassNames(
                "text-header font-header text-subtext-color",
                {
                  "text-brand-700": variant === "brand",
                  "text-warning-600": variant === "escalated",
                  "text-success-600": variant === "resolved",
                  "text-header font-header text-error-600":
                    variant === "at-risk",
                }
              )}
            >
              {value}
            </span>
          ) : null}
          <div className="flex items-center gap-1">
            {secondaryValue ? (
              <span
                className={SubframeCore.twClassNames(
                  "text-label-bold font-label-bold text-default-font",
                  {
                    "text-brand-700": variant === "brand",
                    "text-warning-600": variant === "escalated",
                    "text-success-600": variant === "resolved",
                    "text-error-600": variant === "at-risk",
                  }
                )}
              >
                {secondaryValue}
              </span>
            ) : null}
            {secondaryLabel ? (
              <span className="text-label font-label text-default-font">
                {secondaryLabel}
              </span>
            ) : null}
          </div>
        </div>
        <div
          className={SubframeCore.twClassNames(
            "hidden h-8 w-8 flex-none items-center justify-center gap-2",
            { flex: loading }
          )}
        >
          <Loader size="large" />
        </div>
      </div>
    </div>
  );
});

export const CustomStatCard = CustomStatCardRoot;
