import { Box, Skeleton, Tooltip } from "@mui/material";
import * as SubframeCore from "@subframe/core";
import HtmlTooltip from "components/HtmlTooltip";
import { EVENT_TYPES } from "constants/mixpanel";
import {
  INBOX_ESCALATION_TYPE,
  SENTIMENT,
} from "modules/Retention/constants/constants";
import React, { useEffect, useState } from "react";
import { mixpanelTrackEvent } from "services/mixpanel";
import { DropdownMenu, SentimentSummary } from "subframe";
import { Alert } from "subframe/components/Alert";
import { Avatar } from "subframe/components/Avatar";
import { Button } from "subframe/components/Button";
import { IconButton } from "subframe/components/IconButton";
import { ProfileDetailItem } from "subframe/components/ProfileDetailItem";
import { ProfileSlideout } from "subframe/components/ProfileSlideout";
import { SentimentBadge } from "subframe/components/SentimentBadge";
import { isEmpty as isArrayEmpty } from "utils/array";
import { formatDate } from "utils/date";
import { isNumber } from "utils/number";
import { isEmpty as isObjectEmpty } from "utils/object";
import { formatPhoneNumber } from "utils/phoneNumber";
import {
  getAcronym,
  getFullName,
  isEmpty as isStringEmpty,
} from "utils/string";
import EmployeeGlanceChart from "../EmployeeGlanceChart";
import EscalationFollowUp from "../EscalationFollowUp/EscalationFollowUp";
import History from "../History";
import RecognitionPost from "../SendRecognition/SendRecognition";
import SendReferralSMS from "../SendReferralSMS";

function EmployeeProfileSlideout({
  employeeId,
  escalationId,
  employee = {},
  user = {},
  isLoadingUser = false,
  isOpen = false,
  close = () => {},
  isFetchingEmployee = false,
  employeeHistory = [],
  isFetchingEmployeeHistory = false,
  onEscalationFollowUpSubmit = () => {},
  onRecognitionPostSubmit = () => {},
  onSendReferralSMSSubmit = () => {},
  onToggleNoteSummary = () => {},
}) {
  const [isEscalationFollowupMinimized, setIsEscalationFollowupMinimized] =
    useState(true);
  const [isRecognitionPostMinimized, setIsRecognitionPostMinimized] =
    useState(true);
  const [isSendReferralSMSMinimized, setIsSendReferralSMSMinimized] =
    useState(true);

  const [sentimentDownPoints, setSentimentDownPoints] = useState("");
  const [isSentimentDownAlertShown, setIsSentimentDownAlertShown] =
    useState(false);

  useEffect(() => {
    handleSentimentDownAlert();
  }, [employeeHistory]);

  /**
   * Navigate to the selected employee's profile.
   */
  const navigateToEmployeeProfile = (employeeId) => {
    if (!isNaN(employeeId)) {
      window.open(`/retention/V2/employees/${employeeId}`, "_blank");
    }
    mixpanelTrackEvent(EVENT_TYPES.GO_TO_PROFILE_CLICK);
  };

  const toggleMinimizeEscalationFollowUp = () => {
    setIsEscalationFollowupMinimized(
      (isEscalationFollowupMinimized) => !isEscalationFollowupMinimized
    );

    if (!isRecognitionPostMinimized) {
      setIsRecognitionPostMinimized(true);
    }

    if (!isSendReferralSMSMinimized) {
      setIsSendReferralSMSMinimized(true);
    }

    mixpanelTrackEvent(EVENT_TYPES.LOG_FOLLOW_UP_CLICK);
  };

  const toggleMinimizeRecognitionPost = () => {
    setIsRecognitionPostMinimized(
      (isRecognitionPostMinimized) => !isRecognitionPostMinimized
    );

    if (!isEscalationFollowupMinimized) {
      setIsEscalationFollowupMinimized(true);
    }

    if (!isSendReferralSMSMinimized) {
      setIsSendReferralSMSMinimized(true);
    }

    mixpanelTrackEvent(EVENT_TYPES.RECOGNITION_CLICK);
  };

  const toggleMinimizeSendReferralSMS = () => {
    setIsSendReferralSMSMinimized(
      (isSendReferralSMSMinimized) => !isSendReferralSMSMinimized
    );

    if (!isEscalationFollowupMinimized) {
      setIsEscalationFollowupMinimized(true);
    }

    if (!isRecognitionPostMinimized) {
      setIsRecognitionPostMinimized(true);
    }

    mixpanelTrackEvent(EVENT_TYPES.REFERRAL_SMS_CLICK);
  };

  const handleClose = () => {
    setIsEscalationFollowupMinimized(true);
    setIsRecognitionPostMinimized(true);
    setIsSendReferralSMSMinimized(true);
    close();
  };

  const handleSentimentDownAlert = () => {
    const completedSurveys = employeeHistory?.filter(
      (history) => history?.type === "SURVEY_COMPLETED"
    );

    if (isArrayEmpty(completedSurveys) || completedSurveys.length < 2) {
      setSentimentDownPoints("");
      setIsSentimentDownAlertShown(false);

      return;
    }

    const latestSurvey = completedSurveys[completedSurveys.length - 1];
    const previousSurvey = completedSurveys[completedSurveys.length - 2];

    if (isObjectEmpty(latestSurvey) || isObjectEmpty(previousSurvey)) {
      setSentimentDownPoints("");
      setIsSentimentDownAlertShown(false);

      return;
    }

    const latestSurveySentimentPoints =
      latestSurvey?.data?.survey?.overall_sentiment_score;
    const previousSurveySentimentPoints =
      previousSurvey?.data?.survey?.overall_sentiment_score;

    if (
      !isNumber(latestSurveySentimentPoints) ||
      !isNumber(previousSurveySentimentPoints)
    ) {
      setSentimentDownPoints("");
      setIsSentimentDownAlertShown(false);

      return;
    }

    if (latestSurveySentimentPoints < previousSurveySentimentPoints) {
      setSentimentDownPoints(
        previousSurveySentimentPoints - latestSurveySentimentPoints
      );
      setIsSentimentDownAlertShown(true);

      return;
    }

    setSentimentDownPoints("");
    setIsSentimentDownAlertShown(false);
  };

  const shouldLogNotes = () => {
    const escalationStatus = employee?.survey?.escalation_status;
    return (
      escalationStatus === INBOX_ESCALATION_TYPE["RESOLVED"] ||
      escalationStatus === INBOX_ESCALATION_TYPE["DISMISSED"]
    );
  };

  const formatEmployeeGlanceData = (apiData = []) => {
    return apiData.map((item) => ({
      triggerDay: item.trigger_day,
      completed: item.completed,
      responseRate: item["Response Rate"],
      sentimentScore: item["Sentiment Score"],
      postTermination: item.post_termination,
      overallSentiment: item["Overall Sentiment"],
    }));
  };

  return (
    <ProfileSlideout hidden={false} open={isOpen} className="z-[100]">
      <ProfileSlideout.Content>
        <div className="grow shrink-0 basis-0 h-full w-full flex-col items-start relative flex">
          <div className="w-full border-b border-solid border-neutral-border justify-between px-6 py-4 gap-6 items-center flex flex-col">
            <div className="w-full flex items-center">
              <div className="w-full gap-4 items-center flex">
                {isFetchingEmployee ? (
                  <Box
                    sx={{
                      width: "80%",
                    }}
                  >
                    <Skeleton variant="rounded" width="100%" height="56px" />
                  </Box>
                ) : (
                  <>
                    <Avatar
                      variant={
                        SENTIMENT[employee?.survey?.overall_sentiment]
                          ?.avatarVariant ?? SENTIMENT["No Data"].avatarVariant
                      }
                    >
                      {getAcronym(
                        getFullName(
                          employee?.profile?.first_name,
                          employee?.profile?.last_name
                        )
                      )}
                    </Avatar>
                    <div className="grow shrink-0 basis-0 w-full flex-col items-start flex">
                      <span className="text-default-font text-section-header font-section-header">
                        {getFullName(
                          employee?.profile?.first_name,
                          employee?.profile?.last_name
                        )}
                      </span>
                      <span className="text-subtext-color text-body font-body">
                        {isStringEmpty(employee?.profile?.job_title)
                          ? "-"
                          : employee?.profile?.job_title}
                        ,{" "}
                        {isStringEmpty(employee?.profile?.office_location)
                          ? "-"
                          : employee?.profile?.office_location}
                        ,{" "}
                        {isStringEmpty(employee?.profile?.region)
                          ? "-"
                          : employee?.profile?.region}
                      </span>
                    </div>
                  </>
                )}
              </div>
              <IconButton
                icon="FeatherX"
                size="Small"
                onClick={handleClose}
                title="Close"
              />
            </div>
            {isFetchingEmployee ? (
              <Box
                sx={{
                  width: "100%",
                }}
              >
                <Skeleton variant="rounded" width="100%" height="56px" />
              </Box>
            ) : (
              <div className="w-full flex-col gap-2 pt-2 pr-2 pb-2 pl-2 items-start flex">
                <div className="w-full gap-4 items-start flex">
                  <Tooltip title="Phone Number">
                    <ProfileDetailItem
                      className="w-[calc(50%-1rem)] md:w-full h-auto"
                      icon="FeatherPhone"
                      value={
                        isStringEmpty(employee?.profile?.phone_number)
                          ? "-"
                          : formatPhoneNumber(employee?.profile?.phone_number)
                      }
                    />
                  </Tooltip>
                  <Tooltip title="Email">
                    <ProfileDetailItem
                      className="w-[calc(50%+1rem)] md:w-full h-auto"
                      icon="FeatherMail"
                      value={
                        isStringEmpty(employee?.profile?.email)
                          ? "-"
                          : employee?.profile?.email
                      }
                    />
                  </Tooltip>
                </div>
                <div className="w-full gap-4 items-start flex">
                  <Tooltip title="Hired Date">
                    <ProfileDetailItem
                      className="w-[calc(50%-1rem)] md:w-full h-auto"
                      icon="FeatherBuilding"
                      value={
                        isStringEmpty(employee?.profile?.hired_at)
                          ? "-"
                          : formatDate(employee?.profile?.hired_at)
                      }
                    />
                  </Tooltip>
                  <Tooltip title="Tenure">
                    <ProfileDetailItem
                      className="w-[calc(50%+1rem)] md:w-full h-auto"
                      icon="FeatherCalendar"
                      value={
                        isStringEmpty(employee?.profile?.tenure)
                          ? "-"
                          : employee?.profile?.tenure
                      }
                    />
                  </Tooltip>
                </div>
                <div className="w-full gap-4 items-start flex">
                  <Tooltip title="Supervisor">
                    <ProfileDetailItem
                      className="w-[calc(50%-1rem)] md:w-full h-auto truncate"
                      icon="FeatherUser"
                      value={
                        isStringEmpty(employee?.profile?.supervisor)
                          ? "-"
                          : employee?.profile?.supervisor
                      }
                    />
                  </Tooltip>
                  <Tooltip title="Category">
                    <ProfileDetailItem
                      className="w-[calc(50%+1rem)] md:w-full h-auto"
                      icon="FeatherBriefcase"
                      value={
                        isStringEmpty(employee?.profile?.category)
                          ? "-"
                          : employee?.profile?.category
                      }
                    />
                  </Tooltip>
                </div>
              </div>
            )}

            {isFetchingEmployee ? (
              <Box
                sx={{
                  width: "100%",
                }}
              >
                <Skeleton variant="rounded" width="100%" height="32px" />
              </Box>
            ) : (
              <div className="flex w-full items-center gap-2 flex-wrap">
                <Button
                  className="h-8 w-full grow shrink-0 basis-0"
                  variant="neutral-secondary"
                  icon="FeatherMessageSquarePlus"
                  size="small"
                  onClick={toggleMinimizeEscalationFollowUp}
                >
                  {shouldLogNotes() ? "Log Notes" : "Log Follow-up"}
                </Button>
                <HtmlTooltip
                  title={
                    user?.configuration?.enable_recognition_post ? (
                      ""
                    ) : (
                      <Alert
                        variant="brand"
                        icon="FeatherInfo"
                        title="This feature is not yet enabled for your organization."
                        description="Reach out to Levo if you want to learn more!"
                      />
                    )
                  }
                >
                  <Button
                    className="h-8 w-full grow shrink-0 basis-0"
                    variant="neutral-secondary"
                    icon="FeatherAward"
                    size="small"
                    onClick={toggleMinimizeRecognitionPost}
                    disabled={!user?.configuration?.enable_recognition_post}
                  >
                    Send Recognition
                  </Button>
                </HtmlTooltip>
                <SubframeCore.DropdownMenu.Root>
                  <SubframeCore.DropdownMenu.Trigger asChild={true}>
                    <Button
                      className="h-8 w-8 flex-none"
                      variant="neutral-secondary"
                      icon="FeatherMoreHorizontal"
                      size="small"
                      children=""
                    />
                  </SubframeCore.DropdownMenu.Trigger>
                  <SubframeCore.DropdownMenu.Portal>
                    <SubframeCore.DropdownMenu.Content
                      side="bottom"
                      align="end"
                      sideOffset={4}
                      asChild={true}
                      style={{ zIndex: 100 }}
                    >
                      <DropdownMenu>
                        <DropdownMenu.DropdownItem
                          icon="FeatherExternalLink"
                          onClick={() => navigateToEmployeeProfile(employeeId)}
                        >
                          <span className="text-neutral-700 text-body font-body">
                            Go to Profile
                          </span>
                        </DropdownMenu.DropdownItem>
                        {!isLoadingUser && (
                          <HtmlTooltip
                            title={
                              user?.configuration?.enable_referral_sms ? (
                                ""
                              ) : (
                                <Alert
                                  variant="brand"
                                  icon="FeatherInfo"
                                  title="This feature is not yet enabled for your organization."
                                  description="Reach out to Levo if you want to learn more!"
                                />
                              )
                            }
                          >
                            {/* NOTE: Wrapping with a div here to get the pointer events to show tooltip. */}
                            <div className="w-full">
                              <DropdownMenu.DropdownItem
                                icon="FeatherMailPlus"
                                onClick={toggleMinimizeSendReferralSMS}
                                disabled={
                                  !user?.configuration?.enable_referral_sms
                                }
                                className={
                                  !user?.configuration?.enable_referral_sms
                                    ? "pointer-events-none opacity-50"
                                    : ""
                                }
                              >
                                <span className="text-neutral-700 text-body font-body">
                                  Send Referral SMS
                                </span>
                              </DropdownMenu.DropdownItem>
                            </div>
                          </HtmlTooltip>
                        )}
                      </DropdownMenu>
                    </SubframeCore.DropdownMenu.Content>
                  </SubframeCore.DropdownMenu.Portal>
                </SubframeCore.DropdownMenu.Root>
              </div>
            )}
          </div>
          <div className="grow shrink-0 basis-0 h-full w-full flex-col gap-8 px-6 py-4 items-start overflow-auto flex">
            <div className="flex w-full flex-col items-start gap-2">
              <span className="text-subheader font-subheader text-default-font">
                Retention
              </span>
              {isSentimentDownAlertShown && (
                <Alert
                  variant="neutral"
                  icon="FeatherAlertTriangle"
                  title={`Sentiment down ${sentimentDownPoints} points since last survey`}
                  actions={
                    <IconButton
                      size="medium"
                      icon="FeatherX"
                      onClick={() => setIsSentimentDownAlertShown(false)}
                    />
                  }
                />
              )}
              {isFetchingEmployee ? (
                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  <Skeleton variant="rounded" width="100%" height="150px" />
                </Box>
              ) : (
                <>
                  <div className="w-full gap-4 pt-3 pr-3 pb-3 pl-3 items-center flex">
                    <SentimentSummary
                      score={
                        isNumber(employee?.survey?.["sentiment_score"])
                          ? employee?.survey?.["sentiment_score"]
                          : undefined
                      }
                      variant={
                        SENTIMENT[employee?.survey?.["overall_sentiment"]]
                          ?.value ?? SENTIMENT["No Data"]?.value
                      }
                    />
                  </div>
                  <div className="w-full gap-4 pt-3 pr-3 pb-3 pl-3 items-start flex">
                    <SubframeCore.Icon
                      className="text-neutral-400 text-section-header font-section-header"
                      name="FeatherMessageSquare"
                    />
                    <div className="grow shrink-0 basis-0 w-full flex-col gap-2 items-start flex">
                      <span className="w-full text-default-font text-body-bold font-body-bold">
                        Sentiment by topic
                      </span>
                      <div className="grow shrink-0 basis-0 h-full w-full gap-2 flex-wrap items-start flex">
                        {!isObjectEmpty(employee?.sentiment_categories)
                          ? Object.keys(employee?.sentiment_categories)?.map(
                              (category, index) => (
                                <SentimentBadge
                                  variant={
                                    SENTIMENT[
                                      employee?.sentiment_categories[category]
                                        ?.overall_sentiment
                                    ]?.value ?? SENTIMENT["No Data"]?.value
                                  }
                                  score={category}
                                  key={index}
                                />
                              )
                            )
                          : "-"}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <EmployeeGlanceChart
              data={formatEmployeeGlanceData(employee?.survey_timeline)}
              isLoading={isFetchingEmployee}
            />
            <History
              isLoading={isFetchingEmployeeHistory}
              employeeHistory={employeeHistory}
              employeeId={employeeId}
              handleNoteSummaryToggle={onToggleNoteSummary}
            />
            <EscalationFollowUp
              user={user}
              canMinimize={true}
              employee={employee}
              escalationId={escalationId}
              onSubmit={onEscalationFollowUpSubmit}
              isMinimized={isEscalationFollowupMinimized}
              onMinimize={toggleMinimizeEscalationFollowUp}
              isLogNotes={shouldLogNotes()}
            />
            <RecognitionPost
              user={user}
              canMinimize={true}
              employeeId={employeeId}
              isLoadingUser={isLoadingUser}
              onSubmit={onRecognitionPostSubmit}
              isMinimized={isRecognitionPostMinimized}
              onMinimize={toggleMinimizeRecognitionPost}
            />
            <SendReferralSMS
              canMinimize={true}
              agencyName={user?.name}
              employeeName={employee?.profile?.first_name}
              employeePhoneNumber={employee?.profile?.phone_number}
              onSubmit={onSendReferralSMSSubmit}
              isMinimized={isSendReferralSMSMinimized}
              onMinimize={toggleMinimizeSendReferralSMS}
            />
          </div>
        </div>
      </ProfileSlideout.Content>
    </ProfileSlideout>
  );
}

export default EmployeeProfileSlideout;
