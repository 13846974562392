import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { LoadingButton } from "@mui/lab";
import MuiAlert from "@mui/material/Alert";
import { Snackbar, TextField } from "@mui/material";

import "./RecognitionLogin.css";

import Levo from "../../assets/images/logoV2.svg";
import Login from "../../assets/images/login-image.svg";

import { login } from "../../services/recognition";

import { ERROR_CODE } from "../../../../constants/constants";

import { get } from "../../../../utils/localStorage";
import { isEmpty as isStringEmpty } from "../../../../utils/string";

export default function RecognitionLogin() {
  const navigate = useNavigate();

  useEffect(() => {
    const accessToken = get("token");
    const refreshToken = get("refreshToken");

    if (!isStringEmpty(accessToken) || !isStringEmpty(refreshToken)) {
      navigate("/recognition/me");
    }
  }, []);

  const [searchParams] = useSearchParams();

  const [mobileNumber, setMobileNumber] = useState(
    searchParams.get("mobileNumber") || ""
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [isGeneratingOTP, setIsGeneratingOTP] = useState(false);
  const [isErrorMessageShown, setIsErrorMessageShown] = useState(false);
  const [hasTriedFormSubmission, setHasTriedFormSubmission] = useState(false);

  /**
   * Generate the login OTP via API call.
   *
   * @param {String} mobileNumber
   * @returns {Promise}
   */
  const generateOTPAsync = async (mobileNumber) => {
    try {
      return await login({ phone_number: mobileNumber });
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/recognition");
      }

      return { hasError: true, error };
    }
  };

  /**
   * Handles the generation of the login OTP.
   */
  const handleGenerateOTP = async () => {
    setHasTriedFormSubmission(true);

    if (isStringEmpty(mobileNumber)) {
      return;
    }

    setIsGeneratingOTP(true);
    const response = await generateOTPAsync(mobileNumber);

    setIsGeneratingOTP(false);

    if (response?.hasError) {
      setErrorMessage(
        `Error while generating OTP: ${response?.error?.response?.data?.message}`
      );
      setIsErrorMessageShown(true);

      return;
    }

    const methodId = response?.data?.result?.method_id;

    if (isStringEmpty(methodId)) {
      return;
    }

    navigate(
      `/recognition/otp-verification?mobileNumber=${mobileNumber}&methodId=${methodId}`
    );
  };

  /**
   * Renders the error snackbar.
   *
   * @returns {Component}
   */
  const renderErrorSnackbar = () => (
    <Snackbar
      open={isErrorMessageShown}
      autoHideDuration={6000}
      onClose={() => setIsErrorMessageShown(false)}
    >
      <MuiAlert
        onClose={() => setIsErrorMessageShown(false)}
        severity="error"
        sx={{ width: "100%" }}
      >
        {errorMessage}
      </MuiAlert>
    </Snackbar>
  );

  return (
    <>
      <div className="main-outer-wrapper">
        <div className="logo-wrapper">
          <img src={Levo} alt="Levo" onClick={() => navigate("/recognition")} />
        </div>

        <div className="tag-line-wrapper">
          <h1>Shout Outs</h1>
          <p>Let's build each other up together!</p>
        </div>

        <div className="login-banner-wrapper">
          <img src={Login} alt="login-banner" />
        </div>

        <div className="mobile-number-wrapper">
          <div className="info-wrapper">
            <h2>Enter your Mobile Number</h2>
            <p>We will send you the OTP verification code.</p>
          </div>
          <div className="input-wrapper">
            <TextField
              sx={{
                width: "100%",
              }}
              type="tel"
              id="mobile-number"
              variant="outlined"
              value={mobileNumber}
              label="Mobile Number"
              placeholder="Enter Mobile Number"
              onChange={(event) => setMobileNumber(event?.target?.value)}
              helperText={
                hasTriedFormSubmission && isStringEmpty(mobileNumber)
                  ? "Mobile Number is required."
                  : ""
              }
              error={hasTriedFormSubmission && isStringEmpty(mobileNumber)}
            />
          </div>
        </div>

        <div className="button-wrapper">
          <LoadingButton
            loading={isGeneratingOTP}
            variant="contained"
            onClick={handleGenerateOTP}
            sx={{
              width: "100%",
              height: "51px",
              backgroundColor: "var(--md-ref-palette-primary60)",
            }}
          >
            GENERATE OTP
          </LoadingButton>
        </div>
      </div>
      {renderErrorSnackbar()}
    </>
  );
}
