import config from "../../../config";

import { http, levoHttp } from "../../../utils/http";
import { create as createQueryParam } from "../../../utils/queryParam";

import endpoints from "../constants/endpoints";

/**
 * Method to fetch the inbox summary information.
 *
 * @param {Object} query
 * @returns {Promise}
 */
export const fetchInboxSummary = (query = {}) => {
  const param = createQueryParam(query);
  const url = `${config.authBaseUrl}${endpoints.inboxSummary}${param}`;

  return levoHttp.get(url);
};

/**
 * Method to fetch list of escalations.
 *
 * @param {Object} query
 * @returns {Promise}
 */
export const fetchEscalations = (query = {}) => {
  const param = createQueryParam(query);
  const url = `${config.authBaseUrl}${endpoints.employeeEscalations}${param}`;

  return levoHttp.get(url);
};

/**
 * Method to fetch the employee's escalation history.
 *
 * @param {Object} query
 * @returns {Promise}
 */
export const fetchEmployeeHistory = (query = {}) => {
  const param = createQueryParam(query);
  const url = `${config.authBaseUrl}${endpoints.employeeHistory}${param}`;

  return levoHttp.get(url);
};

/**
 * Method to fetch the employee's details.
 *
 * @param {Object} query
 * @returns {Promise}
 */
export const fetchEmployee = (query = {}) => {
  const param = createQueryParam(query);
  const url = `${config.authBaseUrl}${endpoints.employee}${param}`;

  return levoHttp.get(url);
};

/**
 * Method to add an escalation follow up.
 *
 * @param {Object} payload
 * @returns {Promise}
 */
export const followUpEscalation = (payload) => {
  const url = `${config.authBaseUrl}${endpoints.followUpEscalation}`;

  return levoHttp.post(url, payload);
};

/**
 * Method to fetch stats of roster.
 *
 * @param {Object} query
 * @returns {Promise}
 */
export const fetchRosterStats = (query = {}) => {
  const param = createQueryParam(query);
  const url = `${config.authBaseUrl}${endpoints.rosterStats}${param}`;

  return levoHttp.get(url);
};

/**
 * Method to fetch the report highlights.
 *
 * @param {Object} query
 * @returns {Promise}
 */
export const fetchReportsHighlights = (query = {}) => {
  const param = createQueryParam(query);
  const url = `${config.authBaseUrl}${endpoints.reportsHighlights}${param}`;

  return levoHttp.get(url);
};

/**
 * Method to fetch the employee stats by region.
 *
 * @param {Object} query
 * @returns {Promise}
 */
export const fetchEmployeeStatsByRegion = (query = {}) => {
  const param = createQueryParam(query);
  const url = `${config.authBaseUrl}${endpoints.employeeStatsByRegion}${param}`;

  return levoHttp.get(url);
};

/**
 * Method to update the account/agency name.
 *
 * @param {Object} payload
 * @returns {Promise}
 */
export const updateAccountName = (payload) => {
  const url = `${config.authBaseUrl}${endpoints.updateAgencyName}`;

  return levoHttp.put(url, payload);
};

/**
 * Method to save the Virtual Contact card.
 *
 * @param {Object} payload
 * @returns {Promise}
 */
export const saveVCard = (payload) => {
  const url = `${config.authBaseUrl}${endpoints.saveVCard}`;

  return levoHttp.post(url, payload);
};

/**
 * Method to fetch the agency settings.
 *
 * @returns {Promise}
 */
export const fetchAgencySettings = () => {
  const url = `${config.authBaseUrl}${endpoints.agencySettings}`;

  return levoHttp.get(url);
};

/**
 * Method to update the agency settings.
 *
 * @param {Object} payload
 * @returns {Promise}
 */
export const updateAgencySettings = (payload) => {
  const url = `${config.authBaseUrl}${endpoints.agencySettings}`;

  return levoHttp.put(url, payload);
};

/**
 * Method to fetch the trends over time (Hires vs Termination)
 *
 * @param {Object} query
 * @returns {Promise}
 */
export const fetchTrendsOverTime = (query = {}) => {
  const param = createQueryParam(query);
  const url = `${config.authBaseUrl}${endpoints.trendsOverTime}${param}`;

  return levoHttp.get(url);
};

/**
 * Method to post a recognition.
 *
 * @param {Object} payload
 * @returns {Promise}
 */
export const postRecognition = (payload) => {
  const url = `${config.authBaseUrl}${endpoints.postRecognition}`;

  return levoHttp.post(url, payload);
};

/**
 * Method to post a public recognition.
 *
 * @param {Object} payload
 * @returns {Promise}
 */
export const postPublicRecognition = (payload) => {
  const url = `${config.authBaseUrl}${endpoints.postPublicRecognition}`;

  return http.post(url, payload);
};

/**
 * Method to fetch JWT token for analytics.
 *
 * @param {Object} payload
 * @returns {Promise}
 */
export const fetchJwtToken = () => {
  const url = `${config.authBaseUrl}${endpoints.embedAnalytics}`;

  return levoHttp.post(url);
};

/**
 * Method to fetch the employee's recognition information.
 *
 * @param {Object} query
 * @returns {Promise}
 */
export const fetchEmployeeRecognitions = (query) => {
  const param = createQueryParam(query);
  const url = `${config.authBaseUrl}${endpoints.employeeRecognitions}${param}`;

  return levoHttp.get(url);
};

/**
 * Method to fetch the caregiver role list.
 *
 * @returns {Promise}
 */
export const fetchCaregiverRoles = () => {
  const url = `${config.authBaseUrl}${endpoints.caregiverRoles}`;

  return levoHttp.get(url);
};

/**
 * Method to fetch the embed dashboard links.
 *
 * @param {Object} query
 * @returns {Promise}
 */
export const fetchMetabaseDashboardLink = (query = {}) => {
  const param = createQueryParam(query);
  const url = `${config.authBaseUrl}${endpoints.embedDashboardUrl}${param}`;

  return levoHttp.get(url);
};

/**
 * Method to fetch the branch's metadata.
 *
 * @param {Object} query
 * @returns {Promise}
 */
export const fetchBranchMetadata = (query = {}) => {
  const param = createQueryParam(query);
  const url = `${config.authBaseUrl}${endpoints.branchMetadata}${param}`;

  return levoHttp.get(url);
};

/**
 * Method to fetch the supervisor's metadata.
 *
 * @param {Object} query
 * @returns {Promise}
 */
export const fetchSupervisorMetadata = (query = {}) => {
  const param = createQueryParam(query);
  const url = `${config.authBaseUrl}${endpoints.supervisorMetadata}${param}`;

  return levoHttp.get(url);
};

/**
 * Method to fetch the branch's turnover rate data.
 *
 * @param {Object} query
 * @returns {Promise}
 */
export const fetchTurnoverRateData = (query = {}) => {
  const param = createQueryParam(query);
  const url = `${config.authBaseUrl}${endpoints.turnoverRateData}${param}`;

  return levoHttp.get(url);
};

/**
 * Method to fetch the branch's wins and opportunities.
 *
 * @param {Object} query
 * @returns {Promise}
 */
export const fetchWinsAndOpportunities = (query = {}) => {
  const param = createQueryParam(query);
  const url = `${config.authBaseUrl}${endpoints.winsAndOpportunities}${param}`;

  return levoHttp.get(url);
};
