import { levoHttp } from "../utils/http";

import config from "../config";
import endpoints from "../constants/endpoints";

export const fetch = () => {
  const url = `${config.authBaseUrl}${endpoints.statistics}`;

  return levoHttp.get(url);
};
