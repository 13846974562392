import { useState } from "react";
import MuiAlert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
import { Skeleton, Snackbar } from "@mui/material";

import { Accordion } from "subframe/components/Accordion";
import { HistorySurveyQuestion } from "subframe/components/HistorySurveyQuestion";

import { ERROR_CODE } from "constants/constants";

import { fetchEmployeeSurvey } from "services/retention/employees";

import { isNumber } from "utils/number";
import { isEmpty as isArrayEmpty } from "utils/array";
import { isEmpty as isStringEmpty } from "utils/string";

import { renderSentimentBadge } from "modules/Retention/utils/sentiment";

const HistorySurveyAccordion = ({ employeeId, surveyId, surveyRate = {} }) => {
  const navigate = useNavigate();

  const [surveys, setSurveys] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [isFetchingEmployeeSurvey, setIsFetchingEmployeeSurvey] =
    useState(false);
  const [isErrorMessageShown, setIsErrorMessageShown] = useState(false);

  /**
   * Method to fetch the employee survey via API call.
   *
   * @param {Number} employeeId
   * @param {Number} surveyId
   *
   *  @returns {Promise}
   */
  const fetchEmployeeSurveyAsync = async (employeeId, surveyId) => {
    try {
      return await fetchEmployeeSurvey({
        employee_id: employeeId,
        survey_id: surveyId,
      });
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  /**
   * Handles the fetching of the employee survey.
   *
   * @param {Number} employeeId
   * @param {Number} surveyId
   *
   * @returns {Promise}
   */
  const handleFetchEmployeeSurvey = async (employeeId, surveyId) => {
    setIsFetchingEmployeeSurvey(true);
    const response = await fetchEmployeeSurveyAsync(employeeId, surveyId);

    setIsFetchingEmployeeSurvey(false);

    if (response?.hasError) {
      setSurveys([]);
      setErrorMessage(
        `Error while fetching survey: ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);

      return;
    }

    setSurveys(response?.result?.survey ?? []);
  };

  const handleOpenChange = (openState) => {
    if (openState) {
      handleFetchEmployeeSurvey(employeeId, surveyId);

      return;
    }

    setSurveys([]);
  };

  /**
   * Renders the error snackbar for the Dashboard page.
   *
   * @returns {Component}
   */
  const renderErrorSnackbar = () => (
    <Snackbar
      open={isErrorMessageShown}
      autoHideDuration={6000}
      onClose={() => setIsErrorMessageShown(false)}
    >
      <MuiAlert
        onClose={() => setIsErrorMessageShown(false)}
        severity="error"
        sx={{ width: "100%" }}
      >
        {errorMessage}
      </MuiAlert>
    </Snackbar>
  );

  return (
    <>
      <Accordion
        trigger={
          <div className="w-full gap-1 items-center flex">
            <span className="text-subtext-color text-label font-label">
              {isNumber(surveyRate?.completed) ? surveyRate?.completed : "-"} /{" "}
              {isNumber(surveyRate?.total) ? surveyRate?.total : "-"} questions
              answered
            </span>
            <Accordion.Chevron />
          </div>
        }
        onOpenChange={handleOpenChange}
      >
        <div className="w-full flex-col gap-2 pt-2 pb-2 items-start flex">
          {isFetchingEmployeeSurvey ? (
            <div className="gap-2 w-full flex-col flex">
              <Skeleton variant="rounded" width="100%" height="30px" />
              <Skeleton variant="rounded" width="100%" height="30px" />
              <Skeleton variant="rounded" width="100%" height="30px" />
            </div>
          ) : (
            !isArrayEmpty(surveys) &&
            surveys.map((survey) => (
              <HistorySurveyQuestion>
                <span className="w-full text-default-font text-body font-body">
                  {isStringEmpty(survey?.question) ? "-" : survey?.question}
                </span>
                {renderSentimentBadge(
                  survey?.answer,
                  survey?.["sentiment"],
                  survey?.type
                )}
              </HistorySurveyQuestion>
            ))
          )}
        </div>
      </Accordion>
      {renderErrorSnackbar()}
    </>
  );
};

export default HistorySurveyAccordion;
