import {
  Box,
  Card,
  Chip,
  Divider,
  Skeleton,
  Typography,
  CardContent,
} from "@mui/material";

import "./EmployeeProfileSummary.css";

import { formatPhoneNumber } from "../../utils/phoneNumber";
import { isEmpty as isStringEmpty } from "../../utils/string";

export default function EmployeeProfileSummary({ formData, isFetching }) {
  return (
    <Card
      variant="outlined"
      sx={{
        width: "100%",
        height: "100%",
        padding: "0",
        display: "flex",
      }}
    >
      <CardContent
        sx={{
          "& .super-app-theme--header": {
            backgroundColor: "#EDEFF5",
          },
          padding: "0",
          "&:last-child": {
            paddingBottom: 0,
          },
          display: "flex",
          flexDirection: "column",
          flex: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: "700",
              textAlign: "left",
              padding: "var(--spacing-4x)",
            }}
          >
            Profile Summary
          </Typography>
        </Box>
        <Divider />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            rowGap: "var(--spacing-3x)",
            padding: "var(--spacing-4x)",
            height: "100%",
            overflow: "auto",
            minHeight: "200px",
          }}
        >
          {isFetching ? (
            <Skeleton variant="rectangular" width="100%" height="100%" />
          ) : (
            <>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "var(--md-sys-color-outline-light)",
                    }}
                  >
                    Region
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  <Typography variant="subtitle1" fontWeight={500}>
                    {!isStringEmpty(formData?.["profile"]?.region)
                      ? formData?.["profile"]?.region
                      : "-"}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "var(--md-sys-color-outline-light)",
                    }}
                  >
                    Email
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    fontWeight={500}
                    sx={{
                      wordBreak: "break-word",
                    }}
                  >
                    {!isStringEmpty(formData?.["profile"]?.email)
                      ? formData?.["profile"]?.email
                      : "-"}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "var(--md-sys-color-outline-light)",
                    }}
                  >
                    Phone Number
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  <Typography variant="subtitle1" fontWeight={500}>
                    {!isStringEmpty(formData?.["profile"]?.phone_number)
                      ? formatPhoneNumber(formData?.["profile"]?.phone_number)
                      : "-"}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "var(--md-sys-color-outline-light)",
                    }}
                  >
                    Hired Date
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  <Typography variant="subtitle1" fontWeight={500}>
                    {!isStringEmpty(formData?.["profile"]?.hired_at)
                      ? formData?.["profile"]?.hired_at
                      : "-"}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "var(--md-sys-color-outline-light)",
                    }}
                  >
                    Status
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  <Typography variant="subtitle1">
                    {formData?.["profile"]?.is_active ? (
                      <Chip
                        label="Active"
                        size="small"
                        color="success"
                        sx={{ mt: 0.5 }}
                      />
                    ) : (
                      <Chip
                        label={`Terminated   ${
                          isStringEmpty(formData?.["profile"]?.terminated_at)
                            ? ""
                            : `on ${formData?.["profile"]?.terminated_at}`
                        }`}
                        size="small"
                        color="error"
                        sx={{ mt: 0.5 }}
                      />
                    )}
                  </Typography>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </CardContent>
    </Card>
  );
}
