import { useState } from "react";

import {
  Checkbox,
  Chip,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

import "./DocumentPanel.css";

export default function DocumentPanel({
  selected,
  name = "",
  isApprovable,
  isChangeable,
  isPreviewable,

  iconImageSrc = "",
  approve = () => {},
  preview = () => {},
  download = () => {},
  tags = ["Document"],
  isSelectedForApproval,
  requestChange = () => {},
  selectDocument = () => {},
  deselectDocument = () => {},
  isSelectedForChangeRequest,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuItemOpen = Boolean(anchorEl);

  const handleCloseMenuItem = () => {
    setAnchorEl(null);
  };

  /**
   * Handles clicking on the three dot menu.
   *
   * @param {Object} event
   */
  const handleClickThreeDotMenu = (event) => {
    setAnchorEl(event?.currentTarget);
  };

  return (
    <div className={`document-panel-main-wrapper ${selected && "highlighted"}`}>
      <div className="document-panel-first-row">
        <div className="document-panel-first-column">
          <div className="image-wrapper">
            <img
              className="document-type-image"
              src={iconImageSrc}
              alt="icon"
            />
          </div>
          <div className="content-wrapper">
            <h3>{name}</h3>
          </div>
        </div>
        <div className="document-panel-second-column">
          <IconButton
            aria-label="more"
            id="three-dot-button"
            title="More Actions"
            sx={{
              width: 28,
              height: 28,
            }}
            onClick={handleClickThreeDotMenu}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={isMenuItemOpen}
            onClose={handleCloseMenuItem}
            onClick={handleCloseMenuItem}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem onClick={() => {}} disabled>
              <ListItemIcon>
                <EditIcon />
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => {}} disabled>
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
            <MenuItem onClick={download}>
              <ListItemIcon>
                <CloudDownloadIcon />
              </ListItemIcon>
              <ListItemText>Download</ListItemText>
            </MenuItem>
          </Menu>
        </div>
      </div>
      <Divider />
      <div className="document-panel-second-row">
        <div className="document-panel-first-column">
          {tags &&
            tags.map((tag) => (
              <Chip
                label={tag}
                sx={{
                  backgroundColor: "#39a1ea",
                  color: "#ffffff",
                }}
              />
            ))}
        </div>
        <div className="document-panel-second-column">
          <IconButton
            aria-label="more"
            id="request-change-button"
            title="Request Change"
            color={isSelectedForChangeRequest ? "error" : "default"}
            sx={{
              width: 28,
              height: 28,
            }}
            onClick={requestChange}
            disabled={!isChangeable}
          >
            <ChangeCircleIcon />
          </IconButton>
          <IconButton
            id="approve-button"
            title="Approve"
            color={isSelectedForApproval ? "success" : "default"}
            sx={{
              width: 28,
              height: 28,
            }}
            onClick={approve}
            disabled={!isApprovable}
          >
            <CheckCircleIcon />
          </IconButton>
          <IconButton
            disabled={!isPreviewable}
            id="preview-button"
            title="Preview"
            sx={{
              width: 28,
              height: 28,
            }}
            onClick={preview}
          >
            <RemoveRedEyeIcon />
          </IconButton>
          {/* <Divider orientation="vertical" sx={{ height: "24px" }} />
          <Checkbox
            sx={{
              width: 28,
              height: 28,
            }}
            onChange={(event) =>
              event?.target?.checked ? selectDocument() : deselectDocument()
            }
            checked={selected}
          /> */}
        </div>
      </div>
    </div>
  );
}
