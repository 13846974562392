import React, { useState } from "react";

import { saveAs } from "file-saver";

import CloseIcon from "@mui/icons-material/Close";

import DocumentPanel from "../DocumentPanel/DocumentPanel";

import "./DocumentsReviewForm.css";

import file from "../../assets/documents/file.png";
import picture from "../../assets/documents/picture.png";

import { isEmpty as isArrayEmpty } from "../../utils/array";
import { getFileExtension, isEmpty as isStringEmpty } from "../../utils/string";

import { FILE_EXTENSIONS } from "../../constants/constants";
import { CircularProgress, Dialog, IconButton } from "@mui/material";

export default function DocumentsReviewForm({
  user = {},
  isLoading,
  isApprovable,
  isChangeable,
  documents = [],
  selectedDocuments = [],
  selectDocument = () => {},
  approveDocument = () => {},
  deselectDocument = () => {},
  requestChangeDocument = () => {},
  selectedDocumentsForApproval = [],
  selectedDocumentsForChangeRequest = [],
}) {
  const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false);
  const [selectedDocumentPreviewLink, setSelectedDocumentPreviewLink] =
    useState(false);

  /**
   * Opens document preview.
   */
  const openPreview = () => {
    setIsPreviewDialogOpen(true);
  };

  /**
   * Closes document preview.
   */
  const closePreview = () => {
    setIsPreviewDialogOpen(false);
  };

  /**
   * Gets the Icon Image Source file based on the document extension type.
   *
   * @param {String} extension
   *
   * @returns {File}
   */
  const getIconImageSrc = (extension = "") => {
    // NOTE: Only document type Image is supported.
    // if (extension === FILE_EXTENSIONS.PDF) {
    //   return documents;
    // }
    // if (
    //   extension === FILE_EXTENSIONS.XLSX ||
    //   extension === FILE_EXTENSIONS.XLS
    // ) {
    //   return documents;
    // }
    // if (
    //   extension === FILE_EXTENSIONS.DOC ||
    //   extension === FILE_EXTENSIONS.DOCX
    // ) {
    //   return documents;
    // }
    // if (
    //   extension === FILE_EXTENSIONS.PPTX ||
    //   extension === FILE_EXTENSIONS.PPT
    // ) {
    //   return documents;
    // }

    if (
      extension === FILE_EXTENSIONS.PNG ||
      extension === FILE_EXTENSIONS.JPG ||
      extension === FILE_EXTENSIONS.JPEG ||
      extension === FILE_EXTENSIONS.SVG ||
      extension === FILE_EXTENSIONS.GIF
    ) {
      return picture;
    }

    return file;
  };

  /**
   * Validate if the document type is previewable.
   *
   * @param {String} link
   *
   * @returns {Boolean}
   */
  const getIsPreviewable = (link = "") => {
    return (
      getFileExtension(link) ===
      (FILE_EXTENSIONS.JPG ||
        FILE_EXTENSIONS.JPEG ||
        FILE_EXTENSIONS.PNG ||
        FILE_EXTENSIONS.SVG)
    );
  };

  /**
   * Handles download of the application document.
   *
   * @param {Object} document
   */
  const handleDownloadDocument = (document) => {
    if (isStringEmpty(document?.url)) {
      return;
    }

    const extension = getFileExtension(document?.url);

    if (isStringEmpty(extension)) {
      return;
    }

    const firstName = isStringEmpty(user?.caregiver?.first_name)
      ? ""
      : `${user?.caregiver?.first_name}_`;
    const lastName = isStringEmpty(user?.caregiver?.last_name)
      ? ""
      : `${user?.caregiver?.last_name}_`;
    const documentName = isStringEmpty(document?.document?.name)
      ? ""
      : `${document?.document?.name}.${extension}`;
    const fileName = `${firstName}${lastName}${documentName}`;

    saveAs(document?.url, fileName);
  };

  /**
   * Handles preview of the selected document.
   *
   * @param {String} link
   */
  const handleDocumentPreview = (link) => {
    setSelectedDocumentPreviewLink(link);
    openPreview();
  };

  /**
   * Check if the document is selected.
   *
   * @param {Number} id
   * @returns {Boolean}
   */
  const isDocumentSelected = (id) => {
    return selectedDocuments?.includes(id);
  };

  return (
    <>
      {isLoading ? (
        <div className="document-empty-wrapper">
          <CircularProgress />
        </div>
      ) : isArrayEmpty(documents) ? (
        <div className="document-empty-wrapper">No Documents Yet.</div>
      ) : (
        <>
          {documents.map((document) => (
            <DocumentPanel
              isApprovable={isApprovable}
              isChangeable={isChangeable}
              name={document?.document?.name}
              selected={isDocumentSelected(document?.id)}
              isPreviewable={getIsPreviewable(document?.url)}
              download={() => handleDownloadDocument(document)}
              selectDocument={() => selectDocument(document?.id)}
              preview={() => handleDocumentPreview(document?.url)}
              approve={() => approveDocument(document?.document?.id)}
              deselectDocument={() => deselectDocument(document?.id)}
              requestChange={() =>
                requestChangeDocument(document?.document?.id)
              }
              isSelectedForApproval={selectedDocumentsForApproval?.includes(
                document?.document?.id
              )}
              isSelectedForChangeRequest={selectedDocumentsForChangeRequest.includes(
                document?.document?.id
              )}
              iconImageSrc={getIconImageSrc(getFileExtension(document?.url))}
            />
          ))}

          <Dialog
            open={isPreviewDialogOpen}
            onClose={closePreview}
            fullWidth
            maxWidth="sm"
            PaperProps={{ sx: { height: "100%" } }}
          >
            <div className="preview-header-wrapper">
              <IconButton
                id="close-preview-button"
                title="Close"
                sx={{
                  width: 28,
                  height: 28,
                }}
                onClick={closePreview}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div className="preview-wrapper">
              <img src={selectedDocumentPreviewLink} alt="Document Preview" />
            </div>
          </Dialog>
        </>
      )}
    </>
  );
}
