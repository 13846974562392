import * as SubframeCore from "@subframe/core";
import { CustomBadge } from "subframe/components/CustomBadge";

import { isEmpty as isArrayEmpty } from "utils/array";
import { isEmpty as isStringEmpty } from "utils/string";
import { ESCALATION_FOLLOW_UP_NOTE_TAGS } from "../constants/constants";

import config from "config";

/**
 * Extracts tags from a description string based on a predefined format (for follow up summary).
 *
 * The function looks for a tag section in the description, identifies the number of tags,
 * and then extracts each tag individually using regular expressions.
 *
 * @param {string} description The input string containing the description to parse.
 * @param {boolean} [getOnlyFilteredTags=true] Flag to get only pre defined tags.
 * @param {boolean} [isEnabled=false] Flag to enable or disable tag extraction.
 * @param {string} [tagTemplateString=config.noteSummaryTagSectionTemplate] The template string that marks the tag section in the description.
 * @param {RegExp} [tagSectionRegex=config.noteSummaryTagSectionRegex] The regular expression to extract the number of tags.
 * @returns {string[]} An array of extracted tags.
 *
 * @example
 * const description = `Some text...
 *
 * ## Tags:
 *
 * 1. JavaScript
 * 2. Node.js
 * 3. Web Development
 *
 * More text...`;
 *
 * const config = {
 *   noteSummaryTagSectionTemplate: '## Tags:',
 *   noteSummaryTagSectionRegex: /## Tags:\n\n([\w\s]+)\./,
 * };
 *
 * const tags = parseTagsInDescription(description, true, config.noteSummaryTagSectionTemplate, config.noteSummaryTagSectionRegex);
 * // tags = ["JavaScript", "Node.js", "Web Development"]
 */
export const parseTagsInDescription = (
  description,
  getOnlyFilteredTags = true,
  isEnabled = false,
  tagTemplateString = config.noteSummaryTagSectionTemplate,
  tagSectionRegex = config.noteSummaryTagSectionRegex
) => {
  if (!isEnabled || isStringEmpty(description)) return [];

  const hasTags = description.includes(tagTemplateString);

  if (!hasTags) return [];

  const tagsMatch = description.match(new RegExp(tagSectionRegex));

  const extractedTags =
    !isArrayEmpty(tagsMatch) && tagsMatch.length > 1
      ? tagsMatch[1]
          .split(/\s*\d+\.\s*/)
          .slice(1)
          .map((tag) => tag.trim())
      : [];
  return extractedTags.filter((tag) => validateTag(tag, getOnlyFilteredTags));
};

/**
 * Validates a tag to ensure it meets specific criteria.
 *
 * @param {string} tag - The tag to validate.
 * @param {boolean} [getOnlyFilteredTags=true] Filter out to select only the predefined tags.
 * @param {number} [maxWords=6] - The maximum number of words allowed in the tag. Defaults to 6 or the value set in `config.noteSummaryTagMaxWords`.
 * @returns {boolean} True if the tag is valid, false otherwise.
 */
export const validateTag = (
  tag,
  getOnlyFilteredTags = true,
  maxWords = config.noteSummaryTagMaxWords || 6
) => {
  if (getOnlyFilteredTags === true) {
    return Object.values(ESCALATION_FOLLOW_UP_NOTE_TAGS).some((noteTag) => {
      return noteTag.value.toLowerCase() === tag.toLowerCase();
    });
  }
  const wordCountRegex = /\b\w+\b/g;
  const wordCount = (tag.match(new RegExp(wordCountRegex)) || []).length;

  return wordCount >= 1 && wordCount <= maxWords;
};

/**
 * Extracts a summary section from a description string based on a predefined format (for follow up summary).
 *
 * The function checks for a specific template string in the description that marks the
 * beginning of the summary section. If found, it uses a regular expression to extract
 * the summary content.
 *
 * @param {string} description The input string containing the description to parse.
 * @param {string[]} [noteTags=[]] Tags entered manually to act as toggle for summary title.
 * @param {boolean} [isEnabled=false] Flag to enable or disable summary extraction.
 * @param {string} [summarySectionTemplateString=config.noteSummarySectionTemplate] The template string that marks the summary section in the description.
 * @param {RegExp} [summarySectionRegex=config.noteSummarySectionRegex] The regular expression to extract the summary content.
 * @returns {string} The extracted summary string, or the original description if no summary is found or extraction is disabled.
 *
 * @example
 * const description = `Some text...
 *
 * ## Summary:
 *
 * This is a summary of the note.
 *
 * More text...`;
 *
 * const config = {
 *   noteSummarySectionTemplate: '## Summary:',
 *   noteSummarySectionRegex: /## Summary:\n\n([\s\S]*?)(?=\n\n|$)/, // Example regex
 * };
 *
 * const summary = parseDescriptionSummary(description, true, config.noteSummarySectionTemplate, config.noteSummarySectionRegex);
 * // summary = "This is a summary of the note."
 */
export const parseDescriptionSummary = (
  description,
  noteTags = [],
  isEnabled = false,
  summarySectionTemplateString = config.noteSummarySectionTemplate,
  summarySectionRegex = config.noteSummarySectionRegex
) => {
  if (!isEnabled) return description;

  const hasSummary = description?.includes(summarySectionTemplateString);
  if (!hasSummary) return description;

  const summaryMatch = description.match(new RegExp(summarySectionRegex));

  if (!isArrayEmpty(summaryMatch) && summaryMatch.length > 1) {
    return (
      <div className="flex w-full grow shrink-0 basis-0 flex-col items-start gap-2 pt-1 pb-2">
        {(!isArrayEmpty(noteTags) ||
          isArrayEmpty(parseTagsInDescription(description, true, true))) &&
          renderSummaryTitle()}
        {summaryMatch[1].trim()}
      </div>
    );
  }
};

/**
 * Renders a UI component displaying tags extracted from a description.
 *
 * This React component uses the `parseTagsInDescription` function to extract tags
 * from a given description string. If tags are found, it renders them as a list of
 * badges with a "Summarized by Levo AI" label.
 *
 * @param {string} description The input string containing the description to parse for tags.
 * @param {boolean} isEnabled Flag to enable or disable tag extraction and rendering.
 * @returns {JSX.Element} A React component displaying the extracted tags or an empty fragment if no tags are found or disabled.
 */
export const renderFollowupSummaryTags = (description, isEnabled) => {
  const tags = parseTagsInDescription(description, true, isEnabled);

  if (isArrayEmpty(tags)) return <></>;

  return (
    <div className="flex w-full grow shrink-0 basis-0 flex-col items-start gap-4 pt-1 pb-2">
      {renderSummaryTitle()}
      <div className="flex flex-col items-start gap-2">
        <div className="flex items-start gap-1">
          {tags.map((value) => (
            <CustomBadge variant="neutral">{value}</CustomBadge>
          ))}
        </div>
      </div>
    </div>
  );
};

/**
 * Renders a summary section for a note, including tags and a summarized description.
 *
 * This React component combines the output of `renderFollowupSummaryTags` and
 * `parseDescriptionSummary` to display a concise overview of a note.
 *
 * @param {string} description The note description to extract the summary and tags from.
 * @param {string[]} note_tags Tags for the follow up note.
 * @param {boolean} isEnabled Flag to enable/disable the summary features.
 * @returns {JSX.Element} A React component rendering the note summary.
 */
export const renderFollowupSummary = (description, note_tags, isEnabled) => {
  return (
    <>
      {renderFollowupTags(note_tags, description, true)}
      <div className="grow shrink-0 basis-0 h-full w-full flex-col gap-2 items-start flex">
        <span className="text-default-font text-body font-body">
          {parseDescriptionSummary(description, note_tags, isEnabled)}
        </span>
      </div>
    </>
  );
};

/**
 * Renders a list of tags as custom badges.
 *
 * @param {string[]} [tags=[]] - An array of tag strings to display.
 * @returns {JSX.Element} A div containing the rendered badges or an empty fragment if no tags are provided.
 */
export const renderFollowupNoteTags = (tags = []) => {
  if (isArrayEmpty(tags)) return <></>;

  return (
    <div className="flex w-full grow shrink-0 basis-0 items-start gap-2 pt-1 pb-2">
      {tags.map((value, index) => (
        <CustomBadge variant="neutral" key={index}>
          {value}
        </CustomBadge>
      ))}
    </div>
  );
};

/**
 * Renders the title for the summary content.
 *
 * @returns {JSX.Element} A div containing the rendered title element.
 */
export const renderSummaryTitle = () => {
  return (
    <div className="flex items-center gap-2">
      <SubframeCore.Icon
        className="text-label font-label text-brand-500"
        name="FeatherSparkles"
      />
      <span className="text-label font-label text-neutral-400 bg-gradient-to-r from-blue-700 via-purple-700 to-pink-700 bg-clip-text text-transparent">
        Summarized by Levo AI
      </span>
    </div>
  );
};

/**
 * Renders the follow up tag with either custom tag or generated tags.
 *
 * @param {string[]} [tags=[]] - An array of tag strings to display.
 * @param {string} [summaryWithTags=""] - String with the summary and tags description.
 * @returns {JSX.Element} A div containing the rendered badges or an empty fragment if no tags are provided.
 */
export const renderFollowupTags = (
  tags,
  summaryWithTags = "",
  isSummaryEnabled = false
) => {
  if (!isArrayEmpty(tags)) return renderFollowupNoteTags(tags);

  return renderFollowupSummaryTags(summaryWithTags, isSummaryEnabled);
};
