import axios from "axios";

import config from "../config";

import parseJwt from "../services/jwt";
import { refreshAccessToken } from "../services/auth";

import { CONTENT_TYPE_JSON } from "../constants/misc";

import { get, remove, set } from "../utils/localStorage";

export const basicAuth = (url, body = {}, headers = {}) => {
  return axios.post(url, body, {
    headers,
  });
};

/**
 * Axios instance.
 */
export const http = axios.create({
  headers: {
    "Content-Type": CONTENT_TYPE_JSON,
    Accept: CONTENT_TYPE_JSON,
  },
});

/**
 * Axios instance for Levo.
 */
export const levoHttp = axios.create({
  baseURL: config.authBaseUrl,
  headers: {
    "Content-Type": CONTENT_TYPE_JSON,
    Accept: CONTENT_TYPE_JSON,
  },
});

/**
 * Request interceptor for Levo axios instance.
 *
 * @returns {Object}
 */
levoHttp.interceptors.request.use(
  async (requestConfig) => {
    const accessToken = await getAccessToken();
    const refreshToken = await getRefreshToken();

    if (accessToken) {
      return configWithAccessToken(requestConfig, accessToken);
    }

    if (refreshToken) {
      try {
        const response = await refreshAccessToken(refreshToken);

        set("token", response?.newAccessToken);
        set("refreshToken", response?.newRefreshToken);

        return configWithAccessToken(requestConfig, response?.newAccessToken);
      } catch (error) {
        remove("token");
        remove("userRole");
        remove("refreshToken");
        remove("email");
        remove("agency_name");
        remove("full_name");
      }
    }

    remove("token");
    remove("userRole");
    remove("refreshToken");
    remove("email");
    remove("agency_name");
    remove("full_name");

    throw new axios.Cancel("Missing both accessToken and refreshToken.");
  },
  (requestError) => {
    throw requestError;
  }
);

/**
 * Response interceptor for Levo axios instance.
 *
 * @returns {Object}
 */
levoHttp.interceptors.response.use(
  (response) => response.data,
  async (responseError) => handleResponseError(responseError)
);

/**
 * Add access token in the authorization header of the given config.
 *
 * @param {Object} axiosConfig Axios config object.
 * @param {string} accessToken
 *
 * @returns {Object} Axios config object.
 */
const configWithAccessToken = (axiosConfig, accessToken) => ({
  ...axiosConfig,
  headers: {
    ...axiosConfig.headers,
    "LEVO-TOKEN": accessToken,
  },
});

/**
 * Handles error in case of response error.
 *
 * @param {Object} error Axios error response.
 */
const handleResponseError = (error) => {
  if (error?.response?.data?.message) {
    throw new Error(error.response.data.message);
  }

  throw error;
};

/**
 * Get the access token stored in the local storage.
 *
 * @returns {String} - Stored token.
 */
export async function getAccessToken() {
  let token = await get("token");

  if (!token) {
    return null;
  }

  try {
    const decoded = parseJwt(token);
    if (!decoded || decoded.exp <= Date.now() / 1000) {
      token = null;
    }
    return token;
  } catch (e) {
    return null;
  }
}

const getRefreshToken = async () => {
  return await get("refreshToken");
};
