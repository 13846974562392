import { Navigate, useLocation } from "react-router-dom";
import { get } from "../../utils/localStorage";

import { isEmpty as isStringEmpty } from "../../utils/string";

const ProtectedRoute = ({ token, redirectPath = "/", children }) => {
  const location = useLocation();

  if (isStringEmpty(get("token")) && isStringEmpty(get("refreshToken"))) {
    return (
      <Navigate
        to={redirectPath}
        replace
        state={{ from: location?.pathname }}
      />
    );
  }

  return children;
};

export default ProtectedRoute;
