import React from "react";

import { Box, Divider, Typography } from "@mui/material";

import "./SentimentSummaryShort.css";

import { isEmpty as isObjectEmpty } from "../../utils/object";

import { SENTIMENT } from "../../constants/retention/constants";

export default function SentimentSummaryV2({ score, summary }) {
  return isObjectEmpty(SENTIMENT[summary]) || score == null ? (
    ""
  ) : (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "var(--spacing-1x)",
      }}
    >
      <Box style={{ display: "flex", alignItems: "center", columnGap: 2 }}>
        <Typography variant="subtitle1" style={{ fontSize: "0.8rem" }}>
          Score:
        </Typography>
        <Typography
          variant="subtitle1"
          style={{
            fontSize: "0.8rem",
            fontWeight: "var(--fontWeight-semibold)",
          }}
        >
          {score}
        </Typography>
      </Box>
      <Divider sx={{ opacity: 0.5 }} />
      <Box
        mt={0.25}
        style={{
          display: "flex",
          alignItems: "center",
          gap: "var(--spacing-2x)",
        }}
      >
        {SENTIMENT[summary]?.icon}
        <Typography
          component="span"
          sx={{ color: SENTIMENT[summary]?.color, fontSize: "0.8rem" }}
        >
          {SENTIMENT[summary]?.label}
        </Typography>
      </Box>
    </Box>
  );
}
