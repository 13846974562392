import config from "../../../config";

import { http } from "../../../utils/http";
import { create as createQueryParam } from "../../../utils/queryParam";

import endpoints from "../constants/endpoints";

import { convertToHyphenatedLowerCase } from "utils/string";

/**
 * Method to add a new referred caregiver.
 *
 * @param {Object} payload
 * @returns {Promise}
 */
export const addNewCaregiver = (payload) => {
  const url = `${config.referralBaseUrl}${endpoints.addNewCaregiver}`;

  return http.post(url, payload);
};

/**
 * Method to get agency key for a referral.
 *
 * @param {String} agencyName
 * @returns {String}
 */
export const generateAgencyKey = (agencyName) => {
  return convertToHyphenatedLowerCase(agencyName);
};

/**
 * Method to send a referral sms.
 *
 * @param {Object} payload
 * @returns {Promise}
 */
export const sendReferralSMS = (payload) => {
  const url = `${config.referralBaseUrl}${endpoints.postOneReferralSMS}`;

  return http.post(url, payload);
};

/**
 * Method to fetch the employee's referral bonus information
 *
 * @param {String} caregiverId
 * @returns {Promise}
 */
export const fetchEmployeeReferrals = (query) => {
  const param = createQueryParam(query);
  const url = `${config.referralBaseUrl}${endpoints.getCaregiverReferralBonus}${param}`;

  return http.get(url);
};
