/**
 * Returns the percentage value.
 *
 * @param {Number} partialValue
 * @param {Number} totalValue
 * @returns {Number}
 */
export const percentage = (partialValue, totalValue) => {
  if (totalValue === 0) {
    return 0;
  }

  return (100 * partialValue) / totalValue;
};

/**
 * Checks if the given variable is a number.
 *
 * @param {Number} value
 * @returns {Boolean}
 */
export const isNumber = (value) => {
  return typeof value === "number" && isFinite(value);
};

/**
 * Returns integer number given number in word.
 * Note: Only upto number 5 for now.
 * 
 * @param {String} word
 * @returns {Number|Null}
 */
export const getNumberFromWord = (word) => {
  const wordToIntMap = {
    'one': 1,
    'two': 2,
    'three': 3,
    'four': 4,
    'five': 5
  };
  const lowercaseWord = word.trim().toLowerCase();
  if (wordToIntMap.hasOwnProperty(lowercaseWord)) {
    return wordToIntMap[lowercaseWord];
  } 
  return null;
}
