import React from "react";

import "./RecognitionEmployeeProfile.css";

import Recognitions from "../Recognitions";

export default function RecognitionEmployeeProfile() {
  return (
    <div className="recognition-employee-profile-main-outer-wrapper">
      <Recognitions />
    </div>
  );
}
