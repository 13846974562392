"use client";
/*
 * Documentation:
 * Tabs Pills — https://app.subframe.com/library?component=Tabs+Pills_8f17aa84-fc4b-4423-9276-1040e2f49e0d
 */

import React from "react";
import * as SubframeCore from "@subframe/core";

interface ItemProps extends React.HTMLAttributes<HTMLDivElement> {
  active?: boolean;
  children?: string;
  icon?: SubframeCore.IconName;
  className?: string;
}

const Item = React.forwardRef<HTMLElement, ItemProps>(function Item(
  {
    active = false,
    children,
    icon = null,
    className,
    ...otherProps
  }: ItemProps,
  ref
) {
  return (
    <div
      className={SubframeCore.twClassNames(
        "group/363182f0 flex h-9 cursor-pointer items-center justify-center gap-1.5 rounded pt-2 pr-3 pb-2 pl-3 hover:bg-neutral-50 flex-wrap",
        { "bg-brand-50": active },
        className
      )}
      ref={ref as any}
      {...otherProps}
    >
      <SubframeCore.Icon
        className={SubframeCore.twClassNames(
          "text-body font-body text-neutral-500 group-hover/363182f0:text-neutral-600",
          { "text-brand-600 group-hover/363182f0:text-brand-600": active }
        )}
        name={icon}
      />
      {children ? (
        <span
          className={SubframeCore.twClassNames(
            "text-body-bold font-body-bold text-neutral-500 group-hover/363182f0:text-neutral-600",
            { "text-brand-600 group-hover/363182f0:text-brand-600": active }
          )}
        >
          {children}
        </span>
      ) : null}
    </div>
  );
});

interface TabsPillsRootProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  className?: string;
}

const TabsPillsRoot = React.forwardRef<HTMLElement, TabsPillsRootProps>(
  function TabsPillsRoot(
    { children, className, ...otherProps }: TabsPillsRootProps,
    ref
  ) {
    return (
      <div
        className={SubframeCore.twClassNames(
          "flex w-full items-start gap-1",
          className
        )}
        ref={ref as any}
        {...otherProps}
      >
        {children}
      </div>
    );
  }
);

export const TabsPills = Object.assign(TabsPillsRoot, {
  Item,
});
