import React from "react";

import { Avatar, Box, Chip, Divider, Typography } from "@mui/material";

import {
  getAcronym,
  getFullName,
  isEmpty as isStringEmpty,
} from "../../../utils/string";

import "./EmployeeSearchResultItem.css";

export default function EmployeeSearchResultItem({
  id,
  tenure,
  last_name,
  job_title,
  is_active,
  first_name,
  supervisor,
  office_location,
  onClick = () => {},
  profile_picture_url,
}) {
  return (
    <div className="search-result-item-wrapper" onClick={() => onClick(id)}>
      {/* Note: Removing this avatar component for now. Can add it later once we have employee's profile pictures. */}
      {/* <div className="avatar-wrapper">
        <Avatar
          src={profile_picture_url}
          sx={{
            width: 32,
            height: 32,
            fontSize: "12px",
            bgcolor: "var(--md-sys-color-primary-light)",
          }}
        >
          {getAcronym(getFullName(first_name, last_name))}
        </Avatar>
      </div> */}
      <div className="details-wrapper">
        <Box
          sx={{
            mb: 0.75,
            display: "flex",
            alignContent: "flex-start",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Typography variant="h6">
              {getFullName(first_name, last_name)}
            </Typography>
          </Box>
          {!is_active && <Chip label="Terminated" size="small" color="error" />}
        </Box>
        <div className="info-wrapper">
          <div className="info">
            <Typography variant="caption">Job:</Typography>
            <Typography variant="caption" fontWeight={600}>
              {!isStringEmpty(job_title) ? job_title : "-"}
            </Typography>
          </div>

          <Divider orientation="vertical" sx={{ height: "12px" }} />

          <div className="info">
            <Typography variant="caption">Location:</Typography>
            <Typography variant="caption" fontWeight={600}>
              {!isStringEmpty(office_location) ? office_location : "-"}
            </Typography>
          </div>

          <Divider orientation="vertical" sx={{ height: "12px" }} />

          <div className="info">
            <Typography variant="caption">Tenure:</Typography>
            <Typography variant="caption" fontWeight={600}>
              {!isStringEmpty(tenure) ? tenure : "-"}
            </Typography>
          </div>
          <Divider orientation="vertical" sx={{ height: "12px" }} />

          <div className="info">
            <Typography variant="caption">Supervisor:</Typography>
            <Typography variant="caption" fontWeight={600}>
              {!isStringEmpty(supervisor) ? supervisor : "-"}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}
