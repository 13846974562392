import { useState } from "react";

import {
  Menu,
  Button,
  Divider,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import { ACTIONS, ACTIONS_MAPPER } from "../../constants/constants";

import "./ActionButton.css";

export default function ActionButton({
  action,
  openChatBox = () => {},
  performAction = () => {},
  openDocumentsReviewForm = () => {},
  openInterviewDecisionForm = () => {},
  navigateToCaregiverProfile = () => {},
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAction = (action) => {
    switch (action) {
      case ACTIONS_MAPPER["calls"]?.value:
        performAction(action);

        break;
      case ACTIONS_MAPPER["close-application"]?.value:
        performAction(action);

        break;
      case ACTIONS_MAPPER["interview-decision"]?.value:
        openInterviewDecisionForm();

        break;
      case ACTIONS_MAPPER["documents-review"]?.value:
        openDocumentsReviewForm();

        break;
      case ACTIONS_MAPPER["view-edit-profile"]?.value:
        navigateToCaregiverProfile();

        break;
      case ACTIONS_MAPPER["send-message"]?.value:
        openChatBox();

        break;
      default:
        break;
    }
  };

  const getAdditionalActions = (defaultAction = "") => {
    let additionalActions = [];

    ACTIONS.forEach((ACTION) => {
      if (ACTION !== defaultAction) {
        additionalActions = [
          ...additionalActions,
          <div>
            {ACTION === "calls" && <Divider />}
            <MenuItem
              disabled={ACTION === "calls"}
              sx={{
                color: "#2075d9",
              }}
              onClick={() => handleAction(ACTION)}
            >
              <ListItemIcon
                sx={{
                  color: "#2075d9",
                }}
              >
                {ACTIONS_MAPPER[ACTION]?.["icon"]}
              </ListItemIcon>
              <ListItemText
                sx={{
                  color: "#2075d9",
                }}
              >
                {ACTIONS_MAPPER[ACTION]?.["label"]}
              </ListItemText>
            </MenuItem>
            {ACTION === "view-edit-profile" && <Divider />}
          </div>,
        ];
      }
    });

    return additionalActions;
  };

  return (
    <div className="action-button-wrapper">
      <Button
        variant="outlined"
        startIcon={ACTIONS_MAPPER[action]?.["icon"]}
        onClick={() => handleAction(action)}
        sx={{ textTransform: "none", width: 200 }}
      >
        {ACTIONS_MAPPER[action]?.["label"]}
      </Button>
      <IconButton
        aria-label="more"
        id="long-button"
        title="More Actions"
        sx={{
          width: 28,
          height: 28,
          color: "#2075d9",
        }}
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {getAdditionalActions(action)}
      </Menu>
    </div>
  );
}
