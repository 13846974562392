import { Divider } from "@mui/material";

import MultiProgress from "react-multi-progress";

import "./TaskProgressBar.css";

export default function TaskProgressBar({ data = {} }) {
  return (
    <div className="task-progress-bar-wrapper">
      <div className="task-progress-bar-first-row">
        <div className="multi-progress-wrapper">
          <MultiProgress backgroundColor="#f6f8fd" elements={data.tasks} />
        </div>
        <Divider orientation="vertical" sx={{ height: "24px" }} />

        <div className="total-task">
          <strong>{data.total} </strong> Tasks
        </div>
      </div>

      <div className="task-progress-bar-second-row">
        {data.tasks &&
          data.tasks.map((task) => (
            <div className="task-progress-indicator">
              <div
                className="task-counter"
                style={{
                  color: task.color,
                  borderColor: task.color,
                }}
              >
                {task.count}
              </div>
              <div className="task-label">{task.label}</div>
            </div>
          ))}
      </div>
    </div>
  );
}
