"use client";
/*
 * Documentation:
 * Sentiment Summary — https://app.subframe.com/library?component=Sentiment+Summary_11801da8-9aa1-4b91-86ec-0e5480a63481
 * Progress — https://app.subframe.com/library?component=Progress_60964db0-a1bf-428b-b9d5-f34cdf58ea77
 */

import React from "react";
import * as SubframeCore from "@subframe/core";
import { LinearProgress } from "@mui/material";

interface SentimentSummaryRootProps
  extends React.HTMLAttributes<HTMLDivElement> {
  score?: number;
  variant?: "neutral" | "low" | "high";
  className?: string;
}

const getProgressBarColor = (variant?: string) => {
  if (variant === "neutral") {
    return "#facc15";
  }

  if (variant === "low") {
    return "#dc2626";
  }

  if (variant === "high") {
    return "#16a34a";
  }

  return "#525252";
};

const SentimentSummaryRoot = React.forwardRef<
  HTMLElement,
  SentimentSummaryRootProps
>(function SentimentSummaryRoot(
  { score, variant, className, ...otherProps }: SentimentSummaryRootProps,
  ref
) {
  return (
    <div
      className={SubframeCore.twClassNames(
        "group/11801da8 flex w-full flex-col items-start gap-2",
        className
      )}
      ref={ref as any}
      {...otherProps}
    >
      <div className="flex w-full items-center justify-between">
        <div
          className={SubframeCore.twClassNames("hidden items-center gap-2", {
            flex: variant === "low",
          })}
        >
          <SubframeCore.Icon
            className="text-body font-body text-error-700"
            name="FeatherFrown"
          />
          <span className="text-body font-body text-error-700">
            Low Sentiment
          </span>
        </div>
        <div
          className={SubframeCore.twClassNames("flex items-center gap-2", {
            hidden:
              variant === "high" || variant === "low" || variant === "neutral",
          })}
        >
          <SubframeCore.Icon
            className="text-default-font text-body font-body text-default-font"
            name="FeatherAnnoyed"
          />
          <span className="text-default-font text-body font-body text-default-font">
            No Data
          </span>
        </div>
        <div
          className={SubframeCore.twClassNames("hidden items-center gap-2", {
            flex: variant === "neutral",
          })}
        >
          <SubframeCore.Icon
            className="text-body font-body text-warning-700"
            name="FeatherMeh"
          />
          <span className="text-body font-body text-warning-700">
            Neutral Sentiment
          </span>
        </div>
        <div
          className={SubframeCore.twClassNames("hidden items-center gap-2", {
            flex: variant === "high",
          })}
        >
          <SubframeCore.Icon
            className="text-body font-body text-success-700"
            name="FeatherSmile"
          />
          <span className="text-body font-body text-success-700">
            High Sentiment
          </span>
        </div>
        {score !== undefined &&
        (variant === "high" || variant === "low" || variant === "neutral") ? (
          <div className="flex items-center justify-between">
            <span className="text-body-bold font-body-bold text-default-font">
              {score}
            </span>
          </div>
        ) : null}
      </div>
      <div className="w-full items-center gap-2">
        <LinearProgress
          variant="determinate"
          value={
            score !== undefined &&
            (variant === "high" || variant === "low" || variant === "neutral")
              ? score
              : 0
          }
          sx={{
            height: 8,
            borderRadius: 4,
            backgroundColor: "#e5e5e5",
            "& .MuiLinearProgress-bar": {
              backgroundColor: getProgressBarColor(variant),
            },
          }}
        />{" "}
      </div>
      <div className="flex w-full items-center justify-between">
        <span className="text-label-bold font-label-bold text-subtext-color">
          0
        </span>
        <span className="text-label-bold font-label-bold text-success-700">
          100
        </span>
      </div>
    </div>
  );
});

export const SentimentSummary = SentimentSummaryRoot;
