import { Tooltip } from "@mui/material";
import { IconWithBackground } from "subframe";
import { ESCALATION_FOLLOW_UP_TAGS } from "../constants/constants";
import { isEmpty as isObjectEmpty } from "utils/object";

export const renderEmploymentStatus = (status) => {
  if (status === "Active") {
    return (
      <Tooltip title="Active Employee">
        <IconWithBackground
          variant="success"
          size="medium"
          icon="FeatherUserCheck"
        />
      </Tooltip>
    );
  }

  return (
    <Tooltip title="Terminated Employee">
      <IconWithBackground variant="error" size="medium" icon="FeatherUserX" />
    </Tooltip>
  );
};
