import { useState } from "react";

import { Button, Menu, MenuItem, Skeleton } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import "./StatusDropdown.css";

import { STATUS_MAPPER } from "../../constants/constants";

import { isEmpty as isArrayEmpty } from "../../utils/array";

export default function StatusDropdown({
  status,
  isLoading,
  onStatusChange = () => {},
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  /**
   * Gets the status dropdown menu options.
   *
   * @param {String} currentStatus
   * @returns {Array}
   */
  const getDropdownStatuses = (currentStatus) => {
    return Object.keys(STATUS_MAPPER)?.filter(
      (status) =>
        status !== currentStatus &&
        STATUS_MAPPER?.[status]?.isUsedForChangeStatus
    );
  };

  /**
   * Handles click on the status dropdown button.
   *
   * @param {Object} event
   */
  const handleDropdownButtonClick = (event) => {
    setAnchorEl(event?.currentTarget);
  };

  /**
   * Handles closing of the dropdown menu.
   */
  const handleDropdownMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {isLoading ? (
        <Skeleton variant="rectangular" width={275} height={37} />
      ) : (
        <div>
          <Button
            id="demo-customized-button"
            variant="outlined"
            disableElevation
            onClick={handleDropdownButtonClick}
            endIcon={<KeyboardArrowDownIcon />}
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "235px",
              textTransform: "capitalize",
              color: `${STATUS_MAPPER[status]?.color}`,
              borderColor: `${STATUS_MAPPER[status]?.color}`,
            }}
          >
            {STATUS_MAPPER[status]?.label}
          </Button>
          <Menu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleDropdownMenuClose}
          >
            {!isArrayEmpty(getDropdownStatuses(status)) &&
              getDropdownStatuses(status).map((statusOption) => (
                <MenuItem
                  onClick={() => {
                    handleDropdownMenuClose();
                    onStatusChange(status, statusOption);
                  }}
                  disableRipple
                  sx={{
                    color: `${STATUS_MAPPER[statusOption]?.color}`,
                    width: "235px",
                  }}
                >
                  {STATUS_MAPPER[statusOption]?.label}
                </MenuItem>
              ))}
          </Menu>
        </div>
      )}
    </div>
  );
}
