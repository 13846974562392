const themeConfig = {
  default: {
    progressBarContainer: "#cee5ff",
    progressBarOnContainer: "#007dc1",
  },
  "Agape Care Group": {
    progressBarContainer: "#fed6ff",
    progressBarOnContainer: "#a658b4",
  },
  "St Croix Hospice": {
    progressBarContainer: "#E7E7E0",
    progressBarOnContainer: "#476E36",
  },
  "Longevity Care": {
    progressBarContainer: "#EAE4D8",
    progressBarOnContainer: "#554846",
  },
  "Royal Home Care": {
    progressBarContainer: "#E0EFF7",
    progressBarOnContainer: "#0098A6",
  },
  "Helping Hands Family": {
    progressBarContainer: "#cee5ff",
    progressBarOnContainer: "#006688",
  },
  "Comfort Keepers": {
    progressBarContainer: "#c3e8ff",
    progressBarOnContainer: "#137096",
  },
  "HealthPRO Heritage": {
    progressBarContainer: "#cee5ff",
    progressBarOnContainer: "#009ea0",
  },
  "Amazing Care": {
    progressBarContainer: "#bde9ff",
    progressBarOnContainer: "#006683",
  },
};

export const getEnvironmentTheme = (themeKey) => {
  return themeConfig[themeKey] ?? themeConfig["default"];
};
