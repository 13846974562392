import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Box,
  List,
  Drawer,
  Toolbar,
  Divider,
  ListItem,
  Skeleton,
  ListItemIcon,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import { Logout } from "@mui/icons-material";
import GroupIcon from "@mui/icons-material/Group";
import AvatarViewer from "../AvatarViewer/AvatarViewer";
import DashboardIcon from "@mui/icons-material/Dashboard";

import { isEmpty as isStringEmpty } from "../../utils/string";

export default function NavigationDrawer({
  user,
  isOpen,
  toggle,
  logout,
  isFetchingUser,
  isPersonnelNavigationShown,
}) {
  const navigationDrawerWidth = 250;

  const navigate = useNavigate();

  const location = useLocation();

  return (
    <Drawer open={isOpen} anchor="left" onClose={toggle} role="presentation">
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          width: navigationDrawerWidth,
        }}
      >
        <Toolbar
          sx={{
            height: "80px",
          }}
        ></Toolbar>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <List>
            <ListItem
              disablePadding
              selected={location.pathname === "/retention/dashboard"}
              sx={{
                color: "inherit",
              }}
              onClick={() => {
                navigate("/retention/dashboard");
                toggle();
              }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <DashboardIcon
                    sx={{
                      color:
                        location.pathname === "/retention/dashboard"
                          ? "var(--md-sys-color-primary-light)"
                          : "inherit",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={"Dashboard"}
                  sx={{
                    color:
                      location.pathname === "/retention/dashboard"
                        ? "var(--md-sys-color-primary-light)"
                        : "inherit",
                  }}
                />
              </ListItemButton>
            </ListItem>
            {isPersonnelNavigationShown && (
              <ListItem
                disablePadding
                selected={location.pathname === "/retention/personnel"}
                onClick={() => {
                  navigate("/retention/personnel");
                  toggle();
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <GroupIcon
                      sx={{
                        color:
                          location.pathname === "/retention/personnel"
                            ? "var(--md-sys-color-primary-light)"
                            : "inherit",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Personnel"}
                    sx={{
                      color:
                        location.pathname === "/retention/personnel"
                          ? "var(--md-sys-color-primary-light)"
                          : "inherit",
                    }}
                  />
                </ListItemButton>
              </ListItem>
            )}
          </List>
        </Box>
        <Divider />
        <Box
          sx={{
            p: 2,
          }}
        >
          <Box>
            {isFetchingUser ? (
              <Skeleton variant="rectangular" width={"100%"} height={40} />
            ) : (
              <AvatarViewer name={isStringEmpty(user) ? "Levo User" : user}>
                <div className="menu-item" onClick={logout}>
                  <Logout fontSize="small" />
                  <span>Log Out</span>
                </div>
              </AvatarViewer>
            )}
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
}
