import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { Logout } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { AppBar, Box, IconButton, Skeleton, Toolbar } from "@mui/material";

import "./Navbar.css";

import Logo from "../../assets/images/logoV2.svg";

import { get, remove } from "../../utils/localStorage";
import { getFullName, isEmpty as isStringEmpty } from "../../utils/string";

import EmployeesSearchBar from "../EmployeesSearchBar";
import AvatarViewer from "../AvatarViewer/AvatarViewer";

import { fetchUser } from "../../services/applications";

import { ERROR_CODE } from "../../constants/constants";

import { mixpanelLogoutEvent } from "../../services/mixpanel";

import { fetchCaregiver } from "../../modules/Recognition/services/recognition";

import { useTabletLayout, useLaptopLayout } from "../../hooks/mediaQueryHooks";

import NavigationDrawer from "../NavigationDrawer";

export default function Navbar({
  module,
  userLogo,
  isLoadingUser,
  mainWrapperStyle,
  isPersonnelNavigationShown,
  isEmployeesSearchBarShown = false,
}) {
  const navigate = useNavigate();
  const [user, setUser] = useState("");
  const [
    isFullWidthMobileScreenSearchBarShown,
    setIsFullWidthMobileScreenSearchBarShown,
  ] = useState(false);
  const [isFetchingUser, setIsFetchingUser] = useState(false);
  const [isAuthenticated, setIsAuthentication] = useState(false);
  const [isNavigationDrawerOpen, setIsNavigationDrawerOpen] = useState(false);

  const location = useLocation();

  const laptopLayout = useLaptopLayout();
  const tabletLayout = useTabletLayout();

  useEffect(() => {
    setIsAuthentication(
      !isStringEmpty(get("token")) || !isStringEmpty(get("refreshToken"))
    );
    handleFetchUser();
  }, []);

  /**
   * Method to fetch the user via API call.
   */
  const fetchUserAsync = async () => {
    let response;

    setIsFetchingUser(true);

    try {
      if (module === "Recognition") {
        response = await fetchCaregiver();
      } else {
        response = await fetchUser(get("userRole"));
      }
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate(module === "Recognition" ? "/recognition" : "/");
      }

      return;
    } finally {
      setIsFetchingUser(false);
    }

    if (module === "Recognition") {
      setUser(
        getFullName(
          response?.result?.data?.first_name,
          response?.result?.data?.last_name
        )
      );
    } else {
      if (!isStringEmpty(response?.result?.name)) {
        setUser(response?.result?.name);
      } else if (
        !isStringEmpty(response?.result?.first_name) &
        !isStringEmpty(response?.result?.last_name)
      ) {
        setUser(
          getFullName(response?.result?.first_name, response?.result?.last_name)
        );
      }
    }
  };

  /**
   * Handles the fetching of the user.
   */
  const handleFetchUser = () => {
    if (isStringEmpty(get("token")) && isStringEmpty(get("refreshToken"))) {
      return;
    }

    fetchUserAsync();
  };

  const handleLogout = () => {
    remove("token");
    remove("refreshToken");
    remove("userRole");
    remove("email");
    remove("agency_name");
    remove("full_name");
    mixpanelLogoutEvent(); // Emit logout analytics event

    navigate(module === "Recognition" ? "/recognition/login" : "/");
  };

  /**
   * Toggles the tablet/mobile navigation drawer to open and close.
   */
  const toggleNavigationDrawer = () => {
    setIsNavigationDrawerOpen(
      (isNavigationDrawerOpen) => !isNavigationDrawerOpen
    );
  };

  /**
   * Toggles the full width search bar for the mobile screen.
   */
  const toggleFullWidthMobileScreenSearchBarShown = () => {
    setIsFullWidthMobileScreenSearchBarShown(
      (isFullWidthMobileScreenSearchBarShown) =>
        !isFullWidthMobileScreenSearchBarShown
    );
  };

  const isAvatarWrapperShown =
    isAuthenticated && (module !== "Retention" || !laptopLayout);

  const isHamburgerMenuShown =
    isAuthenticated && laptopLayout && module === "Retention";

  const isNavigationMenuShown =
    !laptopLayout && isAuthenticated && module === "Retention";

  const isNonSmallScreenSearchBarShown =
    isAuthenticated && isEmployeesSearchBarShown && !tabletLayout;

  const isSmallScreenSearchBarActionButtonShown =
    isAuthenticated && isEmployeesSearchBarShown && tabletLayout;

  const isSmallScreenSearchBarShown =
    isAuthenticated &&
    isEmployeesSearchBarShown &&
    tabletLayout &&
    isFullWidthMobileScreenSearchBarShown;

  return (
    <>
      <AppBar
        position="relative"
        elevation={0}
        sx={{
          color: "inherit",
          backgroundColor: "white",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          borderBottom: "1px solid var(--md-ref-palette-neutral90)",
        }}
      >
        <Toolbar>
          <div className="navbar-main-wrapper" style={mainWrapperStyle}>
            <div className="navbar-container-wrapper">
              {isLoadingUser ? (
                <Skeleton width={120} height={56} />
              ) : (
                !isSmallScreenSearchBarShown && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {isHamburgerMenuShown && (
                      <IconButton
                        aria-label="menu"
                        sx={{
                          color: "var(--md-sys-color-primary-light)",
                        }}
                        onClick={toggleNavigationDrawer}
                      >
                        <MenuIcon />
                      </IconButton>
                    )}
                    <Link to={module === "Recognition" ? "/recognition" : "/"}>
                      <div className="navbar-logo-wrapper">
                        <img
                          src={isStringEmpty(userLogo) ? Logo : userLogo}
                          alt="levo-logo"
                        />
                      </div>
                    </Link>
                  </Box>
                )
              )}
              {isNonSmallScreenSearchBarShown && (
                <div className="employees-search-bar-wrapper">
                  <EmployeesSearchBar />
                </div>
              )}

              {isSmallScreenSearchBarShown && (
                <div className="employees-search-bar-wrapper">
                  <EmployeesSearchBar />
                </div>
              )}

              <div className="right-side-wrapper">
                {isNavigationMenuShown && (
                  <div className="navigation-menu-wrapper">
                    <Link to="/retention/dashboard">
                      <div
                        className={`menu ${
                          location.pathname === "/retention/dashboard"
                            ? "active"
                            : "inactive"
                        }`}
                      >
                        Dashboard
                      </div>
                    </Link>

                    {isPersonnelNavigationShown && (
                      <Link to="/retention/personnel">
                        <div
                          className={`menu ${
                            location.pathname === "/retention/personnel"
                              ? "active"
                              : "inactive"
                          }`}
                        >
                          Personnel
                        </div>
                      </Link>
                    )}
                  </div>
                )}

                {isAvatarWrapperShown && (
                  <div className="navbar-avatar-wrapper">
                    {isFetchingUser ? (
                      <Skeleton variant="rectangular" width={190} height={40} />
                    ) : (
                      <AvatarViewer
                        name={isStringEmpty(user) ? "Levo User" : user}
                      >
                        {module === "Recognition" && (
                          <div
                            className="menu-item"
                            onClick={() => navigate("/recognition/me")}
                          >
                            <AccountCircleIcon fontSize="small" />
                            <span>View Profile</span>
                          </div>
                        )}

                        <div className="menu-item" onClick={handleLogout}>
                          <Logout fontSize="small" />
                          <span>Log Out</span>
                        </div>
                      </AvatarViewer>
                    )}
                  </div>
                )}

                {isSmallScreenSearchBarActionButtonShown && (
                  <IconButton
                    aria-label="menu"
                    sx={{
                      padding: 0,
                      color: "var(--md-sys-color-primary-light)",
                    }}
                    onClick={toggleFullWidthMobileScreenSearchBarShown}
                  >
                    {isSmallScreenSearchBarShown ? (
                      <CloseIcon />
                    ) : (
                      <SearchIcon />
                    )}
                  </IconButton>
                )}
              </div>

              {/* <div className="navbar-button-wrapper">
          <Link to={`/login`}>
            <Button variant="text" className="Button">
              Log In
            </Button>
          </Link>
          <Link to={`/signup`}>
            <Button variant="contained">Sign Up</Button>
          </Link>
        </div> */}
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <NavigationDrawer
        user={user}
        logout={handleLogout}
        isOpen={isNavigationDrawerOpen}
        toggle={toggleNavigationDrawer}
        isFetchingUser={isFetchingUser}
        isPersonnelNavigationShown={isPersonnelNavigationShown}
      />
    </>
  );
}
