import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import * as SubframeCore from "@subframe/core";
import useUser from "hooks/userHooks";
import EmployeesSearchBarV2 from "modules/Retention/components/EmployeesSearchBarV2";
import { postRecognition } from "modules/Retention/services/retention";
import { getRecognitionCategoriesForAgency } from "modules/Retention/utils/recognition";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { logoutEmployee } from "services/auth";
import { mixpanelLogoutEvent } from "services/mixpanel";
import { Avatar, DropdownMenu, Loader } from "subframe";
import { get, remove } from "utils/localStorage";
import { EMPLOYEE_ROLES } from "../../../../constants/constants";
import { isEmpty as isObjectEmpty } from "../../../../utils/object";
import {
  getAcronym,
  getFullName,
  isEmpty as isStringEmpty,
} from "../../../../utils/string";
import Logo from "../../assets/images/logoV2.svg";
import RecognitionForm from "../RecognitionForm";

export default function RecognitionSearchEmployeeV2() {
  const navigate = useNavigate();

  const { user, isLoading: isLoadingUser } = useUser();

  const [errorMessage, setErrorMessage] = useState("");
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState({});
  const [isErrorMessageShown, setIsErrorMessageShown] = useState(false);
  const [isRecognitionDialogOpen, setIsRecognitionDialogOpen] = useState(false);

  /**
   * Closes the dialog to provide Recognition.
   */
  const closeRecognitionDialog = () => {
    setIsRecognitionDialogOpen(false);
  };

  /**
   * Opens the dialog to provide Recognition.
   */
  const openRecognitionDialog = () => {
    setIsRecognitionDialogOpen(true);
  };

  /**
   * Method to handle the closing of the Recognition dialog.
   */
  const handleRecognitionDialogClose = () => {
    setSelectedEmployee({});
    closeRecognitionDialog();
  };

  /**
   * Handles when clicked on the employee from search result.
   *
   * @param {Object} employee
   */
  const handleResultClick = (selectedEmployee) => {
    if (isObjectEmpty(selectedEmployee)) {
      return;
    }

    setSelectedEmployee(selectedEmployee);
    openRecognitionDialog();
  };

  const getLoggedInUserAvatar = () => {
    const userRole = get("userRole");

    if (Object.keys(EMPLOYEE_ROLES).includes(userRole)) {
      return (
        <Avatar className="cursor-pointer">
          {getAcronym(getFullName(user?.first_name, user?.last_name))}
        </Avatar>
      );
    }

    return <Avatar className="cursor-pointer">{getAcronym(user?.name)}</Avatar>;
  };

  const getLoggedInUserInfo = () => {
    const userRole = get("userRole");

    if (Object.keys(EMPLOYEE_ROLES).includes(userRole)) {
      return (
        <div className="flex flex-col items-start gap-1">
          <span className="text-body-bold font-body-bold text-default-font">
            {getFullName(user?.first_name, user?.last_name)}
          </span>
          <div className="flex flex-col items-start gap-1">
            <div className="flex items-center gap-2">
              <SubframeCore.Icon
                className="text-label font-label text-subtext-color"
                name="FeatherUserCog"
              />
              <span className="text-label font-label text-subtext-color">
                {isStringEmpty(EMPLOYEE_ROLES[user?.["role-type"]]?.label)
                  ? "-"
                  : EMPLOYEE_ROLES[user?.["role-type"]]?.label}
              </span>
            </div>
            <div className="flex items-center gap-2">
              <SubframeCore.Icon
                className="text-label font-label text-subtext-color"
                name="FeatherMail"
              />
              <span className="text-label font-label text-subtext-color">
                {isStringEmpty(user?.email) ? "-" : user.email}
              </span>
            </div>
            <div className="flex items-center gap-2">
              <SubframeCore.Icon
                className="text-label font-label text-subtext-color"
                name="FeatherMapPin"
              />
              <span className="text-label font-label text-subtext-color">
                {isStringEmpty(user?.region) ? "-" : user.region}
              </span>
            </div>
          </div>
        </div>
      );
    }

    return (
      <span className="text-default-font text-body-bold font-body-bold">
        {isStringEmpty(user?.name) ? "-" : user.name}
      </span>
    );
  };

  const handleLogout = async () => {
    const userRole = get("userRole");
    const email = get("email");

    if (Object.keys(EMPLOYEE_ROLES).includes(userRole)) {
      setIsLoggingOut(true);

      try {
        await logoutEmployee(email);
      } catch (error) {
        setErrorMessage(`Error while logging out: ${error?.message}`);
        setIsErrorMessageShown(true);

        return;
      } finally {
        setIsLoggingOut(false);
      }
    }

    remove("token");
    remove("refreshToken");
    remove("userRole");
    remove("email");
    remove("agency_name");
    remove("full_name");
    navigate("/");
    mixpanelLogoutEvent(); // Emit logout analytics event
  };

  /**
   * Renders the error snackbar.
   *
   * @returns {Component}
   */
  const renderErrorSnackbar = () => (
    <Snackbar
      open={isErrorMessageShown}
      autoHideDuration={6000}
      onClose={() => setIsErrorMessageShown(false)}
    >
      <MuiAlert
        onClose={() => setIsErrorMessageShown(false)}
        severity="error"
        sx={{ width: "100%" }}
      >
        {errorMessage}
      </MuiAlert>
    </Snackbar>
  );

  return (
    <>
      {isRecognitionDialogOpen ? (
        <div class="w-full h-full flex justify-center items-center">
          <RecognitionForm
            employeeName={getFullName(
              selectedEmployee?.first_name,
              selectedEmployee?.last_name
            )}
            jobTitle={selectedEmployee?.job_title}
            employeeId={selectedEmployee?.id}
            officeLocation={selectedEmployee?.office_location}
            supervisor={selectedEmployee?.supervisor}
            onClose={handleRecognitionDialogClose}
            category={
              getRecognitionCategoriesForAgency(user?.agency || user?.name)[0]
                .value
            }
            postRecognition={postRecognition}
            agencyLogo={user?.image_url}
            agencyName={user?.agency || user?.name}
            isLoadingUser={isLoadingUser}
          />
        </div>
      ) : (
        <div className="flex h-full w-full flex-col items-center bg-default-background">
          <div className="flex h-16 w-full flex-none items-center justify-between md:justify-center bg-brand-50">
            <div className="flex h-16 w-full md:max-w-[768px] flex-none items-center justify-between pr-4 pl-4">
              <img
                className="w-16 flex-none"
                src={isStringEmpty(user?.image_url) ? Logo : user?.image_url}
                alt="levo-logo"
              />
              <div className="flex-col gap-2">
                {!isLoadingUser && (
                  <SubframeCore.DropdownMenu.Root>
                    <SubframeCore.DropdownMenu.Trigger asChild={true}>
                      {getLoggedInUserAvatar()}
                    </SubframeCore.DropdownMenu.Trigger>
                    <SubframeCore.DropdownMenu.Portal>
                      <SubframeCore.DropdownMenu.Content
                        side="bottom"
                        align="end"
                        sideOffset={8}
                        asChild={true}
                        style={{ zIndex: 99 }}
                      >
                        <DropdownMenu>
                          <div className="grow shrink-0 basis-0 h-full w-full flex-col items-start flex">
                            <div className="grow shrink-0 basis-0 h-full w-full flex-col pt-2 pr-3 pb-2 pl-3 items-start flex">
                              {getLoggedInUserInfo()}
                            </div>
                            <div className="w-full flex-col pt-0.5 pb-0.5 items-start flex">
                              <div className="flex-none h-px w-full flex-col gap-2 items-center flex bg-neutral-300" />
                            </div>
                            <div
                              className="w-full h-8 gap-1.5 pr-3 pl-3 justify-between items-center  flex cursor-pointer hover:bg-neutral-100 data-[highlighted]:bg-neutral-100"
                              onClick={handleLogout}
                            >
                              <div className="flex items-center gap-1.5">
                                <SubframeCore.Icon
                                  className="text-neutral-700 text-body font-body"
                                  name="FeatherLogOut"
                                />
                                <span className="text-neutral-700 text-body font-body">
                                  Log Out
                                </span>
                              </div>
                              {isLoggingOut && <Loader />}
                            </div>
                          </div>
                        </DropdownMenu>
                      </SubframeCore.DropdownMenu.Content>
                    </SubframeCore.DropdownMenu.Portal>
                  </SubframeCore.DropdownMenu.Root>
                )}
              </div>
            </div>
          </div>
          <div className="container max-w-none md:max-w-[768px] flex h-full w-full grow shrink-0 basis-0 flex-col items-center gap-10 pt-6 pr-6 pb-6 pl-6">
            <div className="flex flex-col items-center gap-1">
              <span className="text-header font-header text-default-font text-center font-bold">
                Shout Outs
              </span>
              <span className="text-subheader font-subheader text-subtext-color text-center">
                Let's build each other up together!
              </span>
            </div>
            <div className="flex h-full shrink-0 basis-0 flex-col items-center justify-center gap-8">
              <img
                className="w-40 flex-none relative bottom-[-8px]"
                src="https://s3.us-west-2.amazonaws.com/cdn.hellolevo.com/assets/recognition.png"
                alt="Recognition"
              />
            </div>
            <div className="flex w-full grow flex-col items-center gap-8">
              <div className="flex w-full flex-col items-center gap-1">
                <span className="text-subheader font-subheader text-default-font text-center font-bold ">
                  Provide your recognition
                </span>
                <span className="text-body font-body text-subtext-color text-center">
                  Who do you want to recognize?
                </span>
              </div>
              <div className="w-full h-full">
                <EmployeesSearchBarV2
                  autoFocus={false}
                  isNoSearchResultsYetShown={false}
                  listBoxProps={{
                    style: {
                      maxHeight: "200px",
                    },
                  }}
                  onEmployeeClick={handleResultClick}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {renderErrorSnackbar()}
    </>
  );
}
