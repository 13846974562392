import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

export default function ConfirmationDialog({
  isOpen,
  isLoading,
  cancel = () => {},
  action = () => {},
  message = {
    title: "",
    body: "",
    acceptButtonText: "Confirm",
  },
}) {
  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
      maxWidth="xs"
      open={isOpen}
      onClose={!isLoading && cancel}
    >
      <DialogTitle>{message?.title}</DialogTitle>
      <DialogContent
        dividers
        sx={{
          lineHeight: "28px",
        }}
      >
        Are you sure you want to <strong> {message?.body}</strong>?
      </DialogContent>
      <DialogActions>
        <LoadingButton onClick={cancel} disabled={isLoading}>
          Cancel
        </LoadingButton>
        <LoadingButton onClick={action} loading={isLoading}>
          {message?.acceptButtonText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
