import { postPublicRecognition } from "modules/Retention/services/retention";
import React from "react";
import { useSearchParams } from "react-router-dom";
import RecognitionForm from "../RecognitionForm";

function PublicRecognitionWrapper() {
  const [searchParams] = useSearchParams();

  return (
    <div class="w-full h-full flex justify-center items-center">
      <RecognitionForm
        {...{
          employeeName: searchParams.get("employee_name") || "",
          jobTitle: searchParams.get("job_title") || "",
          employeeId: +searchParams.get("employee_id"),
          officeLocation: searchParams.get("office_location") || "",
          category: searchParams.get("recognition_category") || "",
          supervisor: searchParams.get("supervisor") || "",
          agencyId: +searchParams.get("agency_id") || "",
          agencyName: searchParams.get("agency_name") || "",
          recognizedById: +searchParams.get("recognized_by_id") || "",
        }}
        postRecognition={postPublicRecognition}
      />
    </div>
  );
}

export default PublicRecognitionWrapper;
