import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  Chip,
  Card,
  Divider,
  Snackbar,
  Skeleton,
  Accordion,
  Typography,
  CardContent,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
} from "@mui/material";
import { Box } from "@mui/system";
import Timeline from "@mui/lab/Timeline";
import MuiAlert from "@mui/material/Alert";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import ErrorIcon from "@mui/icons-material/Error";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";

import "./EmployeeProfile.css";

import { ERROR_CODE } from "../../constants/constants";

import { isNumber } from "../../utils/number";
import { isEmpty as isArrayEmpty } from "../../utils/array";
import { isEmpty as isObjectEmpty } from "../../utils/object";
import { isEmpty as isStringEmpty } from "../../utils/string";
import { initializeSentimentSummary } from "../../utils/sentiment";

import {
  fetchEmployee,
  fetchEmployeeSurvey,
  fetchQuestionCategoryStatsByEmployee,
  fetchQuestionStatsByCategoryAndEmployee,
} from "../../services/retention/employees";

import Navbar from "../Navbar";
import EmployeeDataGrid from "../EmployeeDataGrid";
import SentimentSummary from "../SentimentSummary";
import SentimentBreakdown from "../SentimentBreakdown";
import EmployeeProfileSummary from "../EmployeeProfileSummary";

import Question from "../../assets/images/retention/question.png";

import useUser from "../../hooks/userHooks";
import RatingScaleResponse from "../RatingScaleResponse";

import { useTabletLayout } from "../../hooks/mediaQueryHooks";

export default function EmployeeProfile() {
  let { id } = useParams();
  const navigate = useNavigate();

  const tabletLayout = useTabletLayout();

  const { user, isLoading: isLoadingUser } = useUser();

  const [surveys, setSurveys] = useState([]);
  const [employee, setEmployee] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedSurveyId, setSelectedSurveyId] = useState();
  const [isFetchingEmployeeSurvey, setIsFetchingEmployeeSurvey] =
    useState(false);
  const [isUndeterminedSurveyShown, setIsUndeterminedSurveyShown] =
    useState(false);
  const [isFetchingEmployee, setIsFetchingEmployee] = useState(false);
  const [isErrorMessageShown, setIsErrorMessageShown] = useState(false);
  const [questionCategoryPanel, setQuestionCategoryPanel] = useState({
    data: [],
    icon: Question,
    value: "employeeProfile_questionCategory",
    label: "Question Category",
    isLoadingData: false,
  });
  const [expandedSurveyTimeline, setExpandedSurveyTimeline] = useState();

  useEffect(() => {
    loadInitialData();
  }, []);

  const loadInitialData = async () => {
    handleFetchQuestionCategoriesStatsByEmployee(id);
    const employee = await handleFetchEmployee(id);

    if (isArrayEmpty(employee?.survey_timeline)) {
      return;
    }

    setSelectedSurveyId(employee?.survey_timeline[0]?.survey_id);
    handleFetchEmployeeSurvey(id, employee?.survey_timeline[0]?.survey_id);
  };

  /**
   * Handles the fetching of the employee survey.
   *
   * @param {Number} employeeId
   * @param {Number} surveyId
   *
   * @returns {Promise}
   */
  const handleFetchEmployeeSurvey = async (employeeId, surveyId) => {
    setIsFetchingEmployeeSurvey(true);
    const response = await fetchEmployeeSurveyAsync(employeeId, surveyId);

    setIsFetchingEmployeeSurvey(false);

    if (response?.hasError) {
      setSurveys([]);
      setErrorMessage(
        `Error while fetching employee survey: ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);

      return;
    }

    setSurveys(response?.result?.survey ?? []);
  };

  /**
   * Method to fetch the employee profile.
   *
   * @param {Number} id
   */
  const fetchEmployeeAsync = async (id) => {
    try {
      return await fetchEmployee({ employee_id: id });
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  /**
   * Method to fetch the employee survey via API call.
   *
   * @param {Number} employeeId
   * @param {Number} surveyId
   *
   *  @returns {Promise}
   */
  const fetchEmployeeSurveyAsync = async (employeeId, surveyId) => {
    try {
      return await fetchEmployeeSurvey({
        employee_id: employeeId,
        survey_id: surveyId,
      });
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  /**
   * Gets the selected user's full name.
   *
   * @param {String} firstName
   * @param {String} lastName
   * @returns {String}
   */
  const getFullName = (firstName = "", lastName = "") => {
    return `${isStringEmpty(firstName) ? "" : firstName}${
      !isStringEmpty(firstName) && !isStringEmpty(lastName) ? " " : ""
    }${isStringEmpty(lastName) ? "" : lastName}`;
  };

  /**
   * Handles fetching of the employee profile.
   *
   * @param {Number} id
   * @param {Boolean} isSilent
   *
   * @returns {Promise}
   */
  const handleFetchEmployee = async (id, isSilent = false) => {
    !isSilent && setIsFetchingEmployee(true);
    const response = await fetchEmployeeAsync(id);

    !isSilent && setIsFetchingEmployee(false);

    if (response?.hasError) {
      setErrorMessage(
        `Error while fetching employee details: ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);

      return;
    }

    if (!isObjectEmpty(response?.result?.employee)) {
      setEmployee(response?.result?.employee);

      return response?.result?.employee;
    }
  };

  /**
   * Handles the fetching of question category information for an employee.
   *
   * @param {Number} id
   */
  const handleFetchQuestionCategoriesStatsByEmployee = async (id) => {
    setQuestionCategoryPanel((prevState) => ({
      ...prevState,
      isLoadingData: true,
    }));
    const response = await fetchQuestionCategoriesStatsByEmployeeAsync(id);

    setQuestionCategoryPanel((prevState) => ({
      ...prevState,
      isLoadingData: false,
    }));

    if (response?.hasError) {
      setErrorMessage(
        `Error while fetching Question Category stats: ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);
      setQuestionCategoryPanel((prevState) => ({
        ...prevState,
        data: [],
      }));

      return;
    }

    const questionCategoryStat = response?.result?.data;
    let data = [];

    if (!isObjectEmpty(questionCategoryStat)) {
      data = Object.keys(questionCategoryStat)?.map(
        (questionCategory, index) => ({
          id: index,
          name: questionCategory,
          question: questionCategoryStat[questionCategory]?.["Question"],
          responseRate:
            questionCategoryStat[questionCategory]?.["Response Rate"],
          sentiment: initializeSentimentSummary(
            questionCategoryStat[questionCategory]?.["Response Rate"],
            questionCategoryStat[questionCategory]?.["Overall Sentiment"]
          ),
          breakdown: questionCategoryStat[questionCategory]?.["Sentiment"],
          recordId: questionCategoryStat[questionCategory]?.id,
          sentimentScore: isNumber(
            questionCategoryStat[questionCategory]?.["Sentiment Score"]
          )
            ? questionCategoryStat[questionCategory]?.["Sentiment Score"]
            : "-",
        })
      );
    }

    setQuestionCategoryPanel((prevState) => ({
      ...prevState,
      data,
    }));
  };

  /**
   * Fetches the question category stats by employee with promise call.
   *
   * @param {Number} id
   * @returns {Promise}
   */
  const fetchQuestionCategoriesStatsByEmployeeAsync = async (id) => {
    try {
      return await fetchQuestionCategoryStatsByEmployee({
        employee_id: id,
      });
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  /**
   * Handles the fetching of Question Stats by Employee and Question Category.
   *
   * @param {String} questionCategory
   * @param {Number} employeeId
   */
  const handleFetchQuestionStatsByCategoryAndEmployee = async (
    questionCategory = "",
    employeeId
  ) => {
    setQuestionCategoryPanel((prevState) => ({
      ...prevState,
      isLoadingData: true,
    }));
    const response = await fetchQuestionStatsByCategoryAndEmployeeAsync(
      questionCategory,
      employeeId
    );

    setQuestionCategoryPanel((prevState) => ({
      ...prevState,
      isLoadingData: false,
    }));

    if (response?.hasError) {
      setErrorMessage(
        `Error while fetching Questions: ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);
      setQuestionCategoryPanel((prevState) => ({ ...prevState, data: [] }));

      return;
    }

    const questionStat = response?.result?.data;
    let data = [];

    if (!isObjectEmpty(questionStat)) {
      data = Object.keys(questionStat)?.map((question, index) => ({
        id: index,
        question: questionStat[question]?.["title"],
        totalResponse: questionStat[question]?.["total_response"],
        responseRate: questionStat[question]?.["Response Rate"],
        breakdown: questionStat[question]?.["Sentiment"],
        sentiment: initializeSentimentSummary(
          questionStat[question]?.["Response Rate"],
          questionStat[question]?.["Overall Sentiment"]
        ),
        job: questionStat[question]?.["job"],
        recordId: question,
        sentimentScore: isNumber(questionStat[question]?.["Sentiment Score"])
          ? questionStat[question]?.["Sentiment Score"]
          : "-",
      }));
    }

    setQuestionCategoryPanel((prevState) => ({ ...prevState, data }));
  };

  /**
   * Fetches the question stats by question category and employee.
   *
   * @param {String} questionCategory
   * @param {Number} employeeId
   *
   * @returns {Promise}
   */
  const fetchQuestionStatsByCategoryAndEmployeeAsync = async (
    questionCategory,
    employeeId
  ) => {
    try {
      return await fetchQuestionStatsByCategoryAndEmployee({
        category: questionCategory,
        employee_id: employeeId,
      });
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  /**
   * Renders the error snackbar for the Dashboard page.
   *
   * @returns {Component}
   */
  const renderErrorSnackbar = () => (
    <Snackbar
      open={isErrorMessageShown}
      autoHideDuration={6000}
      onClose={() => setIsErrorMessageShown(false)}
    >
      <MuiAlert
        onClose={() => setIsErrorMessageShown(false)}
        severity="error"
        sx={{ width: "100%" }}
      >
        {errorMessage}
      </MuiAlert>
    </Snackbar>
  );

  /**
   * Handles the clicking on the time frame dot of the employee's survey timeline.
   *
   * @param {Number} employeeId
   * @param {Number} surveyId
   */
  const handleSurveyTimeFrameClick = (employeeId, surveyId) => {
    setSelectedSurveyId(surveyId);
    handleFetchEmployeeSurvey(employeeId, surveyId);
  };

  /**
   * Renders the employee's survey timeline.
   *
   * @param {Array} Holds the list of surveys sent to the employee.
   * @returns {Component}
   */
  const renderSurveyTimeline = (surveys = []) => {
    return isArrayEmpty(surveys) ? (
      renderEmptyState()
    ) : (
      <>
        <Timeline
          sx={{
            margin: 0,
            padding: 0,
          }}
        >
          {surveys?.map((survey, index) => {
            return (
              <>
                <TimelineItem>
                  <TimelineOppositeContent
                    sx={{
                      display: "flex",
                      padding: "14px var(--spacing-2x) 0 0",
                    }}
                    variant="body2"
                    color="text.secondary"
                  >
                    <Box
                      sx={{
                        width: 80,
                        display: "flex",
                        textAlign: "right",
                        alignItems: "flex-start",
                        justifyContent: "flex-end",
                      }}
                    >
                      <SentimentSummary
                        value={initializeSentimentSummary(
                          survey?.["Response Rate"],
                          survey?.["Overall Sentiment"]
                        )}
                        sx={{ display: "inline-flex" }}
                      />
                    </Box>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <div
                      surveyId={survey?.survey_id}
                      onClick={(event) =>
                        handleSurveyTimeFrameClick(
                          id,
                          +event?.currentTarget?.getAttribute("surveyId")
                        )
                      }
                    >
                      <TimelineDot
                        variant="outlined"
                        sx={{
                          width: "24px",
                          height: "24px",
                          display: "flex",
                          cursor: "pointer",
                          alignItems: "center",
                          borderColor:
                            survey?.survey_id === selectedSurveyId
                              ? survey?.completed
                                ? "var(--md-sys-color-primary-light)"
                                : "var(--md-sys-color-error-light)"
                              : "transparent",

                          justifyContent: "center",
                        }}
                      >
                        {survey?.completed ? (
                          <CheckCircleIcon
                            sx={{
                              width: "20px",
                              height: "20px",
                              color: "var(--md-sys-color-primary-light)",
                            }}
                          />
                        ) : (
                          <ErrorIcon
                            sx={{
                              width: "20px",
                              height: "20px",
                              color: "var(--md-sys-color-error-light)",
                            }}
                          />
                        )}
                      </TimelineDot>
                    </div>

                    {surveys?.length - 1 !== index && (
                      <TimelineConnector
                        sx={{
                          height: "40px",
                          backgroundColor: survey?.completed
                            ? "var(--md-sys-color-primary-light)"
                            : "var(--md-sys-color-error-light)",
                        }}
                      />
                    )}
                  </TimelineSeparator>
                  <TimelineContent
                    sx={{
                      flex: 0,
                      display: "inline-block",
                      padding: "15px 0 0 var(--spacing-2x)",
                      opacity: survey?.survey_id === selectedSurveyId ? 1 : 0.6,
                      fontWeight:
                        survey?.survey_id === selectedSurveyId
                          ? "var(--fontWeight-bold)"
                          : "var(--fontWeight-normal)",
                    }}
                    variant="body2"
                    color="text.secondary"
                  >
                    <Box
                      sx={{
                        width: 60,
                      }}
                    >
                      {survey?.post_termination ? (
                        <span
                          style={{
                            color: "var(--md-sys-color-error-light)",
                          }}
                        >
                          Exit
                        </span>
                      ) : (
                        `Day ${survey?.trigger_day}`
                      )}
                    </Box>
                  </TimelineContent>
                </TimelineItem>
              </>
            );
          })}
        </Timeline>
      </>
    );
  };

  /**
   * Renders the employee's survey timeline as accordion list.
   * Note: This is for the Tablet view in the responsive design layout.
   *
   * @param {Array} surveyTimeline the list of surveys sent to the employee.
   * @param {Array} surveys the question and responses from a particular survey.
   * @returns {Component}
   */
  const renderSurveyAccordions = (surveyTimeline = [], surveys = []) => {
    return isArrayEmpty(surveyTimeline)
      ? renderEmptyState()
      : surveyTimeline?.map((survey, index) => {
          return (
            <Accordion
              expanded={expandedSurveyTimeline === `panel${index}`}
              onChange={(event, isExpanded) => {
                setExpandedSurveyTimeline(isExpanded ? `panel${index}` : false);
              }}
              disableGutters={true}
              onClick={() => {
                if (expandedSurveyTimeline !== `panel${index}`) {
                  handleSurveyTimeFrameClick(id, survey?.survey_id);
                }
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box
                  sx={{
                    mr: 1.5,
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      columnGap: 1,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {survey?.completed ? (
                      <CheckCircleIcon
                        sx={{
                          width: "20px",
                          height: "20px",
                          color: "var(--md-sys-color-primary-light)",
                        }}
                      />
                    ) : (
                      <ErrorIcon
                        sx={{
                          width: "20px",
                          height: "20px",
                          color: "var(--md-sys-color-error-light)",
                        }}
                      />
                    )}
                    <Box>
                      {survey?.post_termination ? (
                        <span
                          style={{
                            color: "var(--md-sys-color-error-light)",
                          }}
                        >
                          Exit
                        </span>
                      ) : (
                        `Day ${survey?.trigger_day}`
                      )}
                    </Box>
                  </Box>
                  <Box>
                    <SentimentSummary
                      value={initializeSentimentSummary(
                        survey?.["Response Rate"],
                        survey?.["Overall Sentiment"]
                      )}
                      sx={{ display: "inline-flex" }}
                    />
                  </Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                {isFetchingEmployeeSurvey ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress size={16} />
                  </Box>
                ) : (
                  renderSurveys(surveys)
                )}
              </AccordionDetails>
            </Accordion>
          );
        });
  };

  /**
   * Renders the empty state.
   *
   * @returns {Component}
   */
  const renderEmptyState = () => {
    return <div className="empty-state-wrapper">No Data</div>;
  };

  /**
   * Renders the surveys for the selected trigger day.
   *
   * @param {Array} surveys
   * @returns {Component}
   */
  const renderSurveys = (surveys = []) => {
    return isArrayEmpty(surveys)
      ? renderEmptyState()
      : surveys?.map((survey, index) => (
          <>
            <Box
              sx={{
                display: "flex",
                "@media (max-width:768px)": {
                  flexDirection: "column",
                  rowGap: "var(--spacing-2x)",
                  marginTop: index !== 0 ? "var(--spacing-4x)" : 0,
                  marginBottom:
                    index !== surveys.length - 1 ? "var(--spacing-4x)" : 0,
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "65%",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  rowGap: "var(--spacing-2x)",
                  "@media (max-width:768px)": {
                    width: "100%",
                  },
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: "500",
                    textAlign: "left",
                  }}
                >
                  {isStringEmpty(survey?.question) ? "-" : survey?.question}
                </Typography>
                {isStringEmpty(survey?.category) ? (
                  ""
                ) : (
                  <Chip
                    label={survey?.category}
                    size="small"
                    sx={{
                      color: "var(--md-sys-color-on-primary-container-light)",
                      backgroundColor:
                        "var(--md-sys-color-on-primary-container-dark)",
                    }}
                  />
                )}
              </Box>
              <Box
                sx={{
                  width: "35%",
                  display: "flex",
                  alignItems: "flex-end",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  "@media (max-width:768px)": {
                    width: "100%",
                    flexDirection: "row",
                  },
                }}
              >
                {/* <RatingScaleResponse
                  answer={survey?.answer}
                  companyAverage={survey?.companyAverage}
                /> */}
                <Box>
                  <SentimentSummary value={survey?.sentiment} />
                </Box>
                <Box>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      mr: 0.5,
                      display: "inline-block",
                      color: "var(--md-sys-color-outline-light)",
                    }}
                  >
                    Response:
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    fontWeight={500}
                    sx={{
                      display: "inline-block",
                    }}
                  >
                    {!isStringEmpty(survey?.answer) ? survey?.answer : "-"}
                  </Typography>
                </Box>
              </Box>
            </Box>
            {index !== surveys.length - 1 && <Divider />}
          </>
        ));
  };

  return (
    <div>
      <Navbar
        isLoadingUser={isLoadingUser}
        userLogo={user?.image_url}
        module="Retention"
        isEmployeesSearchBarShown={user?.configuration?.enable_employee_search}
        isPersonnelNavigationShown={user?.configuration?.enable_personal_tab}
      />

      <div className="employee-profile-body-wrapper">
        <div className="employee-profile-body-inner-wrapper">
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              overflow: "hidden",
              columnGap: "var(--spacing-4x)",
              "@media (max-width:992px)": {
                overflow: "auto",
                flexDirection: "column",
                rowGap: "var(--spacing-4x)",
              },
            }}
          >
            <Box
              sx={{
                width: "25%",
                display: "flex",
                flexDirection: "column",
                rowGap: "var(--spacing-4x)",
                "@media (max-width:992px)": {
                  width: "100%",
                  columnGap: "var(--spacing-4x)",
                },
              }}
            >
              <Card
                variant="outlined"
                sx={{
                  padding: "0",
                  flexShrink: 0,
                }}
              >
                <CardContent
                  sx={{
                    "& .super-app-theme--header": {
                      backgroundColor: "#EDEFF5",
                    },
                    padding: "0",
                    "&:last-child": {
                      paddingBottom: 0,
                    },
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-end",
                        padding: "var(--spacing-4x)",
                        background: "var(--md-sys-color-primary-light)",
                      }}
                    >
                      {isFetchingEmployee ? (
                        <Skeleton
                          variant="rounded"
                          width="100%"
                          height="35px"
                        />
                      ) : (
                        <Typography
                          sx={{
                            fontSize: "24px",
                            fontWeight: "600",
                            textAlign: "left",
                            color: "var(--white)",
                          }}
                        >
                          {isStringEmpty(
                            getFullName(
                              employee?.["profile"]?.first_name,
                              employee?.["profile"]?.last_name
                            )
                          )
                            ? "..."
                            : getFullName(
                                employee?.["profile"]?.first_name,
                                employee?.["profile"]?.last_name
                              )}
                        </Typography>
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                        background: "var(--white)",
                        alignContent: "flex-start",
                        rowGap: "var(--spacing-1x)",
                        padding: "var(--spacing-4x)",
                        columnGap: "var(--spacing-2x)",
                      }}
                    >
                      {isFetchingEmployee ? (
                        <Skeleton
                          variant="rounded"
                          width="100%"
                          height="60px"
                        />
                      ) : (
                        <>
                          <div className="info">
                            <Typography variant="caption">Job: </Typography>
                            <Typography variant="caption" fontWeight={600}>
                              {!isStringEmpty(employee?.["profile"]?.job_title)
                                ? employee?.["profile"]?.job_title
                                : "-"}
                            </Typography>
                          </div>
                          <Divider
                            orientation="vertical"
                            sx={{ height: "12px" }}
                          />
                          <div className="info">
                            <Typography variant="caption">
                              Location:{" "}
                            </Typography>
                            <Typography variant="caption" fontWeight={600}>
                              {!isStringEmpty(
                                employee?.["profile"]?.office_location
                              )
                                ? employee?.["profile"]?.office_location
                                : "-"}
                            </Typography>
                          </div>
                          <Divider
                            orientation="vertical"
                            sx={{ height: "12px" }}
                          />
                          <div className="info">
                            <Typography variant="caption">Tenure: </Typography>
                            <Typography variant="caption" fontWeight={600}>
                              {!isStringEmpty(employee?.["profile"]?.tenure)
                                ? employee?.["profile"]?.tenure
                                : "-"}
                            </Typography>
                          </div>
                          <Divider
                            orientation="vertical"
                            sx={{ height: "12px" }}
                          />
                          <div className="info">
                            <Typography variant="caption">
                              Supervisor:{" "}
                            </Typography>
                            <Typography variant="caption" fontWeight={600}>
                              {!isStringEmpty(employee?.["profile"]?.supervisor)
                                ? employee?.["profile"]?.supervisor
                                : "-"}
                            </Typography>
                          </div>
                        </>
                      )}
                    </Box>
                  </Box>
                  <Divider />
                  <Box
                    sx={{
                      maxWidth: "500px",
                      background: "var(--white)",
                      padding: "var(--spacing-4x)",
                    }}
                  >
                    {isFetchingEmployee ? (
                      <Skeleton variant="rounded" width="100%" height="25px" />
                    ) : (
                      <SentimentBreakdown
                        data={employee?.["survey"]?.Sentiment}
                      />
                    )}
                  </Box>
                </CardContent>
              </Card>
              <Box
                sx={{
                  flex: 1,
                  overflow: "hidden",
                }}
              >
                <EmployeeProfileSummary
                  formData={employee}
                  isFetching={isFetchingEmployee}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "75%",
                overflow: "auto",
                "@media (max-width:992px)": {
                  padding: 0,
                  width: "100%",
                  overflow: "visible",
                },
              }}
            >
              <div className="employee-profile-body-sub-wrapper">
                <div className="employee-summary-history-wrapper">
                  <Card
                    variant="outlined"
                    sx={{
                      width: "100%",
                      padding: "0",
                    }}
                  >
                    <CardContent
                      sx={{
                        "& .super-app-theme--header": {
                          backgroundColor: "#EDEFF5",
                        },
                        padding: "0",
                        "&:last-child": {
                          paddingBottom: 0,
                        },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                        }}
                      >
                        <Typography
                          variant="h6"
                          sx={{
                            fontWeight: "700",
                            textAlign: "left",
                            padding: "var(--spacing-4x)",
                          }}
                        >
                          Survey History
                        </Typography>
                      </Box>

                      <Divider />
                      <div className="employee-summary-history-inner-wrapper">
                        {!tabletLayout && (
                          <>
                            <div className="employee-summary-timeline-wrapper">
                              {isFetchingEmployee ? (
                                <Skeleton
                                  variant="rounded"
                                  width="200px"
                                  height="100%"
                                />
                              ) : (
                                renderSurveyTimeline(employee?.survey_timeline)
                              )}
                            </div>
                            <Divider orientation="vertical" />
                            <div className="employee-summary-questions-wrapper">
                              {isFetchingEmployee ||
                              isFetchingEmployeeSurvey ? (
                                <Skeleton
                                  variant="rounded"
                                  width="100%"
                                  height="100%"
                                />
                              ) : (
                                renderSurveys(surveys)
                              )}
                            </div>
                          </>
                        )}

                        {tabletLayout && (
                          <>
                            {isFetchingEmployee ? (
                              <Skeleton
                                variant="rounded"
                                sx={{
                                  flex: 1,
                                  margin: "var(--spacing-4x)",
                                }}
                              />
                            ) : (
                              renderSurveyAccordions(
                                employee?.survey_timeline,
                                surveys
                              )
                            )}
                          </>
                        )}
                      </div>
                    </CardContent>
                  </Card>
                </div>
                <div className="employee-breakdown-wrapper">
                  <Card
                    variant="outlined"
                    sx={{
                      width: "100%",
                      padding: "0",
                    }}
                  >
                    <CardContent
                      sx={{
                        "& .super-app-theme--header": {
                          backgroundColor: "#EDEFF5",
                        },
                        padding: "0",
                        "&:last-child": {
                          paddingBottom: 0,
                        },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                        }}
                      >
                        <Typography
                          variant="h6"
                          sx={{
                            fontWeight: "700",
                            textAlign: "left",
                            padding: "var(--spacing-4x)",
                          }}
                        >
                          Survey Summary
                        </Typography>
                      </Box>

                      <Divider />

                      <div
                        style={{
                          padding: "var(--spacing-4x)",
                        }}
                      >
                        <EmployeeDataGrid
                          {...questionCategoryPanel}
                          fetchQuestionCategoriesStatsByEmployee={() =>
                            handleFetchQuestionCategoriesStatsByEmployee(id)
                          }
                          fetchQuestionsStatsByCategoryAndEmployee={(
                            category
                          ) =>
                            handleFetchQuestionStatsByCategoryAndEmployee(
                              category,
                              id
                            )
                          }
                          isUndeterminedSurveyShown={isUndeterminedSurveyShown}
                        />
                      </div>
                    </CardContent>
                  </Card>
                </div>
              </div>
            </Box>
          </Box>
        </div>
      </div>
      {renderErrorSnackbar()}
    </div>
  );
}
