"use client";
/*
 * Documentation:
 * History Survey Question — https://app.subframe.com/library?component=History+Survey+Question_29773008-8eb1-4936-93ea-8e1e0552008a
 * Sentiment Badge — https://app.subframe.com/library?component=Sentiment+Badge_e1962f43-fe6e-498c-9b8e-21e501824ce3
 */

import React from "react";
import * as SubframeCore from "@subframe/core";

interface HistorySurveyQuestionRootProps
  extends React.HTMLAttributes<HTMLDivElement> {
  question?: string;
  children?: React.ReactNode;
  className?: string;
}

const HistorySurveyQuestionRoot = React.forwardRef<
  HTMLElement,
  HistorySurveyQuestionRootProps
>(function HistorySurveyQuestionRoot(
  {
    question,
    children,
    className,
    ...otherProps
  }: HistorySurveyQuestionRootProps,
  ref
) {
  return (
    <div
      className={SubframeCore.twClassNames(
        "flex w-full flex-col items-start gap-2 pt-2",
        className
      )}
      ref={ref as any}
      {...otherProps}
    >
      {children}
    </div>
  );
});

export const HistorySurveyQuestion = HistorySurveyQuestionRoot;
