"use client";
/*
 * Documentation:
 * Search Results — https://app.subframe.com/library?component=Search+Results_cd113f35-aff1-49d0-a6d1-71b8d0b7c91a
 * Text Field — https://app.subframe.com/library?component=Text+Field_be48ca43-f8e7-4c0e-8870-d219ea11abfe
 */

import React from "react";
import * as SubframeCore from "@subframe/core";
import { TextField } from "./TextField";
import { ProfileDetailItem } from "./ProfileDetailItem";

interface SearchResultProps extends React.HTMLAttributes<HTMLDivElement> {
  name?: string;
  role?: string;
  location?: string;
  tenure?: string;
  supervisor?: string;
  className?: string;
}

const SearchResult = React.forwardRef<HTMLElement, SearchResultProps>(
  function SearchResult(
    {
      name,
      role,
      location,
      tenure,
      supervisor,
      className,
      ...otherProps
    }: SearchResultProps,
    ref
  ) {
    return (
      <div
        className={SubframeCore.twClassNames(
          "group/bb7a919a flex w-full cursor-pointer items-center gap-4 rounded pt-3 pr-3 pb-3 pl-3 hover:bg-neutral-50",
          className
        )}
        ref={ref as any}
        {...otherProps}
      >
        <div className="flex w-full grow shrink-0 basis-0 flex-col items-start gap-1">
          {name ? (
            <span className="w-full text-subheader font-subheader text-default-font">
              {name}
            </span>
          ) : null}
          <div className="flex items-center gap-4">
            <ProfileDetailItem
              className="h-auto w-auto flex-none"
              icon="FeatherBriefcase"
              label="Label"
              value={role}
            />
            <ProfileDetailItem
              className="h-auto w-auto flex-none"
              icon="FeatherMapPin"
              label="Label"
              value={location}
            />
            <ProfileDetailItem
              className="h-auto w-auto flex-none"
              icon="FeatherCalendar"
              label="Label"
              value={tenure}
            />
            <ProfileDetailItem
              className="h-auto w-auto flex-none"
              icon="FeatherUser"
              label="Label"
              value={supervisor}
            />
          </div>
        </div>
      </div>
    );
  }
);

interface SearchResultsRootProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

const SearchResultsRoot = React.forwardRef<HTMLElement, SearchResultsRootProps>(
  function SearchResultsRoot(
    { className, ...otherProps }: SearchResultsRootProps,
    ref
  ) {
    return (
      <div
        className={SubframeCore.twClassNames(
          "flex w-full flex-col items-start overflow-hidden rounded bg-default-background shadow",
          className
        )}
        ref={ref as any}
        {...otherProps}
      >
        <div className="flex w-full flex-col items-start gap-4 pt-4 pr-4 pl-4">
          <TextField
            className="h-auto w-full flex-none"
            label=""
            helpText=""
            icon="FeatherSearch"
          >
            <TextField.Input placeholder="Search for a person..." />
          </TextField>
        </div>
        <div className="flex w-full flex-col items-start pt-2 pr-4 pb-4 pl-4">
          <SearchResult
            name="Jamal Renalli"
            role="Nurse"
            location="Philadelphia"
            tenure=" 90 - 120 days"
            supervisor="Ryan Smith"
          />
          <SearchResult
            name="Florence Zuniga"
            role="Nurse"
            location="Chicago"
            tenure="14 - 30 days"
            supervisor="Sarah Ross"
          />
          <SearchResult
            name="Dale Bruce"
            role="Therapist"
            location="Philadelphia"
            tenure="<14 days"
            supervisor="Ryan Smith"
          />
          <SearchResult
            name="Rachel Prince"
            role="Manager"
            location="Boston"
            tenure="120 - 180 days"
            supervisor="Megan Koch"
          />
          <SearchResult
            name="Scott Rosario"
            role="Nurse"
            location="Philadelphia"
            tenure="1 - 2 years"
            supervisor="Ryan Smith"
          />
        </div>
      </div>
    );
  }
);

export const SearchResults = Object.assign(SearchResultsRoot, {
  SearchResult,
});
