import { Avatar, CustomBadge } from "subframe";
import * as SubframeCore from "@subframe/core";

import {
  getAcronym,
  getFullName,
  isEmpty as isStringEmpty,
} from "utils/string";

const EmployeeSearchResultItemV2 = ({
  id,
  tenure,
  last_name,
  job_title,
  is_active,
  first_name,
  supervisor,
  office_location,
  escalation_status,
  onClick = () => {},
  profile_picture_url,
}) => {
  return (
    <div
      className="flex w-full grow shrink-0 basis-0 items-center gap-3 rounded pt-2 pr-3 pb-2 pl-3 cursor-pointer hover:bg-neutral-50 justify-between sm:justify-normal"
      onClick={() =>
        onClick({
          id,
          tenure,
          last_name,
          first_name,
          job_title,
          supervisor,
          office_location,
        })
      }
    >
      <Avatar size="small" color="Default Inverted" className="hidden sm:flex">
        {getAcronym(getFullName(first_name, last_name))}
      </Avatar>
      <div
        className={SubframeCore.twClassNames(
          "flex w-full grow shrink-0 basis-0 flex-col gap-y-2 sm:flex-row sm:gap-x-2"
        )}
      >
        <div className="flex w-full sm:w-auto justify-between items-center">
          <span
            className={SubframeCore.twClassNames(
              "text-body-bold font-body-bold text-default-font",
              { "text-subtext-color": !is_active }
            )}
          >
            {getFullName(first_name, last_name)}
          </span>
          <div className="flex gap-1 sm:hidden">
            <CustomBadge
              className={SubframeCore.twClassNames("hidden", {
                flex: escalation_status === "AT_RISK",
              })}
              variant="error"
              icon="FeatherAlertTriangle"
            >
              At Risk
            </CustomBadge>
            <CustomBadge
              className={SubframeCore.twClassNames("hidden", {
                flex: !is_active,
              })}
              variant="error"
              icon="FeatherUserX"
            >
              Terminated
            </CustomBadge>
          </div>
        </div>
        <div className="flex items-center gap-x-2 gap-y-1 flex-wrap">
          <div className="flex items-center gap-2">
            <span className="text-label font-label text-subtext-color">•</span>
            <span className="text-label font-label text-subtext-color">
              {isStringEmpty(job_title) ? "-" : job_title}
            </span>
          </div>
          <div className="flex items-center gap-2">
            <span className="text-label font-label text-subtext-color">•</span>
            <span className="text-label font-label text-subtext-color">
              {isStringEmpty(office_location) ? "-" : office_location}
            </span>
          </div>
          <div className="flex items-center gap-2">
            <span className="text-label font-label text-subtext-color">•</span>
            <span className="text-label font-label text-subtext-color">
              {isStringEmpty(supervisor) ? "-" : supervisor}
            </span>
          </div>
          <div className="flex items-center gap-2">
            <span className="text-label font-label text-subtext-color">•</span>
            <span className="text-label font-label text-subtext-color">
              {isStringEmpty(tenure) ? "-" : tenure}
            </span>
          </div>
        </div>
      </div>
      <div className="hidden sm:flex sm:gap-1">
        <CustomBadge
          className={SubframeCore.twClassNames("hidden", {
            flex: escalation_status === "AT_RISK",
          })}
          variant="error"
          icon="FeatherAlertTriangle"
        >
          At Risk
        </CustomBadge>
        <CustomBadge
          className={SubframeCore.twClassNames("hidden", {
            flex: !is_active,
          })}
          variant="error"
          icon="FeatherUserX"
        >
          Terminated
        </CustomBadge>
      </div>
    </div>
  );
};

export default EmployeeSearchResultItemV2;
