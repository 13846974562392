import { Divider } from "@mui/material";
import MultiProgress from "react-multi-progress";

import { SENTIMENT } from "../../constants/retention/constants";

import { sortArrayObjects } from "../../utils/array";
import { isEmpty as isObjectEmpty } from "../../utils/object";

import "./SentimentBreakdown.css";

export default function SentimentBreakdown({ data = {} }) {
  /**
   * Arranges the data required for the multi-step task bar component.
   *
   * @param {*} data
   * @returns
   */
  const arrangeData = (data = {}) => {
    if (isObjectEmpty(data)) {
      return [];
    }

    const unorderedData = Object.keys(data).map((key) => ({
      color: SENTIMENT[key]?.color,
      value: data[key],
      sentiment: key,
    }));

    return sortArrayObjects(unorderedData, "sentiment");
  };

  return (
    <div className="sentiment-breakdown-progress-bar-wrapper">
      <div className="details">
        <div style={{ color: SENTIMENT["Negative"]?.color }}>
          Negative : {data?.["Negative"] ?? 0}%
        </div>
        <Divider
          orientation="vertical"
          sx={{
            height: "8px",
          }}
        />
        <div style={{ color: SENTIMENT["Neutral"]?.color }}>
          Neutral : {data?.["Neutral"] ?? 0}%
        </div>
        <Divider
          orientation="vertical"
          sx={{
            height: "8px",
          }}
        />
        <div style={{ color: SENTIMENT["Positive"]?.color }}>
          Positive : {data?.["Positive"] ?? 0}%
        </div>
      </div>
      <MultiProgress
        elements={arrangeData(data)}
        height={8}
        backgroundColor="rgba(32, 117, 217, 0.2)"
      />
    </div>
  );
}
