import { Dialog } from "@mui/material";
import { useState, useEffect, forwardRef, useImperativeHandle } from "react";

import { publish } from "utils/event";

import EmployeesSearchBarV2 from "../EmployeesSearchBarV2";

const SearchEmployeesDialog = (props, searchEmployeesDialogRef) => {
  const [open, setOpen] = useState(false);

  // Toggle the menu when ⌘K is pressed
  useEffect(() => {
    const down = (e) => {
      if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        publish("closeSlideout");
        requestAnimationFrame(() => setOpen((open) => !open));
      }
    };

    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, []);

  useImperativeHandle(searchEmployeesDialogRef, () => ({ setOpen }), []);

  return (
    <>
      <Dialog
        onClose={() => {
          setOpen(false);
        }}
        fullWidth
        maxWidth="md"
        open={open}
        PaperProps={{
          sx: {
            height: 430, // 16 (padding-top) + 38 (search-bar-height) + 360 (search-results) + 16 (padding-bottom) ,
          },
        }}
      >
        <div className="w-full h-full p-4">
          <EmployeesSearchBarV2
            listBoxProps={{
              style: {
                maxHeight: "360px",
              },
            }}
          />
        </div>
      </Dialog>
    </>
  );
};

export default forwardRef(SearchEmployeesDialog);
