import {
  Radio,
  Select,
  MenuItem,
  FormLabel,
  TextField,
  InputLabel,
  RadioGroup,
  FormControl,
  FormHelperText,
  FormControlLabel,
} from "@mui/material";

import { isEmpty as isArrayEmpty } from "../../utils/array";
import { isEmpty as isStringEmpty } from "../../utils/string";

import "./AddCaregiverForm.css";

export default function AddCaregiverForm({
  jobs = [],
  formData = {},
  hasTriedFormSubmission,
  changeFormData = () => {},
}) {
  return (
    <div className="add-caregiver-form-main-wrapper">
      <div className="form-row">
        <div className="form-column">
          <TextField
            required
            id="firstName"
            label="First Name"
            value={formData?.first_name}
            variant="outlined"
            sx={{ width: "100%" }}
            onChange={(event) =>
              changeFormData("first_name", event?.target?.value)
            }
            helperText={
              hasTriedFormSubmission && isStringEmpty(formData?.first_name)
                ? "First Name is required."
                : ""
            }
            error={
              hasTriedFormSubmission && isStringEmpty(formData?.first_name)
            }
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-column">
          <TextField
            required
            id="lastName"
            label="Last Name"
            value={formData?.last_name}
            variant="outlined"
            sx={{ width: "100%" }}
            onChange={(event) =>
              changeFormData("last_name", event?.target?.value)
            }
            helperText={
              hasTriedFormSubmission && isStringEmpty(formData?.last_name)
                ? "Last Name is required."
                : ""
            }
            error={hasTriedFormSubmission && isStringEmpty(formData?.last_name)}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-column">
          <TextField
            required
            type="number"
            id="phoneNumber"
            label="Phone Number"
            value={formData?.phone_number}
            variant="outlined"
            sx={{ width: "100%" }}
            InputProps={{ inputProps: { min: 0 } }}
            onChange={(event) => {
              changeFormData("phone_number", event?.target?.value);
            }}
            helperText={
              hasTriedFormSubmission && isStringEmpty(formData?.phone_number)
                ? "Phone Number is required."
                : ""
            }
            error={
              hasTriedFormSubmission && isStringEmpty(formData?.phone_number)
            }
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-column">
          <TextField
            required
            id="email"
            label="Email"
            value={formData?.email}
            variant="outlined"
            sx={{ width: "100%" }}
            onChange={(event) => changeFormData("email", event?.target?.value)}
            helperText={
              hasTriedFormSubmission && isStringEmpty(formData?.email)
                ? "Email is required."
                : ""
            }
            error={hasTriedFormSubmission && isStringEmpty(formData?.email)}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-column">
          <FormControl>
            <InputLabel
              id="job-select-helper-label"
              error={hasTriedFormSubmission && isNaN(formData?.job_id)}
            >
              Job *
            </InputLabel>
            <Select
              required
              labelId="job-select-helper-label"
              id="job-select"
              value={formData?.job_id}
              label="Job *"
              onChange={(event) =>
                changeFormData("job_id", event?.target?.value)
              }
              sx={{ width: "100%" }}
              error={hasTriedFormSubmission && isNaN(formData?.job_id)}
            >
              {!isArrayEmpty(jobs) &&
                jobs.map((job) => (
                  <MenuItem value={job.id} key={job.id}>
                    {job.job_title}
                  </MenuItem>
                ))}
            </Select>
            {hasTriedFormSubmission && isNaN(formData?.job_id) ? (
              <FormHelperText error>Job is required.</FormHelperText>
            ) : (
              ""
            )}
          </FormControl>
        </div>
      </div>
      <div className="form-row">
        <div className="form-column">
          <FormControl
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <FormLabel
              id="outreach-radio-buttons-group-label"
              sx={{
                marginRight: "16px",
              }}
            >
              Send Outreach Message
            </FormLabel>
            <RadioGroup
              row
              value={formData?.outreach}
              name="outreach-radio-buttons-group"
              onChange={(event) =>
                changeFormData("outreach", event?.target?.value)
              }
            >
              <FormControlLabel value={false} control={<Radio />} label="No" />
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
    </div>
  );
}
