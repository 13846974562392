import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";

import {
  Tab,
  Card,
  Tabs,
  Avatar,
  Divider,
  Snackbar,
  CardContent,
  CircularProgress,
} from "@mui/material";
import { Box } from "@mui/system";
import MuiAlert from "@mui/material/Alert";
import CallMadeIcon from "@mui/icons-material/CallMade";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

import "./Recognitions.css";

import { formatDate } from "../../../../utils/date";
import { isEmpty as isArrayEmpty } from "../../../../utils/array";
import { getAcronym, getFullName } from "../../../../utils/string";
import { isEmpty as isStringEmpty } from "../../../../utils/string";

import Job from "../../assets/images/job.png";
import Badge from "../../assets/images/badge.png";
import Location from "../../assets/images/tenure.png";
import EmptyBox from "../../assets/images/empty-box.png";

import { ERROR_CODE } from "../../../../constants/constants";

import {
  fetchProvidedRecognitions,
  fetchReceivedRecognitions,
} from "../../services/recognition";

const RECOGNITION_TYPE = {
  RECEIVED: "RECEIVED",
  PROVIDED: "PROVIDED",
};

export default function Recognitions() {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [recognitionType, setRecognitionType] = useState(
    RECOGNITION_TYPE.RECEIVED
  );
  const [receivedRecognitions, setReceivedRecognitions] = useState([]);
  const [providedRecognitions, setProvidedRecognitions] = useState([]);
  const [isErrorMessageShown, setIsErrorMessageShown] = useState(false);
  const [isLoadingProvidedRecognitions, setIsLoadingProvidedRecognitions] =
    useState(false);
  const [isLoadingReceivedRecognitions, setIsLoadingReceivedRecognitions] =
    useState(false);

  useEffect(() => {
    handleFetchProvidedRecognitions();
    handleFetchReceivedRecognitions();
  }, []);

  /**
   * Fetches the provided recognitions via API call.
   *
   * @returns {Promise}
   */
  const fetchProvidedRecognitionsAsync = async () => {
    try {
      return await fetchProvidedRecognitions();
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/recognition");
      }

      return { hasError: true, error };
    }
  };

  /**
   * Fetches the received recognitions via API call.
   *
   * @returns {Promise}
   */
  const fetchReceivedRecognitionsAsync = async () => {
    try {
      return await fetchReceivedRecognitions();
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/recognition");
      }

      return { hasError: true, error };
    }
  };

  /**
   * Handles the fetching of the provided recognitions.
   *
   * @returns {Promise}
   */
  const handleFetchProvidedRecognitions = async () => {
    setIsLoadingProvidedRecognitions(true);
    const response = await fetchProvidedRecognitionsAsync();

    setIsLoadingProvidedRecognitions(false);

    if (response?.hasError) {
      setErrorMessage(
        `Error while fetching Provided Recognitions: ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);
      setProvidedRecognitions([]);

      return;
    }

    setProvidedRecognitions(response?.result?.recognitions);
  };

  /**
   * Handles the fetching of the received recognitions.
   *
   * @returns {Promise}
   */
  const handleFetchReceivedRecognitions = async () => {
    setIsLoadingReceivedRecognitions(true);
    const response = await fetchReceivedRecognitionsAsync();

    setIsLoadingReceivedRecognitions(false);

    if (response?.hasError) {
      setErrorMessage(
        `Error while fetching Received Recognitions: ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);
      setReceivedRecognitions([]);

      return;
    }

    setReceivedRecognitions(response?.result?.recognitions);
  };

  /**
   * Renders the error snackbar.
   *
   * @returns {Component}
   */
  const renderErrorSnackbar = () => (
    <Snackbar
      open={isErrorMessageShown}
      autoHideDuration={6000}
      onClose={() => setIsErrorMessageShown(false)}
    >
      <MuiAlert
        onClose={() => setIsErrorMessageShown(false)}
        severity="error"
        sx={{ width: "100%" }}
      >
        {errorMessage}
      </MuiAlert>
    </Snackbar>
  );

  /**
   * Renders the empty state.
   *
   * @returns {Component}
   */
  const renderEmptyState = () => {
    return (
      <div className="empty-state-wrapper">
        <img src={EmptyBox} alt="empty-state" />
        No Data Yet.
      </div>
    );
  };

  /**
   * Renders the loading state.
   *
   * @returns {Component}
   */
  const renderLoadingState = () => {
    return (
      <div className="loader-state-wrapper">
        <CircularProgress />
      </div>
    );
  };

  /**
   * Renders the list of received/provided recognitions.
   */
  const handleRenderRecognitions = (recognitions = []) => {
    return isArrayEmpty(recognitions)
      ? renderEmptyState()
      : recognitions?.map((recognition) => {
          return (
            <div className="recognition-list-wrapper" key={recognition?.id}>
              {/* <div className="left">
                <Avatar
                  src={
                    recognition?.[
                      recognitionType === RECOGNITION_TYPE.RECEIVED
                        ? "recognized_by"
                        : "recognized"
                    ]?.caregiver?.profile_picture_url
                  }
                  sx={{
                    width: 40,
                    height: 40,
                    fontSize: "16px",
                    bgcolor: "rgb(90,156,213)",
                  }}
                >
                  {getAcronym(
                    getFullName(
                      recognition?.[
                        recognitionType === RECOGNITION_TYPE.RECEIVED
                          ? "recognized_by"
                          : "recognized"
                      ]?.caregiver?.first_name,
                      recognition?.[
                        recognitionType === RECOGNITION_TYPE.RECEIVED
                          ? "recognized_by"
                          : "recognized"
                      ]?.caregiver?.last_name
                    )
                  )}
                </Avatar>
              </div> */}
              <div className="right">
                <section className="first">
                  <span className="title">
                    {isStringEmpty(
                      getFullName(
                        recognition?.[
                          recognitionType === RECOGNITION_TYPE.RECEIVED
                            ? "recognized_by"
                            : "recognized"
                        ]?.caregiver?.first_name,
                        recognition?.[
                          recognitionType === RECOGNITION_TYPE.RECEIVED
                            ? "recognized_by"
                            : "recognized"
                        ]?.caregiver?.last_name
                      )
                    )
                      ? isStringEmpty(recognition?.agency?.name)
                        ? "-"
                        : recognition?.agency?.name
                      : getFullName(
                          recognition?.[
                            recognitionType === RECOGNITION_TYPE.RECEIVED
                              ? "recognized_by"
                              : "recognized"
                          ]?.caregiver?.first_name,
                          recognition?.[
                            recognitionType === RECOGNITION_TYPE.RECEIVED
                              ? "recognized_by"
                              : "recognized"
                          ]?.caregiver?.last_name
                        )}
                  </span>

                  {/* <div className="info-wrapper">
                    <div className="info">
                      <img src={Job} alt="Job" />
                      <span>
                        {!isStringEmpty(
                          recognition?.[
                            recognitionType === RECOGNITION_TYPE.RECEIVED
                              ? "recognized_by"
                              : "recognized"
                          ]?.job?.job_title
                        )
                          ? recognition?.[
                              recognitionType === RECOGNITION_TYPE.RECEIVED
                                ? "recognized_by"
                                : "recognized"
                            ]?.job?.job_title
                          : "-"}
                      </span>
                    </div>

                    <Divider orientation="vertical" sx={{ height: "14px" }} />

                    <div className="info">
                      <img src={Location} alt="Location" />
                      <span>
                        {!isStringEmpty(
                          recognition?.[
                            recognitionType === RECOGNITION_TYPE.RECEIVED
                              ? "recognized_by"
                              : "recognized"
                          ]?.office_location
                        )
                          ? recognition?.[
                              recognitionType === RECOGNITION_TYPE.RECEIVED
                                ? "recognized_by"
                                : "recognized"
                            ]?.office_location
                          : "-"}
                      </span>
                    </div>
                  </div> */}
                </section>
                <section className="second">
                  <div className="second-first">
                    {isStringEmpty(recognition?.reason)
                      ? "-"
                      : recognition?.reason}
                  </div>
                  <div className="second-second">
                    <div className="category">
                      {isStringEmpty(recognition?.category)
                        ? "-"
                        : recognition?.category}
                    </div>
                    <div className="date">
                      <CalendarMonthIcon fontSize="small" />
                      <span>{formatDate(recognition?.created_at) ?? "-"}</span>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          );
        });
  };

  /**
   * Handles the rendering of the Provided Recognitions.
   *
   * @returns {Component}
   */
  const handleRenderProvidedRecognitions = () => {
    return isLoadingProvidedRecognitions
      ? renderLoadingState()
      : handleRenderRecognitions(providedRecognitions);
  };

  /**
   * Handles the rendering of the Received Recognitions.
   *
   * @returns {Component}
   */
  const handleRenderReceivedRecognitions = () => {
    return isLoadingReceivedRecognitions
      ? renderLoadingState()
      : handleRenderRecognitions(receivedRecognitions);
  };

  /**
   * Handles the total count of the Recognitions.
   *
   * @param {String} recognitionType
   * @returns {String}
   */
  const handleRecognitionCountRender = (recognitionType = "") => {
    if (isStringEmpty(recognitionType)) {
      return "";
    }

    const recognitions =
      recognitionType === RECOGNITION_TYPE.RECEIVED
        ? receivedRecognitions
        : providedRecognitions;
    const isLoading =
      recognitionType === RECOGNITION_TYPE.RECEIVED
        ? isLoadingReceivedRecognitions
        : isLoadingProvidedRecognitions;

    if (isLoading) {
      return "";
    }

    const count = isArrayEmpty(recognitions) ? 0 : recognitions.length;

    return `(${count})`;
  };

  return (
    <>
      <div className="recognitions-main-outer-wrapper">
        <Card
          variant="outlined"
          sx={{
            padding: "0",
            width: "100%",
            height: "100%",
            display: "flex",
          }}
        >
          <CardContent
            sx={{
              flex: 1,
              padding: "0",
              display: "flex",
              flexDirection: "column",
              "&:last-child": {
                paddingBottom: 0,
              },
              "& .super-app-theme--header": {
                backgroundColor: "#EDEFF5",
              },
            }}
          >
            <header className="recognitions-header-wrapper">
              <img src={Badge} alt="badge" />
              <h2>Recognitions</h2>
            </header>

            <Divider />

            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                sx={{
                  minHeight: "8px",
                }}
                variant="fullWidth"
                value={recognitionType}
                aria-label="recognitions tabs"
                onChange={(event, value) => setRecognitionType(value)}
              >
                <Tab
                  sx={{
                    minHeight: "0px",
                    padding: "10px 16px",
                  }}
                  iconPosition="start"
                  icon={<CallReceivedIcon />}
                  value={RECOGNITION_TYPE.RECEIVED}
                  label={`${
                    RECOGNITION_TYPE.RECEIVED
                  } ${handleRecognitionCountRender(RECOGNITION_TYPE.RECEIVED)}`}
                />

                <Tab
                  sx={{
                    minHeight: "0px",
                    padding: "10px 16px",
                  }}
                  iconPosition="start"
                  icon={<CallMadeIcon />}
                  value={RECOGNITION_TYPE.PROVIDED}
                  label={`${
                    RECOGNITION_TYPE.PROVIDED
                  } ${handleRecognitionCountRender(RECOGNITION_TYPE.PROVIDED)}`}
                />
              </Tabs>
            </Box>
            <div className="recognitions-inner-wrapper">
              {recognitionType === RECOGNITION_TYPE.RECEIVED
                ? handleRenderReceivedRecognitions()
                : handleRenderProvidedRecognitions()}
            </div>
          </CardContent>
        </Card>
      </div>
      {renderErrorSnackbar()}
    </>
  );
}
