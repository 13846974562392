const endpoints = {
  saveVCard: "/v1/auth/agency/vcard",
  updateAgencyName: "/v1/auth/agency/user",
  agencySettings: "/v1/auth/agency/settings",
  embedAnalytics: "/v1/auth/agency/lightski_jwt",
  employeeHistory: "/v1/auth/agency/employee/history",
  employee: "/v1/auth/agency/employee/escalation/details",
  postRecognition: "/v1/auth/agency/employees/recognition",
  employeeEscalations: "/v1/auth/agency/employee/escalations",
  rosterStats: "/v1/auth/agency/employee/stats-numbers/roster",
  caregiverRoles: "/v1/auth/agency/employee/caregiver-role-list",
  employeeRecognitions: "/v1/auth/agency/employees/recognitions",
  reportsHighlights: "/v1/auth/agency/employee/reports/highlights",
  trendsOverTime: "/v1/auth/agency/employee/retention-weekly-stats",
  inboxSummary: "/v1/auth/agency/employee/stats-numbers/escalations",
  followUpEscalation: "/v1/auth/agency/employee/escalations/follow-up",
  postPublicRecognition: "/v1/auth/agency/employees/recognition/public",
  employeeStatsByRegion: "/v1/auth/agency/employee/stats-numbers/region",
  embedDashboardUrl: "/v1/auth/agency/employees/metabase/dashboard/url",
  branchMetadata: "/v1/auth/agency/branches/metadata",
  supervisorMetadata: "/v1/auth/agency/supervisor/metadata",
  turnoverRateData: "/v1/auth/agency/reports/branch-metrics",
  winsAndOpportunities: "/v1/auth/agency/employee/top-and-bottom-questions",
};

export default endpoints;
