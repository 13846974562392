import { isEmpty as isObjectEmpty } from "./object";

/**
 * Defining the orders for sorting.
 */
export const SORTING_ORDER = {
  ASCENDING: 1,
  DESCENDING: -1,
};

/**
 * Check if array is empty or not.
 *
 * @param {Array} array
 */
export const isEmpty = (array) => {
  return !Array.isArray(array) || !array.length;
};

/**
 * Sorts the array of objects by the specified sortBy content in
 * both ascending order and descending order given its order type.
 *
 * By default sorts the in ascending order.
 *
 * @param {Array} array
 * @param {String} sortBy
 * @param {Number} order
 *
 * @returns {Array}
 */
export const sortArrayObjects = (
  array,
  sortBy,
  order = SORTING_ORDER.ASCENDING
) => {
  if (!Array.isArray(array)) {
    return [];
  }

  if (!sortBy) {
    return array;
  }

  // Sanitizes the array by removing the undefined data.
  const sanitizedArray = array.filter(
    (content) => content[sortBy] !== undefined
  );

  const sorted = [...sanitizedArray].sort((a, b) => {
    if (typeof a[sortBy] === "string") {
      return a[sortBy].toLowerCase() > b[sortBy].toLowerCase()
        ? 1 * order
        : -1 * order;
    }

    return a[sortBy] > b[sortBy] ? 1 * order : -1 * order;
  });

  return sorted;
};

/**
 * Sorts the Object on the basis of Array's Items Order.
 *
 * @param {Object} obj
 * @param {Array} arr
 * @returns {Object} obj || sortedObj
 */
export const sortObjectByArrayOrder = (obj = {}, arr = []) => {
  let sortedObj = {};

  if (isObjectEmpty(obj) || isEmpty(arr)) {
    return obj;
  }

  arr.forEach((key) => {
    if (obj[key]) {
      sortedObj = { ...sortedObj, [key]: obj[key] };
    }
  });

  return sortedObj;
};
