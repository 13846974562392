import config from "../config";
import endpoints from "../constants/endpoints";

import { levoHttp } from "../utils/http";
import { create as createQueryParam } from "../utils/queryParam";

/**
 * Fetch messages.
 *
 * @param {Object} query
 * @returns
 */
export const fetch = (query) => {
  const param = createQueryParam(query);
  const url = `${config.authBaseUrl}${endpoints.messages}${param}`;

  return levoHttp.get(url);
};

/**
 * Post message.
 *
 * @param {Number} phone_number
 * @param {String} message
 * @returns
 */
export const post = (phone_number, message, application_id) => {
  const payload = {
    phone_number,
    message,
    application_id,
  };
  const url = `${config.authBaseUrl}${endpoints.messages}`;

  return levoHttp.post(url, payload);
};
