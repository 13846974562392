import { Card, CardContent } from "@mui/material";

import "./StatisticsCard.css";

export default function StatisticsCard({
  icon,
  heading,
  subHeading,
  width = "100%",
}) {
  return (
    <Card variant="outlined" sx={{ width, height: "100%" }}>
      <CardContent
        className="statistics-card-content-wrapper"
        sx={{
          height: "100%",
          padding: "16px",
          "&:last-child": {
            paddingBottom: "16px",
          },
        }}
      >
        <div className="statistics-icon-wrapper">{icon}</div>
        <div className="statistics-content-wrapper">
          <div className="statistics-heading-wrapper">{heading}</div>
          <div className="statistics-subheading-wrapper">{subHeading}</div>
        </div>
      </CardContent>
    </Card>
  );
}
