import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import * as SubframeCore from "@subframe/core";
import { EVENT_TYPES } from "constants/mixpanel";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  loginEmployee as login,
  signUpEmployee as signUp,
} from "services/auth";
import { mixpanelLoginEvent, mixpanelSignUpEvent } from "services/mixpanel";
import { TextField } from "subframe";
import { Button } from "subframe/components/Button";
import { LinkButton } from "subframe/components/LinkButton";
import { get, set } from "utils/localStorage";
import { isEmpty as isStringEmpty } from "utils/string";
import Logo from "../../../../assets/images/logoV2.svg";
import { fetchUser } from "../../../../services/applications";

const EmployeeSignUp = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isSigningUp, setIsSigningUp] = useState(false);
  const [hasTriedSigningUp, setHasTriedSigningUp] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorMessageShown, setIsErrorMessageShown] = useState(false);

  useEffect(() => {
    const accessToken = get("token");
    const refreshToken = get("refreshToken");
    const userRole = get("userRole");

    if (
      !isStringEmpty(accessToken) ||
      !isStringEmpty(refreshToken) ||
      !isStringEmpty(userRole)
    ) {
      navigate("/retention/V2");
    }
  }, []);

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleSignUpAsync = async (firstName, lastName, email, password) => {
    try {
      return await signUp(firstName, lastName, email, password);
    } catch (error) {
      return { hasError: true, error };
    }
  };

  const handleLoginAsync = async (email, password) => {
    try {
      setIsLoggingIn(true);
      const response = await login(email, password);
      const token = response?.data?.result?.token;
      const role = response?.data?.result?.role;
      const refreshToken = response?.data?.result?.["refresh-token"];

      if (!isStringEmpty(token)) {
        set("token", token);
        set("refreshToken", refreshToken);
        set("userRole", role);
        set("email", email);
        get("token");

        const userDetails = await fetchUser(role);
        const agency_name = userDetails?.result?.agency
        const first_name = userDetails?.result?.first_name
        const last_name = userDetails?.result?.last_name
        const full_name = `${first_name} ${last_name}`

        set("agency_name", agency_name);
        set("full_name", full_name);
        mixpanelLoginEvent(EVENT_TYPES.EMPLOYEE_LOGIN); // Emit login analytics event

        navigate("/retention/V2");
      }
    } catch (error) {
      setErrorMessage(
        `Error while Logging In: ${error?.response?.data?.message}`
      );
      setIsErrorMessageShown(true);
    } finally {
      setIsLoggingIn(false);
    }
  };

  const handleSignUp = async (firstName, lastName, email, password) => {
    setIsSigningUp(true);
    const response = await handleSignUpAsync(
      firstName,
      lastName,
      email,
      password
    );

    setIsSigningUp(false);

    if (response.hasError) {
      setErrorMessage(
        `Error while Signing Up: ${response?.error?.response?.data?.message}`
      );
      setIsErrorMessageShown(true);

      return;
    }

    mixpanelSignUpEvent(EVENT_TYPES.EMPLOYEE_SIGNUP);
    handleLoginAsync(email, password);
  };

  const isValidEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return emailRegex.test(email);
  };

  const isSignUpValid = () => {
    return (
      !isStringEmpty(email) &&
      !isStringEmpty(password) &&
      !isStringEmpty(firstName) &&
      !isStringEmpty(lastName) &&
      !isStringEmpty(confirmPassword) &&
      isValidEmail() &&
      password === confirmPassword
    );
  };

  const handleSignUpClick = () => {
    setHasTriedSigningUp(true);

    if (!isSignUpValid()) {
      return;
    }

    handleSignUp(firstName, lastName, email, password);
  };

  const getEmailError = () => {
    if (!hasTriedSigningUp) {
      return "";
    }

    if (isStringEmpty(email)) {
      return "Email is required.";
    }

    if (!isValidEmail()) {
      return "Please enter a valid email.";
    }

    return "";
  };

  const getConfirmPasswordError = () => {
    if (!hasTriedSigningUp) {
      return "";
    }

    if (isStringEmpty(confirmPassword)) {
      return "Password is required.";
    }

    if (password !== confirmPassword) {
      return "Password doesn't match";
    }

    return "";
  };

  return (
    <>
      <div className="w-full h-full items-start flex bg-default-background">
        <div className="grow shrink-0 basis-0 w-full h-full gap-12 pt-12 pr-12 pb-12 pl-12 items-center justify-center flex bg-brand-50">
          <div className="flex-col gap-6 items-start flex">
            <span className="text-default-font text-header font-header">
              Understand how your workers are with Levo.
            </span>
            <div className="flex-none h-px w-full flex-col gap-2 items-center flex bg-neutral-200" />
            <div className="gap-2 items-center flex">
              <SubframeCore.Icon
                className="text-brand-700 text-body font-body"
                name="FeatherCheckSquare"
              />
              <span className="text-default-font text-body font-body">
                Easy set up, no coding required.
              </span>
            </div>
            <div className="gap-2 items-center flex">
              <SubframeCore.Icon
                className="text-brand-700 text-body font-body"
                name="FeatherCheckSquare"
              />
              <span className="text-default-font text-body font-body">
                SMS communications, no downloads needed.
              </span>
            </div>
            <div className="gap-2 items-center flex">
              <SubframeCore.Icon
                className="text-brand-700 text-body font-body"
                name="FeatherCheckSquare"
              />
              <span className="text-default-font text-body font-body">
                Unlimited custom questions and timing.
              </span>
            </div>
          </div>
        </div>
        <div className="grow shrink-0 basis-0 w-full h-full flex-col gap-6 pt-12 pr-12 pb-12 pl-12 items-start flex">
          <img className="flex-none h-12 w-20" src={Logo} alt="levo-logo" />
          <div className="grow shrink-0 basis-0 h-full w-full flex-col gap-12 items-start justify-center flex">
            <div className="gap-2 items-center flex">
              <span className="text-default-font text-header font-header">
                Employee Sign Up
              </span>
            </div>
            <div className="w-full flex-col gap-4 items-start justify-center flex">
              <TextField
                value={firstName}
                className="h-auto w-full flex-none"
                label="First Name"
                onChange={handleFirstNameChange}
                error={hasTriedSigningUp && isStringEmpty(firstName)}
                helpText={
                  hasTriedSigningUp && isStringEmpty(firstName)
                    ? "First name is required."
                    : ""
                }
              >
                <TextField.Input placeholder="" />
              </TextField>
              <TextField
                value={lastName}
                className="h-auto w-full flex-none"
                label="Last Name"
                onChange={handleLastNameChange}
                error={hasTriedSigningUp && isStringEmpty(lastName)}
                helpText={
                  hasTriedSigningUp && isStringEmpty(lastName)
                    ? "Last name is required."
                    : ""
                }
              >
                <TextField.Input placeholder="" />
              </TextField>
              <TextField
                value={email}
                className="h-auto w-full flex-none"
                label="Email"
                onChange={handleEmailChange}
                error={!isStringEmpty(getEmailError())}
                helpText={getEmailError()}
              >
                <TextField.Input placeholder="" />
              </TextField>
              <TextField
                value={password}
                className="h-auto w-full flex-none"
                label="Password"
                onChange={handlePasswordChange}
                error={hasTriedSigningUp && isStringEmpty(password)}
                helpText={
                  hasTriedSigningUp && isStringEmpty(password)
                    ? "Password is required."
                    : ""
                }
              >
                <TextField.Input type="password" placeholder="" />
              </TextField>
              <TextField
                value={confirmPassword}
                className="h-auto w-full flex-none"
                label="Confirm Password"
                onChange={handleConfirmPasswordChange}
                helpText={getConfirmPasswordError()}
                error={!isStringEmpty(getConfirmPasswordError())}
              >
                <TextField.Input type="password" placeholder="" />
              </TextField>
            </div>
            <Button
              disabled={isSigningUp || isLoggingIn}
              variant="Brand Primary"
              size="Default"
              onClick={handleSignUpClick}
              loading={isSigningUp || isLoggingIn}
            >
              Sign Up
            </Button>
            <div className="flex w-full flex-wrap items-start gap-2">
              <span className="text-body font-body text-subtext-color">
                Already have an account?
              </span>
              <LinkButton
                variant="brand"
                onClick={() => navigate("/employee-login")}
              >
                Login
              </LinkButton>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={isErrorMessageShown}
        autoHideDuration={6000}
        onClose={() => setIsErrorMessageShown(false)}
      >
        <MuiAlert
          onClose={() => setIsErrorMessageShown(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default EmployeeSignUp;
