/**
 * Check if object is empty or not.
 *
 * @param {Object} object
 */
export const isEmpty = (object) => {
  return (
    !object ||
    (Object.entries(object).length === 0 && object.constructor === Object)
  );
};

/**
 * Update nested object's property by string.
 *
 * @param {Object} obj
 * @param {String} path
 * @param {*} value
 *
 * @returns {Object}
 */
export const setProperty = (obj, path, value) => {
  const [head, ...rest] = path.split(".");

  return {
    ...obj,
    [head]: rest.length ? setProperty(obj[head], rest.join("."), value) : value,
  };
};
