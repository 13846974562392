import { Skeleton } from "@mui/material";
import * as SubframeCore from "@subframe/core";
import { ERROR_CODE } from "constants/constants";
import { SENTIMENT } from "modules/Retention/constants/constants";
import { fetchBranchMetadata } from "modules/Retention/services/retention";
import { renderSentimentBadgeWithScore } from "modules/Retention/utils/sentiment";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Progress } from "subframe";
import { isNumber } from "utils/number";
import { isEmpty as isStringEmpty } from "utils/string";

const BranchWithMetadata = ({ name, responseRate, overallSentiment }) => {
  const [branchMetadata, setBranchMetadata] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isMetaDataOpen, setIsMetaDataOpen] = useState(false);
  const navigate = useNavigate();
  const hoverCardRef = useRef(null);
  const handleClickOutside = (event) => {
    if (hoverCardRef.current && !hoverCardRef.current.contains(event.target)) {
      setIsMetaDataOpen(false);
    }
  };

  useEffect(() => {
    if (isMetaDataOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMetaDataOpen]);

  const renderSentimentIcon = (overallSentiment) => {
    // Icon properties based on sentiment
    const sentimentMapping = {
      high: {
        color: "text-success-700",
        bg: "bg-success-100",
        icon: "FeatherSmile",
      },
      low: {
        color: "text-error-700",
        bg: "bg-error-100",
        icon: "FeatherFrown",
      },
      neutral: {
        color: "text-warning-700",
        bg: "bg-warning-100",
        icon: "FeatherMeh",
      },
      "no-data": {
        color: "text-neutral-700",
        bg: "bg-neutral-100",
        icon: "FeatherAnnoyed",
      },
    };

    const sentiment =
      SENTIMENT[overallSentiment]?.value || SENTIMENT["No Data"]?.value;
    const { color, icon } = sentimentMapping[sentiment];

    return (
      <SubframeCore.Icon
        className={`${color} text-label font-label`}
        name={icon}
      />
    );
  };

  /**
   * Method to fetch the branch metadata.
   *
   * @param {String} name
   * @returns {Promise}
   */
  const fetchBranchMetadataAsync = async (name) => {
    try {
      return await fetchBranchMetadata(name);
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  // Fetch metadata when the popover is open
  const handleFetchBranchMetadata = async () => {
    if (isLoading) return;
    setIsLoading(true);

    const response = await fetchBranchMetadataAsync({ name });

    setIsLoading(false);

    if (response?.hasError) {
      setBranchMetadata({});
      return;
    }

    setBranchMetadata(response?.result?.data);
  };

  // needed because of how subframe is handling events ({passive: true} -> cannot edit inside subframe)
  useEffect(() => {
    const element = hoverCardRef.current;
    if (!element) return;

    const handleEvent = (e) => {
      e.stopPropagation();
      e.preventDefault();
      setIsMetaDataOpen(true);
      handleFetchBranchMetadata();
    };

    // prevent bubbling up of parent's click func
    const events = ["touchstart"];
    events.forEach((eventName) => {
      element.addEventListener(eventName, handleEvent, {
        passive: false,
        capture: true,
      });
    });

    // Cleanup
    return () => {
      events.forEach((eventName) => {
        element.removeEventListener(eventName, handleEvent);
      });
    };
  }, []);

  const handleOpenChange = (isOpen) => {
    if (!isOpen) {
      setIsMetaDataOpen(false);
      return;
    }
    setIsMetaDataOpen(true);
    handleFetchBranchMetadata();
  };

  /**
   * Navigate to the branch's details page.
   */
  const navigateToBranchDetails = (name) => {
    if (!isStringEmpty(name)) {
      window.open(`/retention/V2/branches/${name}`, "_blank");
    }
  };

  return (
    <div className="inline-flex touch-manipulation" ref={hoverCardRef}>
      <SubframeCore.HoverCard.Root
        open={isMetaDataOpen}
        onOpenChange={handleOpenChange}
      >
        <SubframeCore.HoverCard.Trigger asChild={true}>
          <div
            className="flex items-center justify-center gap-1 overflow-hidden pb-0.5 border-b border-dashed border-current cursor-pointer"
            style={{
              borderColor: SENTIMENT[overallSentiment]?.sentimentTextColor,
            }}
          >
            <span
              className="text-label-bold font-label-bold"
              style={{
                color: SENTIMENT[overallSentiment]?.sentimentTextColor,
              }}
            >
              {isStringEmpty(name) ? "-" : name}
            </span>
            {renderSentimentIcon(overallSentiment, responseRate)}
          </div>
        </SubframeCore.HoverCard.Trigger>
        <SubframeCore.HoverCard.Portal>
          <SubframeCore.HoverCard.Content
            side="bottom"
            align="start"
            sideOffset={4}
            asChild={true}
          >
            <div className="flex grow shrink-0 basis-0 flex-col items-start gap-2 rounded-md border border-solid border-neutral-border bg-default-background px-3 py-3 shadow-lg">
              <div className="flex w-full flex-col items-start gap-1">
                <div className="flex w-full items-center justify-between">
                  <span className="text-subheader font-subheader text-default-font">
                    {isStringEmpty(name) ? "-" : name}
                  </span>

                  <div
                    className="flex items-center justify-center gap-1 cursor-pointer group"
                    onMouseDown={() => navigateToBranchDetails(name)}
                  >
                    <SubframeCore.Icon
                      className="font-['Nunito'] text-[12px] font-[400] leading-[16px] text-subtext-color group-hover:text-default-font transition-colors duration-300"
                      name="FeatherExternalLink"
                    />
                    <span className="font-['Nunito'] text-[12px] font-[400] leading-[20px] text-subtext-color group-hover:text-default-font transition-colors duration-300">
                      View Details
                    </span>
                  </div>
                </div>
                <div className="flex items-center gap-1">
                  <SubframeCore.Icon
                    className="text-[12px] font-[400] leading-[16px] text-brand-700"
                    name="FeatherInfo"
                  />
                  <span className="text-[11px] font-[400] leading-[20px] text-brand-700">
                    Data reflects active employees only, excluding terminated
                    ones.
                  </span>
                </div>
              </div>
              <div className="flex h-px w-full flex-none flex-col items-center gap-2 bg-neutral-200" />
              <div className="flex w-full flex-col items-start gap-4">
                <div className="flex w-full items-start gap-2 sm:gap-4">
                  <div className="flex w-1/2 xs:w-48 flex-none flex-col items-start gap-1">
                    <span className="text-label-bold font-label-bold text-subtext-color">
                      No. of Employees
                    </span>
                    {isLoading ? (
                      <Skeleton variant="rounded" width="100%" height="35px" />
                    ) : (
                      <div className="flex items-center gap-1">
                        <span className="text-[14px] font-[700] leading-[20px] text-default-font">
                          {isNumber(branchMetadata?.total_branch_employees)
                            ? branchMetadata.total_branch_employees
                            : "-"}
                        </span>

                        {isNumber(
                          branchMetadata?.branch_employee_percentage
                        ) && (
                          <div className="flex items-center rounded-md bg-neutral-100 px-1 py-0.5">
                            <span className="text-[10px] font-[500] leading-[16px] text-default-font">
                              {branchMetadata.branch_employee_percentage}% of
                              total company
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="flex w-1/2 xs:w-48 flex-none flex-col items-start gap-1">
                    <span className="text-label-bold font-label-bold text-subtext-color">
                      No. of Escalated Employees
                    </span>
                    {isLoading ? (
                      <Skeleton variant="rounded" width="100%" height="35px" />
                    ) : (
                      <div className="flex items-center gap-1">
                        <span className="text-[14px] font-[700] leading-[20px] text-error-800">
                          {isNumber(branchMetadata?.total_escalated_employees)
                            ? branchMetadata.total_escalated_employees
                            : "-"}
                        </span>
                        {isNumber(
                          branchMetadata?.branch_escalated_percentage
                        ) && (
                          <div className="flex items-center rounded-md bg-neutral-100 px-1 py-0.5">
                            <span className="text-[10px] font-[500] leading-[16px] text-default-font">
                              {branchMetadata.branch_escalated_percentage}% of
                              total branch
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex w-full items-start gap-2 sm:gap-4">
                  <div className="flex w-1/2 xs:w-48 flex-none flex-col items-start gap-1">
                    <span className="text-label-bold font-label-bold text-subtext-color">
                      Overall Sentiment
                    </span>
                    {isLoading ? (
                      <Skeleton variant="rounded" width="100%" height="35px" />
                    ) : (
                      renderSentimentBadgeWithScore(
                        branchMetadata?.sentiment?.["Sentiment Score"],
                        branchMetadata?.sentiment?.["Overall Sentiment"],
                        branchMetadata?.sentiment?.["Response Rate"]
                      )
                    )}
                  </div>
                  <div className="flex w-1/2 xs:w-48 flex-none flex-col items-start gap-1">
                    <div className="flex w-32 items-start gap-8">
                      <span className="text-label-bold font-label-bold text-subtext-color">
                        Survey Response
                      </span>
                    </div>
                    {isLoading ? (
                      <Skeleton variant="rounded" width="100%" height="35px" />
                    ) : (
                      <div className="flex w-full items-center gap-2">
                        <div className="flex grow shrink-0 basis-0 flex-col items-start gap-2">
                          <span className="text-[10px] font-[500] leading-[16px] text-default-font">
                            {isNumber(
                              branchMetadata?.sentiment?.["Survey Completed"]
                            )
                              ? branchMetadata.sentiment["Survey Completed"]
                              : "-"}{" "}
                            Completed /{" "}
                            {isNumber(
                              branchMetadata?.sentiment?.["Survey Sent"]
                            )
                              ? branchMetadata.sentiment["Survey Sent"]
                              : "-"}{" "}
                            Sent{" "}
                            {isNumber(
                              branchMetadata?.sentiment?.["Response Rate"]
                            ) &&
                              ` (${branchMetadata?.sentiment?.["Response Rate"]}%)`}
                          </span>
                          <Progress
                            value={branchMetadata?.sentiment?.["Response Rate"]}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row w-full items-start gap-4">
                  <div className="flex w-48 flex-none flex-col items-start gap-1">
                    <span className="text-label-bold font-label-bold text-subtext-color">
                      Avg. Tenure
                    </span>
                    {isLoading ? (
                      <Skeleton variant="rounded" width="100%" height="35px" />
                    ) : (
                      <span className="text-[14px] font-[700] leading-[20px] text-default-font">
                        {isStringEmpty(branchMetadata?.average_tenure)
                          ? "-"
                          : branchMetadata.average_tenure}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </SubframeCore.HoverCard.Content>
        </SubframeCore.HoverCard.Portal>
      </SubframeCore.HoverCard.Root>
    </div>
  );
};

export default BranchWithMetadata;
