import { Divider } from "@mui/material";

import { SENTIMENT } from "modules/Retention/constants/constants";

import { isNumber } from "utils/number";

export default function SentimentBreakdown({ data = {} }) {
  return (
    <>
      <div className="flex w-full gap-2 items-center mb-2">
        <div
          style={{ color: SENTIMENT["Negative"]?.sentimentTextColor }}
          className="text-default-font text-label font-label"
        >
          {SENTIMENT["Negative"].label} :{" "}
          {isNumber(data?.["Negative"]) ? `${data["Negative"]}%` : "-"}
        </div>
        <Divider
          orientation="vertical"
          sx={{
            height: "8px",
          }}
        />
        <div
          style={{ color: SENTIMENT["Neutral"]?.sentimentTextColor }}
          className="text-default-font text-label font-label"
        >
          {SENTIMENT["Neutral"].label} :{" "}
          {isNumber(data?.["Neutral"]) ? `${data["Neutral"]}%` : "-"}
        </div>
        <Divider
          orientation="vertical"
          sx={{
            height: "8px",
          }}
        />
        <div
          style={{ color: SENTIMENT["Positive"]?.sentimentTextColor }}
          className="text-default-font text-label font-label"
        >
          {SENTIMENT["Positive"].label} :{" "}
          {isNumber(data?.["Positive"]) ? `${data["Positive"]}%` : "-"}
        </div>
      </div>
      <div className="flex-none h-4 w-full rounded-full gap-1 overflow-hidden items-center flex bg-neutral-50">
        <div
          style={{
            width: `${isNumber(data?.["Negative"]) ? data["Negative"] : 0}%`,
          }}
          className={`h-full items-center justify-center flex bg-error-100 ${
            data?.Negative === 0 ? "hidden" : ""
          }`}
        ></div>
        <div
          style={{
            width: `${isNumber(data?.["Neutral"]) ? data["Neutral"] : 0}%`,
          }}
          className={`h-full items-center justify-center flex bg-warning-100 ${
            data?.Neutral === 0 ? "hidden" : ""
          }`}
        ></div>
        <div
          style={{
            width: `${isNumber(data?.["Positive"]) ? data["Positive"] : 0}%`,
          }}
          className={`h-full items-center justify-center flex bg-success-100 ${
            data?.Positive === 0 ? "hidden" : ""
          }`}
        ></div>
      </div>
    </>
  );
}
