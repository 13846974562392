import config from "../../../config";

import { http, levoHttp } from "../../../utils/http";
import { create as createQueryParam } from "../../../utils/queryParam";

import endpoints from "../constants/endpoints";

/**
 * Method to login via mobile number.
 *
 * @param {Object} payload
 * @returns {Promise}
 */
export const login = (payload) => {
  const url = `${config.authBaseUrl}${endpoints.login}`;

  return http.post(url, payload);
};

/**
 * Method to verify OTP for login.
 *
 * @param {Object} payload
 * @returns {Promise}
 */
export const verifyOTP = (payload) => {
  const url = `${config.authBaseUrl}${endpoints.verifyOTP}`;

  return http.post(url, payload);
};

/**
 * Fetch the user info.
 *
 * @returns {Promise}
 */
export const fetchCaregiver = () => {
  const url = `${config.authBaseUrl}${endpoints.caregiver}`;

  return levoHttp.get(url);
};

/**
 * Fetch the logged in user's supervisor.
 *
 * @returns {Promise}
 */
export const fetchMySupervisor = () => {
  const url = `${config.authBaseUrl}${endpoints.mySupervisor}`;

  return levoHttp.get(url);
};

/**
 * Submit the recognition.
 *
 * @param {Object} payload
 * @returns {Promise}
 */
export const postRecognition = (payload = {}) => {
  const url = `${config.authBaseUrl}${endpoints.postRecognition}`;

  return levoHttp.post(url, payload);
};

/**
 * Fetch the logged in user's agency category.
 *
 * @returns {Promise}
 */
export const fetchCategories = () => {
  const url = `${config.authBaseUrl}${endpoints.recognitionCategory}`;

  return levoHttp.get(url);
};

/**
 * Fetch the Direct Reports with matching search text.
 *
 * @param {Object} query
 * @returns {Promise}
 */
export const fetchDirectReports = (query = {}) => {
  const param = createQueryParam(query);
  const url = `${config.authBaseUrl}${endpoints.directReports}${param}`;

  return levoHttp.get(url);
};

/**
 * Fetch the Teammates with matching search text.
 *
 * @param {Object} query
 * @returns {Promise}
 */
export const fetchTeammates = (query = {}) => {
  const param = createQueryParam(query);
  const url = `${config.authBaseUrl}${endpoints.peers}${param}`;

  return levoHttp.get(url);
};

/**
 * Fetch the provided recognitions.
 *
 * @param {Object} query
 * @returns {Promise}
 */
export const fetchProvidedRecognitions = (query = {}) => {
  const param = createQueryParam(query);
  const url = `${config.authBaseUrl}${endpoints.providedRecognitions}${param}`;

  return levoHttp.get(url);
};

/**
 * Fetch the received recognitions.
 *
 * @param {Object} query
 * @returns {Promise}
 */
export const fetchReceivedRecognitions = (query = {}) => {
  const param = createQueryParam(query);
  const url = `${config.authBaseUrl}${endpoints.receivedRecognitions}${param}`;

  return levoHttp.get(url);
};
