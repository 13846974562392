import { DataGrid } from "@mui/x-data-grid";

export default function RosterGridView({
  rowCount,
  rows = [],
  columns = [],
  paginationModel,
  isLoading = false,
  setRosterPaginationModel,
  viewEmployeeProfile = () => {},
  columnGroupingModel,
}) {
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <DataGrid
        rows={rows}
        columnGroupingModel={columnGroupingModel}
        experimentalFeatures={{ columnGrouping: true }}
        rowCount={rowCount}
        loading={isLoading}
        rowsPerPageOptions={[20, 50, 100]}
        pagination
        page={paginationModel?.page}
        pageSize={paginationModel?.pageSize}
        paginationMode="server"
        onPageChange={(newPage) =>
          setRosterPaginationModel((old) => ({ ...old, page: newPage }))
        }
        onPageSizeChange={(newPageSize) =>
          setRosterPaginationModel((old) => ({ ...old, pageSize: newPageSize }))
        }
        columns={columns}
        disableSelectionOnClick
        onRowClick={(params) => {
          viewEmployeeProfile(params?.row?.profile?.employee_id);
        }}
        sx={{
          "& .MuiDataGrid-renderingZone": {
            maxHeight: "none !important",
          },
          "& .MuiDataGrid-cell": {
            lineHeight: "unset !important",
            maxHeight: "none !important",
            whiteSpace: "normal",
            paddingTop: "8px",
            paddingBottom: "8px",
          },
          "& .MuiDataGrid-row": {
            maxHeight: "none !important",
          },
          fontFamily: "Nunito",
          color: "#171717",
        }}
      />
    </div>
  );
}
