import {Autocomplete, Box, Chip, createTheme, Snackbar, TextField, ThemeProvider} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import * as SubframeCore from "@subframe/core";
import React, {useState} from "react";
import {useParams, useSearchParams} from "react-router-dom";

import {ESCALATION_FOLLOW_UP_OUTCOME} from "modules/Escalation/constants/constants";

import {TextArea} from "subframe";
import {Button} from "subframe/components/Button";
import {Avatar} from "subframe/components/Avatar";
import {RadioCardGroup} from "subframe/components/RadioCardGroup";

import {getAcronym, isEmpty as isStringEmpty} from "../../../../utils/string";
import {isEmpty as isArrayEmpty} from "utils/array";

import {
  followUpEscalation,
} from "modules/Escalation/services/escalation";

import "./EscalationFollowUp.css";
import {IconWithBackground} from "subframe";
import {ESCALATION_FOLLOW_UP_NOTE_TAGS, ESCALATION_FOLLOW_UP_TAGS} from "modules/Retention/constants/constants";

function EscalationFollowUp() {
  const {id: escalationId} = useParams();
  const [searchParams] = useSearchParams();

  const [notes, setNotes] = useState("");
  const [outcome, setOutcome] = useState(
    ESCALATION_FOLLOW_UP_OUTCOME.ATTENTION_NEEDED.value
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [employeeId] = useState(+searchParams.get("employee_id"));
  const [jobTitle] = useState(searchParams.get("job_title") || "");
  const [supervisor] = useState(searchParams.get("supervisor") || "");
  const [isErrorMessageShown, setIsErrorMessageShown] = useState(false);
  const [phoneNumber] = useState(searchParams.get("phone_number") || "");
  const [employeeName] = useState(searchParams.get("employee_name") || "");
  const [isSuccessMessageShown, setIsSuccessMessageShown] = useState(false);
  const [hasTriedFormSubmission, setHasTriedFormSubmission] = useState(false);
  const [officeLocation] = useState(searchParams.get("office_location") || "");
  const [selectedStatusTags, setSelectedStatusTags] = useState([]);
  const [selectedNoteTags, setSelectedNoteTags] = useState([]);

  // check for status state validation
  const isStatusFieldValid = outcome === ESCALATION_FOLLOW_UP_OUTCOME.ATTENTION_NEEDED.value
    ? !isArrayEmpty(selectedStatusTags)
    : true;

  const isFormValid = !isStringEmpty(outcome) && !isStringEmpty(notes) && isStatusFieldValid;

  const resetForm = () => {
    setOutcome(ESCALATION_FOLLOW_UP_OUTCOME.ATTENTION_NEEDED.value);
    setNotes("");
    setHasTriedFormSubmission(false);
    setSelectedNoteTags([]);
    setSelectedStatusTags([]);
  };

  const theme = createTheme({
    typography: {
      allVariants: {
        fontFamily: "Nunito",
        fontSize: "13px",
        lineHeight: "16px",
        fontWeight: 400,
        color: "#171717",
      },
    },
  });

  /**
   * Submits the escalation follow-up form.
   *
   * @param {Object} payload
   * @returns {Promise}
   */
  const submitEscalationFollowUpAsync = async (payload) => {
    try {
      return await followUpEscalation(payload);
    } catch (error) {
      return {hasError: true, error};
    }
  };

  /**
   * Handles the submission of the escalation follow-up, creates escalation follow up.
   */
  const handleFormSubmit = async () => {
    setHasTriedFormSubmission(true);

    if (!isFormValid) {
      return;
    }

    setIsSubmitting(true);
    const response = await submitEscalationFollowUpAsync({
      escalation_id: +escalationId,
      employee_id: employeeId,
      outcome,
      notes,
      ...(outcome === ESCALATION_FOLLOW_UP_OUTCOME.ATTENTION_NEEDED.value && {
        tags: (selectedStatusTags ?? []).map(
          (selectedStatusTag) => selectedStatusTag.value
        ),
      }),
      note_tags: (selectedNoteTags ?? []).map(
        (selectedNoteTag) => selectedNoteTag.value
      ),
    });

    setIsSubmitting(false);

    if (response?.hasError) {
      setErrorMessage(
        `Error while updating: ${response?.error?.response?.data?.message}`
      );
      setIsErrorMessageShown(true);

      return;
    }

    resetForm();
    setSuccessMessage("Successfully updated follow-up.");
    setIsSuccessMessageShown(true);
  };

  /**
   * Renders the error snackbar.
   *
   * @returns {Component}
   */
  const renderErrorSnackbar = () => (
    <Snackbar
      open={isErrorMessageShown}
      autoHideDuration={6000}
      onClose={() => setIsErrorMessageShown(false)}
    >
      <MuiAlert
        onClose={() => setIsErrorMessageShown(false)}
        severity="error"
        sx={{width: "100%"}}
      >
        {errorMessage}
      </MuiAlert>
    </Snackbar>
  );

  /**
   * Renders the success snackbar.
   *
   * @returns {Component}
   */
  const renderSuccessSnackbar = () => (
    <Snackbar
      open={isSuccessMessageShown}
      autoHideDuration={6000}
      onClose={() => setIsSuccessMessageShown(false)}
    >
      <MuiAlert
        onClose={() => setIsSuccessMessageShown(false)}
        severity="success"
        sx={{width: "100%"}}
      >
        {successMessage}
      </MuiAlert>
    </Snackbar>
  );

  return (
    <div className="follow-up-outer-wrapper">
      <div className="shrink-0 flex-col gap-6 pt-6 pr-12 pb-6 pl-12 items-start flex follow-up-main-wrapper">
        <div className="w-full gap-2 items-center flex">
          <SubframeCore.Icon
            className="text-brand-700 text-section-header font-section-header"
            name="FeatherMessagesSquare"
          />
          <span className="grow shrink-0 basis-0 w-full text-default-font text-subheader font-subheader">
            Log follow-up
          </span>
        </div>
        <div className="w-full rounded-xl flex-col gap-6 pt-6 pr-6 pb-6 pl-6 items-start flex bg-brand-50">
          <div className="w-full gap-2 items-center flex">
            <Avatar>{getAcronym(employeeName)}</Avatar>
            <span className="grow shrink-0 basis-0 w-full text-default-font text-section-header font-section-header">
              {employeeName}
            </span>
          </div>
          <div className="w-full gap-9 items-start flex">
            <div className="items-start basis-0 w-full gap-1 flex grow shrink-0">
              <SubframeCore.Icon
                className="text-subtext-color text-label-bold font-label-bold"
                name="FeatherBriefcase"
              />
              <div className="flex flex-col gap-0.5">
                <span className="text-subtext-color text-label-bold font-label-bold leading-none">
                  Job Title
                </span>
                <span className="text-default-font text-body-bold font-body-bold">
                  {jobTitle}
                </span>
              </div>
            </div>
            <div className="items-start basis-0 w-full gap-1 flex grow shrink-0">
              <SubframeCore.Icon
                className="text-subtext-color text-label-bold font-label-bold"
                name="FeatherMapPin"
              />
              <div className="flex flex-col gap-0.5">
                <span className="text-subtext-color text-label-bold font-label-bold leading-none">
                  Location
                </span>
                <span className="text-default-font text-body-bold font-body-bold">
                  {officeLocation}
                </span>
              </div>
            </div>
            <div className="items-start basis-0 w-full gap-1 flex grow shrink-0">
              <SubframeCore.Icon
                className="text-subtext-color text-label-bold font-label-bold"
                name="FeatherPhone"
              />
              <div className="flex flex-col gap-0.5">
                <span className="text-subtext-color text-label-bold font-label-bold leading-none">
                  Phone Number
                </span>
                <span className="text-default-font text-body-bold font-body-bold">
                  {phoneNumber}
                </span>
              </div>
            </div>
            <div className="items-start basis-0 w-full gap-1 flex grow shrink-0">
              <SubframeCore.Icon
                className="text-subtext-color text-label-bold font-label-bold"
                name="FeatherUser"
              />
              <div className="flex flex-col gap-0.5">
                <span className="text-subtext-color text-label-bold font-label-bold leading-none">
                  Supervisor
                </span>
                <span className="text-default-font text-body-bold font-body-bold">
                  {supervisor}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex-col gap-8 pt-2 pr-2 pb-2 pl-2 items-start flex">
          <div className="w-full flex-col gap-2 items-start flex">
            <span className="text-default-font text-body-bold font-body-bold">
              Follow-up outcome
            </span>
            <RadioCardGroup
              onValueChange={(value) => setOutcome(value)}
              className="w-full"
            >
              <div className="grow shrink-0 basis-0 w-full flex-col gap-2 items-start flex">
                {Object.keys(ESCALATION_FOLLOW_UP_OUTCOME)?.map(
                  (outcomeKey) => {
                    return (
                      <RadioCardGroup.RadioCard
                        className="flex-none h-auto w-full"
                        checked={
                          outcome ===
                          ESCALATION_FOLLOW_UP_OUTCOME[outcomeKey].value
                        }
                        label={ESCALATION_FOLLOW_UP_OUTCOME[outcomeKey].label}
                        icon={ESCALATION_FOLLOW_UP_OUTCOME[outcomeKey].icon}
                        value={ESCALATION_FOLLOW_UP_OUTCOME[outcomeKey].value}
                      >
                        <div className="gap-2 pr-2 items-center flex">
                          {
                            <IconWithBackground
                              size="Large"
                              icon={
                                ESCALATION_FOLLOW_UP_OUTCOME[outcomeKey].icon
                              }
                            />
                          }
                          <span className="text-default-font text-body-bold font-body-bold">
                            {ESCALATION_FOLLOW_UP_OUTCOME[outcomeKey].label}
                          </span>
                        </div>
                      </RadioCardGroup.RadioCard>
                    );
                  }
                )}
              </div>
            </RadioCardGroup>
          </div>
          <div className="w-full flex-col gap-2 items-start flex">
            <span className="text-default-font text-body-bold font-body-bold">
              Notes
            </span>
            <TextArea
              onChange={(event) => {
                setNotes(event?.target?.value);
              }}
              className="flex-none h-auto w-full"
              label=""
              helpText={
                hasTriedFormSubmission && isStringEmpty(notes)
                  ? "Please write in a note"
                  : ""
              }
              error={hasTriedFormSubmission && isStringEmpty(notes)}
            >
              <TextArea.Input
                value={notes}
                placeholder="Add follow-up notes..."
              />
            </TextArea>
          </div>
          {outcome === ESCALATION_FOLLOW_UP_OUTCOME.ATTENTION_NEEDED.value && (
            <div className="w-full mt-1">
              <ThemeProvider theme={theme}>
                <Autocomplete
                  multiple
                  size="small"
                  disablePortal
                  id="status-tags"
                  value={selectedStatusTags}
                  renderTags={(value, getTagProps) =>
                    value?.map((option, index) => (
                      <Chip
                        size="small"
                        key={index}
                        label={option.label}
                        {...getTagProps({index})}
                        sx={{
                          fontSize: "12px",
                          fontFamily: "Nunito",
                          color: option.color.text,
                          backgroundColor: option.color.fill,
                        }}
                      />
                    ))
                  }
                  options={Object.values(ESCALATION_FOLLOW_UP_TAGS).map(
                    (tag) => ({
                      label: tag.label,
                      value: tag.value,
                      color: tag.color,
                    })
                  )}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Status"
                      error={hasTriedFormSubmission && !isStatusFieldValid}
                      helperText={
                        hasTriedFormSubmission && !isStatusFieldValid
                          ? "Please choose one status"
                          : ""
                      }
                    />
                  )}
                  renderOption={(params, option) => {
                    return (
                      <Box
                        key={option.value}
                        {...params}
                        sx={{
                          color: option.color.text,
                        }}
                      >
                        {option.label}
                      </Box>
                    );
                  }}
                  onChange={(_, newValue) => {
                    if (newValue?.length > 1) {
                      newValue = newValue.slice(-1); // Keep only the last selected value
                    }
                    setSelectedStatusTags(newValue);
                  }}
                />
              </ThemeProvider>
            </div>
          )}
          <div className="w-full mt-1">
            <ThemeProvider theme={theme}>
              <Autocomplete
                multiple
                size="small"
                disablePortal
                id="note-tags"
                value={selectedNoteTags}
                renderTags={(value, getTagProps) =>
                  value?.map((option, index) => (
                    <Chip
                      size="small"
                      key={index}
                      label={option.label}
                      {...getTagProps({index})}
                      sx={{
                        fontSize: "12px",
                        fontFamily: "Nunito",
                        color: option.color.text,
                        backgroundColor: option.color.fill,
                      }}
                    />
                  ))
                }
                options={Object.values(ESCALATION_FOLLOW_UP_NOTE_TAGS).map(
                  (tag) => ({
                    label: tag.label,
                    value: tag.value,
                    color: tag.color,
                  })
                )}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" label="Tags"/>
                )}
                renderOption={(params, option) => {
                  return (
                    <Box
                      key={option.value}
                      {...params}
                      sx={{
                        color: option.color.text,
                      }}
                    >
                      {option.label}
                    </Box>
                  );
                }}
                onChange={(_, newValue) => {
                  if (newValue?.length > 2) {
                    // Keep only the first and last selected values, removing the middle one.
                    // We restrict to at-most two tags for a note.
                    newValue = [newValue[0], newValue.slice(-1)[0]];
                  }
                  setSelectedNoteTags(newValue);
                }}
              />
            </ThemeProvider>
          </div>

        </div>
        <div className="w-full gap-2 items-center justify-end flex">
          {/* <Button variant="Neutral Tertiary" icon={null} rightIcon={null}>
            Skip
          </Button> */}
          <Button
            disabled={isSubmitting}
            loading={isSubmitting}
            onClick={handleFormSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
      {renderErrorSnackbar()}
      {renderSuccessSnackbar()}
    </div>
  );
}

export default EscalationFollowUp;
