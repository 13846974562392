import Face5Icon from "@mui/icons-material/Face5";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import SentimentNeutralIcon from "@mui/icons-material/SentimentNeutral";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";

export const SENTIMENT = {
  Positive: {
    label: "Positive",
    value: "Positive",
    color: "var(--md-sys-color-positive)",
    icon: (
      <SentimentSatisfiedAltIcon
        sx={{
          width: "16px",
          height: "16px",
          color: "var(--md-sys-color-positive)",
        }}
      />
    ),
  },
  "Strongly Positive": {
    label: "Strongly Positive",
    value: "Strongly Positive",
    color: "var(--md-sys-color-strongly-positive)",
    icon: (
      <SentimentVerySatisfiedIcon
        sx={{
          width: "16px",
          height: "16px",
          color: "var(--md-sys-color-strongly-positive)",
        }}
      />
    ),
  },
  Negative: {
    label: "Negative",
    value: "Negative",
    color: "var(--md-sys-color-negative)",
    icon: (
      <SentimentVeryDissatisfiedIcon
        sx={{
          width: "16px",
          height: "16px",
          color: "var(--md-sys-color-negative)",
        }}
      />
    ),
  },
  "Strongly Negative": {
    label: "Strongly Negative",
    value: "Strongly Negative",
    color: "var(--md-sys-color-strongly-negative)",
    icon: (
      <WarningAmberIcon
        sx={{
          width: "16px",
          height: "16px",
          color: "var(--md-sys-color-strongly-negative)",
        }}
      />
    ),
  },
  Neutral: {
    label: "Neutral",
    value: "Neutral",
    color: "var(--md-sys-color-neutral)",
    icon: (
      <SentimentNeutralIcon
        sx={{
          width: "16px",
          height: "16px",
          color: "var(--md-sys-color-neutral)",
        }}
      />
    ),
  },
  "No Data": {
    label: "No Data",
    value: "No Data",
    color: "var(--md-sys-color-fallback)",
    icon: (
      <Face5Icon
        sx={{
          width: "16px",
          height: "16px",
          color: "var(--md-sys-color-fallback)",
        }}
      />
    ),
  },
  Undetermined: {
    label: "Not Enough Data",
    value: "Undetermined",
    color: "var(--md-sys-color-fallback)",
    icon: (
      <ErrorOutlineIcon
        sx={{
          width: "16px",
          height: "16px",
          color: "var(--md-sys-color-fallback)",
        }}
      />
    ),
  },
};
