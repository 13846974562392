"use client";
/*
 * Documentation:
 * Profile Detail Item — https://app.subframe.com/library?component=Profile+Detail+Item_8e9dfadf-1aba-4188-aaae-e694c3b0d82a
 */

import React from "react";
import * as SubframeCore from "@subframe/core";

interface ProfileDetailItemRootProps
  extends React.HTMLAttributes<HTMLDivElement> {
  variant?: "icon" | "label";
  icon?: SubframeCore.IconName;
  label?: string;
  value?: string;
  className?: string;
}

const ProfileDetailItemRoot = React.forwardRef<
  HTMLElement,
  ProfileDetailItemRootProps
>(function ProfileDetailItemRoot(
  {
    variant = "icon",
    icon = "FeatherPlus",
    label,
    value,
    className,
    ...otherProps
  }: ProfileDetailItemRootProps,
  ref
) {
  return (
    <div
      className={SubframeCore.twClassNames(
        "group/8e9dfadf flex w-full items-center gap-2",
        {
          "h-auto w-full grow shrink-0 basis-0 flex-row gap-4 pt-0 pr-0 pb-0 pl-3":
            variant === "label",
        },
        className
      )}
      ref={ref as any}
      {...otherProps}
    >
      <SubframeCore.Icon
        className={SubframeCore.twClassNames(
          "text-body font-body text-neutral-400",
          {
            "inline-flex text-section-header font-section-header":
              variant === "label",
          }
        )}
        name={icon}
      />
      <div
        className={SubframeCore.twClassNames(
          "flex w-full grow shrink-0 basis-0 flex-col items-start gap-2",
          { "flex-col gap-0": variant === "label" }
        )}
      >
        {label ? (
          <span
            className={SubframeCore.twClassNames(
              "line-clamp-1 hidden text-body font-body text-default-font",
              {
                "inline h-auto w-full flex-none text-label font-label text-subtext-color":
                  variant === "label",
              }
            )}
          >
            {label}
          </span>
        ) : null}
        {value ? (
          <span
            className={SubframeCore.twClassNames(
              "break-all text-label font-label text-subtext-color",
              {
                "h-auto w-full flex-none text-body font-body text-default-font":
                  variant === "label",
              }
            )}
          >
            {value}
          </span>
        ) : null}
      </div>
    </div>
  );
});

export const ProfileDetailItem = ProfileDetailItemRoot;
