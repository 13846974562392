"use client";
/*
 * Documentation:
 * Custom Badge — https://app.subframe.com/library?component=Custom+Badge_6c4fd648-485b-4df3-b96e-19a465da6db8
 */

import React from "react";
import * as SubframeCore from "@subframe/core";

interface CustomBadgeRootProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: "brand" | "neutral" | "error" | "warning" | "success" | "alert";
  children?: string;
  icon?: SubframeCore.IconName;
  className?: string;
}

const CustomBadgeRoot = React.forwardRef<HTMLElement, CustomBadgeRootProps>(
  function CustomBadgeRoot(
    {
      variant = "brand",
      children,
      icon = null,
      className,
      ...otherProps
    }: CustomBadgeRootProps,
    ref
  ) {
    return (
      <div
        className={SubframeCore.twClassNames(
          "group/6c4fd648 flex h-6 items-center gap-1 rounded-full bg-brand-100 p-4 md:pr-3 md:pl-3",
          {
            "bg-[#fee9c3ff]": variant === "alert",
            "bg-success-100": variant === "success",
            "bg-warning-100": variant === "warning",
            "bg-error-100": variant === "error",
            "bg-neutral-100": variant === "neutral",
          },
          className
        )}
        ref={ref as any}
        {...otherProps}
      >
        <SubframeCore.Icon
          className={SubframeCore.twClassNames(
            "text-label font-label text-brand-800",
            {
              "text-[#852b0eff]": variant === "alert",
              "text-success-800": variant === "success",
              "text-warning-800": variant === "warning",
              "text-error-800": variant === "error",
              "text-neutral-800": variant === "neutral",
            }
          )}
          name={icon}
        />
        {children ? (
          <span
            className={SubframeCore.twClassNames(
              "text-label font-label text-brand-800",
              {
                "text-[#852b0eff]": variant === "alert",
                "text-success-800": variant === "success",
                "text-warning-800": variant === "warning",
                "text-error-800": variant === "error",
                "text-neutral-800": variant === "neutral",
              }
            )}
          >
            {children}
          </span>
        ) : null}
      </div>
    );
  }
);

export const CustomBadge = CustomBadgeRoot;
