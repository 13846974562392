import {
  Input,
  Avatar,
  Divider,
  IconButton,
  InputAdornment,
  LinearProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";

import "./SearchUser.css";

import { isEmpty as isArrayEmpty } from "../../utils/array";
import { getAcronym, isEmpty as isStringEmpty } from "../../utils/string";

import usersSvg from "../../assets/images/users.svg";

export default function SearchUser({
  isLoading,
  users = [],
  searchValue,
  close = () => {},
  search = () => {},
  viewDetails = () => {},
}) {
  /**
   * Gets the selected user's full name.
   *
   * @param {String} firstName
   * @param {String} lastName
   * @returns {String}
   */
  const getFullName = (firstName = "", lastName = "") => {
    return `${isStringEmpty(firstName) ? "" : firstName}${
      !isStringEmpty(firstName) && !isStringEmpty(lastName) ? " " : ""
    }${isStringEmpty(lastName) ? "" : lastName}`;
  };

  /**
   * Renders the user card from the list of users.
   *
   * @param {Array} users
   * @returns {Component}
   */
  const renderUsersList = (users = []) => {
    return (
      <div className="users-list-wrapper">
        {users.map((user) => {
          return (
            <div className="user-list" onClick={() => viewDetails(user?.id)}>
              <Avatar
                alt={getFullName(
                  user?.caregiver?.first_name,
                  user?.caregiver?.last_name
                )}
                sx={{ width: 36, height: 36, marginRight: "8px" }}
              >
                {getAcronym(
                  getFullName(
                    user?.caregiver?.first_name,
                    user?.caregiver?.last_name
                  )
                )}
              </Avatar>
              {getFullName(
                user?.caregiver?.first_name,
                user?.caregiver?.last_name
              )}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="search-user-main-wrapper">
      <div className="search-user-header-wrapper">
        <div className="search-user-header-right-wrapper">
          <Input
            id="input-with-icon-adornment"
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
            disableUnderline
            placeholder="Search..."
            onChange={(event) => search(event?.target?.value)}
            value={searchValue}
            autoComplete="off"
          />
          <IconButton
            aria-label="more"
            id="close-button"
            title="Close"
            sx={{
              width: 28,
              height: 28,
            }}
            onClick={close}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <Divider />
      {isLoading && <LinearProgress />}
      <div className="search-user-body-wrapper">
        {!isLoading &&
          (isArrayEmpty(users) ? (
            <div className="empty-wrapper">
              <img src={usersSvg} alt="users" />
              <h4>No Search Results.</h4>
            </div>
          ) : (
            <>
              <div className="title">Users ({users.length})</div>
              {renderUsersList(users)}
            </>
          ))}
      </div>
    </div>
  );
}
