import { useEffect } from "react";
import { useParams} from "react-router-dom";

import { fetchSurveyLink } from "modules/Survey/services/survey";


function AudioSurveyInterface() {
  const { id } = useParams();

  useEffect(()=> {
    window.location.replace(fetchSurveyLink(id));
  }, [id]);

  return (
    <>
      <iframe
        allow="microphone"
        allowFullScreen
        src={fetchSurveyLink()}
        style={{width: "100%", height: "100%", border: "None"}}
        title="Audio Survey Interview"
      ></iframe>
    </>
  );
}

export default AudioSurveyInterface;
