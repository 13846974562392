import { useUpdateEffect } from "react-use";
import { useNavigate } from "react-router-dom";
import React, { useCallback, useState } from "react";

import { debounce } from "lodash";

import { ERROR_CODE } from "constants/constants";

import { findEmployee } from "services/retention/employees";

import { mixpanelTrackEvent } from "services/mixpanel";
import { EVENT_TYPES } from "constants/mixpanel";

import SearchBarV2 from "../SearchBarV2";
import EmployeeSearchResultItemV2 from "../EmployeeSearchResultItemV2";

import { isEmpty as isStringEmpty } from "utils/string";

export default function EmployeesSearchBarV2({
  autoFocus,
  listBoxProps,
  isNoSearchResultsYetShown,
  onEmployeeClick,
}) {
  const navigate = useNavigate();

  const [employees, setEmployees] = useState([]);
  const [searchEmployeeValue, setSearchEmployeeValue] = useState("");
  const [isFetchingEmployees, setIsFetchingEmployees] = useState(false);

  useUpdateEffect(() => {
    onSearchEmployeeValueChange();
  }, [searchEmployeeValue]);

  /**
   * Fetches the list of filtered employees.
   *
   * @param {String} name
   * @returns {Promise}
   */
  const fetchEmployeesAsync = async (name = "") => {
    try {
      return await findEmployee({
        employee_name: name,
      });
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  /**
   * Handles the fetching of the employees.
   *
   * @param {String} name
   */
  const handleFetchEmployees = async (name = "") => {
    setIsFetchingEmployees(true);
    const response = await fetchEmployeesAsync(name);

    setIsFetchingEmployees(false);

    if (response?.hasError) {
      setEmployees([]);

      return;
    }

    setEmployees(response?.result?.employees);

    mixpanelTrackEvent(EVENT_TYPES.EMPLOYEE_SEARCH, {
      input: searchEmployeeValue,
    });
  };

  /**
   * Debounced employees fetching on typing user search value.
   */
  const debouncedFetchEmployees = useCallback(
    debounce(handleFetchEmployees, 500),
    []
  );

  /**
   * Navigate to the selected employee's profile.
   */
  const navigateToEmployeeProfile = (id) => {
    if (!isNaN(id)) {
      window.open(`/retention/V2/employees/${id}`, "_blank");
    }
  };

  const onSearchEmployeeValueChange = () => {
    if (isStringEmpty(searchEmployeeValue)) {
      setEmployees([]);

      return;
    }

    if (!isFetchingEmployees) {
      setIsFetchingEmployees(true);
    }

    debouncedFetchEmployees(searchEmployeeValue);
  };

  return (
    <SearchBarV2
      results={employees}
      isLoading={isFetchingEmployees}
      onFilter={setSearchEmployeeValue}
      placeholder="Search for a person..."
      isNoSearchResultsYetShown={isNoSearchResultsYetShown}
      autoFocus={autoFocus}
      listBoxProps={listBoxProps}
      renderOption={(employee) => (
        <EmployeeSearchResultItemV2
          {...employee}
          onClick={
            onEmployeeClick
              ? onEmployeeClick
              : (employee) => navigateToEmployeeProfile(employee?.id)
          }
        />
      )}
    />
  );
}
