import { get } from "lodash";

import { SECTIONS_MAPPER, STATUS_MAPPER } from "../../constants/constants";

import TaskGridView from "./TaskGridView";

export default function TaskGrid({
  rows = [],
  columns = [],
  isLoading = false,
  viewUserProfile = () => {},
}) {
  const dataMapper = (key, row) => {
    switch (key) {
      case "section":
        return {
          value: get(row, key),
          label: SECTIONS_MAPPER[get(row, key)]?.label,
          color: SECTIONS_MAPPER[get(row, key)]?.color,
        };
      case "status": {
        return {
          value: get(row, key),
          label: STATUS_MAPPER[get(row, key)]?.label,
          color: STATUS_MAPPER[get(row, key)]?.color,
        };
      }
      default:
        return get(row, key);
    }
  };

  const arrangeData = (rows = [], columns = []) => {
    return rows.map((row) => {
      let arrangedRow = {
        id: row.id,
      };

      columns.forEach((column) => {
        arrangedRow = {
          ...arrangedRow,
          [column["field"]]: dataMapper(column["field"], row),
        };
      });

      return arrangedRow;
    });
  };

  return (
    <TaskGridView
      columns={columns}
      isLoading={isLoading}
      rows={arrangeData(rows, columns)}
      viewUserProfile={viewUserProfile}
    />
  );
}
