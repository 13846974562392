import * as SubframeCore from "@subframe/core";

export const INBOX_DATE_FILTER_OPTIONS = {
  "Last 7 days": {
    value: 7,
    label: "Last 7 days",
  },
  "Last 30 days": {
    value: 30,
    label: "Last 30 days",
  },
  "All time": {
    value: -1,
    label: "All time",
  },
};

export const INBOX_EMPLOYEE_STATUS_FILTER_OPTIONS = {
  Active: {
    value: "Active",
    label: "Active",
  },
  Terminated: {
    value: "Closed",
    label: "Terminated",
  },
  "All Status": {
    value: null,
    label: "All Status",
  },
};

export const ROSTER_DATE_FILTER_OPTIONS = {
  "Last 7 days": {
    value: 7,
    label: "Last 7 days",
  },
  "Last 30 days": {
    value: 30,
    label: "Last 30 days",
  },
};

export const REPORTS_DATE_FILTER_OPTIONS = {
  "Last 7 days": {
    value: 7,
    label: "Last 7 days",
  },
  "Last 30 days": {
    value: 30,
    label: "Last 30 days",
  },
};

export const SENTIMENT = {
  Positive: {
    value: "high",
    icon: (
      <SubframeCore.Icon
        className="text-success-700 text-section-header font-section-header"
        name="FeatherSmile"
      />
    ),
    label: "High",
    avatarVariant: "success",
    sentimentBackgroundColor: "#dcfce7",
    sentimentTextColor: "#15803d",
  },
  Neutral: {
    value: "neutral",
    icon: (
      <SubframeCore.Icon
        className="text-neutral-700 text-section-header font-section-header"
        name="FeatherMeh"
      />
    ),
    label: "Neutral",
    avatarVariant: "warning",
    sentimentBackgroundColor: "#fef9c3",
    sentimentTextColor: "#a16207",
  },
  Negative: {
    value: "low",
    icon: (
      <SubframeCore.Icon
        className="text-error-700 text-section-header font-section-header"
        name="FeatherFrown"
      />
    ),
    label: "Low",
    avatarVariant: "error",
    sentimentBackgroundColor: "#fee2e2",
    sentimentTextColor: "#b91c1c",
  },
  "No Data": {
    value: "no-data",
    label: "No Data",
    icon: <></>, //TODO: Define the icon after subframe adds the No Data icon.
    avatarVariant: "neutral",
    sentimentBackgroundColor: "#f5f5f5",
    sentimentTextColor: "#404040",
  },
};

const INBOX_SORT_TYPE = {
  date: "date",
  sentiment: "sentiment",
};

export const SORT_BY_OPTIONS = {
  "Oldest to Newest": {
    value: 1,
    label: "Oldest to Newest",
    type: INBOX_SORT_TYPE.date,
  },
  "Newest to Oldest": {
    value: -1,
    label: "Newest to Oldest",
    type: INBOX_SORT_TYPE.date,
  },
  "Sentiment Highest to Lowest": {
    value: -1,
    label: "Sentiment Highest to Lowest",
    type: INBOX_SORT_TYPE.sentiment,
  },
  "Sentiment Lowest to Highest": {
    value: 1,
    label: "Sentiment Lowest to Highest",
    type: INBOX_SORT_TYPE.sentiment,
  },
};

export const INBOX_SORT_MAPPER = {
  overdue: {
    [INBOX_SORT_TYPE.date]: "escalated_on",
    [INBOX_SORT_TYPE.sentiment]: "sentiment_score",
  },
  new: {
    [INBOX_SORT_TYPE.date]: "escalated_on",
    [INBOX_SORT_TYPE.sentiment]: "sentiment_score",
  },
  inProgress: {
    [INBOX_SORT_TYPE.date]: "in_progress_on",
    [INBOX_SORT_TYPE.sentiment]: "sentiment_score",
  },
  resolved: {
    [INBOX_SORT_TYPE.date]: "resolved_on",
    [INBOX_SORT_TYPE.sentiment]: "sentiment_score",
  },
  dismissed: {
    [INBOX_SORT_TYPE.date]: "dismissed_on",
    [INBOX_SORT_TYPE.sentiment]: "sentiment_score",
  },
};

export const INBOX_ESCALATION_TYPE = {
  AT_RISK: "AT_RISK",
  RESOLVED: "RESOLVED",
  DISMISSED: "DISMISSED",
  IN_PROGRESS: "IN_PROGRESS",
};

export const HISTORY_TYPE = {
  HIRED: {
    icon: (
      <SubframeCore.Icon
        className="text-neutral-600 text-body-bold font-body-bold"
        name="FeatherBuilding"
      />
    ),
    value: "HIRED",
    background: "bg-neutral-100",
  },
  SURVEY_COMPLETED: {
    icon: (
      <SubframeCore.Icon
        className="text-brand-600 text-body-bold font-body-bold"
        name="FeatherCheckCircle"
      />
    ),
    value: "SURVEY_COMPLETED",
    background: "bg-brand-100",
    showSentiment: true,
  },
  SURVEY_INCOMPLETE: {
    icon: (
      <SubframeCore.Icon
        className="text-error-600 text-body-bold font-body-bold"
        name="FeatherAlertCircle"
      />
    ),
    value: "SURVEY_INCOMPLETE",
    background: "bg-error-100",
  },
  ESCALATION_RAISED: {
    icon: (
      <SubframeCore.Icon
        className="text-error-600 text-body-bold font-body-bold"
        name="FeatherAlertTriangle"
      />
    ),
    value: "ESCALATION_RAISED",
    background: "bg-error-100",
  },
  ESCALATION_FOLLOWUP: {
    icon: (
      <SubframeCore.Icon
        className="text-brand-600 text-body-bold font-body-bold"
        name="FeatherMessageSquarePlus"
      />
    ),
    value: "ESCALATION_FOLLOWUP",
    background: "bg-brand-100",
  },
  ESCALATION_RESOLVED: {
    icon: (
      <SubframeCore.Icon
        className="text-success-600 text-body-bold font-body-bold"
        name="FeatherCheck"
      />
    ),
    value: "ESCALATION_RESOLVED",
    background: "bg-success-100",
  },
  ESCALATION_DISMISSED: {
    icon: (
      <SubframeCore.Icon
        className="text-neutral-600 text-body-bold font-body-bold"
        name="FeatherX"
      />
    ),
    value: "ESCALATION_DISMISSED",
    background: "bg-neutral-100",
  },
  SURVEY_SENT: {
    icon: (
      <SubframeCore.Icon
        className="text-brand-600 text-body-bold font-body-bold"
        name="FeatherSend"
      />
    ),
    value: "SURVEY_SENT",
    background: "bg-brand-100",
  },
  TERMINATED: {
    icon: (
      <SubframeCore.Icon
        className="text-error-600 text-body-bold font-body-bold"
        name="FeatherUserX"
      />
    ),
    value: "TERMINATED",
    background: "bg-error-100",
  },
  RECOGNITION_SENT: {
    icon: (
      <SubframeCore.Icon
        className="text-body-bold font-body-bold text-brand-700"
        name="FeatherArrowUpRight"
      />
    ),
    value: "RECOGNITION_SENT",
    background: "bg-brand-100",
  },
  RECOGNITION_RECEIVED: {
    icon: (
      <SubframeCore.Icon
        className="text-body-bold font-body-bold text-success-600"
        name="FeatherArrowDownLeft"
      />
    ),
    value: "RECOGNITION_RECEIVED",
    background: "bg-success-100",
  },
  NOTES: {
    icon: (
      <SubframeCore.Icon
        className="text-body-bold font-body-bold text-brand-600"
        name="FeatherPenSquare"
      />
    ),
    value: "NOTES",
    background: "bg-brand-100",
  },
  ESCALATION_IN_PROGRESS: {
    icon: (
      <SubframeCore.Icon
        className="text-body-bold font-body-bold text-brand-700"
        name="FeatherHourglass"
      />
    ),
    value: "ESCALATION_IN_PROGRESS",
    background: "bg-brand-100",
  },
  SURVEY_IN_PROGRESS: {
    icon: (
      <SubframeCore.Icon
        className="text-body-bold font-body-bold text-brand-700"
        name="FeatherHourglass"
      />
    ),
    value: "SURVEY_IN_PROGRESS",
    background: "bg-brand-100",
    showSentiment: true,
  },
};

export const INBOX_TABS = ["At-risk", "In Progress", "Resolved", "Dismissed"];

export const ESCALATION_FOLLOW_UP_OUTCOME = {
  SUCCESSFUL: {
    label: "Resolved",
    value: "SUCCESSFUL",
  },
  ATTENTION_NEEDED: {
    label: "Needs Attention",
    value: "NEEDS_ATTENTION",
  },
  OTHER: {
    label: "Other",
    value: "OTHER",
  },
  DISMISSED: {
    label: "Dismissed",
    value: "DISMISS",
  },
  IN_PROGRESS: {
    label: "In Progress",
    value: "NEEDS_ATTENTION",
  },
};

export const ESCALATION_REASONS = {
  LOW_SENTIMENT_SCORE: {
    label: "Low Sentiment Score",
  },
  INCOMPLETE_SURVEY: {
    label: "Incomplete Survey",
  },
};

export const RECOGNITION_CATEGORY = {
  // Default categories
  CLINICAL_EXCELLENCE: {
    label: "Clinical Excellence",
    value: "CLINICAL_EXCELLENCE",
    icon: "FeatherCheck",
  },
  TEAMWORK: {
    label: "Teamwork",
    value: "TEAMWORK",
    icon: "FeatherLifeBuoy",
  },
  PATIENT_SATISFACTION: {
    label: "Patient Satisfaction",
    value: "PATIENT_SATISFACTION",
    icon: "FeatherUserPlus",
  },
  PERSONAL_IMPROVEMENT: {
    label: "Personal Improvement",
    value: "PERSONAL_IMPROVEMENT",
    icon: "FeatherSmilePlus",
  },
  COMMUNICATION: {
    label: "Communication",
    value: "COMMUNICATION",
    icon: "FeatherMic2",
  },
  OTHER: {
    label: "Other",
    value: "OTHER",
    icon: "FeatherRocket",
  },
  // Graham-specific categories
  PATIENT_CARE: {
    label: "Patient Care",
    value: "PATIENT_CARE",
    icon: "FeatherHeart",
  },
  SERVICE_RECOVERY: {
    label: "Service Recovery",
    value: "SERVICE_RECOVERY",
    icon: "FeatherRefreshCw",
  },
  ABOVE_AND_BEYOND: {
    label: "Above and Beyond",
    value: "ABOVE_AND_BEYOND",
    icon: "FeatherStar",
  },
};

export const RECOGNITION_DIRECTED_AT = {
  SUPERVISOR: {
    label: "Supervisor",
    value: "SUPERVISOR",
  },
  PEER: {
    label: "Peer",
    value: "PEER",
  },
  DIRECT_REPORT: {
    label: "Direct Report",
    value: "DIRECT_REPORT",
  },
};

export const RECOGNITION_TYPE = {
  RECEIVED: "RECEIVED",
  PROVIDED: "PROVIDED",
};

export const SURVEY_TYPE = {
  MULTIPLE_CHOICE: "multiple_choice",
  NUMERICAL: "numerical",
  FREE_RESPONSE: "free_response",
};

export const SIGN_IN_PROVIDERS = {
  GOOGLE: "GOOGLE",
  MICROSOFT: "MICROSOFT",
};

export const ESCALATION_FOLLOW_UP_ENABLED = {
  "Graham Healthcare Group": true,
  "Sample Levo Company": true,
};

export const ESCALATION_FOLLOW_UP_TAGS = {
  "Voicemail Left": {
    label: "Voicemail Left",
    value: "Voicemail Left",
    color: {
      text: "#095748",
      fill: "#D0F7F0",
    },
  },
  "Manager Action Needed": {
    label: "Manager Action Needed",
    value: "Manager Action Needed",
    color: {
      text: "#66244C",
      fill: "#FFDEF2",
    },
  },
  "Escalation Forwarded": {
    label: "Escalation Forwarded",
    value: "Escalation Forwarded",
    color: {
      text: "#B06120",
      fill: "#F5F1C9",
    },
  },
  Other: {
    label: "Other",
    value: "Other",
    color: {
      text: "#3A1E66",
      fill: "#E9DBFF",
    },
  },
};

export const ESCALATION_FOLLOW_UP_NOTE_TAGS = {
  "Low Caseload": {
    label: "Low Caseload",
    value: "Low Caseload",
    color: {
      text: "#262626",
      fill: "#F5F5F5",
    },
  },
  Schedule: {
    label: "Schedule",
    value: "Schedule",
    color: {
      text: "#262626",
      fill: "#F5F5F5",
    },
  },
  "Care Coordination": {
    label: "Care Coordination",
    value: "Care Coordination",
    color: {
      text: "#262626",
      fill: "#F5F5F5",
    },
  },
  Pay: {
    label: "Pay",
    value: "Pay",
    color: {
      text: "#262626",
      fill: "#F5F5F5",
    },
  },
  "CFSS Communication": {
    label: "CFSS Communication",
    value: "CFSS Communication",
    color: {
      text: "#262626",
      fill: "#F5F5F5",
    },
  },
  Documentation: {
    label: "Documentation",
    value: "Documentation",
    color: {
      text: "#262626",
      fill: "#F5F5F5",
    },
  },
  "Access to Training": {
    label: "Access to Training",
    value: "Access to Training",
    color: {
      text: "#262626",
      fill: "#F5F5F5",
    },
  },
  Driving: {
    label: "Driving",
    value: "Driving",
    color: {
      text: "#262626",
      fill: "#F5F5F5",
    },
  },
  "Clinical Growth Opportunity": {
    label: "Clinical Growth Opportunity",
    value: "Clinical Growth Opportunity",
    color: {
      text: "#262626",
      fill: "#F5F5F5",
    },
  },
  Orientation: {
    label: "Orientation",
    value: "Orientation",
    color: {
      text: "#262626",
      fill: "#F5F5F5",
    },
  },
  "High Caseload": {
    label: "High Caseload",
    value: "High Caseload",
    color: {
      text: "#262626",
      fill: "#F5F5F5",
    },
  },
};
