import { DataGrid } from "@mui/x-data-grid";

export default function EmployeeDataGridView({
  rows = [],
  columns = [],
  isLoading = false,
  onRowClick = () => {},
  onSortModelChange = () => {},
}) {
  return (
    <div style={{ height: 350, width: "100%" }}>
      <DataGrid
        rows={isLoading ? [] : rows}
        columns={columns}
        loading={isLoading}
        disableSelectionOnClick
        onRowClick={onRowClick}
        onSortModelChange={onSortModelChange}
        sx={{
          "& .MuiDataGrid-renderingZone": {
            maxHeight: "none !important",
          },
          "& .MuiDataGrid-cell": {
            lineHeight: "unset !important",
            maxHeight: "none !important",
            whiteSpace: "normal",
            paddingTop: "8px",
            paddingBottom: "8px",
          },
          "& .MuiDataGrid-row": {
            maxHeight: "none !important",
          },
        }}
      />
    </div>
  );
}
