/**
 * Checks whether the incoming value is empty or null.
 *
 * @param {String} value
 */
export const isEmpty = (value) => {
  if (typeof value === "string") {
    return !value.trim();
  }
  return !value;
};

/* Creates the acronym from the string passed.
 * For eg: 'Ryan Seek' => 'RS'.
 *
 * @param {String} label
 * @returns {String}
 */
export const getAcronym = (label) => {
  if (isEmpty(label)) {
    return "";
  }

  const word = label.toUpperCase().split(" ");

  return word.length > 1 ? word[0][0] + word[word.length - 1][0] : word[0][0];
};

/**
 * Build supplied string by interpolating properties after delimiter ':' with the given parameters.
 *
 * @param {string} string
 * @param {object} params
 *
 * @returns string
 */

export const interpolate = (string, params = {}) => {
  let formattedString = string;

  for (const [key, value] of Object.entries(params)) {
    const val = value || "";

    formattedString = formattedString.replace(
      new RegExp(":" + key, "gi"),
      val.toString()
    );
  }

  return formattedString;
};

/**
 * Returns the file extension from file link.
 *
 * @param {String} link
 */
export const getFileExtension = (link) => {
  if (isEmpty(link)) {
    return "";
  }

  return link.split(".").pop();
};

/**
 * Gets the selected user's full name.
 *
 * @param {String} firstName
 * @param {String} lastName
 * @returns {String}
 */
export const getFullName = (firstName = "", lastName = "") => {
  return `${isEmpty(firstName) ? "" : firstName}${
    !isEmpty(firstName) && !isEmpty(lastName) ? " " : ""
  }${isEmpty(lastName) ? "" : lastName}`;
};

/**
 * Converts a string to hyphenated lowercase.
 *
 * @param {String} str
 * @returns {String}
 */
export const convertToHyphenatedLowerCase = (str) => {
  const words = str.split(' ');
  const hyphenated = words.map(word => word.toLowerCase()).join('-');

  return hyphenated;
}
