export const EVENT_TYPES = {
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
    RD_VIEW:"RD_VIEW",
    RD_REGION_PANEL_VIEW:"RD_REGION_PANEL_VIEW",
    RD_REGION_BRANCH_PANEL_VIEW:"RD_REGION_BRANCH_PANEL_VIEW",
    RD_REGION_BRANCH_EMPLOYEE_PANEL_VIEW:"RD_REGION_BRANCH_EMPLOYEE_PANEL_VIEW",
    RD_JOB_PANEL_VIEW:"RD_JOB_PANEL_VIEW",
    RD_JOB_CATEGORY_PANEL_VIEW:"RD_JOB_CATEGORY_PANEL_VIEW",
    RD_JOB_CATEGORY_EMPLOYEE_PANEL_VIEW:"RD_JOB_CATEGORY_EMPLOYEE_PANEL_VIEW",
    RD_QUESTIONCATEGORY_PANEL_VIEW:"RD_QUESTIONCATEGORY_PANEL_VIEW",
    RD_QUESTIONCATEGORY_JOB_PANEL_VIEW:"RD_QUESTIONCATEGORY_JOB_PANEL_VIEW",
    RD_QUESTIONCATEGORY_JOB_QUESTION_PANEL_VIEW:"RD_QUESTIONCATEGORY_JOB_QUESTION_PANEL_VIEW",
    RD_QUESTIONCATEGORY_JOB_QUESTION_EMPLOYEE_PANEL_VIEW:"RD_QUESTIONCATEGORY_JOB_QUESTION_EMPLOYEE_PANEL_VIEW",
    RD_TENURE_PANEL_VIEW:"RD_TENURE_PANEL_VIEW",
    RD_TENURE_REGION_PANEL_VIEW:"RD_TENURE_REGION_PANEL_VIEW",
    RD_TENURE_REGION_BRANCH_PANEL_VIEW:"RD_TENURE_REGION_BRANCH_PANEL_VIEW",
    RD_TENURE_REGION_BRANCH_EMPLOYEE_PANEL_VIEW:"RD_TENURE_REGION_BRANCH_EMPLOYEE_PANEL_VIEW",
    RD_TENURE_JOB_PANEL_VIEW:"RD_TENURE_JOB_PANEL_VIEW",
    RD_TENURE_JOB_CATEGORY_PANEL_VIEW:"RD_TENURE_JOB_CATEGORY_PANEL_VIEW",
    RD_TENURE_JOB_CATEGORY_EMPLOYEE_PANEL_VIEW:"RD_TENURE_JOB_CATEGORY_EMPLOYEE_PANEL_VIEW",
    RD_PANEL_SORT:"RD_PANEL_SORT",
    SEARCH_PAGE_NAVIGATION: "SEARCH_PAGE_NAVIGATION",
    INBOX_PAGE_NAVIGATION: "INBOX_PAGE_NAVIGATION",
    REPORTS_PAGE_NAVIGATION: "REPORTS_PAGE_NAVIGATION",
    EMPLOYEES_PAGE_NAVIGATION: "EMPLOYEES_PAGE_NAVIGATION",
    SETTINGS_PAGE_NAVIGATION: "SETTINGS_PAGE_NAVIGATION",
    EMPLOYEE_SEARCH: "EMPLOYEE_SEARCH",
    ESCALATED_EMPLOYEE_CLICK: "ESCALATED_EMPLOYEE_CLICK",
    LOG_FOLLOW_UP_CLICK: "LOG_FOLLOW_UP_CLICK",
    LOG_FOLLOW_UP_SEND: "LOG_FOLLOW_UP_SEND",
    RECOGNITION_CLICK: "RECOGNITION_CLICK",
    RECOGNITION_SEND: "RECOGNITION_SEND",
    REFERRAL_SMS_CLICK: "REFERRAL_SMS_CLICK",
    REFERRAL_SMS_SEND: "REFERRAL_SMS_SEND",
    GO_TO_PROFILE_CLICK: "GO_TO_PROFILE_CLICK",
    EMPLOYEE_LOGIN: "EMPLOYEE_LOGIN",
    EMPLOYEE_SIGNUP: "EMPLOYEE_SIGNUP",
    AGENCY_LOGIN: "AGENCY_LOGIN",
};
