import config from "../../config";

import endpoints from "../../constants/retention/endpoints";

import { levoHttp } from "../../utils/http";
import { create as createQueryParam } from "../../utils/queryParam";

/**
 * Fetch the employee stats by Location.
 *
 * @returns {Promise}
 */
export const fetchStatsByLocation = () => {
  // return [
  //   {
  //     id: 1,
  //     location: "Detroit",
  //     employees: 12,
  //     responseRate: 33,
  //     sentiment: "positive",
  //   },
  //   {
  //     id: 2,
  //     location: "St. Paul",
  //     employees: 12,
  //     responseRate: 33,
  //     sentiment: "negative",
  //   },
  //   {
  //     id: 3,
  //     location: "Homeglory",
  //     employees: 12,
  //     responseRate: 33,
  //     sentiment: "neutral",
  //   },
  // ];

  const url = `${config.authBaseUrl}${endpoints.locationStat}`;

  return levoHttp.get(url);
};

/**
 * Fetch the jobs with employee stats.
 *
 * @param {Object} query
 * @returns {Promise}
 */
export const fetchJobsWithStats = (query = {}) => {
  const param = createQueryParam(query);
  const url = `${config.authBaseUrl}${endpoints.jobStat}${param}`;

  return levoHttp.get(url);
};

/**
 * Fetch the questions with employee stats.
 *
 * @param {Object} query
 * @returns {Promise}
 */
export const fetchQuestionsWithStats = (query = {}) => {
  const param = createQueryParam(query);
  const url = `${config.authBaseUrl}${endpoints.questionStat}${param}`;

  return levoHttp.get(url);
};

/**
 * Fetch the tenures with employee stats.
 *
 * @param {Object} query
 * @returns {Promise}
 */
export const fetchTenuresWithStats = (query = {}) => {
  const param = createQueryParam(query);
  const url = `${config.authBaseUrl}${endpoints.tenureStat}${param}`;

  return levoHttp.get(url);
};

/**
 * Fetch the regions with employee stats.
 *
 * @returns {Promise}
 */
export const fetchRegionsWithStats = (query = {}) => {
  const param = createQueryParam(query);
  const url = `${config.authBaseUrl}${endpoints.regionStat}${param}`;

  return levoHttp.get(url);
};

/**
 * Fetch the branches with employee stats.
 *
 * @returns {Promise}
 */
export const fetchBranchesWithStats = (query = {}) => {
  const param = createQueryParam(query);
  const url = `${config.authBaseUrl}${endpoints.branchStat}${param}`;

  return levoHttp.get(url);
};

/**
 * Fetch the supervisors with employee stats.
 *
 * @returns {Promise}
 */
export const fetchSupervisorsWithStats = (query = {}) => {
  const param = createQueryParam(query);
  const url = `${config.authBaseUrl}${endpoints.supervisorStat}${param}`;

  return levoHttp.get(url);
};

/**
 * Fetch the employees of Region panel.
 *
 * @param {Object} query
 * @returns {Promise}
 */
export const fetchEmployees_region = (query = {}) => {
  const param = createQueryParam(query);
  const url = `${config.authBaseUrl}${endpoints.employeeStat}${param}`;

  return levoHttp.get(url);
};

/**
 * Fetch the job categories with stats.
 *
 * @returns {Promise}
 */
export const fetchCategoriesWithStats = (query = {}) => {
  const param = createQueryParam(query);
  const url = `${config.authBaseUrl}${endpoints.categoryStat}${param}`;

  return levoHttp.get(url);
};

/**
 * Fetch the employees with stats for Job Panel.
 *
 * @param {Object} query
 * @returns {Promise}
 */
export const fetchEmployees_job = (query = {}) => {
  const param = createQueryParam(query);
  const url = `${config.authBaseUrl}${endpoints.jobPanelEmployee}${param}`;

  return levoHttp.get(url);
};

/**
 * Fetch the questions by job with stats.
 *
 * @returns {Promise}
 */
export const fetchQuestionsByJobWithStats = (query = {}) => {
  const param = createQueryParam(query);
  const url = `${config.authBaseUrl}${endpoints.questionByJob}${param}`;

  return levoHttp.get(url);
};

/**
 * Fetch the regions by tenure with stats.
 *
 * @returns {Promise}
 */
export const fetchRegionsByTenureWithStats = (query = {}) => {
  const param = createQueryParam(query);
  const url = `${config.authBaseUrl}${endpoints.regionByTenure}${param}`;

  return levoHttp.get(url);
};

/**
 * Fetch the branches by tenure region with stats.
 *
 * @returns {Promise}
 */
export const fetchBranchesByTenureRegionWithStats = (query = {}) => {
  const param = createQueryParam(query);
  const url = `${config.authBaseUrl}${endpoints.branchByTenureRegion}${param}`;

  return levoHttp.get(url);
};

/**
 * Fetch the employees in Tenure: Region panel.
 *
 * @param {Object} query
 * @returns {Promise}
 */
export const fetchEmployees_tenureRegion = (query = {}) => {
  const param = createQueryParam(query);
  const url = `${config.authBaseUrl}${endpoints.tenureRegionEmployee}${param}`;

  return levoHttp.get(url);
};

/**
 * Fetch the jobs by tenure with stats.
 *
 * @returns {Promise}
 */
export const fetchJobsByTenureWithStats = (query = {}) => {
  const param = createQueryParam(query);
  const url = `${config.authBaseUrl}${endpoints.jobByTenure}${param}`;

  return levoHttp.get(url);
};

/**
 * Fetch the categories by tenure job type with stats.
 *
 * @returns {Promise}
 */
export const fetchCategoriesByTenureJobTypeWithStats = (query = {}) => {
  const param = createQueryParam(query);
  const url = `${config.authBaseUrl}${endpoints.categoryByTenureJobType}${param}`;

  return levoHttp.get(url);
};

/**
 * Fetch the employees in Tenure: Job panel.
 *
 * @param {Query}
 * @returns {Promise}
 */
export const fetchEmployees_tenureJob = (query = {}) => {
  const param = createQueryParam(query);
  const url = `${config.authBaseUrl}${endpoints.tenureJobEmployee}${param}`;

  return levoHttp.get(url);
};

/**
 * Fetch the employees by question category > question with stats.
 *
 * @param {Object} query
 * @returns {Promise}
 */
export const fetchEmployeesByQuestionWithStats = (query = {}) => {
  const param = createQueryParam(query);
  const url = `${config.authBaseUrl}${endpoints.employeeByQuestion}${param}`;

  return levoHttp.get(url);
};

/**
 * Fetch the jobs by question category with stats.
 *
 * @param {Object} query
 * @returns {Promise}
 */
export const fetchJobsByQuestionCategoryWithStats = (query = {}) => {
  const param = createQueryParam(query);
  const url = `${config.authBaseUrl}${endpoints.jobByQuestionCategory}${param}`;

  return levoHttp.get(url);
};

/**
 * Method to fetch the employee's details.
 *
 * @param {Object} query
 * @returns {Promise}
 */
export const fetchEmployee = (query = {}) => {
  const param = createQueryParam(query);
  const url = `${config.authBaseUrl}${endpoints.employeeProfile}${param}`;

  return levoHttp.get(url);
};

/**
 * Fetch the question category stats by employee.
 *
 * @param {Object} query
 * @returns {Promise}
 */
export const fetchQuestionCategoryStatsByEmployee = (query = {}) => {
  const param = createQueryParam(query);
  const url = `${config.authBaseUrl}${endpoints.questionStat}${param}`;

  return levoHttp.get(url);
};

/**
 * Fetch the question stats by question category and employee.
 *
 * @param {Object} query
 * @returns {Promise}
 */
export const fetchQuestionStatsByCategoryAndEmployee = (query = {}) => {
  const param = createQueryParam(query);
  const url = `${config.authBaseUrl}${endpoints.questionByJob}${param}`;

  return levoHttp.get(url);
};

/**
 * Method to fetch the employee survey.
 *
 * @param {Object} query
 * @returns {Promise}
 */
export const fetchEmployeeSurvey = (query = {}) => {
  const param = createQueryParam(query);
  const url = `${config.authBaseUrl}${endpoints.employeeSurvey}${param}`;

  return levoHttp.get(url);
};

/**
 * Method to find the employees.
 *
 * @param {Object} query
 * @returns {Promise}
 */
export const findEmployee = (query = {}) => {
  const param = createQueryParam(query);
  const url = `${config.authBaseUrl}${endpoints.findEmployee}${param}`;

  return levoHttp.get(url);
};

/**
 * Method to fetch list of new hires.
 *
 * @param {Object} query
 * @returns {Promise}
 */
export const fetchNewHires = (query = {}) => {
  const param = createQueryParam(query);
  const url = `${config.authBaseUrl}${endpoints.newHires}${param}`;

  return levoHttp.get(url);
};

/**
 * Method to fetch list of at risks.
 *
 * @param {Object} query
 * @returns {Promise}
 */
export const fetchAtRisks = (query = {}) => {
  const param = createQueryParam(query);
  const url = `${config.authBaseUrl}${endpoints.atRisks}${param}`;

  return levoHttp.get(url);
};

/**
 * Method to fetch list of roster.
 *
 * @param {Object} query
 * @returns {Promise}
 */
export const fetchRoster = (query = {}) => {
  const param = createQueryParam(query);
  const url = `${config.authBaseUrl}${endpoints.roster}${param}`;

  return levoHttp.get(url);
};
