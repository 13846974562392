"use client";
/*
 * Documentation:
 * Custom Checkbox — https://app.subframe.com/library?component=Custom+Checkbox_4b290804-d622-4b4f-884c-4dba34a6eebc
 */

import React from "react";
import * as SubframeCore from "@subframe/core";

interface CustomCheckboxRootProps
  extends React.ComponentProps<typeof SubframeCore.Checkbox.Root> {
  label?: string;
  className?: string;
}

const CustomCheckboxRoot = React.forwardRef<
  HTMLElement,
  CustomCheckboxRootProps
>(function CustomCheckboxRoot(
  { label, className, ...otherProps }: CustomCheckboxRootProps,
  ref
) {
  return (
    <SubframeCore.Checkbox.Root asChild={true} {...otherProps}>
      <div
        className={SubframeCore.twClassNames(
          "group/4b290804 flex cursor-pointer items-center gap-1.5",
          className
        )}
        ref={ref as any}
      >
        <div className="flex h-4 w-4 flex-none flex-col items-center justify-center gap-2 rounded-sm border-2 border-solid border-neutral-300 group-hover/4b290804:border-2 group-hover/4b290804:border-solid group-hover/4b290804:border-neutral-400 group-aria-[checked=true]/4b290804:border group-aria-[checked=true]/4b290804:border-solid group-aria-[checked=true]/4b290804:border-brand-600 group-aria-[checked=true]/4b290804:bg-brand-600">
          <SubframeCore.Icon
            className="hidden text-body font-body text-brand-600 group-aria-[checked=true]/4b290804:inline-flex group-aria-[checked=true]/4b290804:text-white"
            name="FeatherCheck"
          />
        </div>
        {label ? (
          <span className="text-body font-body text-default-font">{label}</span>
        ) : null}
      </div>
    </SubframeCore.Checkbox.Root>
  );
});

export const CustomCheckbox = CustomCheckboxRoot;
