import React, { useCallback, useState } from "react";

import * as SubframeCore from "@subframe/core";

import {
  Box,
  Paper,
  Popper,
  InputBase,
  createTheme,
  Autocomplete,
  ThemeProvider,
  CircularProgress,
} from "@mui/material";

import { isEmpty as isArrayEmpty } from "utils/array";
import { getFullName, isEmpty as isStringEmpty } from "utils/string";

import EmptyState from "../EmptyState";

export default function SearchBarV2({
  isLoading,
  results = [],
  placeholder = "",
  onFilter = () => {},
  renderOption = () => {},
  autoFocus = true,
  listBoxProps = {},
  isNoSearchResultsYetShown = true,
}) {
  const [searchKey, setSearchKey] = useState("");

  const SearchBarPopper = useCallback((props) => {
    const anchorEl = document.getElementById("search-bar-wrapper-id");

    return (
      <Popper
        {...props}
        anchorEl={anchorEl}
        style={{ width: anchorEl.clientWidth }}
        placement="bottom"
        sx={{
          padding: 0,
          boxShadow: "none",
          border: "none",
          outline: "none",
        }}
      />
    );
  }, []);

  const theme = createTheme({
    typography: {
      allVariants: {
        fontFamily: "Nunito",
        boxShadow: "none",
      },
    },
  });

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Box
        id="search-bar-wrapper-id"
        sx={{
          width: "100%",
          "@media (max-width:992px)": {
            maxWidth: "none",
          },
        }}
      >
        <ThemeProvider theme={theme}>
          <Autocomplete
            autoFocus
            openOnFocus
            loading={isLoading}
            ListboxProps={listBoxProps}
            freeSolo
            id="search-bar"
            options={results}
            renderInput={(params) => {
              const { InputLabelProps, InputProps, ...rest } = params;

              return (
                <Paper
                  variant="outlined"
                  component="form"
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <SubframeCore.Icon
                    className="text-subtext-color text-subheader font-subheader mx-1.5"
                    name="FeatherSearch"
                  />
                  <InputBase
                    {...params.InputProps}
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        event.stopPropagation();
                        event.preventDefault();
                      }
                    }}
                    endAdornment={
                      <>
                        {isLoading ? (
                          <CircularProgress
                            color="inherit"
                            size={16}
                            sx={{ margin: "0 var(--spacing-2x)" }}
                          />
                        ) : null}
                      </>
                    }
                    {...rest}
                    sx={{
                      width: "100%",
                    }}
                    placeholder={
                      isStringEmpty(placeholder)
                        ? "Search..."
                        : `${placeholder}`
                    }
                    autoFocus={autoFocus}
                  />
                </Paper>
              );
            }}
            PopperComponent={SearchBarPopper}
            filterOptions={(x) => {
              return x;
            }}
            onInputChange={(event, newInputValue) => {
              setSearchKey(newInputValue);
              onFilter(newInputValue);
            }}
            getOptionLabel={(option) =>
              getFullName(option?.first_name, option?.last_name)
            }
            renderOption={(params, option) => {
              return <div key={option?.id}>{renderOption(option)}</div>;
            }}
          />
        </ThemeProvider>
      </Box>
      {isNoSearchResultsYetShown && isStringEmpty(searchKey) && !isLoading && (
        <Box
          sx={{ background: "pink", width: "100%", display: "flex", flex: 1 }}
        >
          <EmptyState
            icon=<SubframeCore.Icon
              className="text-[44px] font-[100] leading-[44px] text-neutral-400"
              name="FeatherSearch"
            />
            primaryText="No search results yet."
            secondaryText="Please enter the name of the person you're looking for in the search bar above."
          ></EmptyState>
        </Box>
      )}
      {!isStringEmpty(searchKey) && !isLoading && isArrayEmpty(results) && (
        <Box
          sx={{ background: "pink", width: "100%", display: "flex", flex: 1 }}
        >
          <EmptyState
            icon=<SubframeCore.Icon
              className="text-[44px] font-[100] leading-[44px] text-neutral-400"
              name="FeatherSearchX"
            />
            primaryText="No results found."
            secondaryText="Please try again with a different keyword or check for any typos in your search entry."
          ></EmptyState>
        </Box>
      )}
    </Box>
  );
}
